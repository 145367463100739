import React from "react";
import "./DeviceInventory.less";
import { devicesInventory } from "../../utilities/data";

const { device_types } = devicesInventory;

const DeviceGeneralInfoCard = ({ device, selectedDeviceInfo }) => {
  const renderExpandedInfo = () => (
    <div className="expanded-device-info">
      {selectedDeviceInfo.map((info) => (
        <div key={info.key} className="info-row">
          <div className="info-key">{info.key}:</div>
          <div className="info-value">{info.value}</div>
        </div>
      ))}
    </div>
  );

  return (
    <div
      className={`small-device-card ${
        device?.modelnumber &&
        device_types?.smart_camera_devices?.models?.includes(
          device?.modelnumber
        )
          ? "hc200-styled-small-device-card "
          : ""
      }`}
    >
      <div className="general-info-label">General Information</div>
      {renderExpandedInfo()}
    </div>
  );
};

export default DeviceGeneralInfoCard;
