import React from "react";

export const VolumeIcon = (props) => {
    const { className="", style={}, onClick, disabled=false } = props;
    return (
        <svg
            className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
            onClick={disabled ? null: onClick}
            style={style}
            xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
        >
            <path className="stroke" d="M5 6L11 1V19L5 14H3C1.89543 14 1 13.1046 1 12V8C1 6.89543 1.89543 6 3 6H5Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M14 7C14.5 7.5 15 8.5 15 10C15 11.5 14.5 12.5 14 13" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M17 4C18.5 5.5 19 8 19 10C19 12 18.5 14.5 17 16" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}


