import React from "react";

export const SettingsIcon = (props) => {
    const { className="", style={}, onClick, disabled=false } = props;
    return (
        <svg
            className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
            onClick={disabled ? null: onClick}
            style={style}
            width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_374_11273)">
                <path className="stroke" d="M13.8889 4H16.1111C16.7248 4 17.2222 4.49747 17.2222 5.11111V5.7431C17.2222 6.21843 17.5412 6.63139 17.9802 6.81364C18.4194 6.99598 18.9308 6.92649 19.267 6.59026L19.714 6.14323C20.1479 5.70931 20.8514 5.70931 21.2853 6.14323L22.8567 7.71458C23.2907 8.1485 23.2906 8.85201 22.8567 9.28593L22.4098 9.73289C22.0734 10.0691 22.004 10.5805 22.1863 11.0197C22.3686 11.4587 22.7816 11.7778 23.257 11.7778H23.8889C24.5026 11.7778 25 12.2752 25 12.8889V15.1111C25 15.7248 24.5026 16.2222 23.8889 16.2222H23.2569C22.7816 16.2222 22.3687 16.5412 22.1863 16.9802C22.004 17.4194 22.0734 17.9308 22.4098 18.267L22.8567 18.714C23.2907 19.1479 23.2907 19.8514 22.8567 20.2853L21.2853 21.8567C20.8514 22.2907 20.1479 22.2907 19.714 21.8567L19.267 21.4098C18.9308 21.0734 18.4194 21.004 17.9802 21.1863C17.5412 21.3687 17.2222 21.7816 17.2222 22.2569V22.8889C17.2222 23.5026 16.7248 24 16.1111 24H13.8889C13.2752 24 12.7778 23.5026 12.7778 22.8889V22.257C12.7778 21.7816 12.4587 21.3686 12.0197 21.1863C11.5805 21.004 11.0691 21.0734 10.7329 21.4098L10.2859 21.8567C9.85199 22.2907 9.14848 22.2907 8.71457 21.8567L7.14321 20.2853C6.7093 19.8514 6.7093 19.1479 7.14321 18.714L7.59026 18.267C7.92649 17.9308 7.99598 17.4194 7.81364 16.9802C7.63139 16.5412 7.21843 16.2222 6.7431 16.2222H6.11111C5.49747 16.2222 5 15.7248 5 15.1111V12.8889C5 12.2752 5.49747 11.7778 6.11111 11.7778H6.74308C7.21842 11.7778 7.63139 11.4588 7.81366 11.0197C7.996 10.5806 7.92651 10.0692 7.59026 9.73296L7.14323 9.28593C6.70931 8.85201 6.70931 8.1485 7.14323 7.71459L8.71458 6.14323C9.1485 5.70932 9.85201 5.70932 10.2859 6.14323L10.733 6.59026C11.0692 6.92651 11.5806 6.996 12.0197 6.81366C12.4588 6.63139 12.7778 6.21842 12.7778 5.74307V5.11111C12.7778 4.49747 13.2752 4 13.8889 4Z" strokeWidth="1.5"/>
                <path className="stroke" d="M17.2222 13.9999C17.2222 15.2273 16.2273 16.2222 15 16.2222C13.7727 16.2222 12.7778 15.2273 12.7778 13.9999C12.7778 12.7726 13.7727 11.7777 15 11.7777C16.2273 11.7777 17.2222 12.7726 17.2222 13.9999Z" strokeWidth="1.5"/>
            </g>
            <defs>
                <clipPath id="clip0_374_11273">
                    <rect width="30" height="30" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}


