import React from "react";
export const ArrowUpMotion = (props) => {
  const { className = "", style = {} } = props;
  return (
    <svg
      style={style}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.38825 16.3247L11.9976 11.7154L16.607 16.3247C17.216 16.8502 18.1235 16.8502 18.7086 16.2889C19.3535 15.6799 19.3654 14.6529 18.7325 14.032L11.9976 7.28516L5.2627 14.032C4.62981 14.6649 4.65369 15.6799 5.28658 16.2889C5.87171 16.8502 6.77925 16.8502 7.38825 16.3247Z"
        fill="#005D98"
      />
    </svg>
  );
};
