import { Modal } from "antd";
import React from "react";
import CustomButton from "../Common/CustomButton";
import { CloseIcon , ProfileOutlineIcon} from "../Common/Icons";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { translator } from "@commscopemycloud/humaui/Store/configStore";

const UserHubModal = ({ data }) => {
  const trans = useSelector(translator);
  return (
    <Modal 
      centered
      open={true}
      closable={false}
      footer={null}
    >
    <div className="userhubmodalcall"> 
    <div className="image-container">
      <ProfileOutlineIcon image={data.image}/>
    </div>
    <p>{data.devicename}</p>
    <div className="usercustomdivider"></div>       
    <p className="devicestatus">{data.status}</p>
    <CustomButton
        label={trans("DISMISS")}
        icon={<CloseIcon className="cancel-icon"/>}
        onClick={() => data?.cancel(data)}
        />
    </div>
    </Modal>
  );
};

UserHubModal.propTypes = {
  data: PropTypes.object,
};

export default UserHubModal;
