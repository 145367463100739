import React from "react";

export const NewAutoCallIcon = (props) => {
  const { className = "", disabled = false, onClick, style = {} } = props;
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
      onClick={disabled ? null : onClick}
    >
      <circle cx="30" cy="30" r="30" fill="#D9ECFA" />
      <path
        d="M24.35 21.6508C24.55 21.6508 24.85 21.7508 25.05 21.9508C25.85 22.5508 26.45 25.3508 26.25 26.3508C26.15 26.8508 25.65 27.2508 25.15 27.7508C24.95 27.9508 24.85 28.0508 24.65 28.2508C24.35 28.5508 24.25 29.0508 24.45 29.4508C26.05 32.2508 28.35 34.5508 31.15 36.1508C31.35 36.2508 31.45 36.2508 31.65 36.2508C31.8499 36.2508 32.15 36.1508 32.35 35.9508C32.55 35.7508 32.65 35.6508 32.85 35.4508C33.35 34.9508 33.75 34.4508 34.25 34.3508C34.35 34.3508 34.55 34.3508 34.75 34.3508C35.95 34.3508 38.15 34.9508 38.75 35.6508C38.95 35.8508 39.05 36.1508 39.05 36.4508V39.5508C39.05 39.8508 38.9499 40.1508 38.65 40.3508C38.4499 40.5508 38.15 40.6508 37.85 40.6508H37.75C32.95 40.6508 28.55 38.8508 25.25 35.5508C21.95 32.2508 20.15 27.8508 20.15 22.9508C20.15 22.6508 20.25 22.3508 20.45 22.1508C20.65 21.9508 20.95 21.7508 21.25 21.7508L24.35 21.6508ZM24.35 19.0508H21.15C20.15 19.0508 19.15 19.4508 18.45 20.2508C17.75 20.9508 17.45 21.9508 17.45 22.8508C17.45 28.3508 19.55 33.4508 23.25 37.1508C26.95 40.8508 32.05 42.9508 37.45 42.9508C37.55 42.9508 37.5499 42.9508 37.65 42.9508C38.65 42.9508 39.4499 42.6508 40.15 41.9508C40.35 41.7508 40.55 41.4508 40.75 41.2508C41.15 40.6508 41.35 39.9508 41.35 39.3508V36.2508C41.35 35.3508 41.05 34.5508 40.45 33.8508C39.05 32.2508 35.85 31.7508 34.55 31.7508C34.15 31.7508 33.85 31.7508 33.55 31.8508C32.55 32.0508 31.85 32.6508 31.25 33.2508C29.65 32.1508 28.25 30.8508 27.25 29.2508C27.85 28.6508 28.45 27.9508 28.65 26.9508C29.05 25.2508 28.25 21.4508 26.65 20.0508C26.15 19.4508 25.25 19.0508 24.35 19.0508Z"
        fill="#113E57"
      />
      <path
        d="M35.2499 21.9508C34.9499 21.8508 34.7499 21.6508 34.5499 21.4508C34.1499 21.0508 33.7499 20.5508 33.5499 19.9508L33.1499 19.0508L32.7499 19.9508C32.5499 20.5508 32.1499 21.0508 31.7499 21.4508C31.5499 21.6508 31.3499 21.8508 31.0499 21.9508L30.5499 22.3508L31.1499 22.6508C31.4499 22.7508 31.6499 22.9508 31.8499 23.1508C32.2499 23.5508 32.6499 24.0508 32.8499 24.6508L33.2499 25.5508L33.6499 24.6508C33.8499 24.0508 34.2499 23.5508 34.6499 23.1508C34.8499 22.9508 35.0499 22.7508 35.3499 22.6508L35.9499 22.3508L35.2499 21.9508Z"
        fill="#113E57"
      />
      <path
        d="M41.35 25.8512C41.05 25.7512 40.85 25.5512 40.65 25.3512C40.25 24.9512 39.85 24.4512 39.65 23.7512L38.95 21.9512L38.25 23.7512C38.05 24.3512 37.65 24.9512 37.25 25.3512C37.05 25.5512 36.75 25.7512 36.55 25.8512L35.35 26.5512L36.55 27.2512C36.85 27.3512 37.05 27.5512 37.25 27.7512C37.65 28.1512 38.05 28.6512 38.25 29.3512L38.95 31.1512L39.65 29.3512C39.85 28.7512 40.25 28.1512 40.65 27.7512C40.85 27.5512 41.15 27.3512 41.35 27.2512L42.55 26.5512L41.35 25.8512Z"
        fill="#113E57"
      />
    </svg>
  );
};
