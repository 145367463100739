/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import dayjs from "dayjs";
import {
  Divider,
  Form,
  Input,
  TimePicker,
  message,
  Select,
  Spin,
  Modal,
  Tooltip,
} from "antd";
import MainPage from "../../MainPage";
import { useSelector } from "react-redux";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import {
  Actions,
  DateFormat,
  EventTypes,
} from "@commscopemycloud/humaui/Utilities/Constants";
import { getContacts } from "./Contacts";
import ContactSelectList from "../Common/ContactSelectList";
import {
  HSButton,
  HSButtonType,
  HSDatePicker,
  HSDayPicker,
} from "../Common/HSWidget";
import {
  getFormattedDateTime,
  getTimeZoneFormat,
} from "@commscopemycloud/humaui/Utilities/DateTime";
import { getUsername, objectSort } from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import CustomSwitch, { SwitchTypes } from "../Common/CustomSwitch";
import { HSNewRepeatingIcon, NewScheduleIcon } from "../Common/Icons";
import "./AddEvent.less";

/* Sort the EventTypes object by its values */
const SortedEventTypes = Object.keys(EventTypes)
  .map((key) => ({ key, value: EventTypes[key] }))
  .sort(objectSort("value"));

const formatDateTime = (dateMomentObj, hours = 0, minutes = 0) => {
  return moment
    .utc()
    .year(dateMomentObj.year())
    .month(dateMomentObj.month())
    .date(dateMomentObj.date())
    .hours(hours)
    .minutes(minutes)
    .seconds(0)
    .milliseconds(0)
    .format();
};

const FormItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const LocalTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const AddEvent = (props) => {
  console.debug("AddEvent props", props);
  const {
    onlyContent,
    parentBread,
    action,
    hubRecord,
    eventRecord,
    userInfo,
    contacts,
    fetchingContacts,
    staffList,
    fetchingStaffList,
    onActionClick,
    openSubMenu = false,
    showHeader = false,
    selectedUser = "",   
  } = props;

  const trans = useSelector(translator);
  const userApi = useSelector((state) => state.apis.userApi);
  const [loading, setLoading] = useState(true);

  const isEditForm = action === Actions.editEvent;
  const header = (parentBread || []).concat(
    isEditForm
      ? [{
            label: trans("EVENT"),
            onClick: onActionClick(Actions.startEvent, { ...eventRecord, userInfo}),
          },
          {
            label: trans("EDIT")
        }]
      : {
          label: trans("NEWEVENT")
        }
  );
  const scheduleApi = useSelector((state) => state.apis.scheduleApi);

  const [form] = Form.useForm();
  const [processing, setProcessing] = useState(false);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [contactsList, setContactsList] = useState([]);
  const [userContactList, setUserContactList] = useState([]);
  const [isEventReoccuring, setIsEventRecouring] = useState(
    eventRecord?.recurring || false
  );
  const username = useMemo(() => getUsername(hubRecord), [hubRecord]);
 
  const [formData, setFormData] = useState({
    eventname: eventRecord?.event_name ?? "",
    description: eventRecord?.event_description ?? "",
    date: isEditForm
      ? eventRecord?.recurring
        ? dayjs(eventRecord?.display_event_startdate, "YYYY-MM-DD")
        : dayjs(eventRecord?.display_event_date, "YYYY-MM-DD")
      : dayjs(new Date(new Date().setHours(0, 0, 0, 0))),
    time:
      (isEditForm && dayjs(eventRecord?.display_event_time, "HH:mm:ss")) ??
      null,
    eventType: EventTypes[eventRecord?.event_type] ?? EventTypes.schedule,
    url: eventRecord?.meeting_url ?? "",
    enddate:
      (isEditForm && dayjs(eventRecord?.display_event_enddate, "YYYY-MM-DD")) ??
      null,
    duration: eventRecord?.duration_minutes ?? "30",
    reminder: "",
  });
  const [checkedDays, setCheckedDays] = useState(
    isEditForm ? eventRecord?.days_of_week : []
  );
  const [activeTab, setActiveTab] = useState("schedule"); // State to manage the active tab
  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const isSubmitAllowed = useMemo(() => {
    const { time, eventType } = formData;
    const minimumContactsRequired = eventType === EventTypes.schedule ? 2 : 1;

    return Boolean(time) && contactsList.length >= minimumContactsRequired;
  }, [contactsList.length, formData]);
    
  const timeZone = useMemo(
    () => getTimeZoneFormat(userInfo.timezone),
    [userInfo?.timeZone]
  );
  const sortedContactList = useMemo(
    () => contactsList.sort(objectSort("firstname")),
    [contactsList]
  );
  const sortedUserContactList = useMemo(
    () => userContactList.sort(objectSort("firstname")),
    [userContactList]
  );

  /* Function to get UTC offset abbreviation for a time zone */
  const get_utc_offset = (timeZone) => {
    return Intl.DateTimeFormat(navigator.language, {
      timeZoneName: "longOffset",
      timeZone,
    })
      .formatToParts()
      .find((i) => i.type === "timeZoneName").value;
  };
  const timezonedisplay = LocalTimezone + ` (${get_utc_offset(LocalTimezone)})`;

  useEffect(() => {
    let { invited_contacts, userHubsData, useruuid } = eventRecord || {};
    const canInviteList = [...contacts, ...(staffList ?? [])];
    const contactList = !isEditForm
      ? canInviteList.filter((item) => useruuid !== item.useruuid && item.emailVerified === true)
      : canInviteList.filter(
          (item) =>
            !invited_contacts.includes(item.useruuid) &&
            useruuid !== item.useruuid && item.emailVerified === true
        );
    const uniqueContactList = [
      ...new Map(contactList.map((a) => [a.useruuid, a])).values(),
    ];
    setUserContactList(uniqueContactList);
    const list = [];
    if (eventRecord && Object.keys(eventRecord).length > 0) {
      list.push(eventRecord);
    } else {
      list.push(hubRecord);
    }
    const invitedContacts = isEditForm
      ? getContacts(invited_contacts, [
          ...(contacts ?? []),
          ...(userHubsData ?? []),
          ...(userInfo ? [userInfo] : []),
          ...(staffList ?? []),
        ])
      : list;
    setContactsList(invitedContacts);
    if (!fetchingContacts && !fetchingStaffList)
      invitedContacts
        .filter((item) => !("firstname" in item))
        .forEach((item) => {
          fetchData(item.useruuid);
        });
    setLoading(false);
  }, [eventRecord, contacts, staffList, fetchingContacts, fetchingStaffList]);

  const fetchData = (mUseruuid) => {
    const errorCallback = (error) => {
      message.error("Error fetching user information!");
      console.error("Error fetching user information:", error);
    };
    const successCallback = (data) => {
      console.info("UserInfo: fetch result", data);
      setContactsList((prevState) =>
        prevState.map((item) => {
          if (item.useruuid === data?.user?.useruuid) return data.user;
          else return item;
        })
      );
    };
    try {
      if (!mUseruuid) return;
      userApi.getUserById(
        mUseruuid,
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };
  // Reset time when date changes, only if it's for a future event and not in edit mode
  useEffect(() => {
    if (!isEditForm && formData.date && formData.date.isAfter(dayjs(), 'day')) {
      form.setFieldsValue({ time: null });
    }
  }, [formData.date, isEditForm]);  
  
  const disabledPastDate = (current) => {
    return current && current < dayjs().startOf("day");
  };

  const disabledPastCurrentDate = (current) => {
    return current && current <= dayjs().endOf("day");
  };

  const handleAddAllContacts = () => {
    const uniqueContactsToAdd = userContactList.filter(
      (item) => !contactsList.some((selected) => selected.useruuid === item.useruuid)
    );
    setContactsList((prevList) => [...prevList, ...uniqueContactsToAdd]);
    setUserContactList([]); // Clear userContactList after adding
  };
  
  const addContact = (contact) => {
    setContactsList((prevList) => {
      // Check for duplicates using useruuid
      if (!prevList.some((item) => item.useruuid === contact.useruuid)) {
        return [...prevList, contact];
      }
      return prevList; // No change if contact is already in the list
    });
  
    setUserContactList((prevList) =>
      prevList.filter((item) => item.useruuid !== contact.useruuid)
    );
  };
  
  const removeCard = (contact) => {
    setContactsList((prevList) =>
      prevList.filter((item) => item.useruuid !== contact.useruuid)
    );
  
    setUserContactList((prevList) => {
      // Check for duplicates before adding to selection list
      if (!prevList.some((item) => item.useruuid === contact.useruuid)) {
        return [...prevList, contact];
      }
      return prevList; // No change if contact is already in the list
    });
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e?.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleOnChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleOnDayChange = (days) => {
    const lowercaseCheckedValues = days.map((value) => value.toLowerCase());
    setCheckedDays(lowercaseCheckedValues);
  };

  const deleteEvent = () => {
    setCancelConfirm(false);
    const errorCallback = (error) => {
      setProcessing(false);
      message.error("Error deleting Event!");
      console.error("Error deleting Event:", error);
    };
    const successCallback = (data) => {
      setProcessing(false);
      message.success("Event deleted successfully!");
      console.info("Event deleted successfully:", data);
      onActionClick(null, null, activeTab, true)();
    };
    try {
      setProcessing(true);
      eventRecord?.recurring
        ? scheduleApi.cancelAllRecurringEvent(
            eventRecord?.useruuid,
            eventRecord?.schedule_eventuuid,
            apiCallback({
              failCallback: errorCallback,
              errorCallback,
              successCallback,
            })
          )
        : scheduleApi.cancelEvent(
            eventRecord?.useruuid,
            eventRecord?.schedule_eventuuid,
            apiCallback({
              failCallback: errorCallback,
              errorCallback,
              successCallback,
            })
          );
    } catch (error) {
      errorCallback(error);
    }
  };

  const renderSchedule = () => (
    <>
      <div className="form-row form-row-datepicker">
        <div className="form-group">
          <Form.Item label={trans("DATE")} name="date">
            <HSDatePicker
              required={true}
              allowClear={false}
              name="date"
              disabledDate={disabledPastDate}
              defaultValue={formData.date}
              format={DateFormat}
              onDateSelect={(value) => handleOnChange("date", value)}
            />
          </Form.Item>
        </div>
        </div>
        <div className="form-row form-row-datepicker">
        <div className="form-group">
          <Form.Item
            label={trans("TIME")}
            name="time"
            rules={[
              {
                type: "object",
                required: true,
                message: trans("PLEASESELECTTIME"),
              },
            ]}
          >
            <TimePicker
              name="time"
              allowClear={false}
              required={true}
              format="h:mm a"
              size="large"
              style={{ borderColor: "#005d98", height: "45px" }}
              onChange={(value) => handleOnChange("time", value)}
              minuteStep={15}
              suffixIcon={
                <span style={{ color: "black" }}>{timezonedisplay}</span>
              }
              showNow={formData.date && formData.date.isSame(dayjs(), 'day')}
            />
          </Form.Item>
        </div>
      </div>
      </>
  );

  const renderEventForm = () => {
    return (
      <div className="form-container">
        <div className="form-card">
          <div
            style={{ marginLeft: "20px", marginRight: "20px" }}
            className="form-group"
          >
            <Tooltip placement="bottom" title={trans("EVENTNAMELIMIT")}>
              <Form.Item
        label={trans("NAMETHEEVENT")}
        name="eventname"
        rules={[
          {
            required: true,
            message: "Please enter the event name.",
          },
        ]}
      >
        <Input
        className="add-name"
          placeholder="Add a name"
          name="eventname"
          // style={{ width: "35vw" }}
          onChange={(e) => handleInputChange(e)}
          defaultValue={formData.eventname}
          maxLength={45}
        />
      </Form.Item>
    </Tooltip>
    <Form.Item
      label={trans("EVENTTYPE")}
      name="eventType"
      className="form-group-select"
    >
      <Select
        disabled={isEditForm}
        onChange={(e) =>
           handleSelectChange("eventType", e)
        }
        defaultValue={EventTypes.schedule}
        value={formData.eventType}
        name="eventType"
        className="select-type"
      >
        {SortedEventTypes.map((option) => (
          <Select.Option key={option.key} value={option.value}>
            {option.value}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
    {form.getFieldValue("eventType") === EventTypes.zoom && (
            <div className="event-zoom-container">
              <label className="header">{trans("ZOOMMEETINGDETAILS")}</label>
              <div className="zoom-card">
                <div className="form-group">
                  <Form.Item
                    label={trans("ZOOMMEETINGURL")}
                    name="url"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the URL.",
                      },
                      {
                        pattern: /https:\/\/[\w-]*\.?zoom.us\/(j|my)\/[\d\w?=-]+/,
                        message: "Please enter a valid Zoom Meeting URL.",
                      },
                    ]}
                  >
                    <Input
                      placeholder="paste meeting link here"
                      style={{ width: "100%" }}
                      name="url"
                      onChange={(e) => handleInputChange(e)}
                      defaultValue={formData.url}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          )}
            <Tooltip
              placement="bottom"
              title={trans("DESCRIPTIONCHARACTERSLIMIT")}
            >
              <Form.Item label={<span>{trans("DESCRIPTION")} <span className="optional-label"> ({trans("OPTIONAL")})</span></span>} name="description">
                <Input.TextArea
                  placeholder="What is the event about?"
                  name="description"
                  onChange={(e) => handleInputChange(e)}
                  defaultValue={formData.description}
                  className="form-description"
                  maxLength={100}
                />
              </Form.Item>
            </Tooltip>
            <Divider />
          </div>
          {renderSchedule()}
          <div className="form-group flex-row">
            <Form.Item name="isEventReoccuring">
              <CustomSwitch
                onChange={() => setIsEventRecouring((s) => !s)}
                checked={isEventReoccuring}
                disabled={isEditForm}
                switchType={SwitchTypes.normal}
              />
            </Form.Item>
            <label className="repeat-text">
              Repeating
            </label>
             <HSNewRepeatingIcon />
          </div>
          {isEventReoccuring && (
            <div className="form-row form-row-datepicker">
              <div
                className="form-group flex-row"
                style={{ gap: "16px", alignItems: "baseline" }}
              >
                <label>{trans("ON")}</label>
                <Form.Item name="weekday">
                  <HSDayPicker
                    onDayChange={handleOnDayChange}
                    name="weekday"
                    days={checkedDays}
                  />
                </Form.Item>
              </div>
              <div
                className="form-group flex-row"
                style={{
                  marginLeft: "20px",
                  gap: "16px",
                  alignItems: "baseline",
                }}
              >
                <label>{trans("ENDON")}</label>
                <Form.Item name="enddate">
                  <HSDatePicker
                    allowClear={false}
                    name="enddate"
                    defaultText="NO END DATE"
                    defaultValue={formData.enddate}
                    disabledDate={disabledPastCurrentDate}
                    format={DateFormat}
                    onDateSelect={(value) => handleOnChange("enddate", value)}
                  />
                </Form.Item>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const handleSendClick = () => {
    if (!isSubmitAllowed || !formData?.time) {
      return;
    }
    if (isEventReoccuring && checkedDays.length < 1) {
      return;
    }
    form
      .validateFields()
      .then(() => onFormSubmit())
      .catch(() => {});
  };

  const onFormSubmit = () => {
    const errorCallback = (error) => {
      setProcessing(false);
      const msg = `Error ${isEditForm ? "Updating" : "Creating"} Event`;
      message.error(msg);
      console.error(msg, error);
    };
    const successCallback = (data) => {
      setProcessing(false);
      const msg = `Event ${isEditForm ? "Updated" : "Created"}`;
      message.success(msg);
      console.info(msg, data);
      onActionClick(
        isEditForm ? Actions.editEvent : null,
        isEditForm ? eventRecord : null,
        activeTab,
        true
      )();
    };
    try {
      setProcessing(true);
      const eventDate = new Date(formData.time.$d);
      const hours = String(eventDate.getHours()).padStart(2, "0");
      const minutes = String(eventDate.getMinutes()).padStart(2, "0");
      const userUuid = eventRecord ? eventRecord.useruuid : hubRecord.useruuid;
      const opts = {
        createEvent: {
          contacts: contactsList.map(contact => ({ useruuid: contact.useruuid })),
          event_name: formData.eventname,
          event_description: formData.description,
          timezone: timezonedisplay,
          // duration_minutes: formData.duration,
          recurring: isEventReoccuring,
          ...(formData.eventType === EventTypes.zoom
            ? { zoom: { url: formData.url } }
            : formData.eventType === EventTypes.reminder
            ? { event_type: "reminder" }
            : {}),
        },
      };
      if (!isEventReoccuring) {
        const parsedDate = moment(formData.date.$d, "YYYY-MM-DD");
        opts.createEvent.event_starttime = formatDateTime(
          parsedDate,
          hours,
          minutes
        );
        // opts.createEvent.reminder = formData.reminder;
      } else {
        const formattedTime = `${hours}:${minutes}`;
        opts.createEvent.event_time = formattedTime;
        opts.createEvent.days_of_week = checkedDays;
        if (formData.date) {
          opts.createEvent.event_startdate = moment(formData.date.$d).format(
            "YYYY-MM-DD"
          );
        }
        if (formData.enddate && formData.enddate.$D) {
          opts.createEvent.event_enddate = moment(formData.enddate.$d).format(
            "YYYY-MM-DD"
          );
        }
      }
      console.debug("Form Submit, Options:", opts);
      isEditForm
        ? scheduleApi.udpateEvent(
            userUuid,
            eventRecord?.schedule_eventuuid,
            opts,
            apiCallback({
              failCallback: errorCallback,
              errorCallback,
              successCallback,
            })
          )
        : scheduleApi.createEvent(
            userUuid,
            opts,
            apiCallback({
              failCallback: errorCallback,
              errorCallback,
              successCallback,
            })
          );
    } catch (error) {
      errorCallback(error);
    }
  };

  const render = (
    <div className="addevent-container">
      <div className="add-event-header">
        <div className="hub-icon">
          <NewScheduleIcon className="hub-schedule-icon" />
        </div>
      <div className="add-event-title">{isEditForm? trans("EDITEVENT") :"Add Event"}</div>
      </div>
      <Spin size="large" spinning={processing} tip={trans("PROCESSING")}>
        <Form form={form} initialValues={formData} {...FormItemLayout}>
          <div className="add-event-form-container">
          <div className="flex-column">
            <div className="flex-row">
              <div className="participants-container">
                <ContactSelectList
                style={{ width: '100%' }}
                // showRemoveIconCondition= {(c => c.useruuid !== hubRecord?.useruuid)}
                fetchingContacts={fetchingContacts || fetchingStaffList}
                selectedListHeader={`${trans("PARTICIPANTS")}`}
                selectionListHeader={"Add other participants"}
                selectedContactList={sortedContactList}
                selectionContactList={sortedUserContactList}
                onAddClick={(item) => addContact(item)}
                onAddAllContacts={handleAddAllContacts}
                onRemoveClick={(item) => removeCard(item)}
                showRelationship={false}
                selectedUser={selectedUser || username}
                hubRecord={hubRecord}
                className={
                  isEventReoccuring
                    ? formData.eventType === EventTypes.zoom
                      ? 'zoom-reoccuring-class'
                      : 'reoccuring-class'
                    : formData.eventType === EventTypes.zoom
                    ? 'zoom-nonreoccuring-class'
                    : 'nonreoccuring-class'
                }
                />
              </div>
            <div>
              <div>{renderEventForm()}</div>
            </div>
            </div>
            <div className="add-event-form-button-container">
              <Form.Item>
                {isEditForm ? (
                  <>
                    <HSButton
                      type={HSButtonType.cancel}
                      onClick={() => setCancelConfirm(true)}
                    >
                      {" "}
                      {eventRecord?.recurring ? trans("CANCELSERIES_U") : (eventRecord?.event_type === "reminder" ? "CANCEL REMINDER" : trans("CANCELMEETING_U"))}
                    </HSButton>
                    <Modal
                      open={cancelConfirm}
                      centered
                      closable={false}
                      footer={null}
                    >
                      <div className="cancelmeeting">
                        <div className="cancelform">
                          <h4>{trans("AREYOUSURE")}</h4>
                          <p>
                            {(eventRecord?.event_type === "reminder" ? "Are you sure you want to cancel the reminder," : trans("AREYOUSURETOCANCELTHEMEETING"))}
                            <span style={{ fontSize: "14px", fontWeight: 600 }}>
                              {" "}
                              {trans("CHECKUPWITH")}{" "}
                              {eventRecord?.event_name + " "}
                              {trans("AT")}{" "}
                              {getFormattedDateTime(
                                eventRecord?.event_timestamp,
                                timeZone
                              ).format("MM/DD/YY")}
                            </span>
                          </p>
                        </div>
                        <Form.Item>
                          <div className="form-button-cancel">
                            <HSButton
                              className="hsbuttons"
                              onClick={deleteEvent}
                              type={HSButtonType.cancel_the_meeting}
                            >
                              {" "}
                              {eventRecord?.recurring ? trans("CANCELTHESERIES_U") : (eventRecord?.event_type === "reminder" ? "CANCEL THE REMINDER" : trans("CANCELTHEMEETING_U"))}
                            </HSButton>
                            <HSButton
                              className="hsbuttons"
                              onClick={() => setCancelConfirm(false)}
                              type={HSButtonType.keep_the_meeting}
                            >
                              {" "}
                              {eventRecord?.event_type === 'reminder' ? 'KEEP THE REMINDER' : 'KEEP THE MEETING'}
                            </HSButton>
                          </div>
                        </Form.Item>
                      </div>
                    </Modal>
                  </>
                ) : (
                  <HSButton
                    onClick={onActionClick(null, null)}
                    type={HSButtonType.cancel}
                  />
                )}
              </Form.Item>
              <Form.Item>
                <HSButton
                  onClick={isSubmitAllowed ? handleSendClick : () => {}}
                  type={HSButtonType.send}
                  disabled={!isSubmitAllowed || !formData.time || loading}
                />
              </Form.Item>
            </div>
          </div>
          </div>
        </Form>
      </Spin>
    </div>
  );

  return onlyContent ? render : <MainPage header={header} hasSider={showHeader ? false :true} activeKey={activeTab} openSubMenu={openSubMenu} onChange={handleTabChange} hubUserRole={hubRecord?.rolename} noModals={true}>{render}</MainPage>;
};

AddEvent.propTypes = {
  onlyContent: PropTypes.bool,
  parentBread: PropTypes.arrayOf(PropTypes.object),
  action: PropTypes.string,
  eventRecord: PropTypes.object,
  userInfo: PropTypes.object,
  contacts: PropTypes.arrayOf(PropTypes.object),
  fetchingContacts: PropTypes.bool,
  staffList: PropTypes.arrayOf(PropTypes.object),
  fetchingStaffList: PropTypes.bool,
  onActionClick: PropTypes.func,
};

export default AddEvent;
