import React, { useState } from "react";
import PropTypes from "prop-types";
import { AddIcon, CloseIcon, NewContactsIcon } from "./Icons";
import Loading from "./Loading";
import Contact from "./Contact";
import { getUsername } from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import { Tooltip } from "antd";

const getSelectedListStyle = (onlySelected) => {
  return {
    ...(onlySelected ? {} : { maxHeight: "50%" }),
  };
};

const ContactSelectList = ({
  style,
  className,
  fetchingContacts = false,
  displayOnlySelected = false,
  selectedListHeader = "Included Contacts",
  selectionListHeader = "Add Contacts",
  selectedContactList = [],
  selectionContactList = [],
  showAddIcon = true,
  showRemoveIcon = true,
  onAddClick = () => {},
  onRemoveClick = () => {},
  onAddAllContacts = () =>{},
  selectedContactProps = {},
  selectionContactProps = {},
  showRelationship = true,
  showAddIconCondition = () => true,
  showRemoveIconCondition = () => true,
  assignStaffAction = false,
  selectedUser = "",
  hubRecord = {},
  starteventAction = false,
}) => {
  const isEmpty = assignStaffAction ? selectedContactList !== null : (Array.isArray(selectedContactList) && selectedContactList.length !== 0);
  const [showAllContacts, setShowAllContacts] = useState(false);

   // Determine the list of contacts to display
  const displayedContacts = showAllContacts ? selectionContactList : selectionContactList.slice(0, 4);
 
  return (
    <div style={style} className={`contact-selection-list-wrapper ${className ?? ""}`}>
      {/* Add Participants label with close icon */}
      {showAllContacts && (
        <div className="add-participant">
          <span className="add-participant-label">Add other participants</span>
          <span className="remove-expanded-list">
            <CloseIcon
            className="close-icon"
            onClick={() => {
              setShowAllContacts(false); // Revert to showing only 4 contacts
            }}
          /></span>
        </div>
      )}

      {/* Selected List Header */}
      {selectedListHeader && <label className="selected-header">{!showAllContacts?selectedListHeader: "Participants added"}</label>}      
      <div  className={`${ isEmpty ? "selected-list-container" :"selected-list-empty"}`}
      style={getSelectedListStyle(displayOnlySelected)}>
        {selectedContactList?.map((item) => (
          <Contact
            key={"selected_" + item.useruuid}
            className="primary"
            contact={item}
            hubRecord={hubRecord}
            starteventAction={starteventAction}
            actionIcon={
              showRemoveIcon &&
              showRemoveIconCondition(item) && (
                <CloseIcon className="close-icon" onClick={() => onRemoveClick(item)} />
              )
            }
            showRelationship={showRelationship}
            {...selectedContactProps}
          />
        ))}
      </div>

      {!displayOnlySelected && (
        <>          
          {selectionListHeader && <label className="selection-list-header">{selectionListHeader}</label>}
          
          {fetchingContacts ? (
            <Loading />
          ) : (
            <div className="selection-list-container">
              <div className="account-name">
                <NewContactsIcon />
                <span className="username">
                  <span className="username-truncate">
                    <Tooltip title={selectedUser}>{selectedUser}</Tooltip>
                  </span>
                  {"'s contacts"}
                </span>
              </div>
              {displayedContacts.map((item) => (
                <Contact
                  key={"contact_" + item.useruuid}
                  onClick={!item.disabled && (item.onAddIconClick ?? onAddClick)}
                  contact={item}
                  hubRecord={hubRecord}
                  starteventAction={starteventAction}
                  actionIcon={
                    item.addIcon ??
                    (showAddIcon && showAddIconCondition(item) && (
                      <AddIcon className="add-icon" />
                    ))
                  }
                  showRelationship={showRelationship}
                  {...selectionContactProps}
                />
              ))}
              {selectionContactList.length > 4 && !showAllContacts && (
                <div
                  className="contct-list-btn view-more-contacts"
                  onClick={() => setShowAllContacts(true)} // Show all contacts
                >
                  View more contacts
                </div>
              )}
               { !assignStaffAction && (<div className="contct-list-btn add-all-contacts"  onClick={onAddAllContacts}>
                  Add all contacts
                </div>)}
            </div>
          )}
        </>
      )}
    </div>
  );
};

ContactSelectList.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  fetchingContacts: PropTypes.bool,
  displayOnlySelected: PropTypes.bool,
  selectedListHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  selectionListHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  selectedContactList: PropTypes.arrayOf(PropTypes.object),
  selectionContactList: PropTypes.arrayOf(PropTypes.object),
  showAddIcon: PropTypes.bool,
  showRemoveIcon: PropTypes.bool,
  onAddClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  selectedContactProps: PropTypes.object,
  selectionContactProps: PropTypes.object,
  showRelationship: PropTypes.bool,
  onAddAllContacts: PropTypes.func.isRequired,  
};

export default ContactSelectList;
