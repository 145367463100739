import React from "react";

export const NightIcon = () => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11119_9389)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.26087 0.5C7.49565 0.5 7.67826 0.691304 7.67826 0.926087V1.35217H8.09565C8.33044 1.35217 8.51304 1.54348 8.51304 1.77826C8.51304 2.01304 8.32174 2.20435 8.09565 2.20435H7.67826V2.63043C7.67826 2.86522 7.48696 3.05652 7.26087 3.05652C7.03478 3.05652 6.84348 2.86522 6.84348 2.63043V2.20435H6.42609C6.1913 2.20435 6.0087 2.01304 6.0087 1.77826C6.0087 1.54348 6.2 1.35217 6.42609 1.35217H6.84348V0.926087C6.84348 0.691304 7.03478 0.5 7.26087 0.5ZM4.25217 5.2913C4.25217 7.45652 5.9913 9.21304 8.13043 9.21304C8.44348 9.21304 8.74783 9.17826 9.03478 9.1087C9.26087 9.05652 9.47826 9.26522 9.3913 9.48261C8.65217 11.2652 6.91304 12.5087 4.88696 12.5087C2.1913 12.5087 0 10.3 0 7.56957C0 4.83913 2.1913 2.62174 4.88696 2.62174C5.04348 2.62174 5.12174 2.81304 5.02609 2.94348C4.52174 3.62174 4.25217 4.44783 4.25217 5.3V5.2913ZM9.86087 7.53478C9.7913 7.76087 9.47826 7.76087 9.4 7.53478L8.94783 6.14348C8.92174 6.07391 8.86957 6.01304 8.7913 5.98696L7.41739 5.53478C7.1913 5.45652 7.1913 5.14348 7.41739 5.06522L8.7913 4.61304C8.86087 4.58696 8.92174 4.53478 8.94783 4.45652L9.4 3.06522C9.46957 2.83913 9.78261 2.83913 9.86087 3.06522L10.313 4.45652C10.3391 4.52609 10.3913 4.58696 10.4696 4.61304L11.8435 5.06522C12.0696 5.14348 12.0696 5.45652 11.8435 5.53478L10.4696 5.98696C10.4 6.01304 10.3391 6.06522 10.313 6.14348L9.86087 7.53478Z"
          fill="#0072BC"
        />
      </g>
      <defs>
        <clipPath id="clip0_11119_9389">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
