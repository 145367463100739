import React from "react";

export const NewSocialIcon = (props) => {
    const { className = "", style={}, onClick, disabled=false } = props;
    return (
        <svg className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
        onClick={disabled ? null: onClick} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <g clipPath="url(#clip0_9650_11976)">
    <path d="M22.5 0H1.5C0.675 0 0 0.675 0 1.5V18C0 18.825 0.675 19.5 1.5 19.5H6V24L12.1875 19.5H22.5C23.325 19.5 24 18.825 24 18V1.5C24 0.675 23.325 0 22.5 0ZM21.6562 17.025H2.34375V2.53125H21.6562V17.0062V17.025Z" stroke="none"/>
    <path d="M17.8687 6H5.86865V7.5H17.8687V6Z" stroke="none"/>
    <path d="M17.8687 9H5.86865V10.5H17.8687V9Z" stroke="none"/>
    <path d="M13.3687 12H5.86865V13.5H13.3687V12Z" stroke="none"/>
    </g>
    <defs>
    <clipPath id="clip0_9650_11976">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    );
}

// Figma: new event