import React from "react";

export const BodyWeightIcon = (props) => {
  const { className = "", style = {}, disabled = false, onClick } = props;

  return (
    <svg
      className={className}
      style={style}
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="30" cy="30" rx="30" ry="30" fill="#F5F5F5" />
      <path
        d="M39.158 19.2663C40.0247 19.2663 40.7339 19.9754 40.7339 20.8421V38.474C40.7339 39.3407 40.0247 40.0498 39.158 40.0498H19.7752C18.9085 40.0498 18.1994 39.3407 18.1994 38.474V20.8421C18.1994 19.9754 18.9085 19.2663 19.7752 19.2663H39.158ZM39.158 17.2002H19.7752C17.766 17.2002 16.1333 18.8329 16.1333 20.8421V38.474C16.1333 40.4832 17.766 42.1159 19.7752 42.1159H39.158C41.1672 42.1159 42.8 40.4832 42.8 38.474V20.8421C42.8 18.8329 41.1672 17.2002 39.158 17.2002Z"
        fill="#113E57"
      />
      <path
        d="M24.9229 25.845C25.1812 26.2915 25.7634 26.4009 26.1792 26.1032C27.0241 25.4992 28.0615 25.1446 29.1777 25.1446C30.2939 25.1446 31.3926 25.5211 32.2506 26.1601C32.6708 26.4709 33.2574 26.3659 33.52 25.915L34.5443 24.1378C34.7675 23.7526 34.6581 23.2536 34.2904 22.9997C32.8503 22.0148 31.1125 21.437 29.2346 21.437C27.3567 21.437 25.6189 22.0148 24.1788 22.9997C23.8111 23.2536 23.7017 23.7526 23.9249 24.1378L24.9142 25.8494L24.9229 25.845Z"
        fill="#113E57"
      />
    </svg>
  );
};
