import React from "react";
import { DeviceUnpairIcon, NewClearAllHistoryIcon } from "../Common/Icons";
import { useUserRole, hasStaffPrivileges } from "./helpers";
import { getConfirmationMessage } from "./DeviceInventoryModal";

const MedicalDeviceModalInfo = ({
  device,
  performAction,
  actionsData,
  hubRecord,
  hideModal,
  ConfirmationModal,
  isActionModalVisible,
  activeDeviceAction,
  handleDeviceActionClick,
}) => {
  const userRole = useUserRole();

  const handleUnpairMedicalDevice = (device) => {
    let data = {
      ...actionsData.unpair_medical_device,
      deviceUuid: hubRecord?.deviceuuid,
    };
    performAction(data);
    hideModal("unpair_medical_device");
  };

  const handleClearAllHistory = (device) => {
    let data = {
      ...actionsData.unpair_medical_device,
      deviceUuid: hubRecord?.deviceuuid,
    };
    performAction(data);
    hideModal("unpair_medical_device");
  };

  return (
    <>
      {hasStaffPrivileges(userRole) && device?.paired_status !== "UNPAIRED" && (
        <div
          className={`device-actions-container ${
            device?.paired_status === "UNPAIRED" ? "device-details-transparent" : ""
          }`}
        >
          <div className="device-action-block">
            <div className="device-action-command-description">
              <div className="device-action-label">Actions</div>
              <div className="device-action-description-block">
                {/* <div className="medical-action-description-label">
                  <div className="medical-action-title">
                    {`  “Clear all History”`}
                    <span className="medical-action-desc">{` : clear all data  by clicking on the button`}</span>
                  </div>
                </div> */}
                <div className="medical-action-description-label">
                  <div className="medical-action-title">
                    {` “Unpair”`}
                    <span className="medical-action-desc">{` : unpair the sensor by clicking on the unpair button`}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="medical-actions-container">
              {/* <div
                className="clear-all-history-icon-label"
                onClick={() => handleDeviceActionClick("factory_reset_hc200")}
              >
                <span>
                  <NewClearAllHistoryIcon className="new-factory-reset-icon" />
                </span>
                <div className="unpair-action-label">Clear all History</div>
              </div> */}
              <div
                className="device-action-icon-label"
                onClick={() => handleDeviceActionClick("unpair_medical_device")}
              >
                <DeviceUnpairIcon
                  className="unpair-icon"
                  strokeColor="#005d98"
                  fillColor="#0072BC"
                />
                <div className="unpair-action-label">Unpair</div>
              </div>
            </div>
          </div>
        </div>
      )}

      <ConfirmationModal
        visible={isActionModalVisible[activeDeviceAction]}
        title={
          <span className="custom-modal-title">
            {getConfirmationMessage(actionsData[activeDeviceAction]?.title?.toLowerCase())}
          </span>
        }
        description={actionsData[activeDeviceAction]?.description}
        onConfirm={() =>
          activeDeviceAction === "unpair_medical_device"
            ? handleUnpairMedicalDevice(device)
            : handleClearAllHistory(device)
        }
        onCancel={() => hideModal(activeDeviceAction)}
        customLabel={actionsData[activeDeviceAction]?.title}
        customIcon={
          activeDeviceAction === "unpair_medical_device" ? (
            <DeviceUnpairIcon fillColor="#113E57" strokeColor="#113E57" />
          ) : (
            <NewClearAllHistoryIcon />
          )
        }
        activeDeviceAction={activeDeviceAction}
      />
    </>
  );
};

export default MedicalDeviceModalInfo;