import React from "react";
import PropTypes from "prop-types";
import "../../styles/CustomSwitch.less";

export const SwitchTypes = {
  normal: { height: 23, width: 41, handle: 17 },
  iconed: { height: 35, width: 70 },
};

const CustomSwitch = ({
  className,
  style = {},
  checked = false,
  onChange = () => {},
  disabled = false,
  checkedIcon,
  uncheckedIcon,
  switchType = SwitchTypes.iconed,
}) => {
  const { height, width, handle } = switchType;
  return (
    <button
      className={`custom-switch-wrapper ${className ?? ""} ${
        disabled ? "disabled" : ""
      } ${checked ? "checked" : "unchecked"}`}
      type="button"
      role="switch"
      aria-checked={checked}
      onClick={!disabled ? onChange : null}
      style={{ height: `${height}px`, width: `${width}px`, ...style }}
    >
      <div
        className="switch-handle"
        style={{
          height: `${handle ?? height - 4}px`,
          width: `${handle ?? height - 4}px`,
        }}
      >
        {checked && checkedIcon}
        {!checked && uncheckedIcon}
      </div>
    </button>
  );
};
CustomSwitch.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  checkedIcon: PropTypes.node,
  uncheckedIcon: PropTypes.node,
  switchType: PropTypes.object,
};

export default CustomSwitch;
