import React from "react";

export const ContactIcon = (props) => {
    const { className="", style={}, onClick, disabled=false } = props;
    return (
        <svg 
            className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
            onClick={disabled ? null: onClick}
            style={style}
            width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="none"
        >
            <g clipPath="url(#clip0_9650_11928)">
                <path d="M12 0C5.37931 0 0 5.37931 0 12C0 18.6207 5.37931 24 12 24C18.6207 24 24 18.6207 24 12C24 5.37931 18.6207 0 12 0ZM12 21.7931C6.59004 21.7931 2.2069 17.41 2.2069 12C2.2069 6.59004 6.59004 2.2069 12 2.2069C17.41 2.2069 21.7931 6.59004 21.7931 12C21.7931 17.41 17.41 21.7931 12 21.7931Z" stroke="none"/>
                <path d="M8.19913 13.3641C8.96541 13.3641 9.62442 13.0882 10.1761 12.5365C10.7279 11.9848 11.0037 11.3258 11.0037 10.5595C11.0037 9.7932 10.7279 9.13419 10.1761 8.58247C9.62442 8.03074 8.96541 7.75488 8.19913 7.75488C7.43285 7.75488 6.77384 8.03074 6.22212 8.58247C5.67039 9.13419 5.39453 9.7932 5.39453 10.5595C5.39453 11.3258 5.67039 11.9848 6.22212 12.5365C6.77384 13.0882 7.43285 13.3641 8.19913 13.3641Z" stroke="none"/>
                <path d="M19.2337 15.6783C18.2069 15.0653 16.8582 14.7588 15.1877 14.7588C14.4061 14.7588 13.7164 14.8354 13.0728 14.9733C14.5134 15.9695 14.8352 17.2569 14.8352 18.1764V20.1228C16.9195 19.4025 18.636 17.9159 19.6628 16.0002C19.5249 15.8929 19.4023 15.7703 19.2337 15.6783Z" stroke="none"/>
                <path d="M12.2453 15.6783C12.0767 15.571 11.8775 15.4944 11.6936 15.4025C11.4637 15.2952 11.2032 15.2032 10.9426 15.1266C10.5442 15.004 10.1304 14.912 9.67062 14.8507C9.21085 14.7894 8.72043 14.7588 8.19935 14.7588C7.35644 14.7588 6.60548 14.8354 5.93115 14.9887C5.25682 15.1419 4.67445 15.3718 4.1687 15.6783C5.56334 18.6055 8.55184 20.6132 12.0001 20.6132C12.6131 20.6132 13.2108 20.5519 13.7932 20.4293V18.1764C13.7932 17.6553 13.6706 17.1802 13.4101 16.7665C13.1495 16.3527 12.7664 15.9848 12.2607 15.6783H12.2453Z" stroke="none"/>
                <path d="M15.1877 13.3641C15.9539 13.3641 16.6129 13.0882 17.1647 12.5365C17.7164 11.9848 17.9923 11.3258 17.9923 10.5595C17.9923 9.7932 17.7164 9.13419 17.1647 8.58247C16.6129 8.03074 15.9539 7.75488 15.1877 7.75488C14.4214 7.75488 13.7624 8.03074 13.2106 8.58247C12.6589 9.13419 12.3831 9.7932 12.3831 10.5595C12.3831 11.3258 12.6589 11.9848 13.2106 12.5365C13.7624 13.0882 14.4214 13.3641 15.1877 13.3641Z" stroke="none"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_9650_11928">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}

// Figma > contact