import React from "react";

export const BatteryLowIcon = (props) => {
  const {
    className = "",
    style = {},
    disabled = false,
    onClick,
    connectionStatus,
    env = false,
  } = props;
  return (
    <svg
      className={className}
      style={style}
      width="63"
      height="60"
      viewBox="0 0 63 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="30" cy="30" rx="30" ry="30" fill="#F5F5F5" />
      {connectionStatus === "CONNECTED" ? (
        <circle cx="52.5" cy="49.5" r="7.5" fill="#4BD37B" />
      ) : connectionStatus === false ? (
        <circle
          cx="52.5"
          cy="49.5"
          r="7.5"
          stroke="grey"
          strokeWidth="1"
          fill="white"
        />
      ) : (
        <svg>
          <circle
            cx="52.5"
            cy="49.5"
            r="7.5"
            fill="white"
            stroke="grey"
            strokeWidth="1"
          />
          <line x1="49" y1="46" x2="56" y2="53" stroke="grey" strokeWidth="1" />
          <line x1="56" y1="46" x2="49" y2="53" stroke="grey" strokeWidth="1" />
        </svg>
      )}
      <path
        d="M36.7402 15H22.2689C21.574 15 21 15.574 21 16.2689V38.5045C21 42.0997 23.9154 45 27.4955 45H31.4985C35.0937 45 37.994 42.0846 37.994 38.5045V16.2689C37.994 15.574 37.4199 15 36.7251 15H36.7402ZM29.6103 41.5106C27.0121 41.5106 24.9124 39.4109 24.9124 36.8127C24.9124 34.2145 27.0121 32.1148 29.6103 32.1148C32.2085 32.1148 34.3082 34.2145 34.3082 36.8127C34.3082 39.4109 32.2085 41.5106 29.6103 41.5106Z"
        fill="#113E57"
      />
      <path
        d="M29.6405 34.5615C28.4019 34.5615 27.3898 35.5736 27.3898 36.8123C27.3898 38.0509 28.4019 39.063 29.6405 39.063C30.8792 39.063 31.8913 38.0509 31.8913 36.8123C31.8913 35.5736 30.8792 34.5615 29.6405 34.5615Z"
        fill="#113E57"
      />
      <mask
        id="mask0_13571_2999"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="43"
        y="17"
        width="20"
        height="20"
      >
        <rect x="43" y="17" width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_13571_2999)">
        <path
          d="M52.1667 28.667H53.8334V23.667H52.1667V28.667ZM53 32.0003C53.2362 32.0003 53.4341 31.9205 53.5938 31.7607C53.7535 31.601 53.8334 31.4031 53.8334 31.167C53.8334 30.9309 53.7535 30.733 53.5938 30.5732C53.4341 30.4135 53.2362 30.3337 53 30.3337C52.7639 30.3337 52.566 30.4135 52.4063 30.5732C52.2466 30.733 52.1667 30.9309 52.1667 31.167C52.1667 31.4031 52.2466 31.601 52.4063 31.7607C52.566 31.9205 52.7639 32.0003 53 32.0003ZM49.6667 35.3337C49.4306 35.3337 49.2327 35.2538 49.073 35.0941C48.9132 34.9344 48.8334 34.7364 48.8334 34.5003V21.167C48.8334 20.9309 48.9132 20.733 49.073 20.5732C49.2327 20.4135 49.4306 20.3337 49.6667 20.3337H51.3334V18.667H54.6667V20.3337H56.3334C56.5695 20.3337 56.7674 20.4135 56.9271 20.5732C57.0868 20.733 57.1667 20.9309 57.1667 21.167V34.5003C57.1667 34.7364 57.0868 34.9344 56.9271 35.0941C56.7674 35.2538 56.5695 35.3337 56.3334 35.3337H49.6667ZM50.5 33.667H55.5V22.0003H50.5V33.667Z"
          fill="#F03B3B"
        />
      </g>
    </svg>
  );
};
