import React from "react";
export const ArrowDownMotion = (props) => {
  const { className = "", style = {} } = props;
  return (
    <svg
      style={style}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6037 7.59695L12.002 12.2898L7.38836 7.59695C6.78037 7.07417 5.88625 7.07417 5.29017 7.63342C4.64641 8.25345 4.63449 9.299 5.26633 9.93119L12.002 16.8002L18.7377 9.93119C19.3695 9.28684 19.3457 8.25345 18.7138 7.63342C18.1297 7.06202 17.2236 7.06202 16.6156 7.59695H16.6037Z"
        fill="#005D98"
      />
    </svg>
  );
};
