import React from "react";

export const _5 = (props) => {
  const { className = "", style = {} } = props;
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 840 840"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M632.4 520.818C633.1 518.368 634.48 516.648 635.51 514.768C646.2 495.108 653.31 474.238 656.55 452.098C658.76 436.958 658.98 421.758 657.38 406.498C655.22 385.908 649.74 366.288 640.99 347.558C633.45 331.408 623.76 316.688 611.92 303.358C595.66 285.048 576.55 270.528 554.53 259.878C537.01 251.408 518.55 246.008 499.28 243.198C486.99 241.408 474.66 241.148 462.33 241.788C440.38 242.918 419.37 248.238 399.27 257.158C376.38 267.318 356.42 281.578 339.29 299.798C331.74 307.818 324.99 316.488 319.08 325.778C318.1 327.318 317.03 327.968 315.14 327.918C303.97 327.598 292.8 327.358 281.63 327.228C279.17 327.198 279.42 326.428 280.31 324.758C286.97 312.208 294.62 300.308 303.54 289.238C318.65 270.488 336.27 254.498 356.64 241.598C381.56 225.818 408.52 215.378 437.63 210.458C452.45 207.958 467.34 206.728 482.35 207.358C509.34 208.498 535.33 214.178 560.15 224.998C577.9 232.738 594.48 242.458 609.62 254.518C631.23 271.738 649.04 292.258 662.92 316.198C676.69 339.938 685.52 365.408 689.89 392.448C692.38 407.848 692.92 423.378 692 438.888C690.39 466.138 683.84 492.228 672.5 517.108C671.22 519.918 669.71 520.948 666.57 520.888C655.29 520.688 644.02 520.818 632.4 520.818Z"
        fill="#E4C276"
      />
      <path
        d="M198.96 453.709C207.93 453.709 218.93 453.909 229.92 453.669C254.54 453.129 279.17 453.559 303.79 453.099C325.78 452.689 347.77 452.989 369.76 452.599C395.57 452.139 421.38 452.449 447.18 452.009C469.9 451.619 492.62 451.899 515.33 451.499C539.86 451.069 564.4 451.079 588.93 451.059C593.22 451.059 596.89 452.379 600.19 455.109C609.91 463.149 608.82 479.149 598.08 485.739C594.64 487.849 590.76 487.639 587 488.019C585.02 488.219 583.01 488.139 581.01 488.149C545.84 488.369 510.67 488.409 475.51 488.869C450.34 489.199 425.17 488.979 400 489.429C377.01 489.839 354.02 489.519 331.03 489.949C306.04 490.409 281.05 490.059 256.07 490.519C232.54 490.949 209 490.769 185.47 491.009C176.84 491.099 168.21 491.359 159.58 491.369C151.07 491.379 144.23 486.759 141.24 479.239C136.76 467.999 145.06 454.969 157.15 454.289C170.4 453.559 183.68 454.099 198.96 453.709Z"
        fill="#808080"
      />
      <path
        d="M121.42 342.17C155.04 342.17 188.65 341.93 222.27 342.26C244.35 342.47 266.43 342.27 288.5 342.73C312.66 343.23 336.84 342.83 361 343.32C372.08 343.54 383.17 343.32 394.24 343.68C401.61 343.92 407.34 347.38 410.62 354.05C413.73 360.38 413.32 366.8 409.3 372.67C405.48 378.24 399.94 380.84 393.22 380.77C376.51 380.61 359.79 380.66 343.08 380.35C323.46 379.99 303.82 380.32 284.2 379.99C259.85 379.57 235.5 379.91 211.16 379.43C187.9 378.97 164.64 379.4 141.39 378.87C124.49 378.49 107.6 378.7 90.7 378.47C84.19 378.38 78.84 376.11 74.95 370.81C70.81 365.17 69.73 359.03 72.81 352.63C76.04 345.91 81.49 341.74 89.01 341.49C99.81 341.14 110.63 341.4 121.45 341.4C121.42 341.67 121.42 341.92 121.42 342.17Z"
        fill="#808080"
      />
      <path
        d="M260.37 572.279C255.93 572.449 251.48 572.689 247.03 572.259C238.09 571.399 230.6 564.029 230.19 555.429C229.71 545.409 236 537.289 245.53 535.609C247.87 535.199 250.22 535.129 252.58 535.119C293.75 535.069 334.92 535.219 376.08 534.879C416.43 534.549 456.77 534.559 497.11 534.559C504.77 534.559 511 537.579 514.94 544.179C522.09 556.159 513.76 570.949 499.71 571.519C497.53 571.609 495.35 571.579 493.17 571.599C420.57 572.189 347.96 572.129 275.35 572.259C270.35 572.289 265.36 572.279 260.37 572.279Z"
        fill="#808080"
      />
      <path
        d="M626.25 582.399C585.19 621.139 536.79 642.249 480.65 643.919C420.3 645.709 368.06 624.999 323.47 584.129C324.6 583.289 325.44 583.569 326.2 583.569C342.74 583.549 359.28 583.569 375.82 583.519C377.71 583.509 379.35 583.929 380.98 584.879C401.77 596.999 424.06 604.779 447.89 608.239C457.25 609.599 466.65 610.409 476.08 610.219C508.94 609.559 539.67 601.179 568 584.359C570.09 583.119 572.16 582.519 574.63 582.449C590.71 581.949 606.78 582.049 622.86 581.949C623.82 581.929 624.82 581.659 626.25 582.399Z"
        fill="#E4C276"
      />
      <path
        d="M616.85 533.54C634.84 533.54 652.83 533.52 670.83 533.55C679.66 533.57 686.49 541.35 687.76 549.21C688.48 553.67 687.81 557.9 685.54 561.75C681.76 568.18 675.8 570.94 668.53 570.97C656.54 571.03 644.54 570.94 632.55 570.94C608.74 570.94 584.93 571 561.12 570.93C554.42 570.91 548.64 568.58 544.7 562.87C540.71 557.1 540.16 550.82 543.11 544.53C545.91 538.55 550.86 535.04 557.45 534.07C560.33 533.65 563.23 533.58 566.14 533.59C583.04 533.62 599.95 533.61 616.85 533.61C616.85 533.58 616.85 533.56 616.85 533.54Z"
        fill="#808080"
      />
      <path
        d="M476.97 344.12C484.88 344.12 493.14 344.04 501.4 344.14C510.8 344.26 518.79 351.21 519.75 359.92C520.83 369.79 515.09 378.12 505.43 380.42C503.16 380.96 500.76 381.13 498.42 381.15C486.53 381.28 474.63 381.37 462.74 381.34C457.21 381.32 451.71 382.09 446.16 381.38C436.29 380.12 428.87 372.11 429.43 362.51C429.99 352.89 436.33 345.51 447.22 344.68C457 343.93 466.81 344.47 476.97 344.12Z"
        fill="#808080"
      />
      <path
        d="M84.2 491.39C76.31 491 68.4 491.96 60.53 490.91C52.21 489.79 45.27 482.22 44.85 473.81C44.41 465.02 50.29 456.99 58.63 454.89C61.29 454.22 63.96 453.93 66.71 453.94C77.89 454.01 89.06 453.92 100.24 454.04C104.3 454.08 108.41 454.12 112.25 455.9C120.18 459.58 124.38 467.03 123.23 475.58C122.23 483.05 115.86 489.51 107.87 490.89C101.38 492.01 94.81 491.2 88.29 491.4C86.93 491.43 85.56 491.39 84.2 491.39Z"
        fill="#808080"
      />
      <path
        d="M234.05 635.449C233.92 628.839 236.67 624.169 241.31 620.439C241.94 619.929 242.63 619.469 243.21 618.899C252.44 609.889 261.68 600.899 270.87 591.849C277.46 585.359 285.64 583.699 292.94 587.499C301.16 591.779 304.31 603.139 299.46 611.049C298.08 613.309 296.4 615.319 294.44 617.109C284.07 626.629 273.9 636.359 264.09 646.449C259.37 651.299 253.7 652.809 247.22 651.489C240.21 650.069 233.83 642.629 234.05 635.449Z"
        fill="#E4C276"
      />
      <path
        d="M223.61 222.688C223.74 214.728 227.73 209.288 234.94 206.438C241.53 203.828 247.63 205.408 252.81 210.138C263.54 219.928 274.7 229.208 285.89 238.438C292.4 243.808 294.8 251.838 291.82 259.378C288.39 268.058 277.97 272.128 269.37 268.378C264.89 266.428 261.74 262.888 258.14 259.878C248.76 252.018 239.66 243.808 230.06 236.198C225.8 232.808 223.54 228.278 223.61 222.688Z"
        fill="#E4C276"
      />
      <path
        d="M708.99 423.418C709.01 414.168 715.72 406.728 724.69 406.388C733.56 406.058 742.45 406.388 751.33 406.398C758.5 406.408 765.67 406.318 772.84 406.368C781.91 406.418 789.65 414.088 789.61 423.258C789.56 432.858 782.83 440.468 773.98 440.608C761.94 440.808 749.88 440.928 737.84 440.288C735.58 440.168 733.33 440.208 731.05 440.428C722.17 441.288 714.1 438.268 710.37 430.198C709.33 427.928 708.88 425.548 708.99 423.418Z"
        fill="#E4C276"
      />
      <path
        d="M636.5 244.12C636.48 237.48 640.02 232.68 644.68 228.5C650.5 223.29 655.82 217.59 661.29 212.02C666.31 206.91 670.94 201.38 676.6 196.96C682.26 192.54 690.34 192.94 696.8 197.58C702.23 201.48 704.8 209.61 702.77 216.03C701.67 219.5 699.73 222.24 697.06 224.75C687.47 233.77 678.15 243.05 669.18 252.69C665.48 256.67 661.48 260.01 655.78 260.85C645.52 262.36 636.5 254.63 636.5 244.12Z"
        fill="#E4C276"
      />
      <path
        d="M199.19 439.558C189.2 439.558 179.21 439.358 169.23 439.648C164.61 439.778 160.27 439.658 156.17 437.228C150.06 433.608 146.75 425.648 148.52 418.428C150.35 411.008 156.99 405.438 164.31 405.328C169.94 405.248 175.57 405.378 181.21 405.298C191.38 405.168 201.55 404.838 211.72 404.818C219 404.798 225.61 409.768 227.86 416.528C231.23 426.638 224.67 437.018 214.14 438.548C209.15 439.258 204.16 438.758 199.19 439.558Z"
        fill="#E4C276"
      />
      <path
        d="M453.24 155.389C453.24 145.049 453.35 134.699 453.19 124.359C453.12 119.629 452.94 115.039 455.4 110.689C458.61 105.009 466.39 101.499 472.84 102.469C483.57 104.089 489.11 114.969 487.25 123.079C487.05 123.949 487.13 124.889 487.12 125.789C487.09 138.499 487.07 151.209 487.04 163.919C487.02 173.089 481.99 180.249 474.17 182.279C463.48 185.049 453.12 177.339 452.74 166.269C452.61 162.639 452.72 159.009 452.72 155.379C452.89 155.389 453.06 155.389 453.24 155.389Z"
        fill="#E4C276"
      />
      <path
        d="M484.32 701.468C484.66 709.438 484.2 717.408 484.75 725.398C485.32 733.648 480.39 740.288 473.69 742.588C465.82 745.298 458.82 743.328 453.67 736.828C450.73 733.108 450.09 728.598 450.3 723.988C450.62 716.828 450.38 709.678 450.27 702.518C450.13 693.918 449.04 685.228 450.58 676.718C452.18 667.848 459.22 662.848 468.31 663.188C475.99 663.478 482.6 669.588 483.82 677.828C484.98 685.668 483.83 693.598 484.32 701.468Z"
        fill="#E4C276"
      />
      <path
        d="M152.59 572.529C146.71 572.339 140.78 573.399 134.92 572.209C126.49 570.499 120.15 562.799 120.15 554.319C120.15 545.789 126.4 538.019 134.73 536.219C136.06 535.929 137.42 535.669 138.76 535.649C150.66 535.459 162.56 535.199 174.46 535.209C185.8 535.209 193.48 542.269 193.93 553.599C194.23 561.049 191.03 567.639 182.11 570.949C176.82 572.919 171.33 572.029 165.94 572.459C161.52 572.809 157.04 572.529 152.59 572.529Z"
        fill="#808080"
      />
      <path
        d="M714.01 623.79C713.74 630.63 710.48 636.05 704.04 639.08C697.02 642.38 689.83 640.74 684.46 635.71C674.2 626.1 663.82 616.62 652.92 607.74C645.32 601.54 642.92 591.99 647.18 584.16C648.18 582.32 649.43 581.39 651.69 581.39C658.31 581.39 664.95 581.71 671.56 581.04C673.88 580.8 675.64 581.71 677.4 583.3C687.43 592.35 697.34 601.54 707.78 610.13C711.92 613.53 713.98 618.04 714.01 623.79Z"
        fill="#E4C276"
      />
      <path
        d="M272.72 437.49C267.82 437.49 262.92 437.44 258.01 437.52C256.35 437.55 255.83 436.98 255.8 435.33C255.62 422.45 255.68 409.58 257.47 396.78C257.72 394.98 258.43 394.35 260.27 394.37C269.98 394.48 279.7 394.52 289.41 394.45C291.51 394.44 292.18 395.11 291.84 397.13C289.75 409.79 289.32 422.55 289.73 435.35C289.78 436.94 289.17 437.41 287.68 437.39C282.69 437.34 277.7 437.37 272.7 437.37C272.72 437.42 272.72 437.45 272.72 437.49Z"
        fill="#E4C276"
      />
      <path
        d="M289.58 525.23C288.85 525.23 288.1 525.1 287.41 525.25C281.31 526.61 277.91 523.98 276.04 518.21C274.71 514.09 272.65 510.21 270.97 506.21C270.45 504.97 269.95 503.81 272.25 503.76C282.75 503.57 293.26 503.3 303.75 502.94C305.73 502.87 306.85 503.36 307.72 505.27C310.43 511.2 313.54 516.94 317.04 522.44C317.98 523.92 318.07 524.61 315.94 524.59C307.15 524.53 298.36 524.56 289.57 524.56C289.58 524.79 289.58 525.01 289.58 525.23Z"
        fill="#E4C276"
      />
    </svg>
  );
};
