/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import useContacts from "../Hooks/useContacts";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import useUserInfo from "../Hooks/useUserInfo";
import { DashboardIcon } from "../Common/Icons";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { useLocation } from "react-router-dom";
import ListCallActivity from "./ListCallActivity.component";
import useNotification from "../Hooks/useNotification";
import useSchedule from "../Hooks/useSchedule";

const CallActivity = (props) => {
  console.debug("CallActivity props", props);
  const {
    onlyContent,
    parentBread,
    hubRecord,
    eventRecord,
    userHubsData,
    rolename,
    onActionClick: parentActionClick,
    timezone,
    loginUserTimeformat,
  } = props;
  const trans = useSelector(translator);
  const currentUser = useSelector(getCurrentUser);

  const useruuid = hubRecord?.useruuid;
  const [contacts, fetchingContacts] = useContacts({ useruuid });
  const [userInfo] = useUserInfo({ useruuid });
  const [action, setAction] = useState(props.action);
  const [record, setRecord] = useState(eventRecord);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [fetchingScheduleList, scheduleList, fetchSchedule] = useSchedule();
  const [notifications, fetchingNotifications, fetchNotifications] =
    useNotification({ useruuid, callhistory: true });
  useEffect(() => setAction(props.action), [props.action]);
  useEffect(() => setRecord(props.eventRecord), [props.eventRecord]);
  const initalRender = useRef(true);

  useEffect(() => {
    if (selectedDate) {
      fetchSchedule(useruuid, selectedDate, 7);
      const today = new Date().toDateString();
      const selectdate = new Date(selectedDate);
      if (!initalRender.current || selectdate.toDateString() !== today) {
        fetchNotifications(useruuid, selectedDate, 7);
      }
      initalRender.current = false;
    }
  }, [selectedDate]);

  let { state } = useLocation();
  useEffect(() => {
    if (state != null) {
      state.action && setAction(state.action);
      state.eventRecord && setRecord(state.eventRecord);
    }
  }, [state]);

  const onActionClick =
    (action, record, refresh = false) =>
    () => {
      setAction(action);
      setRecord(record);
      refresh &&
        fetchSchedule(useruuid, selectedDate, 7) &&
        fetchNotifications(useruuid, selectedDate, 7);
    };

  // const removeTimeZone = (str) => {
  //   if (!str) return null;
  //   const index = str.indexOf("(");
  //   if (index !== -1) {
  //     return str.substring(0, index).trim();
  //   }
  //   return str;
  // };

  const header = (parentBread || []).concat(
    parentActionClick
      ? {
          label: " Call Activity",
          onClick: parentActionClick(null, null),
        }
      : {
          label: trans("DASHBOARD"),
          icon: <DashboardIcon />,
          onClick: onActionClick(null, null),
        }
  );

  return !action ? (
    <ListCallActivity
      hubRecord={hubRecord}
      parentBread={header}
      onlyContent={onlyContent}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
      contacts={contacts}
      userInfo={userInfo}
      notifications={notifications}
      fetchingNotifications={fetchingNotifications}
      scheduleList={scheduleList}
      fetchingScheduleList={fetchingScheduleList}
      onActionClick={parentActionClick || onActionClick}
      rolename={rolename}
      timezone={timezone ? timezone.split(" ")[0] : ""}
      loginUserTimeformat={loginUserTimeformat}
    />
  ) : null;
};

CallActivity.defaultProps = {
  onlyContent: false,
  parentBread: [],
};

CallActivity.propTypes = {
  onlyContent: PropTypes.bool,
  parentBread: PropTypes.arrayOf(PropTypes.object),
};

export default CallActivity;
