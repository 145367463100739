/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { message } from "antd";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { storeUserHubs } from "@commscopemycloud/humaui/Store/dataStore";

const useUserHubs = ({ useruuid }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [fetchingData, setFetchingData] = useState(false);

  const trans = useSelector(translator);
  const userApi = useSelector((state) => state.apis.userApi);

  const fetchData = () => {
    const errorCallback = (error) => {
      setFetchingData(false);
      message.error("Error fetching user hubs!");
      console.error("Error fetching user hubs:", error);
      setData([]);
    };
    const successCallback = (data) => {
      setFetchingData(false);
      console.info("UserHubs: fetch result", data);
      dispatch(storeUserHubs({ userHubs: data?.userhubs, useruuid }));
      setData(data?.userhubs ?? []);
    };
    try {
      if (!useruuid) return;
      setFetchingData(true);
      userApi.getUserHubsById(
        useruuid,
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  useEffect(() => fetchData(), [useruuid]);

  return [data, fetchingData, fetchData];
};

export default useUserHubs;
