import React, { useMemo } from "react";
import {
  NewRestartIcon,
  NewFactoryResetIcon,
  NewCheckForUpdatesIcon,
  UnpairedIcon,
  NewDoneIcon,
} from "../Common/Icons";
import { useUserRole, hasStaffPrivileges } from "./helpers";
import NetworkingDetailsBlock from "./NetworkingDetailsBlock";
import HC200icon from "../Common/Icons/HC200icon.svg";
import { getConfirmationMessage } from "./DeviceInventoryModal";

const HC200ModalInfo = ({
  device,
  hubRecord,
  performAction,
  actionsData,
  isTVDevice,
  hideModal,
  ConfirmationModal,
  isActionModalVisible,
  activeDeviceAction,
  handleDeviceActionClick,
}) => {
  const userRole = useUserRole();
  const allowManageAction = useMemo(
    () =>
      hasStaffPrivileges(userRole) ||
      ["manage", "care"].includes(hubRecord?.rolename?.toLowerCase()),
    [userRole, hubRecord]
  );

  const allowFactoryReset = useMemo(
    () =>
      hasStaffPrivileges(userRole) &&
      hubRecord?.rolename?.toLowerCase() === "manage",
    [userRole, hubRecord]
  );

  const showActionButtons =
    (allowManageAction || hasStaffPrivileges(userRole)) &&
    device?.paired_status !== "UNPAIRED";

  const handleRestart = () => {
    const data = {
      ...actionsData.restart_hc200,
      deviceUuid: device.deviceuuid,
    };
    performAction(data);
    hideModal("restart_hc200");
  };

  const handleFactoryReset = () => {
    const data = {
      ...actionsData.factory_reset_hc200,
      deviceUuid: device.deviceuuid,
    };
    performAction(data);
    hideModal("factory_reset_hc200");
  };

  const handleCheckForUpdates = () => {
    const data = {
      ...actionsData.check_for_updates_hc200,
      deviceUuid: device.deviceuuid,
    };
    performAction(data);
    hideModal("check_for_updates_hc200");
  };

  // Define the constant map
  const tvConfigMap = isTVDevice
    ? [
        {
          key: "Manufacturer",
          value: isTVDevice?.device_manufacture_name || "-",
        },
        { key: "Model", value: isTVDevice?.device_tv_model || "-" },
        {
          key: "Connected to",
          value: !!isTVDevice?.device_connected_hdmi_port
            ? `HDMI-${isTVDevice.device_connected_hdmi_port}`
            : "-",
        },
        { key: "Resolution", value: isTVDevice?.device_resolution || "-" },
        { key: "CEC", value: isTVDevice?.device_cec_status || "-" },
        {
          key: "Control mode",
          value:
            !isTVDevice?.device_learning_type ||
            isTVDevice?.device_learning_type === "NONE"
              ? "Not set"
              : `${isTVDevice?.device_learning_type} (${isTVDevice?.device_preferred_device_type})`,
        },
      ]
    : [];

  return (
    <>
      <div className="hc200-container-row-1">
        {showActionButtons && (
          <div className="actions-row">
            <div className="hc200-container">
              <div className="hc200-actions-block">
                <div className="device-action-label">Actions</div>
                <div className="action-description-container">
                  {allowFactoryReset && (
                    <div className="action-description-row">
                      <span className="device-action-row-label">{` “Factory Reset” `}</span>
                      <span className="device-action-row-description">
                        : initiates a Factory Reset by clicking on the button
                      </span>
                    </div>
                  )}
                  {allowManageAction && (
                    <div className="action-description-row">
                      <span className="device-action-row-label">{` “Restart” `}</span>
                      <span className="device-action-row-description">
                        : restart the Smart Camera by clicking on the button
                      </span>
                    </div>
                  )}
                    <div className="action-description-row">
                      <span className="device-action-row-label">{` “Check for Updates” `}</span>
                      <span className="device-action-row-description">
                        : perform check for updates by clicking on the button
                      </span>
                    </div>
                </div>
              </div>
              <div className="action-bottom-buttons">
                <div className="action-buttons">
                  {allowFactoryReset && (
                    <span>
                      <div
                        onClick={() =>
                          handleDeviceActionClick("factory_reset_hc200")
                        }
                      >
                        <div className="factory-reset-icon-label">
                          Factory reset
                        </div>
                      </div>
                    </span>
                  )}
                  <div className="button-container">
                    <div
                      className="button-block"
                      style={{
                        visibility: allowManageAction ? "visible" : "hidden",
                        display: allowManageAction ? "block" : "none",
                      }}
                    >
                      <span className="check-for-updates-label">
                        <div
                          className="device-action-icon-label"
                          onClick={() =>
                            handleDeviceActionClick("check_for_updates_hc200")
                          }
                        >
                          <NewCheckForUpdatesIcon className="new-check-for-updates-icon" />
                          <div className="check-for-updates-label">
                            Check for Updates
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                  {allowManageAction && (
                    <span>
                      <div
                        className="custom-yes-button"
                        onClick={() => handleDeviceActionClick("restart_hc200")}
                      >
                        <NewRestartIcon className="new-restart-icon" />
                        <div className="hc200-action-label">Restart</div>
                      </div>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="hc200-container-row-2">
          <div className="hc200-column-1">
            <div className="hc200-actions-container-column-1">
              <div className="hc200-networking-container">
                <div className="hc200-networking-block">
                  <div className="networking-label">
                    <span className="nw-label">Networking</span>
                  </div>
                  <NetworkingDetailsBlock
                    WANType={device?.device_primary_wan}
                    device={device}
                  />
                </div>
              </div>
              <div className="hc200-tv-container">
                <div className="hc200-tv-block">
                  <div className="tv-label">
                    <span className="hc200-tv-label">TV information</span>
                  </div>
                  <div className="tv-details-container">
                    <div className="done-block">
                      {isTVDevice?.device_learning_type === null ||
                      isTVDevice?.device_learning_type === undefined ||
                      isTVDevice?.device_learning_type === "NONE" ? (
                        <UnpairedIcon label="Not Done" />
                      ) : (
                        <NewDoneIcon label="Done" />
                      )}
                    </div>
                    {tvConfigMap.map((item, index) => (
                      <div key={index} className="tv-info-row">
                        <span className="tv-info-key">{item.key}:</span>
                        <span className="tv-info-value"> {item.value}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hc200-column-2">
            <div className="hc200-block">
              <div className="device-model-label">
                {`HomeSight Smart Camera (Model ${device?.modelnumber ? device?.modelnumber : "-"})`}
              </div>
              <div className="hc200-device-icon">
                <img src={HC200icon} />
              </div>
            </div>
          </div>
        </div>

        <ConfirmationModal
          visible={isActionModalVisible[activeDeviceAction]}
          title={
            <span className="custom-modal-title">
              {getConfirmationMessage(actionsData[activeDeviceAction]?.title?.toLowerCase())}
            </span>
          }
          description={actionsData[activeDeviceAction]?.description}
          onConfirm={() =>
            activeDeviceAction === "restart_hc200"
              ? handleRestart(device)
              : activeDeviceAction === "check_for_updates_hc200"
              ? handleCheckForUpdates(device)
              : handleFactoryReset(device)
          }
          onCancel={() => hideModal(activeDeviceAction)}
          customIcon={
            activeDeviceAction === "restart_hc200" ? (
              <NewRestartIcon strokeColor="#113E57" />
            ) : activeDeviceAction === "check_for_updates_hc200" ? (
              <NewCheckForUpdatesIcon strokeColor="#113E57" />
            ) : null
          }
          customLabel={actionsData[activeDeviceAction]?.title}
        />
      </div>
    </>
  );
};

export default HC200ModalInfo;
