import React from "react";

export const LatestActionIcon = (props) => {
    const { className = "", style = {} } = props;
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.5675 4.51407C16.2623 4.22576 15.8976 4.08161 15.4652 4.08161H14.685V3.37779C14.685 2.94532 14.3374 2.59766 13.9049 2.59766C13.4724 2.59766 13.1248 2.94532 13.1248 3.37779V4.08161H12.0902V3.37779C12.0902 2.94532 11.7426 2.59766 11.3101 2.59766C10.8777 2.59766 10.53 2.94532 10.53 3.37779V4.08161H9.49546V3.37779C9.49546 2.94532 9.14779 2.59766 8.71532 2.59766C8.28286 2.59766 7.93519 2.94532 7.93519 3.37779V4.08161H6.90067V3.37779C6.90067 2.94532 6.553 2.59766 6.12053 2.59766C5.68807 2.59766 5.3404 2.94532 5.3404 3.37779V4.08161H4.56027C4.13628 4.08161 3.76317 4.22576 3.4579 4.51407C3.15263 4.80238 3 5.15005 3 5.55707V15.9278C3 16.3348 3.15263 16.6825 3.4579 16.9708C3.76317 17.2591 4.1278 17.4032 4.56027 17.4032H10.8607H15.4228C16.2962 17.4032 17 16.6994 17 15.826V5.55707C17 5.15005 16.8474 4.80238 16.5421 4.51407H16.5675ZM15.4652 15.9278H4.57723V5.55707H5.35736V6.15065C5.35736 6.58312 5.70503 6.93079 6.13749 6.93079C6.56996 6.93079 6.91763 6.58312 6.91763 6.15065V5.55707H7.95215V6.15065C7.95215 6.58312 8.29982 6.93079 8.73228 6.93079C9.16475 6.93079 9.51242 6.58312 9.51242 6.15065V5.55707H10.5469V6.15065C10.5469 6.58312 10.8946 6.93079 11.3271 6.93079C11.7595 6.93079 12.1072 6.58312 12.1072 6.15065V5.55707H13.1417V6.15065C13.1417 6.58312 13.4894 6.93079 13.9219 6.93079C14.3543 6.93079 14.702 6.58312 14.702 6.15065V5.55707H15.4821V15.9278H15.4652Z" fill="#113E57" />
            <path d="M6.82623 7.91797H6.02641C5.73082 7.91797 5.49609 8.1527 5.49609 8.44829V9.24811C5.49609 9.5437 5.73082 9.77843 6.02641 9.77843H6.82623C7.12182 9.77843 7.35655 9.5437 7.35655 9.24811V8.44829C7.35655 8.1527 7.12182 7.91797 6.82623 7.91797ZM6.86101 9.2568C6.86101 9.2568 6.84362 9.29158 6.82623 9.29158L5.99164 9.2568L6.02641 8.4222L6.86101 8.45698V9.2568Z" fill="#113E57" />
            <path d="M13.8412 8.47461H8.12069C7.95264 8.47461 7.81641 8.61084 7.81641 8.77889V8.93538C7.81641 9.10343 7.95264 9.23966 8.12069 9.23966H13.8412C14.0092 9.23966 14.1454 9.10343 14.1454 8.93538V8.77889C14.1454 8.61084 14.0092 8.47461 13.8412 8.47461Z" fill="#113E57" />
            <path d="M13.8412 10.9434H8.12069C7.95264 10.9434 7.81641 11.0796 7.81641 11.2476V11.4041C7.81641 11.5722 7.95264 11.7084 8.12069 11.7084H13.8412C14.0092 11.7084 14.1454 11.5722 14.1454 11.4041V11.2476C14.1454 11.0796 14.0092 10.9434 13.8412 10.9434Z" fill="#113E57" />
            <path d="M13.763 13.4395H8.04256C7.87451 13.4395 7.73828 13.5757 7.73828 13.7437V13.9002C7.73828 14.0683 7.87451 14.2045 8.04256 14.2045H13.763C13.9311 14.2045 14.0673 14.0683 14.0673 13.9002V13.7437C14.0673 13.5757 13.9311 13.4395 13.763 13.4395Z" fill="#113E57" />
            <path d="M6.82623 10.4043H6.02641C5.73082 10.4043 5.49609 10.639 5.49609 10.9346V11.7344C5.49609 12.03 5.73082 12.2648 6.02641 12.2648H6.82623C7.12182 12.2648 7.35655 12.03 7.35655 11.7344V10.9346C7.35655 10.639 7.12182 10.4043 6.82623 10.4043ZM6.86101 11.7344C6.86101 11.7344 6.84362 11.7692 6.82623 11.7692L5.99164 11.7344L6.02641 10.8998L6.86101 10.9346V11.7344Z" fill="#113E57" />
            <path d="M6.82623 12.8828H6.02641C5.73082 12.8828 5.49609 13.1175 5.49609 13.4131V14.213C5.49609 14.5085 5.73082 14.7433 6.02641 14.7433H6.82623C7.12182 14.7433 7.35655 14.5085 7.35655 14.213V13.4131C7.35655 13.1175 7.12182 12.8828 6.82623 12.8828ZM6.86101 14.213C6.86101 14.213 6.84362 14.2477 6.82623 14.2477L5.99164 14.213L6.02641 13.3784L6.86101 13.4131V14.213Z" fill="#113E57" />
        </svg>
    );
};
