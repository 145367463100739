import React from "react";

export const BadgeAddIcon = (props) => {
    const { className="", style={}, onClick, disabled=false } = props;
    return (
        <svg className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}  onClick={disabled ? null: onClick}
        style={style} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_9650_11950)">
            <path d="M8.87573 13.9678C8.54901 13.8181 8.2223 13.7092 7.88197 13.6411C7.54164 13.5731 7.20131 13.5322 6.82014 13.5322C6.43897 13.5322 6.09864 13.5731 5.75831 13.6411C5.41798 13.7092 5.09127 13.8181 4.76455 13.9678C4.49229 14.104 4.27448 14.281 4.12473 14.5124C3.97499 14.7438 3.89331 15.0024 3.89331 15.2747V15.7103H9.74697V15.2747C9.74697 15.0024 9.66529 14.7438 9.51555 14.5124C9.3658 14.281 9.14799 14.0904 8.87573 13.9678Z" stroke="none" fill="#113E57"/>
            <path d="M6.83381 12.797C7.2422 12.797 7.58253 12.6609 7.86841 12.375C8.15428 12.0891 8.29041 11.7488 8.29041 11.3404C8.29041 10.932 8.15428 10.5917 7.86841 10.3058C7.58253 10.0199 7.2422 9.88379 6.83381 9.88379C6.42541 9.88379 6.08508 10.0199 5.79921 10.3058C5.51333 10.5917 5.3772 10.932 5.3772 11.3404C5.3772 11.7488 5.51333 12.0891 5.79921 12.375C6.08508 12.6609 6.42541 12.797 6.83381 12.797Z" fill="#113E57"/>
            <path d="M15.6143 9.87012H11.7073V11.3403H15.6143V9.87012Z" stroke="none" fill="#113E57"/>
            <path d="M22.4072 15.0433C21.3453 14.0359 20.0657 13.5322 18.5546 13.5322C17.0436 13.5322 15.7639 14.0359 14.7021 15.0433C13.6403 16.0507 13.1094 17.2758 13.1094 18.7052C13.1094 20.1346 13.6403 21.3598 14.7021 22.3808C15.7639 23.3882 17.0436 23.8918 18.5546 23.8918C20.0657 23.8918 21.3453 23.3882 22.4072 22.3808C23.469 21.3734 23.9999 20.1482 23.9999 18.7052C23.9999 17.2622 23.469 16.0507 22.4072 15.0433ZM20.7872 19.5901H19.5212V20.8561C19.5212 21.387 19.0856 21.8226 18.5546 21.8226C18.0237 21.8226 17.5881 21.387 17.5881 20.8561V19.5901H16.3221C15.7912 19.5901 15.3555 19.1545 15.3555 18.6235C15.3555 18.0926 15.7912 17.657 16.3221 17.657H17.5881V16.391C17.5881 15.8601 18.0237 15.4245 18.5546 15.4245C19.0856 15.4245 19.5212 15.8601 19.5212 16.391V17.657H20.7872C21.3181 17.657 21.7537 18.0926 21.7537 18.6235C21.7537 19.1545 21.3181 19.5901 20.7872 19.5901Z" stroke="none" fill="#113E57"/>
            <path d="M11.2853 17.6704H1.94668V6.92957H6.82019C6.82019 7.46048 7.01078 7.92333 7.39195 8.3045C7.77311 8.68567 8.23596 8.87625 8.76688 8.87625H10.7136C11.2445 8.87625 11.7073 8.68567 12.0885 8.3045C12.4697 7.92333 12.6602 7.46048 12.6602 6.92957H17.5338V11.7759C17.8605 11.735 18.1872 11.6942 18.5275 11.6942C18.8678 11.6942 19.1673 11.735 19.494 11.7759V6.94318C19.494 6.41227 19.3035 5.94942 18.9223 5.56825C18.5411 5.18708 18.0783 4.9965 17.5474 4.9965H12.6739V2.05606C12.6739 1.52514 12.4833 1.0623 12.1021 0.681128C11.7209 0.299959 11.2581 0.109375 10.7272 0.109375H8.78049C8.24958 0.109375 7.78673 0.299959 7.40556 0.681128C7.02439 1.0623 6.83381 1.52514 6.83381 2.05606V4.98289H1.94668C1.41577 4.98289 0.952921 5.17347 0.571753 5.55464C0.190584 5.93581 0 6.39866 0 6.92957V17.6704C0 18.2013 0.190584 18.6641 0.571753 19.0453C0.952921 19.4265 1.41577 19.617 1.94668 19.617H11.2717C11.2309 19.3175 11.19 19.0181 11.19 18.705C11.19 18.351 11.2445 18.0107 11.2853 17.6704ZM8.78049 2.05606H10.7272V6.92957H8.78049V2.05606Z" stroke="none" fill="#113E57"/>
            </g>
            <defs>
            <clipPath id="clip0_9650_11950">
            <rect width="24" height="23.7822" fill="white" transform="translate(0 0.109375)"/>
            </clipPath>
            </defs>
            </svg>
            

    );
}


