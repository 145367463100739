/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import MainPage from "../../MainPage";
import { Input, Spin, message } from "antd";
import { CheckIcon, CloseIcon, ToggleIcon } from "../Common/Icons";
import {
  Actions,
  RoleType,
  UserRoles,
} from "@commscopemycloud/humaui/Utilities/Constants";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import useUserInfo from "../Hooks/useUserInfo";
import CustomButton, { ButtonTypes } from "../Common/CustomButton";
import Roles from "../Roles/Roles";
import { useCallback } from "react";
import RebootResetModal from "../Devices/RebootResetModal";

const RequiredFields = {
  firstname: "First Name",
  lastname: "Last Name",
  email: "Email",
};

const DefaultValues = {
  firstname: "",
  lastname: "",
  email: "",
  staffrole: "",
  staffadmin: false,
};

const formatData = (data, isEditForm, role) => {
  const userObj = { rolename: UserRoles.staff };
  Object.keys(DefaultValues).forEach((key) => (userObj[key] = data[key]));
  if (isEditForm) {
    delete userObj.email;
    userObj.useruuid = data.useruuid;
  }
  role === UserRoles.staff
    ? (userObj["staffadmin"] = false)
    : (userObj["staffadmin"] = true);
  return userObj;
};

const StaffForm = (props) => {
  console.debug("StaffForm props", props);
  const { parentBread, record, action, onActionClick } = props;

  const [role, setRole] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [errorData, setErrorData] = useState("");
  const trans = useSelector(translator);
  const userApi = useSelector((state) => state.apis.userApi);

  const currentRole = useMemo(
    () => (action === Actions.editStaff ? record?.permission_level : null),
    [action, record]
  );

  const header = (parentBread || []).concat({ label: trans("ADDSTAFF") });
  const isEditForm = action === Actions.editStaff;

  const [isValid, setIsValid] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [formData, setFormData] = useState(record ?? DefaultValues);

  const [userInfo, fetchingUserInfo] = useUserInfo({
    useruuid: isEditForm && record?.useruuid,
  });
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    validate(false);
  }, [formData]);

  useEffect(() => {
    isEditForm && setFormData(userInfo);
  }, [userInfo]);

  const cancelSubmitError = () => {
    setShowModal(false);
  };
  const modalDataForStaffError = {
    title: <div className="title">{trans("USERALREADYPRESENT")}</div>,
    description: (
      <div className="description">{trans("AUSERWITHEMAILEXISTSMSG")}</div>
    ),
    action: "",
    dismiss: cancelSubmitError,
  };

  const handleInputChange = (e) => {
    const { name, value } = e?.target;
    if (name === "email") {
      const emailPattern =
        /^(["a-zA-Z0-9.!#$%&()'*+/=?^_`{|}~\[\]-])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      const isValidEmail = emailPattern.test(value);
      setEmailError(isValidEmail ? "" : "Please enter a valid email address");
    }
    setFormData({ ...formData, [name]: value });
  };

  const validate = (showError = true) => {
    const values = formData;
    let valid = true;
    let erroredFields = [];
    for (let field of Object.keys(RequiredFields)) {
      if (!values.hasOwnProperty(field) || !!!values[field]) {
        erroredFields.push(RequiredFields[field]);
        valid = false;
      }
    }
    if (showError && !valid) {
      let error = "Please fill required fields: " + erroredFields.join(", ");
      message.error(error, 10);
    }
    setIsValid(valid && !emailError);
    return valid && !emailError;
  };

  const handleSubmit = () => {
    const errorCallback = (error) => {
      setProcessing(false);
      const msg = `Error ${isEditForm ? "updating" : "adding"} staff`;
      console.error(msg, error);
      isEditForm
        ? message.error(msg)
        : (setShowModal(true), setErrorData(modalDataForStaffError));
    };
    const successCallback = (data) => {
      setProcessing(false);
      const msg = `Staff ${isEditForm ? "updated" : "added"} successfully!`;
      console.info(msg, data);
      message.success(msg);
      onActionClick(
        null,
        isEditForm ? record : null,
        true
      )();
    };
    try {
      console.debug("Submit:", formData);
      const valid = validate();
      if (!valid) return;
      const obj = formatData(formData, isEditForm, role);
      setProcessing(true);
      if (isEditForm) {
        userApi.updateStaffUser(
          obj,
          apiCallback({
            translator: trans,
            successCallback,
            errorCallback,
            failCallback: errorCallback,
          })
        );
      } else {
        userApi.addStaffUser(
          obj,
          apiCallback({
            translator: trans,
            successCallback,
            errorCallback,
            failCallback: errorCallback,
          })
        );
      }
    } catch (error) {
      errorCallback(error);
    }
  };

  const handleToggle = () => {
    setFormData((formData) => {
      return { ...formData, staffadmin: !formData.staffadmin };
    });
  };

  const handleRoleSelection = useCallback((role) => {
    setRole(role);
  }, []);

  const renderFormItem = ({
    name,
    label,
    disabled,
    placeholder,
    className,
  }) => (
    <div className={`form-item form-item-verticle ${className ?? ""}`}>
      <label>{label}</label>
      <Input
        name={name}
        placeholder={placeholder}
        value={formData[name]}
        onChange={handleInputChange}
        disabled={disabled}
      />
      <div style={{ height: "0.5rem", marginTop: "0.3rem", color: "red" }}>
        {name === "email" && emailError && <span>{emailError}</span>}
      </div>
    </div>
  );

  const renderRole = ({ label, className }) => {
    return (
      <div className={`form-item form-item-verticle ${className ?? ""}`}>
        <label>{label}</label>
        <Roles
          type={RoleType.staff}
          onHandleSelection={handleRoleSelection}
          currentRole={currentRole}
        />
      </div>
    );
  };
  return (
    <>
      {showModal && <RebootResetModal data={errorData} />}
      <MainPage header={header} hasSider={true}>
        <div className="staff-form-container page-content-wrapper">
          <Spin
            spinning={processing || fetchingUserInfo}
            wrapperClassName="staff-form custom-form"
          >
            <div className="form-group-1">
              {renderFormItem({
                name: "firstname",
                label: trans("FIRSTNAME"),
                placeholder: "",
              })}
              {renderFormItem({
                name: "lastname",
                label: trans("LASTNAME"),
                placeholder: "",
              })}
              {renderFormItem({
                name: "email",
                label: trans("EMAIL"),
                placeholder: "",
                disabled: isEditForm,
              })}
            </div>
            <div className="form-group-2">
              <div className="form-card">
                <span className="header-text">{trans("ADDSTAFF")}</span>
                {renderFormItem({
                  name: "staffrole",
                  label: trans("STAFFFORMJOBDESCRIPTION"),
                  placeholder: "",
                })}
                {renderRole({
                  label: trans("STAFFFORMSTAFFROLE"),
                  className: "top-margin",
                })}
              </div>
            </div>
            <div className="staff-button-container">
              <CustomButton
                label={trans("CANCEL_U")}
                icon={
                  <CloseIcon
                    className="cancel-icon"
                    style={{ height: "12px" }}
                  />
                }
                onClick={() => {
                  setFormData(() => userInfo ?? DefaultValues),
                    onActionClick(null, null)();
                }}
              />
              <CustomButton
                type={ButtonTypes.primary}
                label={trans("SUBMIT_U")}
                icon={<CheckIcon className="submit-icon" />}
                onClick={handleSubmit}
                disabled={!isValid}
              />
            </div>
          </Spin>
        </div>
      </MainPage>
    </>
  );
};

StaffForm.propTypes = {
  parentBread: PropTypes.arrayOf(PropTypes.object),
  record: PropTypes.object,
  action: PropTypes.string,
  onActionClick: PropTypes.func,
};

export default StaffForm;
