/* eslint-disable react-hooks/exhaustive-deps */
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import React from "react";
import { useSelector } from "react-redux";

const ListTask = (props) => {
  const trans = useSelector(translator);
  return <>{trans("LISTOFTASKS")}</>;
};

export default ListTask;
