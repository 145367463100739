import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Dropdown, Checkbox, Menu } from "antd";
import { ExpandMoreIcon } from "./Icons";

const CustomDropdownCheckBox = ({
  className,
  overlayClassName,
  open,
  onClick,
  disabled,
  menuItems = [],
  selectedKeys = [],
  handleChange,
  menuProps = {},
  children,
}) => {
  const [isVisible, setIsVisible] = useState(open);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsVisible(false);
        onClick(event); // Pass event object to onClick callback
      }
    };
    // Only attach the event listener when the dropdown is visible
    if (isVisible) {
      document.addEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isVisible]);

  useEffect(() => {
    setIsVisible(open);
  }, [open]);

  const handleCheckboxChange = (groupKey, itemKey) => {
    handleChange(groupKey, itemKey);
  };

  const renderMenu = (items) => (
    <Menu>
      {items.map((group) => (
        <Menu.ItemGroup key={group.key} title={group.label}>
          {group.children.map((item) => (
            <Menu.Item key={item.key}>
              <Checkbox
                checked={selectedKeys.some(
                  (selected) =>
                    selected.groupKey === group.key &&
                    selected.itemKey === item.key
                )}
                onChange={() => handleCheckboxChange(group.key, item.key)}
              >
                <span onClick={(e) => e.stopPropagation()}>{item.label}</span>
              </Checkbox>
            </Menu.Item>
          ))}
        </Menu.ItemGroup>
      ))}
    </Menu>
  );

  return (
    <Dropdown
      overlay={renderMenu(menuItems)}
      visible={isVisible}
      disabled={disabled}
      {...menuProps}
    >
      <div
        ref={dropdownRef}
        className={`custom-dropdown ${className ?? ""} ${
          disabled ? "disabled" : ""
        }`}
        onClick={() => setIsVisible(!isVisible)}
      >
        <div className="dropdown-label">{children}</div>
        {isVisible ? (
          <ExpandMoreIcon className="status-icon rotate-180" />
        ) : (
          <ExpandMoreIcon className="status-icon" />
        )}
      </div>
    </Dropdown>
  );
};

CustomDropdownCheckBox.propTypes = {
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  open: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  menuItems: PropTypes.arrayOf(PropTypes.object),
  selectedKeys: PropTypes.arrayOf(PropTypes.object),
  handleChange: PropTypes.func,
  menuProps: PropTypes.object,
  children: PropTypes.node.isRequired,
};

export default CustomDropdownCheckBox;
