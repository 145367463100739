/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useWebSocketContext } from "../WebSocket/WebsocketContext";
import {
  getUserInfo,
  getProfilePics,
} from "@commscopemycloud/humaui/Store/dataStore";
import {
  handleVideoCallAccept,
  handleVideoCallReject,
  handleVideoCallTimeout,
} from "../Helpers/VideoCallHelper";
import useUserInfo from "../Hooks/useUserInfo";
import CustomButton from "../Common/CustomButton";
import {
  formatFullNameDisplay,
  getProfilePicUrl,
} from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import {
  PhotoResolution,
  VideoCallNotificationTimeout,
  VideoCallStatusUpdate,
  WSMessageTypes,
} from "@commscopemycloud/humaui/Utilities/Constants";
import { CallDeclineIcon, CallIcon, ContactImage } from "../Common/Icons";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { updateNotificationMiniBanner } from "@commscopemycloud/humaui/Store/notificationStore";
import calling from "../../../static/images/calling.gif";
import ringtone from "../../../static/audio/ringtone.mp3";
import {
  showIncomingCallNotification,
  showMissedCallNotification,
} from "../Helpers/DesktopNotificationHelper";

const audio = new Audio(ringtone);
audio.loop = true;

const VideoCallNotificationModal = () => {
  const dispatch = useDispatch();
  const trans = useSelector(translator);
  const { sendMessage } = useWebSocketContext();
  const resourcesMap = useSelector(getProfilePics());
  const notification = useSelector(
    (state) => state.notification.videoCallNotification
  );
  const visible = useSelector(
    (state) => state.notification.videoCallNotificationModalVisible
  );
  const caller = useSelector(getUserInfo(notification?.useruuid));
  const [, fetchingUserInfo] = useUserInfo({
    useruuid: notification?.useruuid,
  });
  const image = useMemo(
    () =>
      getProfilePicUrl(caller?.useruuid, resourcesMap, PhotoResolution.R128),
    [caller?.useruuid, resourcesMap]
  );
  const meeting_id = useMemo(() => notification?.meeting_id, [notification]);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    let timer = null;
    if (visible) {
      try {
        audio?.play();
        timer = setTimeout(() => {
          handleVideoCallTimeout({ sendMessage, meeting_id, dispatch });
          dispatch(
            updateNotificationMiniBanner({
              message_type: WSMessageTypes.videoCallStatus,
              message: {
                meeting_id: meeting_id,
                call_status__status: VideoCallStatusUpdate.missed,
                event_originator_id: notification.useruuid,
                timestamp: new Date().toISOString(),
              },
            })
          );
          showMissedCallNotification({
            meeting_id: notification?.meeting_id,
            useruuid: notification?.useruuid,
          });
        }, VideoCallNotificationTimeout * 1000);
      } catch (error) {
        console.error("Calling Tune Error:", error);
      }
    }

    return () => {
      audio.pause();
      clearTimeout(timer);
    };
  }, [visible, caller]);

  useEffect(() => {
    if (visible && !fetchingUserInfo) {
      showIncomingCallNotification({
        meeting_id: notification?.meeting_id,
        useruuid: notification?.useruuid,
      });
    }
  }, [visible, caller, fetchingUserInfo]);

  const handleAccept = () => {
    console.debug("Call Accepted");
    handleVideoCallAccept({ sendMessage, meeting_id, dispatch });
  };

  const handleReject = () => {
    console.debug("Call Rejected");
    handleVideoCallReject({ sendMessage, meeting_id, dispatch });
    setTimeout(() => {
      dispatch(
        updateNotificationMiniBanner({
          message_type: WSMessageTypes.videoCallStatus,
          message: {
            call_status__status: VideoCallStatusUpdate.declined,
            meeting_id: meeting_id,
            event_originator_id: notification.useruuid,
            timestamp: new Date().toISOString(),
          },
        })
      );
    }, 500);
  };

  return (
    <>
      {calling && (
        <Modal
          open={visible}
          closable={false}
          footer={null}
          maskClosable={true}
          width={null}
          wrapClassName="vertical-center-modal video-call-notification-modal"
        >
          <header>
            <span className="">{trans("INCOMINGCALL")}</span>
          </header>
          <div className="content">
            <div className="image-container">
              {!imageLoaded && (
                <ContactImage className="placeholder-profile-pic" />
              )}
              {image && (
                <img
                  src={image}
                  className={`user-profile-pic ${
                    imageLoaded ? "show" : "hide"
                  }`}
                  alt="pic"
                  onLoad={() => setImageLoaded(true)}
                />
              )}
              {calling && <img className="calling-gif" src={calling} alt="" />}
            </div>
            <span className="name">
              {caller &&
                formatFullNameDisplay(caller?.firstname, caller?.lastname)}
            </span>
            <span className="relation">{caller?.relationship || " "}</span>
          </div>
          <footer>
            <CustomButton
              label={trans("DECLINE_U")}
              icon={<CallDeclineIcon className="decline-icon" />}
              onClick={handleReject}
              style={{ marginRight: "20px" }}
            />
            <CustomButton
              label={trans("ACCEPT_U")}
              icon={<CallIcon className="accept-icon" />}
              onClick={handleAccept}
            />
          </footer>
        </Modal>
      )}
    </>
  );
};

VideoCallNotificationModal.propTypes = {};

export default VideoCallNotificationModal;
