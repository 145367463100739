import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Layout } from "antd";
import MainSider from "./MainSider";
import VideoCallModal from "./Components/VideoCall/VideoCallModal";
import VideoCallNotificationModal from "./Components/Modals/VideoCallNotificationModal";
import NotificationMiniBanner from "./Components/Notifications/NotificationMiniBanner";
import NotificationBanner from "./Components/Notifications/NotificationBanner";
import Devices from "./Components/Devices/Devices";
import Staff from "./Components/Staff/Staff";
import UserHubs from "./Components/UserHubs/UserHubs";
import Schedule from "./Components/Schedule/Schedule";
import { NavigationKeys } from "@commscopemycloud/humaui/Utilities/Constants";

export const MainPage = ({
  children,
  hasSider = false,
  noModals = false,
  activeKey,
  onChange,
  openSubMenu = false,
  hubUserRole,
  readOnlyHeader = false,
  buttonSelected,
  onActionClick,
  navigationKey,
  offset,
  limit,
  fixPageContent = false,
}) => {
  const videoCallNotificationModalVisible = useSelector(
    (state) => state.notification.videoCallNotificationModalVisible
  );
  const videoCallModalVisible = useSelector(
    (state) => state.videoCall.videoCallModalVisible
  );

  return (
    <>
      {!noModals && (
        <>
          <VideoCallNotificationModal />
          <VideoCallModal />
        </>
      )}
      <Layout
        className="mainpage-wrapper"
        style={
          videoCallModalVisible || videoCallNotificationModalVisible
            ? { overflow: "hidden" }
            : {}
        }
      >
        <Layout>
          {hasSider && (
            <Layout.Sider width={324} className="mainpage-sider">
              <MainSider
                activeKey={activeKey}
                openSubMenu={openSubMenu}
                onChange={onChange}
                hubUserRole={hubUserRole}
                buttonSelected={buttonSelected}
                onActionClick={onActionClick}
                navigationKey={navigationKey}
                offset={offset}
                limit={limit}
              />
            </Layout.Sider>
          )}
          <Layout>
            <Layout.Content>
              <div className="header-actions-container">
                <NotificationBanner readOnly={readOnlyHeader} />
              </div>
              <Layout className={`${fixPageContent ? "mainpageaddaccount-body-container" : "mainpage-body-container"}`}>
                <Layout.Content>
                  {children}
                </Layout.Content>
              </Layout>
            </Layout.Content>
            {/* <Layout.Footer className="main-page-footer">
              ©2024 Vantiva USA LLC. All rights reserved.
            </Layout.Footer> */}
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

MainPage.propTypes = {
  hasSider: PropTypes.bool,
  noModals: PropTypes.bool,
  readonlyHeader: PropTypes.bool,
};

export default MainPage;
