import React, { useMemo, useState } from "react";
import MainPage from "../../MainPage";
import CustomButton, { ButtonTypes } from "../Common/CustomButton";
import { CloseIcon, NewSocialIcon, SocialIcon } from "../Common/Icons";
import ContactSelectList from "../Common/ContactSelectList";
import { useSelector } from "react-redux";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import { Input, message } from "antd";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { useNavigate, useLocation } from "react-router-dom";

const SendMessage = (props) => {
  const {
    parentBread,
    record,
    onActionClick,
    handleTabChange,
    userHubCall = false,
  } = props;
  const [inputMessage, setInputMessage] = useState("");
  const [isSendDisabled, setIsSendDisabled] = useState(true);
  const messagingApi = useSelector((state) => state.apis.messagingApi);
  const currentUser = useSelector(getCurrentUser);
  const useruuid = useMemo(() => currentUser?.useruuid, [currentUser]);
  const currentUserExist = currentUser.useruuid === record.useruuid;
  const trans = useSelector(translator);
  const navigate = useNavigate();
  const location = useLocation();

  const header = useMemo(() => {
    return (parentBread || []).concat({
      label: trans("SENDMESSAGE"),
    });
  }, []);

  const createSearchParams = () => {
    const currentSearchParams = new URLSearchParams(location.search);
    const paramsMap = new Map(currentSearchParams.entries());
    const uniqueSearchParams = new URLSearchParams(paramsMap);
    return uniqueSearchParams.toString();
  };

  const handleInputChange = (e) => {
    let value = e.target.value.replace(/(\r\n|\n|\r)/gm, "");
    if (value.length > 126) {
      value = value.substring(0, 126);
    }
    setInputMessage(value);
    setIsSendDisabled(value.trim() === "");
  };

  const changeTabKey = (key) => {
    handleTabChange(key);
  };

  const postMessage = () => {
    const errorCallback = (error) => {
      message.error("Error while sending message!");
      console.error("Error while sending message:", error);
    };
    const successCallback = (data) => {
      message.success("Message sent");
      console.info("Messaging", data);
      setInputMessage("");
      setIsSendDisabled(true);
    };
    try {
      messagingApi.createUserNotification(
        useruuid,
        { message: inputMessage, contacts: [{ useruuid: record?.useruuid }] },
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  const cancelMessage = () => {
    currentUserExist
      ? navigate("/" + "?" + createSearchParams())
      : userHubCall
      ? onActionClick(null, null, true)()
      : changeTabKey("schedule");
  };

  return (
    <>
      <div className="send-message-page-container">
        <div className="send-message-header">
          <div className="send-message-icon">
            <NewSocialIcon className="send-message-icon" />
          </div>
          <div className="send-message-title">{trans("SENDMESSAGE")}</div>
        </div>
        <div className="message-form-container">
          <div className="message-input-container">
            <div className="send-message">
              <p className="label">{trans("MESSAGE")}</p>
              <Input.TextArea
                type="text"
                placeholder="Enter message"
                className="input"
                maxLength={128}
                value={inputMessage}
                onChange={handleInputChange}
              />
            </div>
            {/* <p className="limit">{trans("MAXIMUM42CHARACTERS")}</p> */}
            <p className="limit">Maximum 126 characters</p>
          </div>
        </div>
        <div className="send-message-actions-container">
          <CustomButton
            label={trans("CANCEL_U")}
            icon={<CloseIcon className="cancel-icon" />}
            onClick={cancelMessage}
          />
          <CustomButton
            type={ButtonTypes.primary}
            label={trans("SENDNOW_U")}
            icon={<SocialIcon />}
            onClick={postMessage}
            disabled={isSendDisabled}
          />
        </div>
      </div>
    </>
  );
};

export default SendMessage;
