import React from "react";

export const _17 = (props) => {
  const { className = "", style = {} } = props;
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 840 841"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M625.97 319.879C585.1 319.879 544.22 319.879 503.35 319.879C493.91 319.879 485.64 315.909 477.78 311.449C455.17 298.629 442.35 278.789 437.67 253.479C429.54 209.569 454.63 161.849 495.91 143.179C513.29 135.319 531.31 132.839 550.06 137.159C551.5 137.489 552.56 137.669 553.23 135.829C560.09 116.969 573.08 103.389 590.09 93.3285C604.13 85.0185 619.38 80.3685 635.52 78.4185C663.1 75.0885 689.45 79.2685 714.26 91.8185C732.74 101.169 747.84 114.319 757.71 132.869C764.75 146.089 767.31 160.159 765.39 175.009C765.12 177.059 765.22 178.089 767.84 178.489C789.64 181.869 803.96 194.999 811.87 214.789C822.62 241.679 819.52 267.709 803.66 291.889C793.63 307.179 779.08 315.959 761 318.809C755.9 319.609 750.77 319.989 745.59 319.989C705.71 319.959 665.84 319.969 625.96 319.969C625.97 319.939 625.97 319.909 625.97 319.879ZM683.31 286.029C683.31 286.059 683.31 286.089 683.31 286.119C705.65 286.119 727.99 286.229 750.33 286.059C758.89 285.999 766.49 283.119 772.47 276.649C779.21 269.349 782.56 260.529 783.57 250.859C784.69 240.129 783.1 229.809 776.68 220.769C769.52 210.689 758.27 209.039 749.17 217.249C742.18 223.559 734.64 224.329 726.19 221.769C719.42 219.719 714.68 213.389 714.5 206.329C714.35 200.489 716.85 195.969 720.84 191.859C730.83 181.569 734.72 169.489 730.34 155.449C726.73 143.869 719.05 135.239 709.29 128.469C689.72 114.899 667.84 109.959 644.28 111.669C631.52 112.589 619.47 115.969 608.26 122.129C599.84 126.759 592.66 132.819 587.95 141.329C584.52 147.519 582.11 153.979 584.13 161.329C584.81 163.819 584.16 166.399 583.29 168.809C579.48 179.319 567.02 183.329 557.41 177.169C555.35 175.849 553.45 174.299 551.19 173.289C536.76 166.809 522.53 168.069 508.63 174.749C483.57 186.789 467.51 215.809 470.52 243.579C472.06 257.779 477.76 269.959 489.45 278.699C494.55 282.509 500.06 285.709 506.79 285.729C546.74 285.849 586.7 285.609 626.65 286.019C645.53 286.189 664.42 286.029 683.31 286.029Z"
        fill="#808080"
      />
      <path
        d="M154.6 530.45C140.43 530.45 121.54 530.41 102.65 530.47C96.0304 530.49 89.9004 528.83 84.2504 525.48C66.7004 515.07 53.7604 500.62 46.8504 481.31C39.6904 461.29 40.4304 441.65 52.0004 423.3C61.3204 408.52 75.1004 400.05 92.1504 396.8C103.8 394.58 115.28 395.6 126.54 399.13C128.45 399.73 129.16 399.65 129.45 397.37C131.68 379.49 138.91 363.77 150.59 350.12C164.59 333.76 182.23 322.88 202.53 316.09C218.92 310.6 235.8 308.44 252.96 309.73C280.64 311.81 305.52 321.1 325.96 340.46C340.67 354.39 349.78 371.41 352.57 391.59C353.57 398.8 353.7 406.07 352.34 413.27C351.93 415.43 352.64 415.99 354.67 416.45C375.7 421.25 391.55 432.87 399.29 453.31C407.27 474.4 402.9 493.8 387.6 510.28C375.6 523.21 360.44 529.57 342.69 529.6C303 529.67 263.31 529.59 223.62 529.96C202.17 530.18 180.74 530.07 154.6 530.45ZM143.67 496.56C160.44 496.26 175.33 496.38 190.22 496.2C230.98 495.68 271.76 496.24 312.52 495.6C322.78 495.44 333.05 495.33 343.3 495.54C349.65 495.67 355.03 493.5 359.93 489.83C371.23 481.35 372.17 466.66 361.92 456.98C354.29 449.78 344.95 448.34 334.84 449.28C326.92 450.02 319.51 448.48 314.76 441.21C310.66 434.94 311.76 428.56 314.69 422.05C320.28 409.65 320.8 396.95 315.71 384.26C310.26 370.67 300.36 360.99 287.66 354.27C263.5 341.48 238.25 340.27 212.53 348.66C195.05 354.36 180.28 364.22 170.66 380.31C162.43 394.08 160.04 408.59 166.69 423.96C169.75 431.03 169.69 438.21 164.14 444.32C156.72 452.47 145.29 452.35 137.15 444.23C130.65 437.74 123.04 433.05 114.08 430.98C105 428.89 95.9904 428.95 87.8404 434.3C78.3604 440.52 74.3504 452.04 77.2404 464.39C80.3404 477.63 88.1104 487.53 99.3604 494.87C101.22 496.08 103.26 496.61 105.53 496.6C118.86 496.54 132.2 496.56 143.67 496.56Z"
        fill="#808080"
      />
      <path
        d="M573.91 427.239C569.97 428.499 566.83 431.199 563.4 433.329C539.75 448.029 516.07 462.689 492.21 477.059C490.36 478.179 488.27 478.909 486.32 479.869C485.32 480.359 484.27 480.979 483.32 480.029C482.25 478.949 483.03 477.779 483.62 476.809C486.05 472.769 488.58 468.789 491.04 464.769C507.53 437.899 524 411.009 540.5 384.149C549.99 368.709 559.55 353.319 569.04 337.879C569.98 336.349 570.97 335.629 572.92 335.649C591.36 335.799 609.79 335.809 628.23 335.899C630.85 335.909 633.47 336.149 636.12 336.289C636.5 337.529 635.51 338.139 635.01 338.899C627.25 350.629 618.51 361.659 610.36 373.119C609.84 373.849 609.05 374.429 609.35 375.499C609.12 375.549 608.9 375.589 608.67 375.629C608.73 375.739 608.79 375.839 608.84 375.949C609.02 375.819 609.21 375.689 609.39 375.559C614.63 373.089 619.89 370.679 625.09 368.149C643.43 359.229 661.44 349.629 680.17 341.509C681.01 341.149 682 340.019 682.81 341.099C683.52 342.039 682.45 342.929 681.91 343.729C672.43 357.909 661.89 371.329 651.79 385.049C624.2 422.509 596.52 459.889 568.85 497.289C546.74 527.169 524.55 556.999 502.47 586.899C482.97 613.299 463.56 639.769 444.09 666.189C443.34 667.199 442.43 668.129 441.47 668.939C440.69 669.599 439.68 669.949 438.73 669.189C437.95 668.569 437.9 667.759 438.08 666.759C438.36 665.189 439.07 663.849 439.83 662.509C455.21 635.459 470.64 608.439 485.96 581.349C496.68 562.399 507.14 543.299 517.87 524.349C529.58 503.669 541.39 483.039 553.31 462.469C559.95 451.009 566.89 439.729 573.68 428.359C573.86 428.059 573.91 427.679 574.01 427.339C574.08 427.229 574.16 427.119 574.23 427.009C574.13 427.089 574.02 427.169 573.91 427.239Z"
        fill="#E4C276"
      />
      <path
        d="M192.16 545.049C194.97 545.049 197.79 545.019 200.6 545.069C201.44 545.089 202.59 544.529 203.05 545.509C203.4 546.259 202.51 546.969 202.08 547.629C196.16 556.659 190.24 565.689 184.3 574.709C153 622.219 121.69 669.729 90.3902 717.239C80.8102 731.789 71.2902 746.379 61.7302 760.939C55.2302 770.849 44.2502 773.869 35.1402 768.259C26.3602 762.849 23.7202 751.129 29.6102 741.969C38.9902 727.399 48.6702 713.029 58.2102 698.559C82.7802 661.279 107.34 623.999 131.91 586.719C140.45 573.759 149.01 560.829 157.47 547.819C158.55 546.159 159.7 545.539 161.65 545.559C171.81 545.639 181.98 545.599 192.14 545.599C192.16 545.409 192.16 545.229 192.16 545.049Z"
        fill="#6087A0"
      />
      <path
        d="M218.04 294.289C256.91 204.699 351.29 154.719 442.1 165.509C440.41 167.889 438.96 170.219 437.22 172.339C431.43 179.389 427.88 187.639 424.55 195.979C423.8 197.849 422.95 198.419 421.03 198.369C402.97 197.829 385.23 199.969 367.83 204.899C342.42 212.109 319.57 224.109 299.31 241.059C282.64 254.999 268.87 271.369 258.03 290.199C257.19 291.659 256.33 291.939 254.58 291.849C245.89 291.419 237.18 291.129 228.5 292.359C225.11 292.839 221.74 293.349 218.04 294.289Z"
        fill="#E4C276"
      />
      <path
        d="M153.09 733.55C139.1 733.58 130.17 720.48 134.89 708.34C136.69 703.71 139.77 699.85 142.45 695.76C169.92 653.86 197.46 612.01 224.97 570.13C227.06 566.94 229.03 563.68 231.77 560.97C237.72 555.06 247.11 553.8 254.09 558.01C261.49 562.47 264.84 571.24 262.3 579.43C261.51 581.96 260.32 584.3 258.87 586.51C233 625.87 207.13 665.24 181.27 704.6C177.03 711.05 172.87 717.54 168.58 723.95C164.66 729.8 159.29 733.29 153.09 733.55Z"
        fill="#6087A0"
      />
      <path
        d="M533.41 337.929C537.31 337.929 541.22 337.919 545.12 337.939C545.99 337.939 547.1 337.599 547.57 338.619C547.97 339.489 547.08 340.109 546.65 340.749C533.12 361.169 519.57 381.579 506.02 401.999C492.72 422.039 479.46 442.089 466.11 462.099C459.26 472.359 452.45 482.659 445.26 492.679C440.83 498.849 434.31 501.459 426.82 500.069C419.3 498.679 414.44 493.869 412.54 486.399C411.31 481.539 412.09 476.819 414.6 472.479C419.02 464.859 424.2 457.729 429.04 450.379C437.64 437.349 446.34 424.399 454.94 411.369C470.43 387.899 485.89 364.399 501.28 340.869C502.47 339.039 503.74 338.359 505.89 338.389C515.05 338.499 524.22 338.439 533.38 338.439C533.41 338.269 533.41 338.099 533.41 337.929Z"
        fill="#6087A0"
      />
      <path
        d="M355.47 540.099C358.74 540.099 362.01 540.059 365.28 540.119C366.3 540.139 367.72 539.509 368.27 540.579C368.73 541.459 367.57 542.319 367.03 543.119C359.7 554.159 352.36 565.199 345.02 576.229C319.42 614.699 293.8 653.159 268.25 691.659C265 696.559 261.25 700.829 255.39 702.389C247.62 704.459 240.93 702.359 235.96 696.009C231.42 690.209 231.11 683.699 233.89 676.989C234.9 674.549 236.39 672.359 237.85 670.169C266.06 627.769 294.28 585.369 322.45 542.949C323.52 541.339 324.65 540.649 326.63 540.669C336.25 540.769 345.87 540.709 355.49 540.709C355.47 540.509 355.47 540.309 355.47 540.099Z"
        fill="#6087A0"
      />
      <path
        d="M746.7 336.789C752.69 336.789 758.68 336.839 764.68 336.759C766.65 336.729 767.11 337.099 766.02 338.969C756.14 355.999 746.31 373.049 736.49 390.119C725.14 409.859 713.85 429.629 702.46 449.349C696.56 459.559 690.49 469.669 684.46 479.799C679.15 488.719 670.25 492.319 661.36 489.239C651.12 485.679 646.03 473.879 651.02 463.799C654.67 456.419 659.24 449.499 663.46 442.409C683.48 408.729 703.57 375.089 723.46 341.339C725.51 337.869 727.61 336.289 731.73 336.639C736.69 337.059 741.71 336.739 746.7 336.739C746.7 336.749 746.7 336.769 746.7 336.789Z"
        fill="#6087A0"
      />
      <path
        d="M109.39 546.178C112.77 546.178 115.04 546.158 117.31 546.188C117.92 546.198 118.79 546.128 119.08 546.478C119.65 547.158 118.86 547.808 118.49 548.368C113.6 555.688 108.7 562.998 103.78 570.288C91.5002 588.488 79.2102 606.678 66.9302 624.878C63.1602 630.468 58.0702 634.038 51.2302 634.318C37.4102 634.878 28.1302 620.598 33.9802 607.968C35.4802 604.738 37.6102 601.898 39.5802 598.968C50.7802 582.338 62.0202 565.728 73.1602 549.058C74.3102 547.348 75.5202 546.688 77.5802 546.688C88.5502 546.658 99.5402 546.708 109.39 546.178Z"
        fill="#6087A0"
      />
      <path
        d="M483.55 554.689C480.44 559.609 477.27 564.439 474.28 569.389C469.18 577.849 464.15 586.349 459.2 594.899C458.32 596.429 457.2 597.049 455.59 597.339C439.73 600.109 423.74 601.229 407.67 600.549C391.06 599.839 374.71 597.259 358.67 592.879C355.89 592.119 355.82 591.749 357.4 589.259C362.13 581.839 366.84 574.409 371.54 566.969C371.93 566.359 372.31 565.729 372.58 565.059C374.08 561.419 374.08 561.389 377.81 562.249C394.73 566.139 411.91 567.159 429.16 566.149C447.17 565.099 464.65 561.219 481.53 554.769C481.95 554.609 482.38 554.479 482.82 554.389C482.96 554.359 483.14 554.509 483.55 554.689Z"
        fill="#E4C276"
      />
      <path
        d="M427.86 659.119C428.2 665.849 427.82 673.919 428.29 681.989C428.79 690.789 422.94 699.319 411.83 700.209C403.44 700.889 395.57 696.189 393.26 686.899C392.49 683.809 392.99 680.619 392.96 677.469C392.84 663.959 392.55 650.439 392.49 636.929C392.46 630.929 395.05 626.029 399.96 622.539C410.42 615.099 425.1 621.209 427.35 633.889C427.66 635.669 427.91 637.449 427.89 639.289C427.82 645.449 427.86 651.619 427.86 659.119Z"
        fill="#E4C276"
      />
      <path
        d="M657.4 579.81C657.55 586.87 653.09 593.18 647.36 595.64C640.07 598.78 633.16 597.95 627.17 592.48C616.47 582.7 605.75 572.96 594.67 563.61C588.85 558.7 586 552.34 588.06 544.69C591.38 532.35 605.98 527.77 616.22 535.92C621.74 540.31 626.74 545.35 632.08 549.97C638.1 555.18 643.78 560.78 650.1 565.65C654.71 569.2 657.36 573.85 657.4 579.81Z"
        fill="#E4C276"
      />
      <path
        d="M185.93 162.01C189.16 162.3 192.95 163.56 196.07 166.46C206.85 176.44 218.34 185.58 229.51 195.1C234.99 199.77 238.11 205.57 236.55 213.11C235.06 220.31 227.68 227.13 219.15 226.51C212.58 226.04 208.09 222.21 203.47 218.19C194.11 210.05 184.96 201.66 175.05 194.18C168.7 189.38 165.96 182.35 167.81 175.18C169.8 167.41 176.83 162.03 185.93 162.01Z"
        fill="#E4C276"
      />
      <path
        d="M396.8 112.219C396.8 102.149 396.79 92.0793 396.8 82.0093C396.8 79.4193 396.47 76.8493 396.73 74.2493C397.73 64.3193 407.66 57.4993 417.37 59.4993C427.16 61.5093 432.6 71.9493 430.8 79.8393C430.6 80.7093 430.66 81.6393 430.66 82.5493C430.63 95.2593 430.61 107.969 430.58 120.679C430.56 129.839 425.53 137.049 417.75 139.069C407.06 141.849 396.68 134.169 396.29 123.119C396.16 119.489 396.27 115.859 396.27 112.229C396.45 112.219 396.63 112.219 396.8 112.219Z"
        fill="#E4C276"
      />
      <path
        d="M626.9 443.069C626.2 445.579 625.58 448.029 624.82 450.429C618.13 471.589 607.4 490.689 595.05 508.999C587.31 520.459 578.43 530.969 568.87 540.889C560.42 549.659 551.19 557.549 540.39 563.389C539.73 563.749 538.91 564.609 538.2 563.809C537.31 562.809 538.56 562.209 539.03 561.559C559.18 533.839 579.35 506.149 599.51 478.439C608.09 466.639 616.65 454.819 625.22 443.009C625.53 442.589 625.76 441.949 626.43 442.229C626.68 442.309 626.76 442.779 626.9 443.069Z"
        fill="#E4C276"
      />
      <path
        d="M609.41 375.55C609.23 375.68 609.04 375.81 608.86 375.94C608.8 375.83 608.74 375.73 608.69 375.62C608.92 375.58 609.14 375.53 609.37 375.49C609.36 375.49 609.41 375.55 609.41 375.55Z"
        fill="#E4C276"
      />
      <path
        d="M573.91 427.24C574.02 427.17 574.13 427.09 574.24 427.02C574.17 427.13 574.09 427.24 574.02 427.35L573.91 427.24Z"
        fill="#E4C276"
      />
    </svg>
  );
};
