import React from "react";

export const ProviderKey = (props) => {
    const { className = "", style={}} = props;
    return (
        <svg className={className} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
        <g id="vpn_key">
        <mask id="mask0_6093_41738" style={{maskType: "alpha"}}  maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
        <rect id="Bounding box" width="40" height="40" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_6093_41738)">
        <path id="vpn_key_2" d="M11.6673 28.3346C9.35251 28.3346 7.38492 27.5252 5.76453 25.9063C4.14416 24.2874 3.33398 22.3216 3.33398 20.0088C3.33398 17.6961 4.14416 15.7278 5.76453 14.1038C7.38492 12.4799 9.35251 11.668 11.6673 11.668C13.381 11.668 14.9451 12.1466 16.3597 13.1038C17.7742 14.0611 18.7706 15.3211 19.3489 16.8838H36.6673V23.1188H32.9964V28.3346H27.0177V23.1188H19.3489C18.7706 24.6815 17.7742 25.9415 16.3597 26.8988C14.9451 27.856 13.381 28.3346 11.6673 28.3346ZM11.6673 26.9244C13.5505 26.9244 15.0701 26.3337 16.2261 25.1525C17.3821 23.9712 18.0718 22.8232 18.2954 21.7086H28.4964V26.9244H31.5861V21.7086H35.2571V18.2941H18.2934C18.0712 17.1794 17.3821 16.0314 16.2261 14.8501C15.0701 13.6689 13.5505 13.0782 11.6673 13.0782C9.74957 13.0782 8.11629 13.7523 6.76749 15.1005C5.41865 16.4486 4.74423 18.0811 4.74423 19.9979C4.74423 21.9147 5.41865 23.5483 6.76749 24.8988C8.11629 26.2492 9.74957 26.9244 11.6673 26.9244ZM11.6673 21.8902C12.1858 21.8902 12.6303 21.705 13.0007 21.3346C13.371 20.9643 13.5562 20.5198 13.5562 20.0013C13.5562 19.4828 13.371 19.0383 13.0007 18.668C12.6303 18.2976 12.1858 18.1124 11.6673 18.1124C11.1488 18.1124 10.7043 18.2976 10.334 18.668C9.96362 19.0383 9.77844 19.4828 9.77844 20.0013C9.77844 20.5198 9.96362 20.9643 10.334 21.3346C10.7043 21.705 11.1488 21.8902 11.6673 21.8902Z" fill="#1C1B1F"/>
        </g>
        </g>
        </svg>
    );
}
