import React from "react";

export const _37 = (props) => {
  const { className = "", style = {} } = props;
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 840 840"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M251.365 446.847C260.665 446.847 271.755 447.057 282.845 446.807C307.195 446.257 331.555 446.697 355.905 446.237C378.165 445.817 400.435 446.127 422.695 445.727C448.225 445.267 473.765 445.587 499.295 445.147C522.285 444.747 545.275 445.037 568.265 444.637C592.895 444.207 617.515 444.237 642.145 444.197C646.455 444.187 650.085 445.517 653.375 448.257C663.095 456.337 662.005 472.287 651.245 478.877C647.805 480.987 643.925 480.777 640.165 481.157C638.185 481.357 636.165 481.277 634.175 481.287C599.275 481.507 564.385 481.547 529.485 482.007C504.405 482.337 479.325 482.117 454.245 482.567C430.705 482.987 407.175 482.657 383.645 483.097C359.205 483.557 334.755 483.197 310.315 483.657C286.875 484.097 263.425 483.907 239.985 484.147C230.905 484.237 221.815 484.497 212.735 484.517C204.235 484.527 197.395 479.887 194.405 472.367C189.985 461.237 198.105 448.237 210.075 447.467C223.225 446.617 236.415 447.267 251.365 446.847Z"
        fill="#F5F5F5"
      />
      <path
        d="M175.455 335.309C209.075 335.309 242.695 335.069 276.305 335.389C299.295 335.609 322.285 335.399 345.265 335.889C368.525 336.379 391.785 335.959 415.035 336.459C425.845 336.689 436.655 336.529 447.465 336.789C455.865 336.989 462.785 342.479 465.075 350.529C467.245 358.129 464.155 366.319 457.405 370.769C453.885 373.089 449.985 373.969 445.775 373.929C414.705 373.679 383.635 373.479 352.555 373.259C322.665 373.049 292.775 373.009 262.885 372.539C239.715 372.179 216.545 372.519 193.385 371.999C176.855 371.629 160.315 371.829 143.785 371.609C136.335 371.509 130.595 368.489 126.835 362.099C118.965 348.719 130.335 334.859 142.205 334.589C153.285 334.329 164.375 334.529 175.465 334.529C175.455 334.799 175.455 335.059 175.455 335.309Z"
        fill="#F5F5F5"
      />
      <path
        d="M478.154 327.498C470.024 327.498 461.894 327.438 453.774 327.538C451.594 327.568 451.114 326.768 451.464 324.788C456.244 298.288 464.504 272.958 476.984 249.048C477.864 247.358 478.824 245.708 479.734 244.038C480.024 243.488 480.544 242.858 480.114 242.278C479.654 241.668 478.964 242.098 478.364 242.268C470.304 244.468 462.464 247.278 454.794 250.568C439.184 257.258 424.754 265.968 411.604 276.668C394.994 290.178 381.004 306.058 370.034 324.508C369.174 325.958 368.244 326.498 366.594 326.488C355.504 326.438 344.424 326.488 333.334 326.478C330.524 326.478 330.404 326.288 331.654 323.768C339.674 307.598 349.494 292.628 361.154 278.828C376.584 260.558 394.534 245.258 414.984 232.938C437.574 219.338 461.854 210.028 487.834 205.328C498.344 203.428 508.904 201.378 519.644 202.178C525.604 202.618 531.734 203.628 535.314 209.408C537.974 213.718 538.894 218.318 537.344 223.438C535.514 229.478 531.504 233.978 527.804 238.798C508.234 264.308 495.804 293.038 489.554 324.488C489.034 327.088 488.284 328.498 485.264 328.058C482.954 327.718 480.554 327.988 478.184 327.988C478.144 327.838 478.154 327.668 478.154 327.498Z"
        fill="#808080"
      />
      <path
        d="M365.757 528.098C386.567 528.098 407.387 528.298 428.187 528.058C468.987 527.588 509.787 527.698 550.587 527.738C559.967 527.748 567.527 533.258 569.987 541.578C573.327 552.858 564.907 564.208 552.847 564.688C551.027 564.758 549.217 564.738 547.397 564.758C478.247 565.338 409.087 565.278 339.927 565.418C328.117 565.438 316.307 565.658 304.497 565.698C298.727 565.718 293.287 564.568 288.977 560.308C283.727 555.108 281.987 548.828 284.047 541.828C286.177 534.578 291.327 530.278 298.727 528.798C300.577 528.428 302.517 528.318 304.407 528.308C324.857 528.278 345.307 528.288 365.747 528.288C365.757 528.228 365.757 528.158 365.757 528.098Z"
        fill="#F5F5F5"
      />
      <path
        d="M375.125 575.909C376.235 575.789 376.665 575.689 377.095 575.699C393.355 575.969 409.605 575.139 425.855 575.119C427.365 575.119 428.645 575.469 429.945 576.279C451.385 589.659 474.655 598.209 499.565 602.249C506.255 603.339 513.045 603.759 519.835 604.139C539.585 605.239 558.845 602.559 577.855 597.469C579.075 597.139 580.335 596.919 581.825 595.889C568.145 590.129 555.355 583.359 543.035 574.209C546.515 573.949 549.255 573.599 552.005 573.579C564.635 573.479 577.275 573.759 589.895 573.369C605.335 572.889 620.765 573.089 636.205 572.879C638.285 572.849 640.365 572.639 642.435 572.469C648.755 571.949 657.635 578.979 658.185 585.099C658.565 589.289 657.615 592.959 654.805 596.099C649.815 601.679 643.425 605.349 637.045 609.009C618.355 619.739 598.555 627.679 577.555 632.559C567.495 634.899 557.335 636.519 547.035 637.419C538.975 638.119 530.935 638.569 522.855 638.349C503.205 637.809 483.935 634.839 465.075 629.219C436.775 620.779 411.345 606.959 388.535 588.269C384.005 584.529 379.465 580.769 375.125 575.909Z"
        fill="#808080"
      />
      <path
        d="M669.483 526.677C687.663 526.677 705.843 526.637 724.013 526.697C733.163 526.727 739.653 534.697 740.923 542.367C741.663 546.837 740.953 551.057 738.683 554.907C734.903 561.357 728.933 564.077 721.673 564.117C710.953 564.177 700.223 564.077 689.503 564.077C664.503 564.077 639.513 564.137 614.513 564.087C607.933 564.077 602.253 561.937 598.153 556.427C589.913 545.357 596.433 529.337 610.073 527.247C612.313 526.907 614.573 526.727 616.853 526.727C634.393 526.757 651.943 526.747 669.483 526.747C669.483 526.727 669.483 526.707 669.483 526.677Z"
        fill="#F5F5F5"
      />
      <path
        d="M529.884 337.267C538.334 337.267 546.784 337.117 555.234 337.317C562.484 337.487 567.914 341.067 571.124 347.517C574.334 353.967 573.814 360.417 569.614 366.327C565.394 372.267 559.214 374.247 552.264 374.307C539.904 374.417 527.554 374.467 515.194 374.497C509.844 374.507 504.514 375.157 499.144 374.507C489.504 373.347 482.314 365.357 482.544 355.797C482.774 346.167 490.294 338.477 499.924 337.797C504.914 337.447 509.904 337.487 514.904 337.437C519.904 337.387 524.904 337.427 529.894 337.427C529.884 337.367 529.884 337.317 529.884 337.267Z"
        fill="#F5F5F5"
      />
      <path
        d="M136.185 484.528C129.825 484.128 122.175 485.128 114.575 484.098C105.035 482.808 98.1846 475.478 98.0046 466.478C97.8046 457.038 104.125 449.438 113.835 447.528C115.335 447.228 116.895 447.088 118.435 447.088C130.155 447.068 141.885 447.078 153.605 447.128C159.105 447.158 164.505 447.638 169.125 451.148C175.135 455.728 177.515 461.908 176.235 469.148C174.955 476.438 170.375 481.248 163.235 483.488C158.165 485.078 152.955 484.368 147.805 484.498C144.355 484.598 140.895 484.528 136.185 484.528Z"
        fill="#F5F5F5"
      />
      <path
        d="M206.025 565.668C200.135 565.498 194.215 566.548 188.355 565.388C179.745 563.688 173.515 556.318 173.325 547.738C173.135 539.288 179.145 531.548 187.635 529.428C189.035 529.078 190.495 528.818 191.935 528.798C203.655 528.598 215.375 528.358 227.095 528.338C238.985 528.328 246.255 534.698 247.075 546.468C247.595 553.868 244.295 560.758 235.295 564.098C230.175 565.998 224.875 565.188 219.665 565.598C215.135 565.948 210.565 565.668 206.025 565.668Z"
        fill="#F5F5F5"
      />
      <path
        d="M466.486 383.727C471.846 383.727 477.206 383.757 482.566 383.707C484.156 383.687 485.096 383.977 485.186 385.887C485.936 401.577 488.436 416.997 492.386 432.187C492.956 434.377 492.656 434.907 490.306 435.077C479.236 435.877 468.166 435.247 457.096 435.547C455.446 435.587 454.736 435.117 454.356 433.427C452.346 424.687 450.876 415.857 449.756 406.977C448.896 400.157 448.136 393.317 448.006 386.427C447.966 384.337 448.456 383.557 450.696 383.627C455.956 383.787 461.226 383.677 466.496 383.677C466.486 383.697 466.486 383.707 466.486 383.727Z"
        fill="#808080"
      />
      <path
        d="M327.004 437.189C322.184 437.189 317.374 437.148 312.554 437.208C310.994 437.229 310.014 437.059 309.914 435.109C309.854 433.769 309.574 432.429 309.554 431.059C309.404 419.079 309.254 407.109 310.674 395.179C311.084 391.769 311.844 388.398 312.154 384.988C312.324 383.058 313.464 382.728 314.854 382.738C324.744 382.818 334.624 383.029 344.514 383.049C346.764 383.059 346.854 384.149 346.534 385.789C344.044 398.549 343.084 411.439 343.324 424.439C343.384 427.709 343.574 430.959 343.924 434.199C344.154 436.399 343.554 437.269 341.174 437.169C336.454 436.989 331.724 437.119 327.004 437.119C327.004 437.149 327.004 437.169 327.004 437.189Z"
        fill="#808080"
      />
      <path
        d="M536.116 517.647C535.156 517.767 534.726 517.857 534.306 517.867C520.346 518.277 506.386 518.447 492.426 518.437C490.936 518.437 489.956 518.077 489.076 516.737C484.186 509.207 479.816 501.377 475.656 493.417C474.496 491.197 475.446 491.117 477.276 491.127C486.436 491.157 495.606 491.147 504.766 491.067C508.116 491.037 511.456 490.787 514.796 490.637C515.826 490.587 516.716 490.687 517.346 491.747C522.706 500.807 528.946 509.237 536.116 517.647Z"
        fill="#808080"
      />
      <path
        d="M336.403 520.737C334.573 521.277 333.223 519.597 332.033 517.077C328.663 509.937 325.463 502.737 322.753 495.317C322.193 493.797 322.223 493.307 324.043 493.237C334.653 492.807 345.273 492.767 355.893 492.717C357.613 492.707 358.493 493.317 359.193 494.837C362.843 502.747 366.683 510.557 371.443 517.867C372.423 519.377 372.313 520.067 370.273 520.017C366.823 519.927 363.373 519.977 359.923 520.027C352.473 520.157 345.023 519.717 336.403 520.737Z"
        fill="#808080"
      />
    </svg>
  );
};
