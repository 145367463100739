import React from "react";

export const NewMessageIcon = (props) => {
  const { className = "", disabled = false, onClick, style = {} } = props;
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={disabled ? null : onClick}
      className={className}
    >
      <circle cx="30" cy="30" r="30" fill="#D9ECFA" />
      <g clipPath="url(#clip0_9116_71359)">
        <path
          d="M40.5 19H19.5C18.675 19 18 19.675 18 20.5V37C18 37.825 18.675 38.5 19.5 38.5H24V43L30.1875 38.5H40.5C41.325 38.5 42 37.825 42 37V20.5C42 19.675 41.325 19 40.5 19ZM39.6562 36.025H20.3438V21.5312H39.6562V36.0062V36.025Z"
          fill="#113E57"
        />
        <path d="M35.8688 25H23.8688V26.5H35.8688V25Z" fill="#113E57" />
        <path d="M35.8688 28H23.8688V29.5H35.8688V28Z" fill="#113E57" />
        <path d="M31.3688 31H23.8688V32.5H31.3688V31Z" fill="#113E57" />
      </g>
      <defs>
        <clipPath id="clip0_9116_71359">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(18 19)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
