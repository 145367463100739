import React from "react";

export const ContactIcon1 = (props) => {
    const { className="", style={}, onClick, disabled=false } = props;
    return (
        <svg 
            className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
            onClick={disabled ? null: onClick}
            style={style}
            xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17" fill="none"
        >
            <path className="stroke" d="M15.7273 10.8182C17.5347 10.8182 19 12.2835 19 14.091V15.7273H17.3636"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M13.2727 7.44229C14.6843 7.07894 15.7273 5.79761 15.7273 4.27265C15.7273 2.74769 14.6843 1.46633 13.2727 1.10303"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M7.54546 7.54545C9.35291 7.54545 10.8182 6.08021 10.8182 4.27273C10.8182 2.46525 9.35291 1 7.54546 1C5.73798 1 4.27274 2.46525 4.27274 4.27273C4.27274 6.08021 5.73798 7.54545 7.54546 7.54545Z"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M10.8182 10.8182H4.27273C2.46525 10.8182 1 12.2835 1 14.091V15.7273H14.0909V14.091C14.0909 12.2835 12.6256 10.8182 10.8182 10.8182Z"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

// Figma > contact