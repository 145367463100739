import React from "react";
import PropTypes from "prop-types";
import {
  getProfilePicUrl,
  getUsername,
} from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import { ContactImage, NewHC200Icon, UnassignedHS200DeviceIcon } from "./Icons";
import { Tooltip } from "antd";
import { PhotoResolution } from "@commscopemycloud/humaui/Utilities/Constants";
import { useSelector } from "react-redux";
import { getProfilePics } from "@commscopemycloud/humaui/Store/dataStore";

const Contact = ({
  className,
  contact,
  actionIcon,
  onClick,
  renderDetails = [],
  showRelationship = true,
  defaultName = "",
  hubRecord = {},
  starteventAction = false,
}) => {
  const name = getUsername(contact, { defaultName });
  const resourcesMap = useSelector(getProfilePics());
  const url = getProfilePicUrl(
    contact.useruuid,
    resourcesMap,
    PhotoResolution.R64
  );
  const image =
    (contact?.profilePics && contact?.profilePics[PhotoResolution.R64]) ?? url;

  return (
    <div
      className={`contact-wrapper ${className ?? ""} ${
        onClick ? "clickable-item" : ""
      }`}
      onClick={() => onClick && onClick(contact)}
    >
      <div className="group-1">
        <div className="photo-container">
          {image ? (
            <img
              src={image}
              className="user-profile-pic user-profile-image"
              alt="pic"
            />
          ) : (
            <ContactImage className="photo-container" />
          )}
        </div>
        <div className="info-container">
          <Tooltip title={name} className="name">
            {name}
          </Tooltip>
          {showRelationship && (
            <span className="role">{contact?.relationship || " "}</span>
          )}
          {renderDetails.map((renderItem) => renderItem(contact))}
        </div>
      </div>
      <div className="group-2">
        {!starteventAction && (
          <span style={{ width: "60px" }}>
            {(contact?.useruuid === hubRecord?.useruuid &&
              hubRecord?.deviceconnectstatus) ||
            contact?.deviceuuid != null ? (
              <NewHC200Icon
                status={
                  contact?.useruuid === hubRecord?.useruuid
                    ? hubRecord.deviceconnectstatus?.toLowerCase()
                    : contact.deviceconnectstatus?.toLowerCase()
                }
                className="device-assigned"
              />
            ) : contact?.usertype === "Principal" ? (
              <UnassignedHS200DeviceIcon className="device-unassigned" />
            ) : null}
          </span>
        )}
        <span>{actionIcon}</span>
      </div>
    </div>
  );
};

Contact.defaultProps = {
  contact: {},
};

Contact.propTypes = {
  contact: PropTypes.object,
};

export default Contact;
