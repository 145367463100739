import React from "react";

export const NewFilterIcon = (props) => {
    const { className = "", style={}, onClick, disabled=false } = props;
    return (       
        <svg className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
            onClick={disabled ? null: onClick} 
            width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" 
            style={style}>
            <path d="M9.95536 17.6107C9.95536 17.0484 10.4154 16.5884 10.9776 16.5884H13.022C13.5842 16.5884 14.0442 17.0484 14.0442 17.6107C14.0442 18.1729 13.5842 18.6329 13.022 18.6329H10.9776C10.4154 18.6329 9.95536 18.1729 9.95536 17.6107ZM5.86647 12.4995C5.86647 11.9373 6.32647 11.4773 6.88869 11.4773H17.1109C17.6731 11.4773 18.1331 11.9373 18.1331 12.4995C18.1331 13.0618 17.6731 13.5218 17.1109 13.5218H6.88869C6.32647 13.5218 5.86647 13.0618 5.86647 12.4995ZM2.7998 7.38843C2.7998 6.82621 3.2598 6.36621 3.82203 6.36621H20.1776C20.7398 6.36621 21.1998 6.82621 21.1998 7.38843C21.1998 7.95066 20.7398 8.41066 20.1776 8.41066H3.82203C3.2598 8.41066 2.7998 7.95066 2.7998 7.38843Z" fill="#113E57"/>
        </svg>        
    );
}