import "./index.css";
import "./styles/Common.less";
import "./styles/Components.less";
import React from "react";
import ReactDOM from "react-dom/client";
import AppRoutes from "./Components/Routes";
import { Provider } from "react-redux";
import { store } from "@commscopemycloud/humaui/Store/Store";
import { ConfigProvider } from "antd";
import { ThemeProvider } from "styled-components";
import {
  GlobalStyles,
  LoggerProvider,
  MeetingProvider,
  lightTheme,
} from "amazon-chime-sdk-component-library-react";
import { ConsoleLogger, LogLevel } from "amazon-chime-sdk-js";
import { BrowserRouter } from "react-router-dom";
import { MainProvider } from "./MainContext";

const logger = new ConsoleLogger("ChimeMeetingLogs", LogLevel.WARN);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <LoggerProvider logger={logger}>
    <ThemeProvider theme={lightTheme}>
      <GlobalStyles />
      <MeetingProvider>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: "Euclid Circular",
            },
          }}
        >
          <Provider store={store}>
            <BrowserRouter>
              <MainProvider>
                <AppRoutes />
              </MainProvider>
            </BrowserRouter>
          </Provider>
        </ConfigProvider>
      </MeetingProvider>
    </ThemeProvider>
  </LoggerProvider>
);
