import React from "react";

export const ArrowUpIcon = (props) => {
  const { className = "", style = {} } = props;
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9899 24.6167L14.01 24.6167V8.21177L9.34312 12.8787L7.92891 11.4645L15 4.3934L22.071 11.4645L20.6568 12.8787L15.9899 8.21178L15.9899 24.6167Z"
        fill="#113E57"
      />
    </svg>
  );
};
