import React from "react";
import { DeviceUnpairIcon } from "../Common/Icons";
import { useUserRole, hasStaffPrivileges } from "./helpers";
import EM200icon from "../Common/Icons/EM200icon.svg";
import { getConfirmationMessage } from "./DeviceInventoryModal";

const EM200ModalInfo = ({
  device,
  performAction,
  actionsData,
  hubRecord,
  hideModal,
  ConfirmationModal,
  isActionModalVisible,
  activeDeviceAction,
  handleDeviceActionClick,
}) => {
  const userRole = useUserRole();

  const handleUnpairEM200 = (device) => {
    let data = {
      ...actionsData.unpair_em200,
      deviceUuid: hubRecord?.deviceuuid,
    };
    const params =
      actionsData.unpair_em200.action === "perform_unPairAccessories"
        ? {
            params: [
              { name: "devicemodel", value: device?.modelnumber },
              { name: "macaddress", value: device?.basemacaddress },
            ],
          }
        : {};
    performAction(data, params);
    hideModal("unpair_em200");
  };

  return (
    <>
      {hasStaffPrivileges(userRole) && device?.paired_status !== "UNPAIRED" && (
        <div className="device-actions-container">
          <div className="device-action-block">
            <div className="device-action-command-description">
              <div className="device-action-label">Actions</div>
              <div className="device-action-description-block">
                <div className="unpair-description-label">
                  <div className="unpair">
                    {` “Unpair”`}
                    <span className="unpair-desc">{` : unpair the sensor by clicking on the unpair button`}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="unpair-block-container">
              <div
                className="device-action-icon-label"
                onClick={() => handleDeviceActionClick("unpair_em200")}
              >
                <DeviceUnpairIcon
                  className="unpair-icon"
                  strokeColor="#005d98"
                  fillColor="#0072BC"
                />
                <div className="unpair-action-label">Unpair</div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`em200-device-info-container ${
          device?.paired_status === "UNPAIRED" ? "device-details-transparent" : ""
        }`}
      >
        <div className="em200-block">
          <div className="device-model-label">
            {`HomeSight Environmental Sensor (Model ${device?.device_model_name ? device?.device_model_name : "-"})`}
          </div>
          <div className="em200-device-icon">
            <img src={EM200icon} />
          </div>
        </div>
      </div>

      <ConfirmationModal
        visible={isActionModalVisible[activeDeviceAction]}
        title={
          <span className="custom-modal-title">{getConfirmationMessage(actionsData[activeDeviceAction]?.title?.toLowerCase())}</span>
        }
        description={actionsData[activeDeviceAction]?.description}
        onConfirm={() => handleUnpairEM200(device)}
        onCancel={() => hideModal(activeDeviceAction)}
        customIcon={
          <DeviceUnpairIcon fillColor="#113E57" strokeColor="#113E57" />
        }
        customLabel={actionsData[activeDeviceAction]?.title}
        activeDeviceAction={activeDeviceAction}
      />
    </>
  );
};

export default EM200ModalInfo;
