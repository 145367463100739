import React from "react";

export const CheckIcon = (props) => {
    const { className="", style={}, onClick, disabled=false } = props;
    return (
        <svg
            className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
            onClick={disabled ? null: onClick}
            style={style}
            xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none"
        >
            <path className="stroke" d="M5 11.5L9.33333 16L18 7" strokeWidth="2"/>
        </svg>
    );
}


