/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Actions } from "@commscopemycloud/humaui/Utilities/Constants";
import ListTask from "./ListTask";
import TaskForm from "./TaskForm";
import "../../styles/Task.less";

const Task = (props) => {
  console.debug("Task props", props);
  const {
    onlyContent,
    hubRecord,
    userInfo,
    onActionClick: parentActionClick,
  } = props;

  const [action, setAction] = useState(props.action);
  const [record, setRecord] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0))
  );

  const onActionClick =
    (action, record, refresh = false) =>
    () => {
      setAction(action);
      setRecord(record);
    };

  return !action ? (
    <ListTask
      hubRecord={hubRecord}
      onlyContent={onlyContent}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
      onActionClick={parentActionClick || onActionClick}
    />
  ) : action === Actions.addTask ? (
    <TaskForm
      onlyContent={onlyContent}
      action={action}
      hubRecord={hubRecord}
      userInfo={userInfo}
      taskRecord={record}
      onActionClick={parentActionClick || onActionClick}
    />
  ) : null;
};

Task.defaultProps = {
  onlyContent: false,
  parentBread: [],
};

Task.propTypes = {
  onlyContent: PropTypes.bool,
  parentBread: PropTypes.arrayOf(PropTypes.object),
};

export default Task;
