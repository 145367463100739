import React from "react";

export const ContactNewUIIcon = (props) => {
    const { className = "", style = {} } = props;

    return (
        <svg className={className} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <g clipPath="url(#clip0_10381_11167)">
                <path d="M12 0.5C5.37931 0.5 0 5.87931 0 12.5C0 19.1207 5.37931 24.5 12 24.5C18.6207 24.5 24 19.1207 24 12.5C24 5.87931 18.6207 0.5 12 0.5ZM12 22.2931C6.59004 22.2931 2.2069 17.91 2.2069 12.5C2.2069 7.09004 6.59004 2.7069 12 2.7069C17.41 2.7069 21.7931 7.09004 21.7931 12.5C21.7931 17.91 17.41 22.2931 12 22.2931Z" fill="#005D98" />
                <path d="M12.0004 12.0399C12.9046 12.0399 13.6709 11.718 14.3146 11.0743C14.9583 10.4307 15.2801 9.66438 15.2801 8.76016C15.2801 7.85595 14.9583 7.08966 14.3146 6.44599C13.6709 5.80231 12.9046 5.48047 12.0004 5.48047C11.0962 5.48047 10.3299 5.80231 9.68622 6.44599C9.04254 7.08966 8.7207 7.85595 8.7207 8.76016C8.7207 9.66438 9.04254 10.4307 9.68622 11.0743C10.3299 11.718 11.0962 12.0399 12.0004 12.0399Z" fill="#005D98" />
                <path d="M16.736 14.9221C15.5406 14.2018 13.9621 13.834 12.0004 13.834C10.0387 13.834 8.46019 14.2018 7.26478 14.9221C6.06938 15.6424 5.47168 16.6233 5.47168 17.8493V18.6616C5.47168 18.6616 5.51766 18.7229 5.54831 18.7535C7.20348 20.4087 9.48701 21.4355 12.0157 21.4355C14.5445 21.4355 16.828 20.4087 18.4832 18.7535C18.4985 18.7382 18.5292 18.7075 18.5445 18.6922V17.8646C18.5445 16.6386 17.9468 15.6731 16.7514 14.9374L16.736 14.9221Z" fill="#005D98" />
            </g>
            <defs>
                <clipPath id="clip0_10381_11167">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
}