import React from "react";

export const ArrowDownIcon = (props) => {
  const { className = "", style = {} } = props;
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.01 5.38335L15.9899 5.38335V21.7882L20.6569 17.1213L22.0711 18.5355L15 25.6066L7.92893 18.5355L9.34314 17.1213L14.01 21.7882L14.01 5.38335Z"
        fill="#113E57"
      />
    </svg>
  );
};
