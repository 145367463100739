import React from "react";

export const RCTV = (props) => {
  const { className = "", style = {}, env = false, isSelected } = props;

  return (
    <svg
      className={`${className} ${isSelected ? "rc-button-selected" : ""}`}
      style={style}
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_18891_160229)">
        <path
          d="M18.9192 37.0349C8.93445 37.0349 0.814453 28.9036 0.814453 18.9178C0.814453 8.93206 8.93445 0.800781 18.9192 0.800781C28.904 0.800781 37.024 8.92314 37.024 18.9178C37.024 28.9125 28.904 37.0349 18.9192 37.0349Z"
          fill="#1C1C1C"
        />
        <path d="M18.9195 1.60486C28.4607 1.60486 36.2096 9.35276 36.2096 18.9195C36.2096 28.4862 28.4607 36.2341 18.9195 36.2341C9.37827 36.2341 1.62943 28.4773 1.62943 18.9195C1.62943 9.36167 9.36922 1.60486 18.9195 1.60486ZM18.9195 0C8.49114 0 0 8.48792 0 18.9195C0 29.3511 8.49114 37.839 18.9195 37.839C29.3478 37.839 37.839 29.3511 37.839 18.9195C37.839 8.48792 29.3569 0 18.9195 0Z" />
        <path
          d="M11.3164 23.8251V15.0876H8.43774V12.707H16.775V15.0876H13.8601V23.8251H11.3164Z"
          fill="white"
        />
        <path
          d="M21.7622 23.8223L17.1365 12.6953H19.8884L22.8938 20.1044L25.8811 12.6953H28.6692L24.0434 23.8223H21.7713H21.7622Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_18891_160229">
          <rect width="37.839" height="37.839" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
