/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { message } from "antd";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { storeUserConfig } from "@commscopemycloud/humaui/Store/dataStore";

const useConfig = ({ useruuid }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [fetchingData, setFetchingData] = useState(false);

  const trans = useSelector(translator);
  const userConfigApi = useSelector((state) => state.apis.userConfigApi);

  const fetchData = () => {
    const errorCallback = (error) => {
      setFetchingData(false);
      message.error("Error fetching user config!");
      console.error("Error fetching user config:", error);
      setData([]);
    };
    const successCallback = (data) => {
      setFetchingData(false);
      console.info("UserConfig: fetch result", data);
      dispatch(storeUserConfig({ userConfig: data?.config, useruuid }));
      setData(data?.config ?? []);
    };
    try {
      if (!useruuid) return;
      setFetchingData(true);
      userConfigApi.getMergedConfigByUserId(
        useruuid,
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  useEffect(() => fetchData(), [useruuid]);

  return [data, fetchingData, fetchData];
};

export default useConfig;
