import React from "react";

export const NewArrowRightIcon = (props) => {
  const { className = "", disabled = false, onClick, style = {} } = props;
  return (
    <svg
      className={className}
      style={style}
      width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.67832 7.39069L12.2824 11.9948L7.67832 16.6109C7.15349 17.2192 7.15349 18.1257 7.7141 18.7102C8.32242 19.3543 9.34821 19.3662 9.96845 18.734L16.7076 11.9948L9.98038 5.26755C9.34821 4.63538 8.33434 4.64731 7.7141 5.29141C7.15349 5.87587 7.15349 6.78238 7.67832 7.39069Z" fill="#0072BC"/>
      </svg>
  );
};
