import React, { useState } from "react";
import PropTypes from "prop-types";
import "../../styles/ProgressBar.less";

const ProgressBar = ({
  className,
  style,
  height = 10,
  percent = 0,
  adjustable = false,
  onChange = () => {},
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleClick = (e) => {
    if (!isDragging) {
      const bar = e.currentTarget;
      const boundingRect = bar.getBoundingClientRect();
      const clickPosition = e.clientX - boundingRect.left;
      let newValue = (clickPosition / bar.clientWidth) * 100;
      newValue = Math.round(newValue);
      onChange(newValue);
    }
  };

  const handleCircleMouseDown = () => {
    setIsDragging(true);
  };
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const bar = e.currentTarget.parentElement;
      const boundingRect = bar.getBoundingClientRect();
      const mouseX = e.clientX;
      const offsetX = mouseX - boundingRect.left;
      let newValue = Math.max(
        0,
        Math.min((offsetX / bar.clientWidth) * 100, 100)
      );
      newValue = Math.round(newValue);
      onChange(newValue);
    }
  };

  return (
    <div
      className={`progressbar-wrapper ${className ?? ""} ${
        adjustable ? "adjustable" : ""
      }`}
      style={{ height: `${height}px`, ...style }}
      {...(adjustable && {
        onClick: handleClick,
        onMouseMove: handleMouseMove,
        onMouseUp: handleMouseUp,
      })}
    >
      <div className="progressbar-inner" style={{ width: `${percent}%` }} />
      {adjustable && (
        <div
          className="adjust-circle"
          style={{
            height: `${height + 10}px`,
            width: `${height + 10}px`,
            left: `${percent}%`,
          }}
          onMouseDown={handleCircleMouseDown}
        />
      )}
    </div>
  );
};

ProgressBar.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  /* height of progress bar (in pixels) */
  height: PropTypes.number,
  /* current filled value of progress bar */
  percent: PropTypes.number,
  /* allow changing bar value by clicking or dragging */
  adjustable: PropTypes.bool,
  /* function tobe called when value is changed by clicking or dragging (applicable for adjustable=true) */
  onChange: PropTypes.func,
};

export default ProgressBar;
