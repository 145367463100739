import React from "react";

export const BodyTemperatureIcon = (props) => {
  const { className = "", style = {}, disabled = false, onClick } = props;

  return (
    <svg
      className={className}
      style={style}
      width="61"
      height="60"
      viewBox="0 0 61 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="30.5" cy="30" rx="30" ry="30" fill="#F5F5F5" />
      <path
        d="M34.6315 31.6418V20.3063C34.6315 18.0054 32.7594 16.1333 30.4585 16.1333C28.1576 16.1333 26.2897 18.0054 26.2897 20.3063V31.6418C24.9083 32.8417 24.1001 34.5901 24.1001 36.4415C24.1001 39.9506 26.9494 42.8 30.4585 42.8C33.9676 42.8 36.817 39.9506 36.817 36.4415C36.817 34.5901 36.0088 32.8417 34.6274 31.6418H34.6315ZM30.4585 41.4351C27.704 41.4351 25.4691 39.2002 25.4691 36.4457C25.4691 34.7715 26.3062 33.2335 27.6587 32.3139V20.3063C27.6587 18.76 28.9163 17.5023 30.4627 17.5023C32.009 17.5023 33.2666 18.76 33.2666 20.3063V32.3139C34.615 33.2293 35.4562 34.7674 35.4562 36.4457C35.4562 39.2002 33.2213 41.4351 30.4668 41.4351H30.4585Z"
        fill="#113E57"
      />
      <path
        d="M31.8935 33.1132V23.0395C31.8935 22.2478 31.2461 21.6045 30.4585 21.6045C29.671 21.6045 29.0236 22.2519 29.0236 23.0395V33.1132C27.737 33.6699 26.834 34.9481 26.834 36.4408C26.834 38.4407 28.4545 40.0654 30.4585 40.0654C32.4626 40.0654 34.0831 38.4449 34.0831 36.4408C34.0831 34.9481 33.1842 33.6699 31.8935 33.1132Z"
        fill="#113E57"
      />
    </svg>
  );
};
