import React from "react";

export const _11 = (props) => {
  const { className = "", style = {} } = props;
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 840 841"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M252.43 455.851C261.41 455.851 272.41 456.051 283.4 455.811C307.84 455.261 332.29 455.701 356.73 455.241C378.72 454.831 400.71 455.121 422.7 454.741C448.41 454.291 474.13 454.591 499.84 454.151C523.65 453.741 547.46 454.051 571.26 453.621C595.07 453.191 618.87 453.251 642.68 453.201C646.92 453.191 650.44 454.621 653.65 457.271C663.38 465.311 662.26 481.321 651.51 487.891C648.07 489.991 644.19 489.781 640.43 490.161C638.45 490.361 636.43 490.281 634.44 490.291C599.27 490.511 564.1 490.551 528.94 491.011C503.95 491.341 478.96 491.121 453.98 491.561C430.9 491.981 407.82 491.651 384.74 492.081C359.84 492.541 334.94 492.191 310.05 492.651C286.52 493.081 262.98 492.901 239.45 493.141C230.64 493.231 221.83 493.491 213.02 493.511C204.52 493.521 197.68 488.881 194.7 481.361C190.23 470.111 198.54 457.111 210.65 456.441C223.88 455.701 237.15 456.241 252.43 455.851Z"
        fill="#808080"
      />
      <path
        d="M149.89 385.57C144.15 386.05 138.48 386.16 133.48 382.42C127.26 377.78 124.27 370.32 126.09 363.03C127.85 355.97 134.6 349.91 141.83 348.94C143.27 348.75 144.71 348.55 146.16 348.54C168.87 348.38 191.58 348.38 214.29 348.05C227.73 347.86 241.18 347.98 254.62 347.65C262.43 347.46 270.24 347.5 278.05 347.44C300.13 347.25 322.2 347.31 344.28 346.94C357.73 346.71 371.17 346.96 384.61 346.59C400.78 346.15 416.95 346.51 433.12 346.26C439.37 346.16 445.63 345.43 451.86 346.45C460.78 347.92 467.11 355.89 466.93 365.34C466.76 374.18 459.79 381.92 450.98 382.96C448.73 383.23 446.46 383.35 444.19 383.37C419.75 383.54 395.31 383.47 370.87 383.89C356.33 384.14 341.8 383.82 327.27 384.21C314.28 384.56 301.28 384.21 288.3 384.59C275.04 384.98 261.77 384.58 248.52 384.9C233.99 385.25 219.45 384.98 204.92 385.32C191.38 385.64 177.85 385.43 164.32 385.61C159.51 385.68 154.7 385.59 149.89 385.57Z"
        fill="#808080"
      />
      <path
        d="M396.22 300.64C379.19 300.64 355.74 300.61 332.3 300.65C323.13 300.67 313.96 300.89 304.79 300.92C299.02 300.94 293.58 299.79 289.27 295.52C284.02 290.32 282.29 284.04 284.34 277.04C286.47 269.79 291.63 265.53 299.02 263.99C301.34 263.51 303.71 263.5 306.07 263.5C346.97 263.45 387.86 263.6 428.76 263.26C469.38 262.93 510 262.93 550.62 262.95C558.25 262.95 564.49 265.93 568.43 272.59C575.59 284.72 567.1 299.64 552.86 299.88C538.41 300.13 523.96 299.89 509.51 300.12C473.88 300.67 438.26 300.3 396.22 300.64Z"
        fill="#808080"
      />
      <path
        d="M402.59 574.411C379.14 574.411 355.7 574.381 332.25 574.421C323.08 574.441 313.91 574.661 304.74 574.691C298.96 574.711 293.53 573.531 289.24 569.261C284 564.051 282.29 557.761 284.37 550.761C286.52 543.521 291.69 539.271 299.08 537.751C301.41 537.271 303.77 537.271 306.13 537.271C347.03 537.221 387.92 537.371 428.82 537.031C469.35 536.701 509.88 536.721 550.4 536.711C558.03 536.711 564.3 539.571 568.32 546.161C575.57 558.061 567.38 572.981 553.35 573.661C551.26 573.761 549.17 573.741 547.08 573.741C498.92 573.741 450.75 573.741 402.59 573.741C402.59 573.971 402.59 574.191 402.59 574.411Z"
        fill="#808080"
      />
      <path
        d="M669.96 261.901C688.04 261.901 706.13 261.891 724.21 261.911C733.07 261.921 739.88 269.671 741.19 277.521C741.93 281.981 741.26 286.231 739.01 290.081C735.25 296.511 729.29 299.311 722.02 299.341C707.75 299.401 693.49 299.331 679.22 299.331C657.68 299.331 636.14 299.381 614.6 299.301C608.02 299.281 602.39 297.031 598.31 291.511C590.18 280.511 597.01 264.231 610.55 262.451C613.07 262.121 615.6 261.941 618.15 261.951C635.42 261.991 652.69 261.971 669.95 261.971C669.96 261.941 669.96 261.921 669.96 261.901Z"
        fill="#808080"
      />
      <path
        d="M669.94 535.68C688.03 535.68 706.11 535.67 724.19 535.68C733.06 535.69 739.87 543.42 741.19 551.27C741.95 555.73 741.27 559.99 739.02 563.83C735.2 570.37 729.14 573.12 721.77 573.12C700.05 573.14 678.33 573.08 656.61 573.07C642.43 573.06 628.25 573.15 614.08 573.08C603.74 573.03 596.32 566.41 594.94 556.25C593.67 546.89 600.97 537.49 610.52 536.23C613.04 535.9 615.57 535.72 618.12 535.72C635.39 535.76 652.66 535.74 669.92 535.74C669.94 535.72 669.94 535.7 669.94 535.68Z"
        fill="#808080"
      />
      <path
        d="M529.85 346.271C538.39 346.271 546.93 346.111 555.46 346.321C562.68 346.501 568.16 350.011 571.38 356.461C574.59 362.901 574.1 369.341 569.93 375.261C565.74 381.211 559.58 383.231 552.62 383.291C540.27 383.401 527.92 383.471 515.57 383.471C510.13 383.471 504.71 384.191 499.26 383.481C489.76 382.241 482.49 373.981 482.83 364.561C483.17 355.021 490.78 347.431 500.46 346.791C510.24 346.151 520.05 346.601 529.85 346.271Z"
        fill="#808080"
      />
      <path
        d="M136.5 493.532C130.14 493.142 122.49 494.132 114.9 493.102C105.33 491.802 98.49 484.492 98.29 475.502C98.08 466.062 104.39 458.452 114.09 456.532C115.59 456.232 117.15 456.092 118.68 456.092C130.22 456.072 141.76 456.042 153.3 456.152C157.91 456.192 162.54 456.282 166.83 458.552C173.91 462.312 177.69 469.442 176.68 477.582C175.83 484.492 170.11 490.642 163.01 492.622C158.25 493.952 153.44 493.372 148.65 493.492C145.03 493.612 141.39 493.532 136.5 493.532Z"
        fill="#808080"
      />
      <path
        d="M206.04 300.9C200.16 300.71 194.23 301.77 188.37 300.58C179.94 298.87 173.71 291.3 173.6 282.69C173.49 274.32 179.87 266.38 188.18 264.59C189.51 264.3 190.87 264.04 192.21 264.02C204.11 263.83 216.01 263.57 227.91 263.58C239.25 263.58 246.93 270.64 247.38 281.97C247.68 289.42 244.48 296.01 235.56 299.33C230.27 301.3 224.78 300.41 219.39 300.84C214.96 301.18 210.49 300.9 206.04 300.9Z"
        fill="#808080"
      />
      <path
        d="M206.05 574.672C200.16 574.482 194.24 575.542 188.38 574.352C179.84 572.622 173.53 564.862 173.59 556.202C173.65 547.762 180.07 540.002 188.42 538.322C189.66 538.072 190.93 537.812 192.19 537.792C204.18 537.602 216.17 537.342 228.16 537.352C239.32 537.362 247.27 544.912 247.4 555.982C247.49 563.612 244.19 569.972 235.32 573.162C230.1 575.042 224.71 574.152 219.41 574.602C214.98 574.972 210.51 574.672 206.05 574.672Z"
        fill="#808080"
      />
    </svg>
  );
};
