/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import dayjs from "dayjs";
import MainPage from "../../MainPage";
import { Divider, Form, Input, TimePicker, message, Select, Spin } from "antd";
import {
  Actions,
  DateFormat,
} from "@commscopemycloud/humaui/Utilities/Constants";
import { HSDatePicker, HSDayPicker } from "../Common/HSWidget";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { objectSort } from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import CustomSwitch, { SwitchTypes } from "../Common/CustomSwitch";
import CustomButton, { ButtonTypes } from "../Common/CustomButton";
import { CloseIcon, SendIcon } from "../Common/Icons";

export const TaskForm = (props) => {
  console.debug("TaskForm props", props);
  const {
    onlyContent,
    action,
    hubRecord,
    // userInfo,
    taskRecord,
    onActionClick,
    openSubMenu = false,
    showHeader = false,
  } = props;
  const [form] = Form.useForm();
  const trans = useSelector(translator);
  const taskApi = useSelector((state) => state.apis.taskApi);

  const TaskCategories = {
    medication: trans("MEDICATION"),
    exercise: trans("EXERCISE"),
    diet: trans("DIET"),
  };
  
  const ReminderDuration = [0, 5, 10, 15, 30];
  
  const SortedCategories = Object.keys(TaskCategories)
    .map((key) => ({ value: key, label: TaskCategories[key] }))
    .sort(objectSort("label"));

    const FormattedReminderDuration = ReminderDuration.map((d) => ({
      value: d,
      label: `${d} ${trans("MIN")}`,
    }));
    
    const formatDateTime = (dateMomentObj, hours = 0, minutes = 0) => {
      return moment
        .utc()
        .year(dateMomentObj.year())
        .month(dateMomentObj.month())
        .date(dateMomentObj.date())
        .hours(hours)
        .minutes(minutes)
        .seconds(0)
        .milliseconds(0)
        .format();
    };
    
    const FormItemLayout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };
    const LocalTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    
  const isEditForm = useMemo(() => action === Actions.editTask, [action]);

  const [processing, setProcessing] = useState(false);
  const [isReoccuring, setIsRecouring] = useState(
    taskRecord?.recurring || false
  );
  const [formData, setFormData] = useState({
    reminder: ReminderDuration[0],
    category: TaskCategories.medication,
    date: dayjs(new Date(new Date().setHours(0, 0, 0, 0))),
  });
  const [checkedDays, setCheckedDays] = useState(
    isEditForm ? taskRecord?.days_of_week : []
  );
  const [activeTab, setActiveTab] = useState("schedule");

  const handleTabChange = (key) => {
    setActiveTab(key);
    onActionClick(null, null, key)();
  };

  /* Function to get UTC offset abbreviation for a time zone */
  const get_utc_offset = (timeZone) => {
    return Intl.DateTimeFormat(navigator.language, {
      timeZoneName: "longOffset",
      timeZone,
    })
      .formatToParts()
      .find((i) => i.type === "timeZoneName").value;
  };
  const timezonedisplay = LocalTimezone + ` (${get_utc_offset(LocalTimezone)})`;

  const disabledPastDate = (current) => {
    return current && current < dayjs().startOf("day");
  };

  const disabledPastCurrentDate = (current) => {
    return current && current <= dayjs().endOf("day");
  };

  const handleInputChange = (e) => {
    const { name, value } = e?.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleValueChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleOnDayChange = (days) => {
    const lowercaseCheckedValues = days.map((value) => value.toLowerCase());
    setCheckedDays(lowercaseCheckedValues);
  };

  const handleSendClick = () => {
    if (isReoccuring && checkedDays.length < 1) {
      message.error(trans("PLEASESELECTATLESTONEDAY"));
      return;
    }
    form
      .validateFields()
      .then(() => onFormSubmit())
      .catch((error) => message.error("Please fill in all required fields"));
  };

  const onFormSubmit = () => {
    const errorCallback = (error) => {
      setProcessing(false);
      const msg = `Error ${isEditForm ? "Updating" : "Creating"} Task!`;
      message.error(msg);
      console.error(msg, error);
    };
    const successCallback = (data) => {
      setProcessing(false);
      const msg = `Task ${isEditForm ? "Updated" : "Created"} Successfully!`;
      message.success(msg);
      console.info(msg, data);
      onActionClick(null, null)();
    };
    try {
      setProcessing(true);
      const eventDate = new Date(formData.time.$d);
      const hours = String(eventDate.getHours()).padStart(2, "0");
      const minutes = String(eventDate.getMinutes()).padStart(2, "0");
      const userUuid = hubRecord.useruuid;
      const opts = {
        createTask: {
          contacts: [{ useruuid: userUuid }],
          event_name: formData.event_name,
          event_description: formData.event_description,
          category: formData.category,
          timezone: timezonedisplay,
          recurring: isReoccuring,
          /* convert minutes to seconds */
          reminder: formData.reminder * 60,
          /* snoozable if reminder is not 0 */
          snoozable: formData.reminder !== 0 ? true : false,
        },
      };
      if (!isReoccuring) {
        const parsedDate = moment(formData.date.$d, "YYYY-MM-DD");
        opts.createTask.event_starttime = formatDateTime(
          parsedDate,
          hours,
          minutes
        );
      } else {
        const formattedTime = `${hours}:${minutes}`;
        opts.createTask.event_time = formattedTime;
        opts.createTask.days_of_week = checkedDays;
        if (formData.date) {
          opts.createTask.event_startdate = moment(formData.date.$d).format(
            "YYYY-MM-DD"
          );
        }
        if (formData.enddate && formData.enddate.$D) {
          opts.createTask.event_enddate = moment(formData.enddate.$d).format(
            "YYYY-MM-DD"
          );
        }
      }
      console.debug("Form Submit, Options:", opts);
      taskApi.createTask(
        userUuid,
        opts,
        apiCallback({
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  const render = (
    <div className="taskform-container">
      <Spin size="large" spinning={processing} tip={trans("PROCESSING")}>
        <Form
          form={form}
          initialValues={formData}
          {...FormItemLayout}
          className="taskform-container"
        >
          <div className="taskform-card">
            <span className="form-title">{trans("ADDTASK")}</span>
            <div className="form-items-container">
              <div className="column-1">
                <div className="form-group">
                  <Form.Item
                    name="event_name"
                    rules={[
                      {
                        required: true,
                        message: trans("PLEASEENTERTHETASKNAME"),
                      },
                    ]}
                  >
                    <Input
                      placeholder={trans("ADDTITLE")}
                      name="event_name"
                      onChange={handleInputChange}
                      defaultValue={formData.event_name}
                      // maxLength={45}
                    />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <Form.Item name="event_description">
                    <Input.TextArea
                      placeholder={trans("ADDDESCRIPTION")}
                      name="event_description"
                      onChange={handleInputChange}
                      defaultValue={formData.event_description}
                      className="form-description"
                      // maxLength={100}
                    />
                  </Form.Item>
                </div>
                <div className="form-row">
                  <Form.Item name="date">
                    <HSDatePicker
                      required={true}
                      allowClear={false}
                      name="date"
                      disabledDate={disabledPastDate}
                      defaultValue={formData.date}
                      format={DateFormat}
                      onDateSelect={(value) => handleValueChange("date", value)}
                    />
                  </Form.Item>
                  <Form.Item
                    name="time"
                    rules={[
                      {
                        type: "object",
                        required: true,
                        message: trans("PLEASESELECTTIME"),
                      },
                    ]}
                  >
                    <TimePicker
                      name="time"
                      allowClear={false}
                      required={true}
                      format="h:mm a"
                      size="large"
                      onChange={(value) => handleValueChange("time", value)}
                      minuteStep={15}
                      suffixIcon={
                        <span style={{ color: "black" }}>
                          {timezonedisplay}
                        </span>
                      }
                    />
                  </Form.Item>
                </div>
                <div className="form-group flex-row">
                  <Form.Item name="recurring">
                    <CustomSwitch
                      onChange={() => setIsRecouring((s) => !s)}
                      checked={isReoccuring}
                      disabled={isEditForm}
                      switchType={SwitchTypes.normal}
                    />
                  </Form.Item>
                  <label style={{ marginLeft: "20px", fontSize: "18px" }}>
                    {trans("RECURRING")}
                  </label>
                </div>
                {isReoccuring && (
                  <div className="form-row flex-space-between">
                    <div
                      className="form-group flex-row"
                      style={{ gap: "16px", alignItems: "baseline" }}
                    >
                      <label>{trans("ON")}</label>
                      <Form.Item name="days_of_week">
                        <HSDayPicker
                          onDayChange={handleOnDayChange}
                          name="days_of_week"
                          days={checkedDays}
                        />
                      </Form.Item>
                    </div>
                    <div
                      className="form-group flex-row"
                      style={{ gap: "16px", alignItems: "baseline" }}
                    >
                      <label>{trans("ENDON")}</label>
                      <Form.Item name="enddate">
                        <HSDatePicker
                          allowClear={false}
                          name="enddate"
                          defaultText="NO END DATE"
                          defaultValue={formData.enddate}
                          disabledDate={disabledPastCurrentDate}
                          format={DateFormat}
                          onDateSelect={(value) =>
                            handleValueChange("enddate", value)
                          }
                        />
                      </Form.Item>
                    </div>
                  </div>
                )}
              </div>
              <div className="column-2">
                <Form.Item name="category" className="form-group-select">
                  <Select
                    disabled={isEditForm}
                    onChange={(e) => handleValueChange("category", e)}
                    defaultValue={formData.category}
                    value={formData.category}
                    name="category"
                    className="select-type"
                    options={SortedCategories}
                  />
                </Form.Item>
                <Form.Item name="reminder" className="form-group-select">
                  <Select
                    disabled={isEditForm}
                    onChange={(e) => handleValueChange("reminder", e)}
                    value={formData.reminder}
                    name="reminder"
                    className="select-type"
                    options={FormattedReminderDuration}
                  />
                </Form.Item>
              </div>
            </div>

            <Divider className="divider" />

            <div className="taskform-action-button-container">
              <Form.Item>
                <CustomButton
                  label={trans("CANCEL_U")}
                  icon={<CloseIcon />}
                  onClick={onActionClick(null, null)}
                />
              </Form.Item>
              <Form.Item>
                <CustomButton
                  label={trans("SEND_U")}
                  icon={<SendIcon />}
                  type={ButtonTypes.primary}
                  onClick={handleSendClick}
                />
              </Form.Item>
            </div>
          </div>
        </Form>
      </Spin>
    </div>
  );

  return onlyContent ? (
    render
  ) : (
    <MainPage
      hasSider={showHeader ? false : true}
      activeKey={activeTab}
      openSubMenu={openSubMenu}
      onChange={handleTabChange}
      hubUserRole={hubRecord?.rolename}
    >
      {render}
    </MainPage>
  );
};

TaskForm.propTypes = {
  onlyContent: PropTypes.bool,
  parentBread: PropTypes.arrayOf(PropTypes.object),
  action: PropTypes.string,
  eventRecord: PropTypes.object,
  userInfo: PropTypes.object,
  contacts: PropTypes.arrayOf(PropTypes.object),
  fetchingContacts: PropTypes.bool,
  staffList: PropTypes.arrayOf(PropTypes.object),
  fetchingStaffList: PropTypes.bool,
  onActionClick: PropTypes.func,
};

export default TaskForm;
