import React from "react";

export const RCMicrophone = (props) => {
  const { className = "", style = {}, env = false, isSelected } = props;

  return (
    <svg
      className={`${className} ${isSelected ? "rc-button-selected" : ""}`}
      style={style}
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_18891_160222)">
        <path
          d="M18.92 37.0349C8.93518 37.0349 0.815186 28.9036 0.815186 18.9178C0.815186 8.93206 8.93518 0.800781 18.92 0.800781C28.9047 0.800781 37.0247 8.93206 37.0247 18.9178C37.0247 28.9036 28.9047 37.0349 18.92 37.0349Z"
          fill="#1C1C1C"
        />
        <path d="M18.9202 1.60486C28.4614 1.60486 36.2103 9.35276 36.2103 18.9195C36.2103 28.4862 28.4614 36.2341 18.9202 36.2341C9.37901 36.2341 1.63016 28.4773 1.63016 18.9195C1.63016 9.36167 9.36995 1.60486 18.9202 1.60486ZM18.9202 0C8.49187 0 0.000732422 8.48792 0.000732422 18.9195C0.000732422 29.3511 8.49187 37.839 18.9202 37.839C29.3486 37.839 37.8397 29.3511 37.8397 18.9195C37.8397 8.48792 29.3576 0 18.9202 0Z" />
        <path
          d="M18.7852 24.6337C21.2747 24.6337 23.3205 22.6187 23.3205 20.1669V13.6583C23.3205 11.2064 21.2747 9.19141 18.7852 9.19141C16.2958 9.19141 14.25 11.2064 14.25 13.6583V20.1669C14.25 22.6187 16.2958 24.6337 18.7852 24.6337Z"
          fill="white"
        />
        <path
          d="M26.0339 20.7906V20.22C26.0339 19.8277 25.708 19.5156 25.3187 19.5156C24.9204 19.5156 24.6036 19.8366 24.6036 20.22V20.5944C24.6036 23.4743 22.5035 26.042 19.6067 26.4165C16.0944 26.8623 13.089 24.1697 13.089 20.7995V20.2289C13.089 19.8366 12.7631 19.5245 12.3738 19.5245C11.9755 19.5245 11.6587 19.8455 11.6587 20.2289V20.7995C11.6587 24.2321 14.13 27.0852 17.416 27.745L17.6061 28.378C17.7691 28.9219 18.285 29.2963 18.8644 29.2963C19.4619 29.2963 19.9869 28.8951 20.1317 28.3334L20.2856 27.745C23.5716 27.0852 26.0429 24.2321 26.0429 20.7995L26.0339 20.7906Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_18891_160222">
          <rect width="37.839" height="37.839" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
