import React from "react";

export const NewCallIcon = (props) => {
  const { className = "", disabled = false, onClick, style = {} } = props;
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
      onClick={disabled ? null : onClick}
    >
      <circle cx="30" cy="30" r="30" fill="#D9ECFA" />
      <g clipPath="url(#clip0_9116_71335)">
        <path
          d="M24.8419 21.5524C25.0546 21.5524 25.356 21.6588 25.5509 21.8183C26.3131 22.4564 26.9867 25.257 26.7563 26.2674C26.6499 26.7459 26.1359 27.2068 25.6396 27.6677C25.4623 27.8272 25.3028 27.9867 25.1433 28.1462C24.8242 28.4653 24.7533 28.9439 24.9837 29.3338C26.5613 32.1167 28.8656 34.421 31.6484 35.9985C31.808 36.0872 31.9675 36.1226 32.127 36.1226C32.3752 36.1226 32.641 36.0162 32.836 35.839C32.9955 35.6795 33.1551 35.5199 33.3146 35.3427C33.7755 34.8464 34.254 34.3146 34.7149 34.226C34.8567 34.1905 35.0162 34.1905 35.1935 34.1905C36.4342 34.1905 38.6322 34.7932 39.1817 35.449C39.3589 35.6617 39.4475 35.9099 39.4475 36.2112V39.3486C39.4475 39.6499 39.3057 39.9513 39.0753 40.164C38.8626 40.3589 38.5967 40.4653 38.2777 40.4653C38.2422 40.4653 38.189 40.4653 38.1536 40.4653C33.3855 40.4653 28.9365 38.6573 25.6573 35.3781C22.3781 32.099 20.5701 27.6499 20.5701 22.7755C20.5347 22.4742 20.6588 22.1728 20.8715 21.9424C21.0842 21.712 21.3855 21.5702 21.6868 21.5702H24.8419C24.8419 21.5702 24.8597 21.5702 24.8774 21.5702M24.8419 19H24.771H21.6514C20.641 19 19.6661 19.4431 18.9571 20.2053C18.3013 20.9321 17.9645 21.8892 18 22.8464C18.0177 28.3412 20.0916 33.4284 23.8493 37.1684C27.5893 40.9084 32.641 42.9823 38.0827 43C38.1536 43 38.2245 43 38.2954 43C39.2703 43 40.1388 42.6632 40.8301 42.0251C41.0605 41.8124 41.2732 41.5643 41.4505 41.2984C41.8227 40.7134 42.0177 40.0399 42.0177 39.3486V36.2112C42.0354 35.3604 41.7341 34.4742 41.1492 33.7829C39.7843 32.1699 36.5051 31.6381 35.2112 31.6381C34.839 31.6381 34.5022 31.6736 34.1831 31.7445C33.1905 31.9572 32.4461 32.5775 31.8789 33.1625C30.3013 32.0812 28.9187 30.7164 27.8552 29.1388C28.4402 28.5716 29.0605 27.8272 29.2732 26.8346C29.6277 25.1507 28.8478 21.2866 27.2348 19.904C26.5967 19.3545 25.7105 19.0177 24.8596 19.0177L24.8419 19Z"
          fill="#113E57"
        />
      </g>
      <defs>
        <clipPath id="clip0_9116_71335">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(18 19)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
