import React from "react";

export const _21 = (props) => {
  const { className = "", style = {} } = props;
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 840 840"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M642.376 341.31C601.386 341.31 560.386 341.27 519.396 341.34C511.946 341.35 505.336 338.55 498.896 335.46C473.596 323.33 458.866 302.83 453.886 275.63C448.176 244.43 456.686 216.58 476.396 192.15C490.916 174.15 509.606 162.51 532.426 157.91C543.706 155.64 554.985 155.8 566.195 158.47C567.665 158.82 568.685 158.91 569.335 157.09C576.115 138.41 588.965 124.9 605.765 114.85C621.415 105.49 638.486 100.69 656.566 99.15C682.606 96.93 707.426 101.33 730.736 113.23C749.026 122.57 763.996 135.61 773.816 153.97C780.926 167.26 783.605 181.39 781.616 196.35C781.336 198.44 781.505 199.4 784.095 199.82C807.405 203.57 821.945 218.02 829.385 239.46C839.085 267.43 834.746 293.77 816.816 317.44C806.936 330.49 793.326 337.68 777.225 340.23C771.665 341.11 766.076 341.41 760.436 341.4C721.076 341.36 681.716 341.38 642.356 341.38C642.376 341.37 642.376 341.34 642.376 341.31ZM700.416 307.44C700.416 307.47 700.416 307.5 700.416 307.53C722.496 307.53 744.576 307.64 766.655 307.47C774.905 307.41 782.325 304.7 788.215 298.58C800.575 285.73 803.926 262.24 795.626 246.45C788.396 232.69 776.255 229.33 765.355 238.71C758.435 244.67 751.165 245.67 742.845 243.26C735.785 241.22 730.865 234.91 730.715 227.59C730.595 221.86 732.976 217.36 736.936 213.33C746.516 203.58 750.655 192.04 747.085 178.51C743.825 166.16 735.965 156.91 725.655 149.9C700.835 133.02 673.555 128.77 644.575 135.6C630.455 138.92 617.626 145.11 608.246 156.7C602.096 164.3 597.835 172.67 600.335 183.01C600.625 184.2 600.446 185.56 600.226 186.79C597.906 199.94 583.926 205.74 572.806 197.96C562.906 191.03 551.955 189.29 540.385 191.08C526.085 193.29 514.255 200.45 504.805 211.16C490.485 227.37 484.005 246.32 487.065 267.99C488.975 281.51 495.186 292.8 506.476 300.79C511.356 304.24 516.605 307.13 522.976 307.15C562.316 307.25 601.666 306.99 641.006 307.41C660.796 307.62 680.606 307.44 700.416 307.44Z"
        fill="#808080"
      />
      <path
        d="M301.875 551.469C282.515 551.469 263.156 551.249 243.796 551.519C204.626 552.059 165.435 551.109 126.295 552.319C108.885 552.859 95.7355 546.179 83.8755 535.009C70.8055 522.699 62.2255 507.769 59.1355 489.969C53.1255 455.399 74.1255 423.949 108.745 418.159C120.195 416.239 131.355 417.249 142.355 420.629C144.265 421.219 144.925 421.149 145.235 418.849C148.735 392.889 161.775 372.509 182.205 356.609C200.685 342.229 221.835 334.239 244.936 331.729C274.176 328.559 301.785 333.879 326.755 349.979C346.565 362.749 360.465 380.169 366.565 403.249C369.305 413.629 370.105 424.179 368.275 434.809C367.895 437.019 368.685 437.509 370.665 437.959C391.086 442.579 406.655 453.739 414.655 473.379C423.505 495.089 419.255 515.029 403.345 532.079C391.375 544.909 376.245 551.209 358.585 551.189C339.675 551.159 320.776 551.179 301.866 551.179C301.876 551.279 301.875 551.379 301.875 551.469ZM159.235 518.129C175.795 517.829 190.605 517.959 205.415 517.769C246.206 517.249 287.005 517.809 327.796 517.159C338.246 516.999 348.695 516.889 359.146 517.109C365.396 517.239 370.695 515.179 375.585 511.619C387.205 503.149 388.255 488.219 377.785 478.409C370.355 471.459 361.246 469.669 351.456 470.829C348.686 471.159 346.016 471.019 343.345 470.559C335.935 469.279 330.645 465.389 328.535 457.889C327.085 452.759 328.685 448.109 330.715 443.429C336.745 429.489 336.755 415.559 329.915 401.869C324.076 390.169 314.866 381.649 303.445 375.629C279.895 363.229 255.236 361.799 230.055 369.559C211.815 375.179 196.406 385.189 186.415 401.909C178.235 415.599 175.895 430.079 182.485 445.349C185.545 452.439 185.546 459.609 180.046 465.749C172.666 473.979 161.186 473.949 153.046 465.839C146.535 459.349 138.955 454.629 129.985 452.529C120.805 450.379 111.705 450.439 103.485 455.909C94.1455 462.119 90.1355 473.609 92.9655 485.819C96.0655 499.169 103.875 509.159 115.265 516.499C117.055 517.649 119.005 518.159 121.185 518.149C134.465 518.099 147.735 518.129 159.235 518.129Z"
        fill="#808080"
      />
      <path
        d="M233.855 315.561C237.855 306.201 242.515 297.601 247.655 289.251C258.145 272.211 270.755 256.891 285.475 243.341C304.145 226.151 325.165 212.601 348.575 202.711C364.525 195.971 381.035 191.211 398.075 188.331C413.235 185.771 428.525 184.831 443.895 185.511C448.055 185.691 452.205 186.161 456.355 186.511C456.865 186.551 457.545 186.351 457.805 186.961C458.075 187.581 457.455 187.911 457.125 188.311C450.665 196.251 445.145 204.841 441.775 214.481C440.145 219.141 437.735 219.841 433.325 219.771C402.465 219.271 373.455 226.791 346.365 241.371C315.835 257.801 291.755 281.091 274.265 311.081C273.035 313.191 271.795 313.381 269.475 313.251C261.145 312.801 252.775 312.511 244.475 313.791C241.095 314.311 237.725 314.911 233.855 315.561Z"
        fill="#E4C276"
      />
      <path
        d="M596.652 548.649C596.142 549.389 595.702 550.199 595.102 550.859C569.152 578.989 538.112 599.289 501.732 611.289C486.012 616.469 469.912 619.829 453.402 621.289C444.522 622.069 435.642 622.489 426.752 622.169C413.232 621.689 399.842 620.029 386.602 617.209C384.602 616.779 384.442 616.159 385.502 614.399C390.892 605.469 396.742 596.799 401.142 587.309C401.912 585.659 403.002 585.589 404.442 585.849C414.462 587.699 424.622 588.479 434.762 588.299C474.162 587.629 510.072 576.139 542.042 552.879C543.822 551.589 545.372 551.339 547.542 551.839C562.602 555.329 577.442 553.789 592.122 549.549C593.592 549.119 595.002 548.409 596.602 548.609L596.652 548.649Z"
        fill="#E4C276"
      />
      <path
        d="M595.525 475.039C596.005 479.859 594.025 483.929 591.565 487.789C583.865 499.859 576.185 511.949 568.315 523.909C564.935 529.049 559.825 531.949 553.705 532.179C546.655 532.439 540.985 529.219 537.275 523.279C533.265 516.869 533.485 510.019 537.395 503.689C545.345 490.819 553.515 478.069 561.805 465.419C568.215 455.639 581.055 453.849 589.725 461.589C593.595 465.029 596.085 469.499 595.525 475.039Z"
        fill="#F5F5F5"
      />
      <path
        d="M127.064 587.36C127.434 591.56 125.944 595.24 123.694 598.61C116.514 609.39 109.344 620.18 101.984 630.85C97.9838 636.66 92.2938 640.14 85.1438 639.51C76.7138 638.77 70.7338 634.27 68.5738 625.76C67.1738 620.25 68.1238 614.91 71.3138 610.16C78.7338 599.1 86.1538 588.04 93.7538 577.11C98.2638 570.64 104.764 568.02 112.454 569.45C119.734 570.8 124.554 575.24 126.644 582.51C127.114 584.15 126.974 585.75 127.064 587.36Z"
        fill="#F5F5F5"
      />
      <path
        d="M183.052 200.38C183.642 193.32 186.822 187.95 193.422 184.93C199.572 182.12 205.682 182.56 210.762 186.97C222.382 197.08 234.302 206.83 245.932 216.92C253.032 223.08 254.832 231.21 250.792 238.89C247.022 246.04 238.412 249.86 230.712 247.15C226.392 245.62 223.022 242.83 219.652 239.91C209.862 231.43 200.242 222.76 190.052 214.75C185.472 211.15 182.792 206.51 183.052 200.38Z"
        fill="#E4C276"
      />
      <path
        d="M673.554 601.348C673.544 608.958 669.394 615.208 662.104 617.818C655.574 620.158 648.894 618.858 643.264 613.188C636.824 606.698 629.714 600.908 622.854 594.868C618.234 590.798 613.474 586.838 609.174 582.448C602.384 575.508 602.674 565.288 610.034 558.168C615.824 552.558 624.994 551.298 632.574 557.698C642.944 566.458 652.974 575.628 663.094 584.688C665.564 586.898 668.504 588.548 670.424 591.458C672.434 594.508 673.654 597.678 673.554 601.348Z"
        fill="#E4C276"
      />
      <path
        d="M412.803 133.4C412.803 123.05 412.913 112.7 412.753 102.35C412.683 97.7296 412.573 93.2996 414.853 88.9896C418.193 82.6996 426.893 79.4296 433.493 80.7796C443.283 82.7796 448.783 93.5896 446.813 101.24C446.593 102.1 446.693 103.05 446.693 103.95C446.663 116.67 446.653 129.39 446.613 142.11C446.583 151.13 441.553 158.34 433.923 160.37C423.103 163.24 412.643 155.5 412.293 144.31C412.183 140.68 412.273 137.04 412.273 133.41C412.453 133.4 412.623 133.4 412.803 133.4Z"
        fill="#E4C276"
      />
      <path
        d="M443.812 679.359C444.232 687.429 443.732 695.499 444.332 703.579C444.922 711.539 440.122 718.149 433.442 720.659C426.142 723.399 418.452 721.499 413.552 715.319C410.412 711.359 409.642 706.779 409.892 701.859C410.232 695.059 409.982 688.259 409.862 681.449C409.702 672.569 408.592 663.609 410.142 654.819C411.702 645.959 418.762 640.989 427.882 641.309C435.532 641.579 441.972 647.76 443.452 655.97C444.152 659.87 443.702 663.76 443.792 667.65C443.882 671.54 443.812 675.449 443.812 679.359Z"
        fill="#E4C276"
      />
      <path
        d="M231.485 564.439C235.725 564.439 238.645 564.639 241.535 564.379C244.375 564.119 245.515 565.579 246.515 567.869C249.965 575.829 247.505 582.649 242.765 589.149C235.465 599.169 228.185 609.189 221.015 619.299C215.685 626.809 205.625 629.999 198.015 626.379C186.985 621.129 183.455 608.649 190.435 598.479C197.195 588.639 204.325 579.059 211.295 569.369C211.985 568.409 212.675 567.459 213.305 566.469C213.945 565.479 214.755 564.989 215.985 564.949C221.595 564.789 227.195 564.119 231.485 564.439Z"
        fill="#F5F5F5"
      />
      <path
        d="M122.894 758.58C109.614 758.59 100.684 744.51 106.584 732.56C111.094 723.44 117.364 715.37 122.854 706.84C125.264 703.1 127.584 699.3 131.364 696.76C137.324 692.75 143.754 692 150.114 695.27C156.184 698.4 159.754 703.59 160.334 710.63C160.764 715.84 158.624 720.24 155.984 724.39C151.024 732.19 145.934 739.91 140.714 747.54C137.244 752.61 133.254 757.2 126.664 758.28C126.224 758.35 125.784 758.55 125.344 758.58C124.534 758.62 123.714 758.58 122.894 758.58Z"
        fill="#F5F5F5"
      />
      <path
        d="M651.726 359.92C658.266 359.92 664.806 359.9 671.346 359.93C672.106 359.93 673.136 359.5 673.566 360.3C673.936 360.97 673.156 361.59 672.726 362.17C666.456 370.6 661.066 379.61 655.216 388.31C650.366 395.52 645.706 402.87 640.806 410.05C637.356 415.11 632.356 418.3 626.326 418.65C616.496 419.21 608.036 412.77 606.996 402.16C606.466 396.73 608.346 392.03 611.246 387.6C616.966 378.89 622.666 370.17 628.326 361.42C629.136 360.17 630.176 359.89 631.546 359.9C638.276 359.95 644.996 359.92 651.726 359.92Z"
        fill="#F5F5F5"
      />
      <path
        d="M725.925 458.839C726.275 464.439 723.105 469.069 720.175 473.739C716.235 480.039 712.215 486.289 708.055 492.459C703.935 498.579 698.225 501.889 690.645 501.759C681.925 501.599 673.835 493.589 673.475 484.849C673.275 479.909 674.755 475.559 677.325 471.489C681.865 464.279 686.345 457.029 691.025 449.919C697.055 440.749 707.205 437.149 716.695 442.419C722.825 445.799 726.135 451.249 725.925 458.839Z"
        fill="#F5F5F5"
      />
      <path
        d="M40.8541 662.019C47.3741 662.449 52.7441 665.039 56.1241 670.839C59.6741 676.919 60.2841 683.469 56.3341 689.379C50.3041 698.389 44.7941 707.819 37.4441 715.859C32.0841 721.729 23.9241 722.919 16.4641 719.649C10.2441 716.919 5.71407 709.479 6.01407 702.229C6.17407 698.149 7.66407 694.479 10.0141 691.149C14.7141 684.499 19.2241 677.709 24.1341 671.209C28.2441 665.749 33.3941 661.709 40.8541 662.019Z"
        fill="#F5F5F5"
      />
      <path
        d="M761.173 359.91C767.503 359.91 773.823 359.91 780.153 359.91C782.743 359.91 785.293 359.3 787.943 359.83C789.593 360.16 789.713 360.55 789.033 361.85C786.893 365.96 784.103 369.66 781.543 373.5C775.213 382.99 769.053 392.6 762.383 401.84C756.773 409.63 746.153 411.75 738.183 406.83C729.993 401.77 726.653 392.46 730.863 383.44C734.593 375.46 740.313 368.66 745.043 361.27C745.733 360.19 746.803 359.88 748.103 359.9C752.453 359.95 756.813 359.91 761.173 359.91Z"
        fill="#F5F5F5"
      />
      <path
        d="M269.293 722.638C257.893 723.218 246.463 710.068 253.163 696.548C257.293 688.218 262.823 680.558 269.123 673.648C274.243 668.028 282.733 666.628 289.403 669.638C296.543 672.848 300.963 680.008 300.453 687.718C300.273 690.448 299.623 693.228 298.163 695.478C293.343 702.918 288.713 710.538 282.723 717.128C279.283 720.898 274.843 722.828 269.293 722.638Z"
        fill="#F5F5F5"
      />
      <path
        d="M447.356 519.768C438.176 520.428 426.976 510.018 430.216 497.578C430.986 494.628 431.946 491.848 433.766 489.428C438.386 483.278 442.016 476.378 447.376 470.798C452.616 465.348 460.716 463.768 467.426 466.588C474.506 469.558 479.066 476.448 478.796 484.178C478.656 488.328 477.206 492.148 474.796 495.558C471.096 500.818 467.456 506.118 463.666 511.308C459.756 516.658 454.676 519.928 447.356 519.768Z"
        fill="#F5F5F5"
      />
      <path
        d="M554.713 358.829C556.443 358.829 558.163 358.869 559.893 358.819C561.583 358.769 561.943 359.489 561.463 361.019C560.573 363.899 559.443 366.649 557.743 369.149C551.433 378.439 545.193 387.779 538.793 396.999C534.983 402.489 529.663 406.079 522.933 406.149C513.263 406.249 505.093 399.129 504.443 389.519C504.073 384.109 505.673 379.369 508.763 374.989C511.843 370.629 514.773 366.159 517.783 361.739C518.443 360.779 519.053 359.999 520.503 359.859C525.843 359.319 531.183 359.289 536.523 359.429C542.603 359.569 548.643 358.899 554.713 358.829Z"
        fill="#F5F5F5"
      />
      <path
        d="M326.112 591.049C325.922 586.499 327.262 582.629 329.672 579.079C332.972 574.209 336.282 569.329 339.542 564.429C340.312 563.279 341.222 562.879 342.642 562.729C350.882 561.849 359.152 562.659 367.382 562.159C371.822 561.889 376.242 561.839 380.672 561.669C383.062 561.579 383.692 562.159 382.942 564.619C381.222 570.219 377.612 574.689 374.472 579.429C370.182 585.929 365.752 592.329 361.282 598.709C357.652 603.889 353.222 607.869 346.512 608.729C339.892 609.579 334.592 607.269 330.292 602.529C327.342 599.299 325.772 595.349 326.112 591.049Z"
        fill="#F5F5F5"
      />
      <path
        d="M596.605 548.6C596.705 548.52 596.805 548.43 596.895 548.35C596.815 548.45 596.735 548.55 596.645 548.65C596.655 548.65 596.605 548.6 596.605 548.6Z"
        fill="#E4C276"
      />
    </svg>
  );
};
