import React from "react";

export const HomeSightLogoKey = (props) => {
    const { className = "", style={}} = props;
    return (
        <svg className={className} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}> 
        <g id="Logomark">
        <g id="layer-MC0">
        <path id="path1" d="M0 60H60V-7.62939e-06H0V60Z" fill="#005C98"/>
        <g id="Group 1">
        <path id="path2" d="M45.2345 45.1367H40.6426V14.7641H45.2345V45.1367Z" fill="white"/>
        <path id="path3" d="M27.9445 39.9805H23.3848V20.0325H27.9445V39.9805Z" fill="white"/>
        <path id="path4" d="M19.3555 32.2891H14.7656V27.6997H19.3555V32.2891Z" fill="white"/>
        <path id="path5" d="M36.6012 32.2695H32V27.7223H36.6012V32.2695Z" fill="white"/>
        </g>
        </g>
        </g>
        </svg>
    );
}