import React from "react";

export const EndCallIcon = (props) => {
    const { className="", style = {}, disabled = false, onClick } = props;
    return (
        <svg
            className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
            onClick={disabled ? null: onClick}
            xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
            style={style}
        >
            <path className="stroke" d="M18 5.45562L17.1501 6.35814C16.3988 7.15588 15.3844 7.19449 14.3648 6.56033C14.3579 6.55666 14.3527 6.55114 14.3458 6.54563C13.4456 5.92434 12.7723 5.50525 12.7723 5.50525L12.7498 3.748C12.7498 3.748 11.564 3.30501 9.96105 3.37302C8.35811 3.44103 7.2364 3.88402 7.2364 3.88402L7.20004 5.47584L5.76328 6.40409C5.76328 6.40409 5.75289 6.41696 5.74597 6.42063C4.65715 7.1761 3.71546 7.15772 2.95207 6.34527L2 5.3343C2 5.3343 3.85567 1 10.3765 1C16.0959 1 18 5.45562 18 5.45562Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M6 14L10 18L14 14" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M10 18L10 10" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}


