/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { DashboardIcon } from "../Common/Icons";
import ListNotification from "./ListNotification";
import { useMainContext } from "../../MainContext";
import { useSelector } from "react-redux";
import { translator } from "@commscopemycloud/humaui/Store/configStore";

const Notification = (props) => {
  const trans = useSelector(translator);
  const { parentBread, openSubMenu, hubRecord } = props;
  const { notifications, fetchingNotifications, fetchNotifications } =
    useMainContext();

  const [action, setAction] = useState(null);
  const [record, setRecord] = useState(null);

  useEffect(() => fetchNotifications(), []);

  /* handler functions */
  const onActionClick =
    (action, record, refresh = false) =>
      () => {
        setAction(action);
        setRecord(record);
        refresh && fetchNotifications();
      };

  const header = (parentBread || []).concat(
    {
      label: <Link to="/">{trans("DASHBOARD")}</Link>,
      icon: (
        <Link to="/">
          <DashboardIcon />
        </Link>
      ),
    },
    {
      label: trans("NOTIFICATIONS"),
      onClick: onActionClick(null, null),
    }
  );

  return !action ? (
    <ListNotification
      header={header}
      notifications={notifications}
      fetchingNotifications={fetchingNotifications}
      onActionClick={onActionClick}
      openSubMenu={openSubMenu}
      hubRecord={hubRecord}
    />
  ) : null;
};

Notification.propTypes = {
  parentBread: PropTypes.arrayOf(PropTypes.object),
};

export default Notification;
