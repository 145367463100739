import React from "react";

export const HomeSightNewLogo = (props) => {
    const { className = "", style={} } = props;
    return (
        <svg className={"custom-icon "+className} width="156" height="69" viewBox="0 0 156 69" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
        <g clipPath="url(#clip0_8946_22065)">
        <path d="M65.5288 11.5171C60.8314 11.5171 57.1525 15.1682 57.1525 19.8296C57.1525 24.491 60.8314 28.1421 65.5288 28.1421C70.2263 28.1421 73.9041 24.491 73.9041 19.8296C73.9041 15.1682 70.2251 11.5171 65.5288 11.5171ZM65.5288 24.6397C62.873 24.6397 60.9464 22.619 60.9464 19.8354C60.9464 17.0518 62.873 15.0323 65.5288 15.0323C68.165 15.0323 70.0776 17.0518 70.0776 19.8354C70.0776 22.619 68.165 24.6397 65.5288 24.6397Z" fill="#0072BC" stroke="none"/>
        <path d="M93.8923 11.5171C91.7508 11.5171 89.8649 12.3428 88.6885 13.7886C87.6062 12.3021 85.9827 11.5171 83.983 11.5171C82.3723 11.5171 80.9497 12.0606 79.9417 13.0546V11.7714H76.2104V27.889H79.9417V17.9297C79.935 17.3837 80.104 16.85 80.4236 16.4073C80.7467 15.9616 81.1726 15.6003 81.665 15.3543C82.1575 15.1083 82.7021 14.9847 83.2525 14.994C85.207 14.994 86.3741 16.4224 86.3741 18.8135V27.889H90.1041V18.4012C90.1041 18.3002 90.1041 18.2003 90.0972 18.0993C90.0622 17.52 90.222 16.9456 90.5512 16.4677C91.248 15.4992 92.2072 14.994 93.3836 14.994C95.3183 14.994 96.4726 16.4224 96.4726 18.8135V27.889H100.204V18.4012C100.204 14.1544 97.7861 11.5171 93.8923 11.5171Z" fill="#0072BC" stroke="none"/>
        <path d="M79.9289 38.3984H76.1976V54.7064H79.9289V38.3984Z" fill="#0072BC" stroke="none"/>
        <path d="M79.9126 31.9941H76.1814V35.7254H79.9126V31.9941Z" fill="#0072BC" stroke="none"/>
        <path d="M98.0903 51.9916V39.0548H94.3638V40.417C93.1653 39.4218 91.7567 38.9375 90.067 38.9375C85.5263 38.9375 82.2306 42.246 82.2306 46.8052C82.2306 51.3645 85.5263 54.673 90.067 54.673C91.8577 54.673 93.3929 54.1272 94.5182 53.0913C94.5182 53.0913 95.215 57.899 89.8545 58.0523C88.0282 58.0989 86.2399 57.5259 84.7808 56.4265L84.6728 56.3452L82.8368 59.5748L82.9274 59.6363C85.2186 61.1762 87.9929 61.4944 89.9183 61.4944L91.0866 61.4805C92.9509 61.4805 94.7388 60.7399 96.057 59.4216C97.3753 58.1034 98.1159 56.3155 98.1159 54.4512L98.0903 51.9916ZM94.3591 49.1836C93.5114 50.483 92.0365 51.2274 90.3178 51.2274C87.828 51.2274 86.021 49.3694 86.021 46.8052C86.021 44.2609 87.828 42.4144 90.3178 42.4144C92.0598 42.4144 93.5323 43.1588 94.3591 44.4571V49.1836Z" fill="#0072BC" stroke="none"/>
        <path d="M109.239 38.3348C107.45 38.3348 105.916 38.8899 104.879 39.9048V32.0034H101.145V54.7055H104.876V44.3201C105.538 42.7024 106.836 41.8117 108.536 41.8117C110.729 41.8117 112.039 43.2389 112.039 45.6312V54.7055H115.769V45.2177C115.769 41.0371 113.205 38.3348 109.235 38.3348" fill="#0072BC" stroke="none"/>
        <path d="M121.856 49.1243V42.1066L125.572 42.1182V38.3882H121.865V34.8521H118.132L118.124 49.8873C118.124 52.9508 119.927 54.7066 123.071 54.7066H125.569V51.2982H123.895C122.446 51.2982 121.856 50.67 121.856 49.1301" fill="#0072BC" stroke="none"/>
        <path d="M54.8578 7.60449H51.1266V31.9904H54.8578V7.60449Z" fill="#0072BC" stroke="none"/>
        <path d="M41.0338 11.8096H37.3026V27.8691H41.0338V11.8096Z" fill="#0072BC" stroke="none"/>
        <path d="M47.9458 17.9644H44.2146V21.6956H47.9458V17.9644Z" fill="#0072BC" stroke="none"/>
        <path d="M34.1229 17.9644H30.3917V21.6956H34.1229V17.9644Z" fill="#0072BC" stroke="none"/>
        <path d="M116.363 14.0336C114.953 12.5553 113.026 11.7749 110.789 11.7749C106.128 11.7749 102.612 15.3447 102.612 20.0781C102.612 24.7337 106.231 28.3814 110.858 28.3814C113.645 28.3814 116.117 27.2654 117.645 25.3214L117.715 25.2319L115.03 22.921L114.952 23.0174C114.262 23.879 112.992 24.9079 110.858 24.9079C108.843 24.9079 106.677 23.5399 106.358 20.9259H118.577V20.8097C118.707 18.0761 117.919 15.6687 116.359 14.0313M110.559 14.9917C112.766 14.9917 114.275 16.1681 114.639 18.1481H106.493C107.01 15.9683 108.895 14.9917 110.558 14.9917" fill="#0072BC" stroke="none"/>
        <path d="M65.7879 55.2941C62.2808 55.2941 59.2243 53.8007 57.6101 51.2981L57.595 51.276L57.6159 51.2586L60.6294 48.8036L60.648 48.8292C61.6455 50.1751 63.5013 51.6163 65.7194 51.6163C68.0419 51.6163 69.4274 50.6176 69.4274 48.9453C69.4274 47.1198 68.1383 46.2093 65.9121 45.2547L64.0099 44.4639C60.4099 42.9345 58.5902 40.6107 58.5902 37.5589C58.5902 35.7983 59.3787 34.1911 60.8094 33.0356C62.1403 31.9591 63.9554 31.3413 65.7879 31.3413C68.5877 31.3413 70.8058 32.3133 72.5698 34.3142L72.5884 34.3351L72.5686 34.3549L69.7943 37.1129L69.7734 37.0874C68.5204 35.6032 67.3835 35.0226 65.7194 35.0226C64.1818 35.0226 62.6245 35.8831 62.6245 37.5275C62.6245 39.007 63.5907 39.986 66.0712 41.0207L67.9397 41.8103C71.6559 43.406 73.4617 45.6333 73.4617 48.6178C73.4617 52.6103 70.3773 55.2941 65.7879 55.2941Z" fill="#0072BC" stroke="none"/>
        </g>
        <defs>
        <clipPath id="clip0_8946_22065">
        <rect width="95.2258" height="54" fill="white" transform="translate(30.3871 7.5)"/>
        </clipPath>
        </defs>
        </svg>
        
    );
}


