import React from "react";

export const TemperatureIcon = (props) => {
  const {
    className = "",
    style = {},
    disabled = false,
    onClick,
    fill = null,
  } = props;
  return (
    <svg
      className={className}
      style={style}
      width="24"
      height="51"
      viewBox="0 0 24 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.8686 29.2701V7.86861C19.8686 3.53285 16.3358 0 12 0C7.66423 0 4.13139 3.53285 4.13139 7.86861V29.2555C1.53285 31.5182 0 34.8175 0 38.3212C0 44.9489 5.37226 50.3212 12 50.3212C18.6277 50.3212 24 44.9489 24 38.3212C24 34.8175 22.4818 31.5328 19.8686 29.2555V29.2701ZM12 47.7372C6.80292 47.7372 2.58394 43.5182 2.58394 38.3212C2.58394 35.1533 4.16058 32.2628 6.71533 30.5255V7.86861C6.71533 4.9489 9.09489 2.58394 12 2.58394C14.9051 2.58394 17.2847 4.9635 17.2847 7.86861V30.5255C19.8394 32.2482 21.4161 35.1533 21.4161 38.3212C21.4161 43.5182 17.1971 47.7372 12 47.7372Z"
        fill={fill ? fill : "#005D98"}
      />
      <path
        d="M9.28471 18.7764V32.0464C6.86135 33.0975 5.15332 35.5063 5.15332 38.3238C5.15332 42.1048 8.21901 45.1559 11.9854 45.1559C15.7519 45.1559 18.8176 42.0902 18.8176 38.3238C18.8176 35.5063 17.1241 33.0975 14.6862 32.0464V18.7764H9.27011H9.28471Z"
        fill={fill ? fill : "#005D98"}
      />
      <path
        d="M14.7005 15.7949H9.28442V17.5613H14.7005V15.7949Z"
        fill={fill ? fill : "#005D98"}
      />
      <path
        d="M14.7005 12.7412H9.28442V14.5076H14.7005V12.7412Z"
        fill={fill ? fill : "#005D98"}
      />
      <path
        d="M14.7005 10.0176H9.28442V11.784H14.7005V10.0176Z"
        fill={fill ? fill : "#005D98"}
      />
    </svg>
  );
};
