import React, { useEffect, useState } from "react";
import "./MotionCalendar.less";

const MotionCalendar = ({
  isTrend,
  onActionClick,
  shiftDate,
  dateToDisplay,
}) => {
  const [selectedDate, setSelectedDate] = useState(dateToDisplay);
  // const [showCal, setShowCal] = useState(true);
  const handleDateClick = (date) => {
    setSelectedDate(date.toDateString());
    const inputDateString = new Date(date + "UTC").toISOString();

    const [datePart, timePart] = inputDateString.split("T");

    const modifiedStartTime = "00:00:00";
    const modifiedEndTime = "23:59:59";
    const modifiedStartDateTime = `${datePart}T${modifiedStartTime}`;
    const modifiedEndDateTime = `${datePart}T${modifiedEndTime}`;
    const timezoneOffset = "+05:30";
    const updatedActivityDateStart = `${modifiedStartDateTime}${timezoneOffset}`;
    const updatedActivityDateEnd = `${modifiedEndDateTime}${timezoneOffset}`;
    onActionClick(
      date,
      updatedActivityDateStart,
      updatedActivityDateEnd,
      true
    )();
    // setShowCal(false);
  };

  useEffect(() => {
    if (shiftDate === "forward") {
      const nextDate = new Date(selectedDate);
      nextDate.setDate(nextDate.getDate() + 1);
      setSelectedDate(nextDate);
    } else if (shiftDate === "back") {
      const prevDate = new Date(selectedDate);
      prevDate.setDate(prevDate.getDate() - 1);
      setSelectedDate(prevDate);
    }
  }, [shiftDate]);

  const today = new Date();
  const prevMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  const daysInPrevMonth = new Date(
    today.getFullYear(),
    today.getMonth(),
    0
  ).getDate();

  const calendarDaysPrev = [];
  const calendarDays = [];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const renderCurrentMonthName = () => {
    const currentMonth = months[today.getMonth()];
    return (
      <div
        style={{
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "17.75px",
          textAlign: "left",
        }}
      >
        {currentMonth}
      </div>
    );
  };

  const renderPrevMonthName = () => {
    const prevMonth = months[today.getMonth() - 1];
    return (
      <div
        style={{
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "17.75px",
          textAlign: "left",
        }}
      >
        {prevMonth}
      </div>
    );
  };

  const renderCalendarPrev = () => {
    for (let i = today.getDate(); i <= daysInPrevMonth; i++) {
      const date = new Date(prevMonth.getFullYear(), prevMonth.getMonth(), i);
      calendarDaysPrev.push(
        <div
          key={date.toISOString()}
          onClick={() => handleDateClick(date)}
          className={`calendar-day ${
            selectedDate && selectedDate === date.toDateString()
              ? "selected"
              : ""
          }`}
        >
          {date.getDate()}
        </div>
      );
    }

    return calendarDaysPrev;
  };

  const renderCalendar = () => {
    for (let i = 1; i <= today.getDate(); i++) {
      const date = new Date(today.getFullYear(), today.getMonth(), i);
      calendarDays.push(
        <div
          key={date.toISOString()}
          onClick={() => handleDateClick(date)}
          className={`calendar-day ${
            selectedDate && selectedDate === date.toDateString()
              ? "selected"
              : ""
          }`}
        >
          {date.getDate()}
        </div>
      );
    }

    return calendarDays;
  };

  const trendPostion = isTrend ? { left: "200px", top: "22px" } : {};

  return (
    <div
      className="date-picker"
      style={{ fontFamily: "Euclid Circular", ...trendPostion }}
    >
      <div
        className="select-date-heading"
        // onClick={() => onActionClick(null, null, null, true)()}
      >
        <div className="select-date-label">Select Date</div>
      </div>
      <div>{renderPrevMonthName()}</div>
      <br />
      <div className="calendar">{renderCalendarPrev()}</div>
      <br />
      <div>{renderCurrentMonthName()}</div>
      <br />
      <div className="calendar">{renderCalendar()}</div>
    </div>
  );
};

export default MotionCalendar;
