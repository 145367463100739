import React from "react";

export const AddPlusIcon = (props) => {
    const { className = "", disabled = false, onClick, style = {} } = props;
    return (
        <svg className={className} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}
            onClick={disabled ? null : onClick}>
            <circle cx="30" cy="30" r="30" fill="#D9ECFA" />
            <path d="M38.1503 26.4703H33.5303V21.8503C33.5303 19.9003 31.9503 18.3203 30.0003 18.3203C28.0503 18.3203 26.4703 19.9003 26.4703 21.8503V26.4703H21.8503C19.9003 26.4703 18.3203 28.0503 18.3203 30.0003C18.3203 31.9503 19.9003 33.5303 21.8503 33.5303H26.4703V38.1503C26.4703 40.1003 28.0503 41.6803 30.0003 41.6803C31.9503 41.6803 33.5303 40.1003 33.5303 38.1503V33.5303H38.1503C40.1003 33.5303 41.6803 31.9503 41.6803 30.0003C41.6803 28.0503 40.1003 26.4703 38.1503 26.4703Z" fill="#113E57" />
        </svg>
    );
};