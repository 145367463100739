import React from "react";
import PropTypes from "prop-types";
import "../../styles/HomesightLoading.less";
import logo from "../../../static/images/homesight_loading.gif";

const HomesightLoading = ({ className, message }) => (
  <div className={`homesight-loading ${className}`}>
    <div className="logo-container">
      <img className="homesight-logo" alt="HomeSight" src={logo} />
    </div>
    <div className="loading-message">{message}</div>
  </div>
);

HomesightLoading.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
};

HomesightLoading.defaultProps = {
};

export default HomesightLoading;
