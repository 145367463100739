import React from "react";

export const DeclinedCallIcon = (props) => {
    const { className = "", style = {} } = props;

    return (
        <svg className={className} width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <path d="M29.727 15.0007C29.727 23.1344 23.1334 29.728 14.9997 29.728C6.86608 29.728 0.272461 23.1344 0.272461 15.0007C0.272461 6.86706 6.86608 0.273438 14.9997 0.273438C23.1334 0.273438 29.727 6.86706 29.727 15.0007Z" fill="#808080" />
            <path d="M11.2632 8.66552C10.5461 7.94847 9.38355 7.94847 8.6665 8.66552C7.94945 9.38257 7.94945 10.5451 8.6665 11.2622L8.69412 11.2898L12.8757 15.5267L8.69412 19.7082C7.96413 20.4078 7.95243 21.5757 8.65981 22.2981C9.37456 23.028 10.5545 23.0412 11.2769 22.3188L11.2908 22.3049L15.5276 18.0681L19.7092 22.3049C20.4066 23.0326 21.5659 23.0449 22.2786 22.3322L22.3332 22.2776C23.0459 21.5649 23.0336 20.4056 22.3059 19.7082L18.0691 15.5267L22.3059 11.2898L22.3198 11.2759C23.0422 10.5535 23.029 9.37359 22.2991 8.65884C21.5766 7.95146 20.4088 7.96316 19.7092 8.69315L15.5276 12.8747L11.2908 8.69315L11.2632 8.66552Z" fill="white" />
        </svg>
    );
}