import React from "react";

export const FallbackMedicalIcon = (props) => {
  const { className = "", style = {}, disabled = false, onClick } = props;

  return (
    <svg
      className={className}
      style={style}
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="30" cy="30" rx="30" ry="30" fill="#F5F5F5" />
    </svg>
  );
};
