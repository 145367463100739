import React from "react";

export const HumidityIcon = (props) => {
  const {
    className = "",
    style = {},
    disabled = false,
    onClick,
    fill = null,
  } = props;
  return (
    <svg
      className={className}
      style={style}
      width="37"
      height="43"
      viewBox="0 0 37 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13571_5518)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.5376 32.3564C15.5376 26.476 20.3366 21.6982 26.2762 21.6982C32.2157 21.6982 37.0148 26.4613 37.0148 32.3564C37.0148 38.2514 32.2157 43.0145 26.2762 43.0145C20.3366 43.0145 15.5376 38.2514 15.5376 32.3564ZM24.2914 32.1064C24.9283 31.4743 25.2542 30.6952 25.2542 29.8131C25.2542 28.9311 24.9283 28.1372 24.2914 27.5051C23.6545 26.8729 22.8695 26.5495 21.9808 26.5495C21.092 26.5495 20.2922 26.8729 19.6553 27.5051C19.0184 28.1372 18.6925 28.9017 18.6925 29.8131C18.6925 30.7246 19.0184 31.4743 19.6553 32.1064C20.2922 32.7386 21.0624 33.062 21.9808 33.062C22.8991 33.062 23.6545 32.7386 24.2914 32.1064ZM21.1513 30.6511C20.9291 30.4306 20.8106 30.1512 20.8106 29.8131C20.8106 29.475 20.9291 29.2104 21.1513 28.9752C21.3735 28.7547 21.6549 28.637 21.9808 28.637C22.3066 28.637 22.5732 28.7547 22.7954 28.9899C23.0176 29.2251 23.1361 29.4897 23.1361 29.8131C23.1361 30.1365 23.0176 30.4158 22.7954 30.6364C22.5732 30.8569 22.3066 30.9892 21.9808 30.9892C21.6549 30.9892 21.3735 30.8716 21.1513 30.6511ZM31.4159 26.6965H28.8386L21.3438 38.4278H23.9211L31.4159 26.6965ZM33.0896 37.5899C33.7265 36.9577 34.0524 36.1786 34.0524 35.2965C34.0524 34.4145 33.7265 33.6206 33.0896 32.9885C32.4527 32.3564 31.6677 32.0329 30.779 32.0329C29.8903 32.0329 29.0904 32.3564 28.4535 32.9885C27.8166 33.6206 27.4908 34.3851 27.4908 35.2965C27.4908 36.208 27.8166 36.9577 28.4535 37.5899C29.0904 38.222 29.8607 38.5454 30.779 38.5454C31.6973 38.5454 32.4527 38.222 33.0896 37.5899ZM29.6237 35.2965C29.6237 34.9731 29.7422 34.7085 29.9643 34.4733C30.1865 34.2381 30.4679 34.1205 30.7938 34.1205C31.1197 34.1205 31.3863 34.2381 31.6085 34.4733C31.8306 34.7085 31.9343 34.9731 31.9343 35.2965C31.9343 35.62 31.8306 35.8846 31.6085 36.1198C31.3863 36.355 31.1197 36.4726 30.7938 36.4726C30.4679 36.4726 30.2013 36.355 29.9643 36.1198C29.7422 35.8846 29.6237 35.62 29.6237 35.2965Z"
          fill={fill ? fill : "#005D98"}
        />
        <path
          d="M7.4207 32.0038C5.34704 29.6516 4.31021 26.9173 4.31021 23.8007C4.31021 22.0807 4.62126 20.493 5.25817 19.0082C5.89507 17.5234 6.82822 16.2003 8.08723 15.0096L17.2706 5.99795L26.4539 15.0096C27.3871 15.9063 28.1573 16.8913 28.7498 17.9791C28.8534 18.185 28.9275 18.3908 29.0312 18.5966C30.9419 18.9641 32.7193 19.7285 34.2746 20.787C33.9635 19.1846 33.4451 17.6557 32.7045 16.2444C31.8602 14.6421 30.7938 13.1867 29.4904 11.9077L17.2854 0L5.08042 11.9077C3.46593 13.4366 2.20692 15.2154 1.33303 17.2441C0.444314 19.2581 0.0147705 21.4485 0.0147705 23.8154C0.0147705 28.1374 1.46633 31.8862 4.38427 35.0762C7.22814 38.1781 10.7237 40.0157 14.8414 40.6038C13.7602 39.1337 12.9751 37.4431 12.5456 35.6055C10.6052 34.8704 8.90188 33.6797 7.43551 32.0185L7.4207 32.0038Z"
          fill={fill ? fill : "#005D98"}
        />
      </g>
      <defs>
        <clipPath id="clip0_13571_5518">
          <rect width="37" height="43" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
