import React from "react";

export const VisibilityOffIcon = (props) => {
    const { className="", style={}, onClick, disabled=false } = props;
    return (
        <svg
            className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
            onClick={disabled ? null: onClick}
            style={style}
            width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
            <mask id="mask0_45_18583" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <rect width="24" height="24" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_45_18583)">
                <path className="fill" d="M16.1 13.3L14.65 11.85C14.8 11.0667 14.575 10.3334 13.975 9.65005C13.375 8.96672 12.6 8.70005 11.65 8.85005L10.2 7.40005C10.4833 7.26672 10.7708 7.16672 11.0625 7.10005C11.3542 7.03338 11.6667 7.00005 12 7.00005C13.25 7.00005 14.3125 7.43755 15.1875 8.31255C16.0625 9.18755 16.5 10.25 16.5 11.5C16.5 11.8334 16.4667 12.1459 16.4 12.4375C16.3333 12.7292 16.2333 13.0167 16.1 13.3ZM19.3 16.4501L17.85 15.05C18.4833 14.5667 19.0458 14.0375 19.5375 13.4626C20.0292 12.8876 20.45 12.2334 20.8 11.5C19.9667 9.81672 18.7708 8.47922 17.2125 7.48755C15.6542 6.49588 13.9167 6.00005 12 6.00005C11.5167 6.00005 11.0417 6.03338 10.575 6.10005C10.1083 6.16672 9.65 6.26672 9.2 6.40005L7.65 4.85005C8.33333 4.56672 9.03333 4.35422 9.75 4.21255C10.4667 4.07088 11.2167 4.00005 12 4.00005C14.5167 4.00005 16.7583 4.69588 18.725 6.08755C20.6917 7.47922 22.1167 9.28338 23 11.5C22.6167 12.4834 22.1125 13.3959 21.4875 14.2375C20.8625 15.0792 20.1333 15.8167 19.3 16.4501ZM19.8 22.6L15.6 18.4501C15.0167 18.6334 14.4292 18.7709 13.8375 18.8626C13.2458 18.9542 12.6333 19 12 19C9.48333 19 7.24167 18.3042 5.275 16.9125C3.30833 15.5209 1.88333 13.7167 1 11.5C1.35 10.6167 1.79167 9.79588 2.325 9.03755C2.85833 8.27922 3.46667 7.60005 4.15 7.00005L1.4 4.20005L2.8 2.80005L21.2 21.2001L19.8 22.6ZM5.55 8.40005C5.06667 8.83338 4.625 9.30838 4.225 9.82505C3.825 10.3417 3.48333 10.9 3.2 11.5C4.03333 13.1834 5.22917 14.5209 6.7875 15.5126C8.34583 16.5042 10.0833 17.0001 12 17.0001C12.3333 17.0001 12.6583 16.9792 12.975 16.9376C13.2917 16.8959 13.6167 16.85 13.95 16.8L13.05 15.85C12.8667 15.9001 12.6917 15.9376 12.525 15.9626C12.3583 15.9875 12.1833 16 12 16C10.75 16 9.6875 15.5625 8.8125 14.6875C7.9375 13.8125 7.5 12.75 7.5 11.5C7.5 11.3167 7.5125 11.1417 7.5375 10.975C7.5625 10.8084 7.6 10.6334 7.65 10.45L5.55 8.40005Z"/>
            </g>
        </svg>
    );
}


