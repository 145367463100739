import React from "react";

export const UnassignedHS200DeviceIcon = (props) => {
    const { className } = props;
    return (       
        <svg className={className} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="30" cy="30" rx="30" ry="30" fill="#F5F5F5"/>
        <path d="M50.3024 24H9.68222C6.56468 24 4 26.4642 4 29.4597C4 32.4552 6.56468 34.9194 9.68222 34.9194H10.1737V36.2474C10.1737 36.6606 10.5269 37 10.9569 37H49.0431C49.4731 37 49.8263 36.6606 49.8263 36.2474V34.9194H50.3178C53.4507 34.9194 56 32.4552 56 29.4597C56 26.4642 53.4353 24 50.3178 24H50.3024ZM9.66686 33.3258C7.51683 33.3258 5.75074 31.6288 5.75074 29.563C5.75074 27.4972 7.50148 25.8002 9.66686 25.8002H40.2126C42.3627 25.8002 44.1288 27.4972 44.1288 29.563C44.1288 31.6288 42.3627 33.3258 40.2126 33.3258H9.66686Z" fill="#F46363"/>
        <path d="M28.0344 26.9199H16.8389C15.3546 26.9199 14.1514 28.0761 14.1514 29.5022C14.1514 30.9284 15.3546 32.0845 16.8389 32.0845H28.0344C29.5187 32.0845 30.722 30.9284 30.722 29.5022C30.722 28.0761 29.5187 26.9199 28.0344 26.9199Z" fill="#F46363"/>
        <path d="M9.80517 32.1181C11.3064 32.1181 12.5234 30.9488 12.5234 29.5063C12.5234 28.0639 11.3064 26.8945 9.80517 26.8945C8.30392 26.8945 7.08691 28.0639 7.08691 29.5063C7.08691 30.9488 8.30392 32.1181 9.80517 32.1181Z" fill="#F46363"/>
        </svg>
    )
}