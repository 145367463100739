import React from "react";

export const VideoOffIcon = (props) => {
    const { className = "", style = {}, disabled = false, onClick } = props;
    return (
        <svg
            className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
            onClick={disabled ? null: onClick}
            width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
            style={style}
        >
            <path className="stroke" d="M1 6V15.5C1 16.0523 1.44772 16.5 2 16.5H12" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M1 1L19 19" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M8 3.5H13C13.5523 3.5 14 3.94772 14 4.5V7V9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M19 15V5L14 7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}