export const DefaultClientId = "public-user-client";
export const DateTimeFormat = "DD/MM/YYYY HH:mm:ss";
export const DateFormat = "DD/MM/YYYY";
export const TimeFormat = "HH:mm";
export const UnknownUsername = "Unknown";

/* API related */
export const APIResponseStatus = {
  success: "Success",
  fail: "Failure",
};

export const APIResponseErrorCodes = {
  tokenExpired: "Token-Verification-Failure",
  unauthorizedAccess: "UserAccess-to-Method-NotAllowed",
};

/* Keys */
export const NavigationKeys = {
  accounts: "accounts",
  schedule: "schedule",
  staff: "staff",
  devices: "devices",
  profile: "profile",
  about: "about",
  notification: "notification",
};

export const AppStorageKeys = {
  envVars: "ENV_VARS",
  currentUser: "CURRENT_USER",
  language: "LANGUAGE",
  translation: "TRANSLATION",
  notificationLastRead: "NOTIF_LAST_READ",
};

export const MessageKeys = {
  apiError: "api_error",
};

/* Notification */
export const NotificationEventTypes = {
  videoCall: "video_call",
  eventSche: "schedule",
  eventZoom: "zoom",
};
export const NotificationTypes = {
  missedCall: "missed_call",
  declinedCall: "decliend_call",
  newEvent: "calendar",
  newContact: "contact",
  scheduledEvent: "scheduled_event",
  callStatus: "call_status",
};

/* Websocket Notification */
export const WSMessageTypes = {
  notification: "notification",
  videoCallStatus: "call_status_update",
  calendar: "calendar",
  contact: "contact",
};
export const WSNotificationTypes = {
  videoCall: "video_call",
  event: "calendar",
};

/** General */
export const StringFormats = {
  upper: "upper_case",
  lower: "lower_case",
  pascal: "pascal_case",
  sentence: "sentence_case",
};

/* Actions */
export const Actions = {
  listHub: "list_hub",
  hubDetails: "hub_details",
  callActivity: "callactivity",

  addEvent: "add_event",
  startEvent: "start_event",
  editEvent: "edit_event",

  addTask: "add_task",
  editTask: "edit_task",

  addStaff: "add_staff",
  editStaff: "edit_staff",
  assignStaff: "assign_staff",

  assignDevice: "assign_device",

  listContacts: "list_contacts",
  inviteContact: "invite_contact",
  editContact: "edit_contact",

  editProfile: "edit_profile",
  sendMessage: "send_message",
};

/* Hub related */
export const UserRoles = {
  admin: "Admin",
  contact: "Contact",
  principal: "Principal",
  staff: "Staff",
  manage: "Manage",
  care: "Care",
  social: "Social",
  empty: "",
};

/* User related */
export const UserTypes = {
  providerAdmin: "ProviderAdmin",
  admin: "Admin",
  principal: "Principal",
  contact: "Contact",
  staff: "Staff",
};
export const UserTypeDisplay = {
  [UserTypes.providerAdmin]: "Staff Admin",
  [UserTypes.admin]: "Account Manager",
  [UserTypes.principal]: "Account Principal",
  [UserTypes.contact]: "Social",
  [UserTypes.staff]: "Staff",
};

export const UserLocale = {
  "en-US": "English",
  "en-GB": "English",
  "en-CA": "English",
  "fr-CA": "Fran\u00E7ais",
  "es-ES": "Espa\u00F1ol",
  "en-AU": "English",
  "en-NZ": "English",
  "fr-FR": "Fran\u00E7ais", // "ç" Unicode value
  "es-US": "Espa\u00F1ol", // "ñ" Unicode value
  "nl-NL": "Nederlands",
  "en-IN": "English",
};

export const UserPermissionLevel = { ...UserTypes, staffAdmin: "Staff Admin" };

export const UserPermissionLevelDisplay = {
  ...UserTypeDisplay,
  [UserPermissionLevel.staffAdmin]: "Staff Admin",
};

export const RoleType = {
  user: "user",
  staff: "staff",
};

/* Video Call related */
export const VideoCallInactiveScreenDismissTimeout = 60; /* seconds */
export const VideoCallingTimeout = 60; /* seconds */
export const VideoRingingTimeout = 30; /* seconds */
export const VideoCallNotificationTimeout = 30; /* seconds */
export const VideoCallStatus = {
  calling: "Calling",
  ringing: "Ringing",
  accepted: "Accepted",
  joining: "Joining",
  incall: "In Call",
  leaving: "Left Call",
  declined: "Call Declined",
  missed: "No Response",
  busy: "Busy",
  failed: "Invite Failed",
  unreachable: "Unreachable",
};
export const VideoCallStatusUpdate = {
  calling: "calling",
  invited: "invited",
  ringing: "ringing",
  accepted: "accepted",
  unreachable: "unreachable",
  declined: "declined",
  missed: "missed",
  busy: "busy",
  joining: "joining",
  leaving: "leaving",
  available: "available",
  call_ack: "call-acknowledged",
};
export const InviteStatusFromAPI = {
  success: "notified",
  error: "error",
  fail: "failed",
};
export const ResendInviteDisabled = [
  VideoCallStatus.calling,
  VideoCallStatus.ringing,
  VideoCallStatus.accepted,
  VideoCallStatus.joining,
  VideoCallStatus.incall,
];
export const CallBusyStatus = [
  VideoCallStatus.calling,
  VideoCallStatus.ringing,
  VideoCallStatus.accepted,
  VideoCallStatus.joining,
  VideoCallStatus.incall,
];
export const CallFailStatus = [
  VideoCallStatus.declined,
  VideoCallStatus.missed,
  VideoCallStatus.busy,
  VideoCallStatus.failed,
  VideoCallStatus.unreachable,
];
export const CallSuccessStatus = [
  VideoCallStatus.accepted,
  VideoCallStatus.incall,
];
export const CallWaitStatus = [
  VideoCallStatus.calling,
  VideoCallStatus.ringing,
  ...CallFailStatus,
];
export const CallEndStatus = [VideoCallStatus.leaving];

/* Event related */
export const ReminderOptions = [
  "5 minutes",
  "10 minutes",
  "15 minutes",
  "30 minutes",
  "1 hour",
  "2 hours",
  "4 hours",
];
export const WeekDays = [
  { label: "Sunday", value: "sunday" },
  { label: "Monday", value: "monday" },
  { label: "Tuesday", value: "tuesday" },
  { label: "Wednesday", value: "wednesday" },
  { label: "Thursday", value: "thursday" },
  { label: "Friday", value: "friday" },
  { label: "Saturday", value: "saturday" },
];

export const sessionType = {
  zoom: "zoom",
  schedule: "schedule",
  reminder: "reminder",
};

export const TimeZones = [
  "America/Los_Angeles",
  "America/Denver",
  "America/Chicago",
  "America/New_York",
  "Europe/London",
  "Europe/Amsterdam",
  "Europe/Belgrade",
  "Europe/Brussels",
  "Asia/Colombo",
  "Australia/Perth",
  "Australia/Darwin",
  "Australia/Brisbane",
  "Australia/Adelaide",
  "Australia/Sydney",
  "NZ",
  "Pacific/Honolulu",
  "America/Adak",
  "America/Anchorage",
  "America/Phoenix",
];
export const DefaultTimezone = TimeZones[4];

export const Units = [
  { label: "Imperial (°F / lbs)", value: "imperial" },
  { label: "Metric (°C / kg)", value: "metric" },
];

export const DefaultUnit = Units[0];

export const EventTypes = {
  zoom: "Zoom Meeting",
  schedule: "Video Call",
  reminder: "Calendar Reminder",
};

/* Device related */
export const DeviceStatus = {
  userassociated: "userassociated",
  provisioned: "provisioned",
  active_not_provisioned: "active_not_provisioned",
  not_activated: "not_activated",
};

/* resolutions supported for profile pics */
export const PhotoResolution = Object.freeze({
  R32: "32x32",
  R64: "64x64",
  R128: "128x128",
  R256: "256x256",
  R512: "512x512",
  Full: "full",
});

/* translation related constants */
export const LanguageCodes = {
  english: "en-US",
  french: "fr-FR",
};

export const DefaultLanguage = LanguageCodes.english;

export const DefaultTranslation = {
  session_expired: "Session expired. Please login again.",
  error_message: "Something went wrong",
  welcome: "Welcome",
  logout: "Logout",
};
