import React, { useMemo } from "react";
import {
  FindMeIcon,
  DeviceUnpairIcon,
  LatestActionIcon,
  NewDoneIcon,
  UnpairedIcon,
} from "../Common/Icons";
import { useUserRole, hasStaffPrivileges } from "./helpers";
import { getConfirmationMessage } from "./DeviceInventoryModal";
import RemoteControlLayout from "./RemoteControlLayout";
import { RCButtons } from "./RemoteControlLayout";
import {
  Access,
  checkRoleAccess,
} from "@commscopemycloud/humaui/Utilities/AccessControl";

const RemoteControlModalInfo = ({
  device,
  hubRecord,
  performAction,
  actionsData,
  hideModal,
  ConfirmationModal,
  isActionModalVisible,
  isTVDevice,
  activeDeviceAction,
  handleDeviceActionClick,
}) => {
  const userRole = useUserRole();

  const allowShowOrientation = useMemo(
    () => checkRoleAccess(Access.beta.labsPreview) && hubRecord,
    [hubRecord]
  );

  const allowManageAction = useMemo(
    () =>
      hasStaffPrivileges(userRole) &&
      hubRecord?.rolename?.toLowerCase() === "manage",
    [userRole, hubRecord]
  );
  const allowFindMeAction = useMemo(
    () => ["manage", "care"].includes(hubRecord?.rolename?.toLowerCase()),
    [userRole, hubRecord]
  );

  const showActionsBloc =
    (allowFindMeAction || hasStaffPrivileges(userRole)) &&
    device?.paired_status !== "UNPAIRED";

  const handleUnpair = (device) => {
    let data = {
      ...actionsData.unpair_rcu,
      deviceUuid: hubRecord?.deviceuuid,
    };
    performAction(data, null);
    hideModal("unpair_rcu");
  };

  const handleFindMe = (device) => {
    let data = {
      ...actionsData.find_me,
      deviceUuid: hubRecord?.deviceuuid,
    };
    performAction(data, null);
    hideModal("find_me");
  };

  // Extract the last orientation from device_motion_events
  const lastMotionEvent = device?.device_motion_events
    ? JSON.parse(device.device_motion_events).slice(-1)[0]
    : null;

  const lastOrientation = lastMotionEvent?.orientation || "-";

  // Define the constant map for device information
  const tvInfoMap = [
    {
      key: "Brand",
      value: device?.device_brand_id ? device.device_brand_id : "not set",
    },
    {
      key: "Remote IR code",
      value: device?.device_codeset_id ? device.device_codeset_id : "not set",
    },
  ];

  return (
    <div className="rc400-bloc-bottom">
      <div
        className={`bloc-left ${
          device?.paired_status === "UNPAIRED" ? "device-details-transparent" : ""
        }`}
      >
        <div className="tv-configuration-block">
          <div className="configuration-block-container">
            <div className="tv-custom-title-label">TV Configuration</div>
            <div className="configuration-details-block">
              <div className="done-block">
                {isTVDevice?.device_learning_type === null ||
                isTVDevice?.device_learning_type === undefined ||
                isTVDevice?.device_learning_type === "NONE" ? (
                  <UnpairedIcon label="Not Done" />
                ) : (
                  <NewDoneIcon label="Done" />
                )}
              </div>
              {tvInfoMap.map((item, index) => (
                <div key={index} className="tv-info-row">
                  <span className="tv-info-key">{item.key}:</span>
                  <span className="tv-info-value"> {item.value}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        {showActionsBloc && (
          <div className="actions-bloc">
            <div className="actions-bloc-container">
              <div className="actions-main">
                <div className="device-action-label">Actions</div>
                <div className="device-page-description-container">
                  {allowFindMeAction && (
                    <div className="find-me">
                      {` “Find Me”`}
                      <span className="find-me-desc">{` : activates a beeping for 30 seconds on the remote`}</span>
                    </div>
                  )}
                  {device?.paired_status !== "UNPAIRED" &&
                    hasStaffPrivileges(userRole) && (
                      <div className="unpair">
                        {` “Unpair”`}
                        <span className="unpair-desc">{` : unpair the sensor by clicking on the unpair button`}</span>
                      </div>
                    )}
                </div>
              </div>
              <div className="action-bottom">
                {allowFindMeAction && (
                  <div
                    className="find-me-button"
                    onClick={() => handleDeviceActionClick("find_me")}
                  >
                    <FindMeIcon
                      strokeColor="#005D98"
                      className="find-me-icon"
                    />
                    <div className="action-label">Find Me</div>
                  </div>
                )}
                {device?.paired_status !== "UNPAIRED" &&
                  hasStaffPrivileges(userRole) && (
                    <div
                      className="unpair-button"
                      onClick={() => handleDeviceActionClick("unpair_rcu")}
                    >
                      <DeviceUnpairIcon
                        className="unpair-icon"
                        strokeColor="#005d98"
                        fillColor="#0072BC"
                      />
                      <div className="action-label">Unpair</div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className={`device-bloc ${allowShowOrientation ? "large" : "small"} ${
          device?.paired_status === "UNPAIRED" ? "device-details-transparent" : ""
        }`}
      >
        <div className="top-info">
          <div className="device-model-label">Live view</div>
          {allowShowOrientation && (
            <div className="latest-action">
              <LatestActionIcon className="latest-action-icon" />
              <div className="latest-action-title">
                {` Latest action :`}
                <span className="latest-action-subtitle">
                  {device?.device_key_name
                    ? `${device.device_key_name} pressed `
                    : "-"}
                  <span className="highlight-text">
                    {device?.device_key_action
                      ? `“${device.device_key_action}”`
                      : "-"}
                  </span>
                </span>
              </div>
            </div>
          )}
          <div className="remote-container">
            <RemoteControlLayout
            // buttonSelected={RCButtons.RCMicrophone}
            // allowShowOrientation={allowShowOrientation}
            />
          </div>
          {allowShowOrientation && (
            <div className="subtitle-info">
              <div className="current-orientation-label">
                Current orientation :
                <span className="current-orientation-subtitle">
                  {` ${lastOrientation}`}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>

      <ConfirmationModal
        visible={isActionModalVisible[activeDeviceAction]}
        title={
          activeDeviceAction !== "find_me" ? (
            <span className="custom-modal-title">
              {getConfirmationMessage(
                actionsData[activeDeviceAction]?.title?.toLowerCase()
              )}
            </span>
          ) : (
            <span className="custom-modal-title">
              Are you sure you want to find my remote?
            </span>
          )
        }
        description={actionsData[activeDeviceAction]?.description}
        onConfirm={() =>
          activeDeviceAction === "find_me"
            ? handleFindMe(device)
            : handleUnpair(device)
        }
        onCancel={() => hideModal(activeDeviceAction)}
        customLabel={actionsData[activeDeviceAction]?.title}
        customIcon={
          activeDeviceAction === "find_me" ? (
            <FindMeIcon strokeColor="#113E57" />
          ) : (
            <DeviceUnpairIcon fillColor="#113E57" strokeColor="#113E57" />
          )
        }
        activeDeviceAction={activeDeviceAction}
      />
    </div>
  );
};

export default RemoteControlModalInfo;