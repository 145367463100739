import React from "react";

export const AutoCallIcon = (props) => {
    const { className = "", style = {}, disabled = false, onClick } = props;
    return (
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.7 11.2996C19.1 11.2996 19.7 11.4996 20.1 11.8996C21.7 13.0996 22.9 18.6996 22.5 20.6996C22.3 21.6996 21.3 22.4996 20.3 23.4996C19.9 23.8996 19.7 24.0996 19.3 24.4996C18.7 25.0996 18.5 26.0996 18.9 26.8996C22.1 32.4996 26.7 37.0996 32.3 40.2996C32.7 40.4996 32.9 40.4996 33.3 40.4996C33.7 40.4996 34.3 40.2996 34.7 39.8996C35.1 39.4996 35.3 39.2996 35.7 38.8996C36.7 37.8996 37.5 36.8996 38.5 36.6996C38.7 36.6996 39.1 36.6996 39.5 36.6996C41.9 36.6996 46.3 37.8996 47.5 39.2996C47.9 39.6996 48.1 40.2996 48.1 40.8996V47.0996C48.1 47.6996 47.9 48.2996 47.3 48.6996C46.9 49.0996 46.3 49.2996 45.7 49.2996H45.5C35.9 49.2996 27.1 45.6996 20.5 39.0996C13.9 32.4996 10.3 23.6996 10.3 13.8996C10.3 13.2996 10.5 12.6996 10.9 12.2996C11.3 11.8996 11.9 11.4996 12.5 11.4996L18.7 11.2996ZM18.7 6.09961H12.3C10.3 6.09961 8.30002 6.89961 6.90002 8.49961C5.50002 9.89961 4.90002 11.8996 4.90002 13.6996C4.90002 24.6996 9.10003 34.8996 16.5 42.2996C23.9 49.6996 34.1 53.8996 44.9 53.8996C45.1 53.8996 45.1 53.8996 45.3 53.8996C47.3 53.8996 48.9 53.2996 50.3 51.8996C50.7 51.4996 51.1 50.8996 51.5 50.4996C52.3 49.2996 52.7 47.8996 52.7 46.6996V40.4996C52.7 38.6996 52.1 37.0996 50.9 35.6996C48.1 32.4996 41.7 31.4996 39.1 31.4996C38.3 31.4996 37.7 31.4996 37.1 31.6996C35.1 32.0996 33.7 33.2996 32.5 34.4996C29.3 32.2996 26.5 29.6996 24.5 26.4996C25.7 25.2996 26.9 23.8996 27.3 21.8996C28.1 18.4996 26.5 10.8996 23.3 8.09961C22.3 6.89961 20.5 6.09961 18.7 6.09961Z" fill="#005D98" />
            <path d="M40.5 11.8996C39.9 11.6996 39.5 11.2996 39.1 10.8996C38.3 10.0996 37.5 9.09961 37.1 7.89961L36.3 6.09961L35.5 7.89961C35.1 9.09961 34.3 10.0996 33.5 10.8996C33.1 11.2996 32.7 11.6996 32.1 11.8996L31.1 12.6996L32.3 13.2996C32.9 13.4996 33.3 13.8996 33.7 14.2996C34.5 15.0996 35.3 16.0996 35.7 17.2996L36.5 19.0996L37.3 17.2996C37.7 16.0996 38.5 15.0996 39.3 14.2996C39.7 13.8996 40.1 13.4996 40.7 13.2996L41.9 12.6996L40.5 11.8996Z" fill="#005D98" />
            <path d="M52.7 19.6996C52.1 19.4996 51.7 19.0996 51.3 18.6996C50.5 17.8996 49.7 16.8996 49.3 15.4996L47.9 11.8996L46.5 15.4996C46.1 16.6996 45.3 17.8996 44.5 18.6996C44.1 19.0996 43.5 19.4996 43.1 19.6996L40.7 21.0996L43.1 22.4996C43.7 22.6996 44.1 23.0996 44.5 23.4996C45.3 24.2996 46.1 25.2996 46.5 26.6996L47.9 30.2996L49.3 26.6996C49.7 25.4996 50.5 24.2996 51.3 23.4996C51.7 23.0996 52.3 22.6996 52.7 22.4996L55.1 21.0996L52.7 19.6996Z" fill="#005D98" />
        </svg>

    );
}