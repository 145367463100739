import React from "react";

export const FilterIcon = (props) => {
    const { className = "", style={}, onClick, disabled=false } = props;
    return (
        <svg
            className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
            onClick={disabled ? null: onClick}
            xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none"
            style={style}
        >
            <path className="stroke" d="M1 1L8.05882 12.2941V20.7647L16.5294 25V12.2941L23.5882 1H1Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}


