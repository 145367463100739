import React from "react";

export const NewManageIcon = (props) => {
  const { className = "", style = {}, onClick } = props;
  return (
    <svg
      onClick={onClick}
      className={"custom-icon " + className}
      style={style}
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.2046 19.3442C21.5632 19.3442 18.6094 22.298 18.6094 25.9395C18.6094 29.581 21.5632 32.5347 25.2046 32.5347C28.8461 32.5347 31.7999 29.581 31.7999 25.9395C31.7999 22.298 28.8461 19.3442 25.2046 19.3442ZM28.8461 24.7517L24.9702 28.6276C24.767 28.8308 24.4857 28.9402 24.22 28.9402C23.9543 28.9402 23.673 28.8308 23.4699 28.6276L21.5475 26.7053C21.1412 26.299 21.1412 25.6269 21.5475 25.205C21.9539 24.7986 22.6259 24.7986 23.0479 25.205L24.22 26.3771L27.3457 23.2514C27.7521 22.845 28.4241 22.845 28.8461 23.2514C29.2681 23.6577 29.2524 24.3298 28.8461 24.7517Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="none"
      />
      <path
        d="M17.2967 28.0178H7.07561V8.9197H10.1075V11.139C10.1075 12.1861 10.9671 13.0456 12.0142 13.0456H21.954C23.0011 13.0456 23.8607 12.1861 23.8607 11.139V8.9197H27.1583V18.0468C27.6584 18.1093 28.1429 18.2187 28.6274 18.3594C29.1119 18.5 29.5651 18.6719 30.0183 18.8751V8.9197C30.0183 8.16953 29.737 7.52876 29.1744 6.99739C28.6118 6.46602 27.9397 6.20033 27.1427 6.20033H26.752C26.752 6.20033 26.6426 6.20033 26.5957 6.20033H20.8913C20.6412 5.40327 20.1567 4.74687 19.4378 4.23113C18.7189 3.71539 17.8906 3.46533 16.9841 3.46533C16.0777 3.46533 15.3119 3.71539 14.5773 4.23113C13.8428 4.74687 13.3583 5.40327 13.0926 6.20033H7.35692C7.35692 6.20033 7.24752 6.20033 7.20064 6.20033H7.07561C6.29418 6.20033 5.60652 6.46602 5.04389 6.99739C4.48127 7.52876 4.19995 8.16953 4.19995 8.9197V28.0334C4.19995 28.7836 4.48127 29.4244 5.04389 29.9558C5.60652 30.4871 6.27855 30.7528 7.07561 30.7528H18.6876C18.3751 30.3465 18.1094 29.9089 17.8749 29.4556C17.6405 29.0024 17.453 28.5179 17.2811 28.0178H17.2967ZM16.062 6.6223C16.3277 6.35662 16.6559 6.23159 17.0466 6.23159C17.4373 6.23159 17.7655 6.35662 18.0312 6.6223C18.2969 6.88799 18.4219 7.21619 18.4219 7.6069C18.4219 7.99762 18.2969 8.32582 18.0312 8.5915C17.7655 8.85719 17.4373 8.98222 17.0466 8.98222C16.6559 8.98222 16.3277 8.85719 16.062 8.5915C15.7963 8.32582 15.6713 7.99762 15.6713 7.6069C15.6713 7.21619 15.7963 6.88799 16.062 6.6223Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="none"
      />
    </svg>
  );
};
