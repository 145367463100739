/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { message } from "antd";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { storeStaffList } from "@commscopemycloud/humaui/Store/dataStore";
import { UserRoles } from "@commscopemycloud/humaui/Utilities/Constants";

const useStaffList = ({ useruuid }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [fetchingData, setFetchingData] = useState(true);

  const trans = useSelector(translator);
  const userApi = useSelector((state) => state.apis.userApi);

  const fetchData = () => {
    const errorCallback = (error) => {
      setFetchingData(false);
      message.error("Error fetching staff list!");
      console.error("Error fetching staff list:", error);
      setData([]);
    };
    const successCallback = (data) => {
      setFetchingData(false);
      console.info("StaffList: fetch result", data);
      dispatch(storeStaffList({ useruuid, staffList: data?.contacts ?? [] }));
      setData(data?.contacts ?? []);
    };
    try {
      if (!useruuid) {
        setFetchingData(false);
        return;
      }
      setFetchingData(true);
      userApi.getContactsByRole(
        useruuid,
        UserRoles.staff,
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  useEffect(() => fetchData(), [useruuid]);

  return [data, fetchingData, fetchData];
};

export default useStaffList;
