import React from "react";
import hc200_icon from "../../../../static/images/hc200_icon.png";

export const HC200Icon = (props) => {
  const { className = "", status, style = {} } = props;
  const OfflineIcon = (status)=> (
    <div className="hc200-icon-status">
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="7.5" cy="7.5" r="7" fill="#F7F7F7" stroke="#868585" />
            <svg x="3" y="3" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 8L8 0.999999" className={status}/>
              <path d="M1 1L8 8" className={status} />
            </svg>
          </svg>
        </div>
  )
  return (
    <div className={`hc200-icon-container ${className}`} style={style}>
      <img className="hc200-icon" src={hc200_icon} alt="HC200" />
      {status === "connected" ? (
        <div className="hc200-icon-status">
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="7.5" cy="7.5" r="7.5" fill="#4BD37B" />
          </svg>
        </div>
      ) : status === "disconnected" ? (
        OfflineIcon(status)
      ) : status === "certificate sn mismatch" || status === "invalid credentials" ? (
        OfflineIcon("failure")
      ) : <></>}
    </div>
  );
};
