import React from "react";

export const EnvironmentalTrendIcon = (props) => {
  const { className = "", style = {}, disabled = false, onClick } = props;
  return (
    <svg
      className={className}
      style={style}
      onClick={onClick}
      width="39"
      height="32"
      viewBox="0 0 39 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.1748 0H5.06276C2.26667 0 0 2.26555 0 5.06025V26.1777C0 28.9724 2.26667 31.2379 5.06276 31.2379H33.1748C35.9709 31.2379 38.2375 28.9724 38.2375 26.1777V5.06025C38.2375 2.26555 35.9709 0 33.1748 0Z"
        fill="#113E57"
      />
      <path
        d="M33.0792 16.139C32.8199 16.139 32.5836 16.2152 32.3777 16.3295L27.1243 10.812C27.2692 10.591 27.3531 10.3319 27.3531 10.0499C27.3531 9.25734 26.7126 8.61719 25.9196 8.61719C25.1267 8.61719 24.4862 9.25734 24.4862 10.0499C24.4862 10.4081 24.6235 10.7358 24.8446 10.9873L19.2633 19.0425C19.1032 18.9816 18.9355 18.9435 18.7601 18.9435C18.5009 18.9435 18.2569 19.0197 18.0434 19.1416L12.8129 13.7536C12.9501 13.5326 13.034 13.2812 13.034 12.9992C13.034 12.2066 12.3935 11.5665 11.6006 11.5665C10.8076 11.5665 10.1672 12.2066 10.1672 12.9992C10.1672 13.3269 10.2815 13.6317 10.4721 13.8756L5.02053 20.8106C4.84516 20.7267 4.64692 20.681 4.43343 20.681C3.64047 20.681 3 21.3212 3 22.1137C3 22.9063 3.64047 23.5465 4.43343 23.5465C5.22639 23.5465 5.86686 22.9063 5.86686 22.1137C5.86686 21.786 5.75249 21.4812 5.56188 21.2373L11.0135 14.3024C11.1889 14.3862 11.3871 14.4319 11.6006 14.4319C11.8598 14.4319 12.1038 14.3557 12.3173 14.2338L17.5478 19.6217C17.4106 19.8427 17.3267 20.0942 17.3267 20.3762C17.3267 21.1687 17.9672 21.8089 18.7601 21.8089C19.5531 21.8089 20.1935 21.1687 20.1935 20.3762C20.1935 20.018 20.0563 19.6903 19.8352 19.4388L25.4164 11.3836C25.5765 11.4445 25.7443 11.4826 25.9196 11.4826C26.1789 11.4826 26.4152 11.4064 26.6211 11.2921L31.8745 16.8096C31.7296 17.0306 31.6457 17.2897 31.6457 17.5717C31.6457 18.3643 32.2862 19.0044 33.0792 19.0044C33.8721 19.0044 34.5126 18.3643 34.5126 17.5717C34.5126 16.7791 33.8721 16.139 33.0792 16.139Z"
        fill="white"
      />
    </svg>
  );
};
