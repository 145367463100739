import React from "react";

export const AddNewScheduleIcon = (props) => {
    const { className = "", style={}, onClick, disabled=false } = props;
    return (
        <svg className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
        onClick={disabled ? null: onClick} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
        <path d="M2.84874 7.0997H18.8507V4.928H2.84874V7.0997ZM2.84874 22.3445C2.22009 22.3445 1.67717 22.1301 1.23426 21.7015C0.791344 21.2729 0.562744 20.7586 0.562744 20.1585V4.928C0.562744 4.32792 0.791344 3.81358 1.23426 3.38495C1.67717 2.95633 2.22009 2.74201 2.84874 2.74201H3.99174V1.71331C3.99174 1.08466 4.50609 0.570312 5.13474 0.570312C5.76339 0.570312 6.27774 1.08466 6.27774 1.71331V2.74201H15.4217V1.71331C15.4217 1.08466 15.9361 0.570312 16.5647 0.570312C17.1934 0.570312 17.7077 1.08466 17.7077 1.71331V2.74201H18.8507C19.4794 2.74201 20.0223 2.95633 20.4652 3.38495C20.9081 3.81358 21.1367 4.32792 21.1367 4.928V11.1002C20.7796 10.943 20.4081 10.8002 20.0223 10.6859C19.6366 10.5716 19.2508 10.5001 18.8507 10.443V9.2714H2.84874V20.1585H10.0496C10.1782 20.5585 10.3354 20.9443 10.5211 21.3015C10.7069 21.6587 10.9212 22.0016 11.1641 22.3302H2.84874V22.3445Z" fill="#113E57" stroke="none"/>
        <path d="M21.7654 14.1435C20.651 13.0863 19.308 12.5576 17.7221 12.5576C16.1362 12.5576 14.7931 13.0863 13.6787 14.1435C12.5643 15.2008 12.0071 16.4867 12.0071 17.9869C12.0071 19.4871 12.5643 20.7729 13.6787 21.8445C14.7931 22.9018 16.1362 23.4304 17.7221 23.4304C19.308 23.4304 20.651 22.9018 21.7654 21.8445C22.8799 20.7872 23.4371 19.5013 23.4371 17.9869C23.4371 16.4724 22.8799 15.2008 21.7654 14.1435ZM20.0652 18.9156H18.7365V20.2443C18.7365 20.8015 18.2793 21.2587 17.7221 21.2587C17.1649 21.2587 16.7077 20.8015 16.7077 20.2443V18.9156H15.3789C14.8217 18.9156 14.3645 18.4584 14.3645 17.9011C14.3645 17.3439 14.8217 16.8867 15.3789 16.8867H16.7077V15.558C16.7077 15.0008 17.1649 14.5436 17.7221 14.5436C18.2793 14.5436 18.7365 15.0008 18.7365 15.558V16.8867H20.0652C20.6224 16.8867 21.0796 17.3439 21.0796 17.9011C21.0796 18.4584 20.6224 18.9156 20.0652 18.9156Z" fill="#113E57" stroke="none"/>
        </svg>
        
    );
}

// Figma: new event