import React from "react";
import {
  RCAcceptCall,
  RCBack,
  RCDeclineCall,
  RCHome,
  RCMicrophone,
  RCNavigation,
  RCPower,
  RCTV,
  RCVolumeDown,
  RCVolumeUp,
} from "../Common/Icons";

export const RCButtons = {
  RCTV: "RCTV",
  RCMicrophone: "RCMicrophone",
  RCPower: "RCPower",
  RCBack: "RCBack",
  RCHome: "RCHome",
  RCVolumeUp: "RCVolumeUp",
  RCAcceptCall: "RCAcceptCall",
  RCVolumeDown: "RCVolumeDown",
  RCDeclineCall: "RCDeclineCall",
  RCArrowUp: "RCArrowUp",
  RCArrowDown: "RCArrowDown",
  RCArrowLeft: "RCArrowLeft",
  RCArrowRight: "RCArrowRight",
  RCOK: "RCOK",
};

const buttonComponents = [
  { component: RCTV, button: RCButtons.RCTV },
  { component: RCMicrophone, button: RCButtons.RCMicrophone },
  { component: RCPower, button: RCButtons.RCPower },
  { component: RCBack, button: RCButtons.RCBack },
  { component: RCHome, button: RCButtons.RCHome },
  { component: RCVolumeUp, button: RCButtons.RCVolumeUp },
  { component: RCAcceptCall, button: RCButtons.RCAcceptCall },
  { component: RCVolumeDown, button: RCButtons.RCVolumeDown },
  { component: RCDeclineCall, button: RCButtons.RCDeclineCall },
];

const RemoteControlLayout = ({ buttonSelected, allowShowOrientation }) => {
  const isSelected = (button) =>
    allowShowOrientation && buttonSelected === button;

  return (
    <div className="remote-body">
      <div className="remote-content">
        <div className="row-1">
          {buttonComponents
            .slice(0, 3)
            .map(({ component: Component, button }) => (
              <Component key={button} isSelected={isSelected(button)} />
            ))}
        </div>
        <div className="row-2">
          <RCNavigation isSelected={allowShowOrientation && buttonSelected} />
          <span
            className={
              buttonSelected === RCButtons.RCOK
                ? "ok-button-selected"
                : "ok-button"
            }
          >
            OK
          </span>
        </div>
        <div className="row-3">
          {buttonComponents
            .slice(3, 5)
            .map(({ component: Component, button }) => (
              <Component key={button} isSelected={isSelected(button)} />
            ))}
        </div>
        <div className="row-4">
          {buttonComponents
            .slice(5, 7)
            .map(({ component: Component, button }) => (
              <Component key={button} isSelected={isSelected(button)} />
            ))}
        </div>
        <div className="row-5">
          {buttonComponents.slice(7).map(({ component: Component, button }) => (
            <Component key={button} isSelected={isSelected(button)} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default RemoteControlLayout;
