import React from "react";

export const ScheduleNewIcon = (props) => {
    const { className = "", style={}, onClick, disabled=false } = props;
    return (
        <svg
            className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
            onClick={disabled ? null: onClick}
            xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none"
            style={style}
        >
            <path className="stroke" d="M13.7059 19.6666H25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M19.3529 24.9999V14.3333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M1 11.6667H9.47057" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M23.5882 8.99995V6.33329C23.5882 4.86053 22.3241 3.66663 20.7647 3.66663H3.82352C2.26413 3.66663 1 4.86053 1 6.33329V22.3333C1 23.806 2.26413 24.9999 3.82352 24.9999H9.47057" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M16.5294 1V6.33332" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M8.05882 1V6.33332" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

// Figma: new event