import React, { createRef } from "react";
import PropTypes from "prop-types";
import "../../styles/StickyHeader.less";

class StickyHeader extends React.Component {
  static defaultProps = {
    autoOffset: false,
    top: "0px",
  };
  constructor(props) {
    super(props);
    this.ref = createRef();
    this.state = {
      topOffset: props.top || 0,
    };
  }

  componentDidMount() {
    const { top } = this.props;
    if (!top) {
      const topOffset = this.getTopOffset() || 0;
      this.setState({ topOffset });
    }
  }

  getOffset = () => {
    const { autoOffset, top } = this.props;
    return autoOffset ? this.getTopOffset() || 0 : top || 0;
  };

  /**
   * TODO: if required in future,
   * handle offset change on window resize
   */

  getTopOffset = () => {
    const headerHeight = 50;
    const x = this.ref?.current?.offsetTop;
    const offset = x ? x - headerHeight : x;
    return typeof offset === "number" ? offset : null;
  };

  render() {
    const { topOffset } = this.state;
    return (
      <div
        ref={this.ref}
        className="page-sticky-container"
        style={{ top: topOffset }}
      >
        {this.props.children}
      </div>
    );
  }
}

StickyHeader.propTypes = {
  /* auto calculate offset based on position */
  autoOffset: PropTypes.bool,
  /* top offset */
  top: PropTypes.string,
};
export default StickyHeader;
