import React from "react";

export const AlertIcon = (props) => {
  const { className = "", style = {}, announcement = true } = props;
  return (
    <svg
      className={className}
      style={style}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99935 1.66602C5.4061 1.66602 1.66602 5.4061 1.66602 9.99935C1.66602 14.5926 5.4061 18.3327 9.99935 18.3327C14.5926 18.3327 18.3327 14.5926 18.3327 9.99935C18.3327 5.4061 14.5926 1.66602 9.99935 1.66602ZM9.99935 16.6164C6.34856 16.6164 3.38229 13.6501 3.38229 9.99935C3.38229 6.34856 6.33863 3.38229 9.99935 3.38229C13.6601 3.38229 16.6164 6.34856 16.6164 9.99935C16.6164 13.6501 13.6601 16.6164 9.99935 16.6164Z"
        fill={announcement ? "#F46363" : "#808080"}
      />
      <path
        d="M9.93998 12.1414C10.3765 12.1414 10.7237 11.7843 10.7237 11.3577V6.24857C10.7237 5.81207 10.3666 5.46484 9.93998 5.46484C9.51339 5.46484 9.15625 5.82199 9.15625 6.24857V11.3577C9.15625 11.7942 9.51339 12.1414 9.93998 12.1414Z"
        fill={announcement ? "#F46363" : "#808080"}
      />
      <path
        d="M10.6437 12.9964C10.5544 12.917 10.4651 12.8476 10.356 12.798C10.2469 12.7583 10.1278 12.7285 10.0088 12.7285C9.88973 12.7285 9.77068 12.7484 9.66155 12.788C9.55242 12.8277 9.45322 12.8972 9.36393 12.9865C9.27465 13.0658 9.21512 13.165 9.16552 13.2742C9.11592 13.3833 9.09608 13.5023 9.106 13.6214C9.106 13.7404 9.106 13.8694 9.16552 13.9884C9.21512 14.1075 9.29449 14.2067 9.37385 14.2861C9.55242 14.4448 9.7806 14.5242 10.0088 14.5242C10.2369 14.5242 10.4651 14.4448 10.6338 14.2861C10.733 14.2067 10.8024 14.0976 10.8421 13.9884C10.8917 13.8793 10.9016 13.7503 10.9016 13.6313C10.9016 13.5122 10.8818 13.3932 10.8421 13.2841C10.7925 13.1749 10.733 13.0757 10.6536 12.9964H10.6437Z"
        fill={announcement ? "#F46363" : "#808080"}
      />
    </svg>
  );
};
