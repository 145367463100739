import React, { useEffect, useMemo, useState } from "react";
import InviteParticipantsComponent from "./InviteParticipants.component";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import useContacts from "../Hooks/useContacts";
import useStaffList from "../Hooks/useStaffList";
import {
  getMeetingId,
  updateInviteStatus,
  updateInvites,
} from "@commscopemycloud/humaui/Store/videoCallStore";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import { VideoCallStatus } from "@commscopemycloud/humaui/Utilities/Constants";
import { getUserHubs } from "@commscopemycloud/humaui/Store/dataStore";

const InviteParticipants = (props) => {
  const dispatch = useDispatch();
  const trans = useSelector(translator);
  const videoCallApi = useSelector((state) => state.apis.videoCallApi);
  const [invitedHub, setInvitedHub] = useState(null);

  const currentUser = useSelector(getCurrentUser);
  const meeting_id = useSelector(getMeetingId);
  const invitees = useSelector((state) => state.videoCall.invites);
  const inviteContacts = useSelector((state) => state.videoCall.inviteContacts);
  const userHubs = useSelector(getUserHubs(currentUser?.useruuid));

  const { isStaff, hubRecord } = inviteContacts ?? {};

  const useruuid = useMemo(
    () => (isStaff ? null : hubRecord?.useruuid ?? currentUser?.useruuid),
    [isStaff, hubRecord, currentUser]
  );
  const [hubContacts, fetchingHubContacts] = useContacts({
    useruuid: invitedHub,
  });
  const [contacts, fetchingContacts] = useContacts({
    useruuid: !isStaff ? useruuid : null,
  });
  const [staffList, fetchingStaffList] = useStaffList({
    useruuid: isStaff ? currentUser.useruuid : null,
  });

  const userHubsMapper = useMemo(() => {
    return userHubs.reduce((result, hub) => {
      result[hub.useruuid] = hub;
      return result;
    }, {});
  }, [userHubs]);

  useEffect(() => {
    if (!!invitedHub) return;
    for (let invitee of invitees) {
      if (userHubsMapper[invitee.useruuid]) {
        setInvitedHub(invitee.useruuid);
        break;
      }
    }
  }, [invitees]);

  const sendInvite = (userList) => {
    console.debug("sendInvite list", userList);
    return new Promise((resolve, reject) => {
      const errorCallback = (error) => {
        console.error("Error inviting contact(s):", error);
        message.error("Error inviting contact(s)!");
        dispatch(
          updateInviteStatus({
            list: userList,
            status: VideoCallStatus.failed,
            error: "Error sending invite(s)!",
          })
        );
        resolve({});
      };
      const successCallback = (data) => {
        console.info("sendInvite result", data);
        dispatch(updateInviteStatus({ list: data?.contacts }));
        resolve(data);
      };
      const contacts = userList.map((user) => {
        return { useruuid: user.useruuid };
      });
      try {
        dispatch(
          updateInvites({ list: userList, status: VideoCallStatus.calling })
        );
        videoCallApi.inviteParticipant(
          { meeting_id, contacts },
          apiCallback({
            translator: trans,
            failCallback: errorCallback,
            errorCallback,
            successCallback,
          })
        );
      } catch (error) {
        errorCallback(error);
      }
    });
  };

  return (
    <InviteParticipantsComponent
      contacts={isStaff ? staffList : contacts}
      fetchingContacts={isStaff ? fetchingStaffList : fetchingContacts}
      hubContacts={hubContacts}
      fetchingHubContacts={fetchingHubContacts}
      sendInvite={sendInvite}
    />
  );
};

export default InviteParticipants;
