/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { Form, Input, Spin, message } from "antd";
import MainPage from "../../MainPage";
import { HC200Icon, NewHC200Icon, ToggleIcon } from "../Common/Icons";
import CustomButton, { ButtonTypes } from "../Common/CustomButton";
import { CheckIcon, CloseIcon } from "../Common/Icons";

const AddAccount = (props) => {
  console.debug("AddAccount props", props);
  const { parentBread, deviceRecord, onActionClick, offset, limit } = props;
  const trans = useSelector(translator);

  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    account_firstname: "",
    account_lastname: "",
    account_email: "",
    accountmanager_firstname: "",
    accountmanager_lastname: "",
    accountmanager_email: "",
    accountmanager_sourcerelationship: "",
    accountmanager_targetrelationship: "",
  });
  const [processing, setProcessing] = useState(false);
  const [isSelfManageAccount, setIsSelfManageAccount] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const userApi = useSelector((state) => state.apis.userApi);

  useEffect(() => {
    validate();
  }, [formData, isSelfManageAccount]);

  const header = (parentBread || []).concat({
    label: trans("ADDACCOUNT"),
  });

  const handleInputChange = (e) => {
    const { name, value } = e?.target;
    const trimmedValue = value.trim();
    setFormData({ ...formData, [name]: trimmedValue });
  };

  const validate = () => {
    let valid = !!formData.account_firstname && !!formData.account_lastname;
    const emailPattern =
      /^(["a-zA-Z0-9.!#$%&()'*+/=?^_`{|}~\[\]-])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (isSelfManageAccount) {
      valid =
        valid &&
        !!formData.account_email &&
        emailPattern.test(formData.account_email);
    } else {
      valid =
        valid &&
        !!formData.accountmanager_firstname &&
        !!formData.accountmanager_lastname &&
        !!formData.accountmanager_email &&
        emailPattern.test(formData.accountmanager_email);
    }
    setIsValid(Boolean(valid));
  };

  const handleClick = (status) => {
    // 👇️ toggle issSelfManageAccount state on click
    if (status !== isSelfManageAccount) {
      setIsSelfManageAccount((current) => !current);
    }
  };

  const onSubmit = () => {
    const errorCallback = (error) => {
      setProcessing(false);
      console.error("Error Creating Account", error);
      message.error("Error creating Account");
    };
    const successCallback = (data) => {
      setProcessing(false);
      message.success("Account Created");
      console.info("Account Created successfully", data);
      onActionClick(null, null, offset, limit, true)();
    };
    try {
      setProcessing(true);
      let deviceid = deviceRecord.deviceid;
      let deviceuuid = deviceRecord.deviceuuid;
      let createAccount = {
        accountname: deviceid,
        rolename: "Principal",
        firstname: formData.account_firstname,
        lastname: formData.account_lastname,
        deviceuuid: deviceuuid,
      };
      if (isSelfManageAccount) {
        createAccount.principalemail = formData.account_email;
      } else {
        let accountmanager = {
          firstname: formData.accountmanager_firstname,
          lastname: formData.accountmanager_lastname,
          email: formData.accountmanager_email,
          sourcerelationship: formData.accountmanager_targetrelationship,
          targetrelationship: formData.accountmanager_sourcerelationship,
        };
        createAccount.accountmanager = accountmanager;
      }
      userApi.createAccount(
        createAccount,
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (e) {
      errorCallback(e);
    }
  };

  const onCancel = () => {
    message.info("Not Supported");
  };

  const formItem = (label, name, disable) => {
    let rules = [];
    if (name.includes("email")) {
      rules = [
        {
          type: "email",
          message: "Please enter a valid email address",
        },
      ];
    }
    return (
      <Form.Item
        label={label}
        name={name}
        rules={rules}
        /*rules={[
        {
          required: true,
          message: `Please enter the {${name}}.`,
        },
      ]}*/
      >
        <Input
          name={name}
          onChange={(e) => handleInputChange(e)}
          defaultValue={formData[{ name }]}
          disabled={disable}
        />
      </Form.Item>
    );
  };

  const renderRelationshipSection = (label, name, optional = false) => {
    // Add "(optional)" to the label if optional is true
    const optionalLabel = optional ? (
      <span>
        <span>{label}</span> <span className="optional-label">({trans("OPTIONAL")})</span>
      </span>
    ) : (
      <span>{label}</span>
    );
    return (
      <div className="account-self-manage">
        <div className="account-self-rel-container">
          <div
            className={
              isSelfManageAccount
                ? "account-form-group account-form-group-disabled"
                : "account-form-group"
            }
          >
            <Form.Item label={optionalLabel} name={name}>
              <Input
                name={name}
                onChange={(e) => handleInputChange(e)}
                defaultValue={formData[name]}
                disabled={isSelfManageAccount}
              />
            </Form.Item>
          </div>
        </div>
      </div>
    );
  };

  const renderFormItem = (label, name) => {
    return <div className="account-form-group">{formItem(label, name)}</div>;
  };

  const renderFormItemWithToogle = (label, name, yesOption) => {
    let disable = yesOption ? !isSelfManageAccount : isSelfManageAccount;
    let disabledClass = disable ? "account-form-group-disabled" : "";
    return (
      <div className={`account-form-group ${disabledClass}`}>
        {formItem(label, name, disable)}
      </div>
    );
  };

  const renderTopSection = () => {
    return (
      <div className="top-section">
        <div className="account-main-label">{trans("ADDACCOUNT")}</div>
        <div className="account-label">{trans("WHOISTHEACCOUNTPRINCIPAL")}</div>
        <div className="name-device-container">
          <div className="account-form-row">
            {renderFormItem(trans("FIRSTNAME"), "account_firstname")}
            {renderFormItem(trans("LASTNAME"), "account_lastname")}
            <div className="account-devicestatus">
              <NewHC200Icon
                status={deviceRecord.deviceconnectstatus?.toLowerCase()}
              />
            </div>
            <div className="account-deviceinfo">
              <div className="accont-device-label">
                {trans("SMARTCAMERASERIALNUMBER")}
              </div>
              <div className="account-device-sn">{deviceRecord.deviceid}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderBottomSection = () => {
    return (
      <div className="bottom-section">
        <div className="account-self-label">
          {trans("ADDCONTACTASACCOUNTMANAGER")}
        </div>
        <div className="account-self-options">
          <ToggleIcon
            onClick={() => handleClick(false)}
            status={!isSelfManageAccount ? "enabled" : "disabled"}
          />
          <div className="account-self-options-text">{trans("YES")}</div>
        </div>
        <div className="account-self-manage">
          <div className="account-self-info-container">
            <div className="account-form-row">
              {renderFormItemWithToogle(
                trans("FIRSTNAME"),
                "accountmanager_firstname",
                false
              )}
              {renderFormItemWithToogle(
                trans("LASTNAME"),
                "accountmanager_lastname",
                false
              )}
              {renderFormItemWithToogle(
                trans("EMAIL"),
                "accountmanager_email",
                false
              )}
            </div>
          </div>
        </div>
        {renderRelationshipSection(
          trans("ADDACCOUNTACCOUNTPRINCIPALRELATIONSHIP"),
          "accountmanager_sourcerelationship",
          true // Set as optional
        )}
        {renderRelationshipSection(
          trans("ADDACCOUNTACCOUNTMANAGERRELATIONSHIP"),
          "accountmanager_targetrelationship",
          true // Set as optional
        )}
        <div className="account-self-hline"></div>
        <div className="account-self-options">
          <ToggleIcon
            disabled={true}
            onClick={() => handleClick(true)}
            status={isSelfManageAccount ? "enabled" : "disabled"}
          />
          <div className="account-self-options-text">
            {trans("NOADDEMAILADDRESSTOMANAGEACCOUNT")}
          </div>
        </div>
        <div className="account-self-manage">
          <div className="account-self-email-container">
            {renderFormItemWithToogle(trans("EMAIL"), "account_email", true)}
          </div>
        </div>
      </div>
    );
  };

  return (
    <MainPage hasSider={true} header={header} fixPageContent={true}>
      <div className="addaccount-container">
        <Spin size="large" spinning={processing} tip={trans("PROCESSING")}>
          <Form form={form}>
            {renderTopSection()}
            {renderBottomSection()}
            <div className="account-button-container">
              <CustomButton
                label={trans("CANCEL_U")}
                onClick={onActionClick(null, null, offset, limit)}
                icon={
                  <CloseIcon
                    className="cancel-icon"
                    style={{ height: "12px" }}
                  />
                }
              />
              <CustomButton
                type={ButtonTypes.primary}
                label={trans("SUBMIT_U")}
                icon={<CheckIcon className="submit-icon" />}
                onClick={onSubmit}
                disabled={!isValid}
              />
            </div>
          </Form>
        </Spin>
      </div>
    </MainPage>
  );
};

AddAccount.propTypes = {
  parentBread: PropTypes.arrayOf(PropTypes.object),
  deviceRecord: PropTypes.object,
  onActionClick: PropTypes.func,
};

export default AddAccount;
