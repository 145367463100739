import React from "react";

export const _1 = (props) => {
  const { className = "", style = {} } = props;
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 840 840"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M644.91 409.629C645.42 529.259 548.09 627.759 426.9 627.869C305.48 627.979 208.34 529.029 208.64 408.979C208.94 289.959 305.38 191.629 426.93 191.379C546.92 191.139 645.3 289.249 644.91 409.629ZM611.06 409.659C611.05 308.529 529.6 226.109 427.37 225.509C326.64 224.929 242.92 306.489 242.61 409.079C242.3 510.789 323.52 593.539 426.24 593.869C530.31 594.209 610.89 510.449 611.06 409.659Z"
        fill="#E4C276"
      />
      <path
        d="M589.23 228.24C589.01 222.76 591.49 218.42 595.18 214.7C604.39 205.42 613.67 196.21 622.96 187C624.89 185.09 626.46 182.85 628.68 181.21C635.48 176.16 646.11 177.22 651.67 183.57C657.77 190.52 657.93 201.1 651.56 207.04C640.39 217.44 629.93 228.53 619.25 239.4C616.98 241.71 614.29 243.35 611.21 244.33C600.21 247.83 589.24 239.78 589.23 228.24Z"
        fill="#E4C276"
      />
      <path
        d="M666.62 607.31C666.56 614.22 662.4 620.63 656.45 623.03C649.31 625.91 641.96 624.54 636.91 619.34C631.34 613.61 625.07 608.67 619.18 603.3C613.7 598.29 607.9 593.61 602.6 588.42C597.46 583.39 596.35 575.48 599.25 569.29C603.96 559.25 616.43 556.06 625.16 562.93C630.21 566.9 634.8 571.44 639.64 575.69C646.59 581.78 653.25 588.2 660.51 593.94C664.37 596.99 666.67 602.09 666.62 607.31Z"
        fill="#E4C276"
      />
      <path
        d="M187.03 619.75C186.91 612.71 189.56 608.1 194.07 604.38C195.25 603.4 196.44 602.41 197.53 601.34C206.56 592.54 215.54 583.69 224.58 574.91C231.73 567.97 242.27 567.56 248.93 573.43C256.62 580.21 256.42 592.03 249.2 599.06C239.39 608.61 229.48 618.05 219.6 627.54C217.39 629.66 215.47 632.05 212.7 633.62C204.86 638.09 194.41 635.35 189.68 627.62C187.98 624.83 186.94 621.98 187.03 619.75Z"
        fill="#E4C276"
      />
      <path
        d="M176.6 206.579C176.81 198.799 180.62 193.369 187.76 190.549C194.44 187.909 200.56 189.429 205.85 194.239C216.38 203.819 227.26 212.969 238.3 221.949C245.65 227.939 248.15 236.659 244.34 244.419C239.98 253.309 228.63 256.519 220.14 251.379C216.83 249.379 214.26 246.559 211.35 244.129C201.68 236.059 192.3 227.659 182.48 219.759C178.4 216.489 176.43 211.949 176.6 206.579Z"
        fill="#E4C276"
      />
      <path
        d="M661.66 407.53C661.67 397.23 669.19 389.75 679.98 390C695.5 390.37 711.01 390.12 726.52 390.38C736.26 390.54 743.85 401.07 741.93 410.66C740.11 419.79 732.14 425.17 724.56 424.86C719.03 424.64 713.51 424.6 707.98 424.55C698.92 424.46 689.85 424.61 680.79 424.51C672.87 424.42 666.51 421.5 662.94 413.95C661.91 411.75 661.55 409.45 661.66 407.53Z"
        fill="#E4C276"
      />
      <path
        d="M131.54 388.701C142.51 388.701 153.48 388.651 164.46 388.721C171.48 388.761 178.18 393.321 180.47 399.421C184.67 410.601 177.29 422.101 165.33 422.931C162.98 423.091 160.61 422.931 158.25 422.961C146.28 423.121 134.3 423.041 122.34 423.601C117.56 423.821 113.15 423.631 109.09 421.051C102.64 416.941 99.83 409.931 101.61 402.271C103.35 394.771 109.86 389.431 117.11 389.281C121.91 389.181 126.72 389.261 131.53 389.261C131.54 389.071 131.54 388.891 131.54 388.701Z"
        fill="#E4C276"
      />
      <path
        d="M423.12 166.91C413.19 166.94 405.57 159.52 405.58 149.83C405.59 136.59 405.9 123.35 406.06 110.1C406.09 107.67 405.97 105.23 405.99 102.8C406.04 92.6303 416.16 84.5603 426.97 86.9103C436.46 88.9703 441.83 99.4403 440.13 106.94C439.91 107.9 439.95 108.92 439.94 109.91C439.9 122.62 439.98 135.32 439.83 148.03C439.73 155.84 436.79 162.12 429.3 165.6C427.18 166.58 424.99 167.02 423.12 166.91Z"
        fill="#E4C276"
      />
      <path
        d="M437.119 689.46C437.479 696.16 436.979 704.13 437.579 712.12C438.169 719.97 433.609 726.589 426.769 729.219C419.489 732.009 411.689 730.11 406.699 723.78C403.669 719.94 402.949 715.43 403.149 710.74C403.439 704.22 403.299 697.71 403.089 691.18C402.819 682.75 402.649 674.29 402.939 665.87C403.219 657.59 410.429 650.57 418.129 649.94C427.899 649.14 435.129 655.89 436.609 664.03C436.979 666.08 437.189 668.14 437.159 670.24C437.069 676.22 437.119 682.21 437.119 689.46Z"
        fill="#E4C276"
      />
      <g clip-path="url(#clip0_82_109)">
        <path
          d="M426.47 415.8C426.2 415.53 425.87 415.39 425.49 415.39H418.6C418.57 414.61 417.93 414 417.16 414H414.39C414.01 414 413.68 414.14 413.41 414.41C413.14 414.68 413 415.01 413 415.39V423.72C413 424.1 413.14 424.43 413.41 424.7C413.68 424.97 414.01 425.11 414.39 425.11H419.72C419.62 424.89 419.53 424.66 419.46 424.43C419.39 424.2 419.34 423.96 419.3 423.72H414.39V416.78H425.5V418.38C425.75 418.46 426 418.55 426.23 418.66C426.46 418.76 426.68 418.89 426.89 419.04V416.78C426.89 416.4 426.75 416.07 426.48 415.8H426.47Z"
          fill="#113E57"
        />
      </g>
      <defs>
        <clipPath id="clip0_82_109">
          <rect
            width="13.88"
            height="11.11"
            fill="white"
            transform="translate(413 414)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
