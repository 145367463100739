import React from "react";

export const AutoAnswerIcon = (props) => {
    const { className="", style={}, onClick, disabled=false } = props;
    return (
        <svg
            className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
            onClick={disabled ? null: onClick}
            style={style}
            width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
            <path className="stroke" d="M26.9993 24.5819V20.969C27.0139 20.3783 26.8106 19.8028 26.4278 19.352C25.3486 18.0808 21.6473 17.2678 20.1801 17.58C19.0365 17.8235 18.2362 18.9718 17.4539 19.7526C14.4329 18.038 11.9314 15.5416 10.2136 12.5264C10.9959 11.7457 12.1465 10.9472 12.3905 9.80572C12.7029 8.3437 11.8908 4.66599 10.6284 3.58245C10.1844 3.20133 9.61662 2.99436 9.031 3.00012H5.41081C4.03143 3.00141 2.88383 4.2386 3.00942 5.62559C2.99999 17.58 12.3334 27 24.3686 26.9906C25.763 27.1164 27.0049 25.9631 26.9993 24.5819Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="fill" d="M20.6794 4.92487C20.4267 4.78339 20.1923 4.61106 19.9827 4.41273C19.5595 4.01188 19.2229 3.49906 18.9823 2.88873L18.632 2L18.2817 2.8888C18.0411 3.49921 17.7045 4.01203 17.2813 4.41273C17.0708 4.61203 16.8365 4.78452 16.5851 4.92532L16 5.25322L16.5851 5.58112C16.8366 5.72208 17.0708 5.89449 17.2812 6.09371C17.7044 6.49434 18.041 7.00708 18.2817 7.61757L18.632 8.50637L18.9823 7.61757C19.2229 7.00723 19.5595 6.49441 19.9828 6.09364C20.1933 5.89441 20.4277 5.72185 20.6796 5.58075L21.2651 5.25277L20.6794 4.92487Z"/>
            <path className="fill" d="M26.8291 8.81651C26.5723 8.67263 26.3341 8.4976 26.1214 8.29627C25.687 7.88494 25.3402 7.35557 25.091 6.72307L24.3903 4.94531L23.6897 6.72307C23.4404 7.35549 23.0937 7.88487 22.6592 8.29627C22.4454 8.49865 22.2075 8.67383 21.9522 8.81688L20.7819 9.47268L21.9522 10.1284C22.2077 10.2715 22.4456 10.4467 22.6592 10.649C23.0937 11.0605 23.4404 11.5898 23.6897 12.2222L24.3904 14L25.0911 12.2222C25.3404 11.5897 25.6871 11.0604 26.1217 10.6489C26.3355 10.4465 26.5736 10.2712 26.8292 10.128L28 9.47216L26.8291 8.81651Z"/>
        </svg>
    );
}


