import React from "react";

export const OfflineIcon = (props) => {
	const { className="", style={}, onClick, disabled=false, status } = props;
	return (
		<svg
			className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
			onClick={disabled ? null: onClick}
			style={style}
			width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="7.5" cy="7.5" r="7" fill="#F7F7F7" stroke="#868585"/>
			<path d="M4 4L11 11" stroke="#B9B9B9" className={status}/>
			<path d="M4 11L11 4" stroke="#B9B9B9" className={status}/>
		</svg>
	);
}
