import React from "react";

export const ContactImage = (props) => {
    const { className="", style={}, onClick, disabled=false } = props;
    return (
        <svg className={"custom-icon "+className} width="59" height="60" viewBox="0 0 59 60" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
        <g clipPath="url(#clip0_10809_9693)">
        <path d="M29.5 2.64078C44.6139 2.64078 56.8592 14.8861 56.8592 30C56.8592 45.1139 44.6139 57.3592 29.5 57.3592C14.3861 57.3592 2.14078 45.1139 2.14078 30C2.14078 14.8861 14.3861 2.64078 29.5 2.64078ZM29.5 0.5C13.23 0.5 0 13.73 0 30C0 46.27 13.23 59.5 29.5 59.5C45.77 59.5 59 46.27 59 30C59 13.73 45.77 0.5 29.5 0.5Z" fill="#808080" stroke="none"/>
        <path d="M29.5 28.7157C32.0261 28.7157 34.1669 27.8166 35.9651 26.0183C37.7634 24.2201 38.6625 22.0793 38.6625 19.5532C38.6625 17.0271 37.7634 14.8863 35.9651 13.088C34.1669 11.2898 32.0261 10.3906 29.5 10.3906C26.9738 10.3906 24.833 11.2898 23.0348 13.088C21.2365 14.8863 20.3374 17.0271 20.3374 19.5532C20.3374 22.0793 21.2365 24.2201 23.0348 26.0183C24.833 27.8166 26.9738 28.7157 29.5 28.7157Z" fill="#808080" stroke="none"/>
        <path d="M42.73 36.7645C39.3904 34.7522 34.9804 33.7246 29.5 33.7246C24.0196 33.7246 19.6096 34.7522 16.2699 36.7645C12.9303 38.7769 11.2605 41.5171 11.2605 44.9423V47.2115C11.2605 47.2115 11.3889 47.3828 11.4746 47.4684C16.0987 52.0925 22.4782 54.9612 29.5428 54.9612C36.6074 54.9612 42.9869 52.0925 47.611 47.4684C47.6538 47.4256 47.7395 47.34 47.7823 47.2972V44.9851C47.7823 41.5599 46.1125 38.8625 42.7728 36.8073L42.73 36.7645Z" fill="#808080" stroke="none"/>
        </g>
        <defs>
        <clipPath id="clip0_10809_9693">
        <rect width="59" height="59" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
        </defs>
        </svg>
    );
}

// Default image for users with no Profile Pic