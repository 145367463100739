import React from "react";

export const ToggleIcon = (props) => {
    const { className = "", style={}, status, onClick, disabled=false } = props;
    return (
      <svg
        className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
        onClick={disabled ? null: onClick}
        style={style}
        xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none"
      >
        <rect className="stroke" x="0.5" y="0.5" width="22" height="22" rx="11" stroke={disabled? "#7A7A7A" : "#005C98"}/>
        {status === "enabled" && <circle className="fill" cx="11.5" cy="11.5" r="8.5" fill={disabled? "#7A7A7A" : "#005C98"}/> }
      </svg>
    );
}


