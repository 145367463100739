import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import PropTypes from "prop-types";
import VideoCall from "../VideoCall/VideoCall";
import { useSelector } from "react-redux";

const VideoCallModal = () => {
  const visible = useSelector((state) => state.videoCall.videoCallModalVisible);
  const [autoCloseTimer, setAutoCloseTimer] = useState(null);

  useEffect(() => {
    if (!visible && autoCloseTimer) {
      console.debug("Auto close timer cleared...", autoCloseTimer);
      clearTimeout(autoCloseTimer);
    }
  }, [visible, autoCloseTimer]);

  return (
    <Modal
      open={visible}
      closable={false}
      footer={null}
      maskClosable={true}
      onCancel={() => {}}
      afterClose={() => {}}
      destroyOnClose={true}
      wrapClassName="vertical-center-modal video-call-modal"
      width="100%"
    >
      <VideoCall
        autoCloseTimer={autoCloseTimer}
        setAutoCloseTimer={setAutoCloseTimer}
      />
    </Modal>
  );
};

VideoCallModal.defaultProps = {
  visible: false,
};

VideoCallModal.propTypes = {
  visible: PropTypes.bool,
};

export default VideoCallModal;
