import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CustomDropdown from "../Common/CustomDropdown";
import { Divider, message } from "antd";
import { MicOnIcon, VolumeIcon, VolumeOffIcon } from "../Common/Icons";
import {
  useAudioInputs,
  useAudioOutputs,
  useAudioVideo,
  useLocalVideo,
  useToggleLocalMute,
  useVideoInputs,
} from "amazon-chime-sdk-component-library-react";
import ProgressBar from "../Common/ProgressBar";
import { useDispatch, useSelector } from "react-redux";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { updateCallSettings } from "@commscopemycloud/humaui/Store/videoCallStore";

const getOptions = (options) =>
  (options || []).map((a) => {
    return { key: a.deviceId, label: a.label };
  });

const getVolume = () => {
  const audioElements = document.querySelectorAll("audio") ?? [];
  return audioElements[0]?.volume ?? 1;
};

const VideoCallSettings = ({
  isSetupDone,
  localChimeUser,
  showSelfView,
  setShowSelfView,
}) => {
  const trans = useSelector(translator);
  const dispatch = useDispatch();
  const callSettings = useSelector((state) => state.videoCall.callSettings);
  const audioVideo = useAudioVideo();
  const { isVideoEnabled } = useLocalVideo();
  const { muted } = useToggleLocalMute();
  const { devices: videoDevices, selectedDevice: selVideoDevice } =
    useVideoInputs();
  const { devices: audioInDevices, selectedDevice: selAudioInDevice } =
    useAudioInputs();
  const { devices: audioOutDevices, selectedDevice: selAudioOutDevice } =
    useAudioOutputs();

  const [selectedVideoDevice, setSelectedVideoDevice] = useState(
    callSettings.videoDevice || selVideoDevice
  );
  const [selectedAudioOutDevice, setSelectedAudioOutDevice] = useState(
    callSettings.audioOutputDevice || selAudioOutDevice
  );
  const [selectedAudioInDevice, setSelectedAudioInDevice] = useState(
    callSettings.audioInputDevice || selAudioInDevice
  );
  const [volumeLevel, setVolumeLevel] = useState(getVolume());
  const [audioInStrength, setAudioInStrength] = useState(0);

  useEffect(() => {
    if (!localChimeUser?.chimeAttendeeId) return;
    audioVideo?.realtimeSubscribeToVolumeIndicator(
      localChimeUser?.chimeAttendeeId,
      (_, volume) => setAudioInStrength((volume ?? 0) * 100)
    );
    return () =>
      audioVideo?.realtimeUnsubscribeFromVolumeIndicator(
        localChimeUser?.chimeAttendeeId
      );
  }, [audioVideo, localChimeUser]);

  useEffect(() => {
    const audioElements = document.querySelectorAll("audio") ?? [];
    if (!audioElements.length) return;
    const element = audioElements[0];
    element.volume = volumeLevel;
  }, [volumeLevel]);

  const handleVideoDeviceChange = (device) => {
    try {
      const { key } = device || {};
      if (!key) return;
      audioVideo?.startVideoInput(key).then(() => {
        setSelectedVideoDevice(key);
        dispatch(updateCallSettings({ videoDevice: key }));
      });
    } catch (error) {
      message.error("Error changing Video device!");
      console.error("Error changing Video device:", error);
    }
  };
  const handleAudioOutputChange = (device) => {
    try {
      const { key } = device || {};
      if (!key) return;
      audioVideo?.chooseAudioOutput(key).then(() => {
        setSelectedAudioOutDevice(key);
        dispatch(updateCallSettings({ audioOutputDevice: key }));
      });
    } catch (error) {
      message.error("Error changing Audio device!");
      console.error("Error changing Audio device:", error);
    }
  };
  const handleAudioInputChange = (device) => {
    try {
      const { key } = device || {};
      if (!key) return;
      audioVideo?.startAudioInput(key).then(() => {
        setSelectedAudioInDevice(key);
        dispatch(updateCallSettings({ audioInputDevice: key }));
      });
    } catch (error) {
      message.error("Error changing Microphone device!");
      console.error("Error changing Microphone device:", error);
    }
  };

  return (
    <div className="videocall-settings-wrapper">
      <div className="settings-section video-settings">
        <div className="flex-just-space-between flex-align-center">
          <label className="header-3">{trans("camera")}</label>
          {/* <CustomSwitch
            checked={isVideoEnabled}
            onChange={toggleVideo}
            disabled={!isSetupDone}
            checkedIcon={<VideoOnIcon className="video-icon" />}
            uncheckedIcon={<VideoOffIcon className="video-off-icon" />}
          /> */}
        </div>
        <CustomDropdown
          className="options"
          menuItems={getOptions(videoDevices)}
          selectedKeys={[selectedVideoDevice]}
          menuProps={{ selectable: false }}
          disabled={!isSetupDone || !isVideoEnabled}
          handleChange={handleVideoDeviceChange}
        />
        {/* <div className="flex-align-center">
          <CustomSwitch
            checked={showSelfView}
            onChange={() => setShowSelfView((s) => !s)}
            switchType={SwitchTypes.normal}
            style={{ marginRight: "20px" }}
          />
          <label className="self-view-setting body-text-2">
            {trans("SHOWTHEVIDEAOFMEONMYSCREEN")}
          </label>
        </div> */}
      </div>
      <Divider className="divider" />
      <div className="settings-section audio-settings">
        <label className="header-3">{trans("audio_output")}</label>
        <CustomDropdown
          className="options"
          menuItems={getOptions(audioOutDevices)}
          selectedKeys={[selectedAudioOutDevice]}
          menuProps={{ selectable: false }}
          disabled={!isSetupDone}
          handleChange={handleAudioOutputChange}
        />
        <div className="flex-align-center">
          <div
            className="settings-icon"
            style={{ marginRight: "7px", cursor: "pointer" }}
          >
            {volumeLevel === 0 ? (
              <VolumeOffIcon
                className="volume-off-icon"
                onClick={() => setVolumeLevel(1)}
              />
            ) : (
              <VolumeIcon
                className="volume-icon"
                onClick={() => setVolumeLevel(0)}
              />
            )}
          </div>
          <ProgressBar
            className="volume-level"
            height={16}
            adjustable={true}
            percent={volumeLevel * 100}
            onChange={(v) => setVolumeLevel(v / 100)}
          />
        </div>
      </div>
      <Divider className="divider" />
      <div className="settings-section microphone-settings">
        <div className="flex-just-space-between flex-align-center">
          <label className="header-3">{trans("MICROPHONE")}</label>
          {/* <CustomSwitch
            checked={!muted}
            onChange={toggleMute}
            disabled={!isSetupDone}
            checkedIcon={<MicOnIcon className="mic-icon" />}
            uncheckedIcon={<MicOffIcon className="mic-off-icon" />}
          /> */}
        </div>
        <CustomDropdown
          className="options"
          menuItems={getOptions(audioInDevices)}
          selectedKeys={[selectedAudioInDevice]}
          menuProps={{ selectable: false }}
          disabled={!isSetupDone || muted}
          handleChange={handleAudioInputChange}
        />
        <div className="flex-align-center">
          <div className="settings-icon" style={{ marginRight: "7px" }}>
            <MicOnIcon className="mic-icon" />
          </div>
          <ProgressBar percent={audioInStrength} height={16} />
        </div>
      </div>
    </div>
  );
};

VideoCallSettings.propTypes = {
  isSetupDone: PropTypes.bool,
  showSelfView: PropTypes.bool,
  setShowSelfView: PropTypes.func,
};

export default VideoCallSettings;
