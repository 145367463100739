import React from "react";

export const RCAcceptCall = (props) => {
  const { className = "", style = {}, env = false, isSelected } = props;

  return (
    <svg
      width="47"
      height="47"
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} ${isSelected ? "rc-button-selected" : ""}`}
      style={style}
    >
      <g clip-path="url(#clip0_18891_160224)">
        <path
          d="M23.5 46.0027C11.0978 46.0027 1.01196 35.9028 1.01196 23.4994C1.01196 11.096 11.0978 0.996094 23.5 0.996094C35.9021 0.996094 45.988 11.096 45.988 23.4994C45.988 35.9028 35.9021 46.0027 23.5 46.0027Z"
          fill="#027B0E"
        />
        <path d="M23.5 1.9934C35.3512 1.9934 44.9761 11.6171 44.9761 23.5C44.9761 35.3829 35.3512 45.0066 23.5 45.0066C11.6488 45.0066 2.02392 35.3718 2.02392 23.5C2.02392 11.6282 11.6376 1.9934 23.5 1.9934ZM23.5 0C10.5469 0 0 10.5429 0 23.5C0 36.4571 10.5469 47 23.5 47C36.4531 47 47 36.4571 47 23.5C47 10.5429 36.4644 0 23.5 0Z" />
        <path
          d="M31.382 31.6497C31.7531 31.6829 32.0454 31.5833 32.2703 31.3728C32.4952 31.1735 32.6189 30.8966 32.6189 30.6087V27.6407C32.6189 27.3639 32.5401 27.1092 32.3715 26.9209C31.7531 26.2011 29.077 25.5698 28.0875 25.7802C27.6378 25.8799 27.1768 26.3672 26.7382 26.8323C26.5808 26.9984 26.4346 27.1535 26.2885 27.3085C25.9849 27.6075 25.5239 27.6629 25.1528 27.4636C22.488 25.9796 20.2842 23.809 18.7775 21.1954C18.5638 20.83 18.6313 20.3648 18.9349 20.0769C19.0811 19.9329 19.2497 19.789 19.4184 19.6339C19.9019 19.202 20.3966 18.759 20.4978 18.3161C20.7114 17.3526 20.0593 14.7279 19.3397 14.1188C19.1485 13.9527 18.8562 13.853 18.6313 13.8641H15.6067C15.3256 13.8641 15.0445 13.997 14.8308 14.2185C14.6284 14.44 14.5272 14.7279 14.5497 15.0048C14.5497 19.6007 16.2813 23.7868 19.4184 26.8766C22.5555 29.9664 26.7945 31.6608 31.3595 31.6608H31.3708L31.382 31.6497Z"
          fill="white"
          stroke="white"
          stroke-width="0.6"
          stroke-miterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_18891_160224">
          <rect width="47" height="47" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
