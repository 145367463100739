import React from "react";

export const SyncIcon = (props) => {
  const { className = "", style = {}, onClick } = props;
  return (
    <svg
      className={className}
      style={style}
      onClick={onClick}
      width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="mask0_4776_27427" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
        <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4776_27427)">
        <path d="M5.1673 16.1269C4.86473 15.5641 4.63621 14.983 4.48172 14.3837C4.32724 13.7843 4.25 13.1731 4.25 12.55C4.25 10.3872 5.00256 8.5465 6.50768 7.02792C8.01281 5.50933 9.84357 4.75004 12 4.75004H12.7808L10.9308 2.90002L11.9846 1.84619L15.6384 5.50002L11.9846 9.15384L10.9308 8.10002L12.7808 6.24999H12C10.2628 6.24999 8.78683 6.86153 7.57207 8.08462C6.35732 9.3077 5.74995 10.7962 5.74995 12.55C5.74995 12.9641 5.79514 13.3779 5.88552 13.7914C5.97591 14.2048 6.11148 14.6083 6.29225 15.0019L5.1673 16.1269ZM12.0153 23.1538L8.36155 19.5L12.0153 15.8462L13.0692 16.9L11.2192 18.75H12C13.7372 18.75 15.2131 18.1385 16.4279 16.9154C17.6426 15.6923 18.25 14.2039 18.25 12.45C18.25 12.0359 18.2048 11.6221 18.1144 11.2087C18.024 10.7952 17.8885 10.3917 17.7077 9.99809L18.8327 8.87312C19.1352 9.43593 19.3637 10.017 19.5182 10.6164C19.6727 11.2157 19.75 11.827 19.75 12.45C19.75 14.6128 18.9974 16.4535 17.4923 17.9721C15.9871 19.4907 14.1564 20.25 12 20.25H11.2192L13.0692 22.1L12.0153 23.1538Z" fill="#005C98"/>
      </g>
    </svg>
  );
};
