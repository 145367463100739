import React from "react";

export const DashboardIcon = (props) => {
    const { className = "", style={} } = props;
    return (
        <svg className={"custom-icon "+className} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" style={style}>
            <rect className="stroke" x="13" y="17" width="12" height="16" rx="1" transform="rotate(-180 13 17)" strokeWidth="2"/>
            <rect className="stroke" x="17" y="13" width="12" height="16" rx="1" strokeWidth="2"/>
            <rect className="stroke" x="13" y="29" width="12" height="8" rx="1" transform="rotate(-180 13 29)" strokeWidth="2"/>
            <rect className="stroke" x="17" y="1" width="12" height="8" rx="1" strokeWidth="2"/>
        </svg>
    );
}


