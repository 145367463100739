import React from "react";

export const ScheduleIcon = (props) => {
    const { className = "", style={} } = props;
    return (
        <svg className={"custom-icon "+className} xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 24 26" fill="none" style={style}>
            <path className="stroke" d="M1 11.6667H22.3333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M1 6.33341V22.3334C1 23.8062 2.19391 25.0001 3.66667 25.0001H19.6667C21.1395 25.0001 22.3333 23.8062 22.3333 22.3334V11.6667V6.33341C22.3333 4.86065 21.1395 3.66675 19.6667 3.66675H3.66667C2.19391 3.66675 1 4.86065 1 6.33341Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M15.6667 1V6.33333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M7.66666 1V6.33333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

// Figma: schedule