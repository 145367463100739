import { Modal } from "antd";
import React from "react";
import CustomButton, { ButtonTypes } from "../Common/CustomButton";
import { CheckIcon, CloseIcon } from "../Common/Icons";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { translator } from "@commscopemycloud/humaui/Store/configStore";

const RebootResetModal = ({ data }) => {
  const trans = useSelector(translator);
  return (
    <Modal
      open={true}
      closable={false}
      footer={null}
      maskClosable={true}
      width={null}
      wrapClassName="vertical-center-modal reboot-reset-modal"
    >
      <div className="content-container">
        {data?.icon}
        <div className="description-container">
          <p className="title">{data?.title}</p>
          <p className="description">{data?.description}</p>
        </div>
        <div className="actions-container">
          {data?.cancel && (
            <CustomButton
              label={trans("CANCEL_U")}
              icon={<CloseIcon className="cancel-icon" />}
              onClick={() => data?.cancel(data)}
            />
          )}
          {data?.proceed && (
            <CustomButton
              type={ButtonTypes.primary}
              label={trans("PROCEED_U")}
              icon={<CheckIcon className="submit-icon" />}
              onClick={() => data?.proceed(data)}
            />
          )}
          {data?.dismiss && (
            <CustomButton
              type={ButtonTypes.primary}
              label={trans("DISMISS")}
              icon={<CheckIcon className="submit-icon" />}
              onClick={() => data?.dismiss(data)}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

RebootResetModal.propTypes = {
  data: PropTypes.object,
};

export default RebootResetModal;
