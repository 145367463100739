import React from "react";

export const AccountIcon = (props) => {
    const { className = "", style={} } = props;
    return (
        <svg className={"custom-icon "+className} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" style={style}>
            <mask id="mask0_45_44651" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="30">
                <rect width="30" height="30"/>
            </mask>
            <g mask="url(#mask0_45_44651)">
                <path className="fill" d="M5 25C4.3125 25 3.72396 24.7552 3.23438 24.2656C2.74479 23.776 2.5 23.1875 2.5 22.5V7.5C2.5 6.8125 2.74479 6.22396 3.23438 5.73438C3.72396 5.24479 4.3125 5 5 5H12.5L15 7.5H25C25.6875 7.5 26.276 7.74479 26.7656 8.23438C27.2552 8.72396 27.5 9.3125 27.5 10V14.0625C27.125 13.7917 26.7292 13.5625 26.3125 13.375C25.8958 13.1875 25.4583 13.0208 25 12.875V10H5V22.5H13.8438C13.9062 22.9375 14 23.3646 14.125 23.7812C14.25 24.1979 14.4062 24.6042 14.5938 25H5ZM17.5 25V24.3125C17.5 23.375 17.9583 22.6302 18.875 22.0781C19.7917 21.526 21 21.25 22.5 21.25C24 21.25 25.2083 21.526 26.125 22.0781C27.0417 22.6302 27.5 23.375 27.5 24.3125V25H17.5ZM22.5 20C21.8125 20 21.224 19.7552 20.7344 19.2656C20.2448 18.776 20 18.1875 20 17.5C20 16.8125 20.2448 16.224 20.7344 15.7344C21.224 15.2448 21.8125 15 22.5 15C23.1875 15 23.776 15.2448 24.2656 15.7344C24.7552 16.224 25 16.8125 25 17.5C25 18.1875 24.7552 18.776 24.2656 19.2656C23.776 19.7552 23.1875 20 22.5 20Z"/>
            </g>
        </svg>
    );
}


