/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { message } from "antd";
import useContacts from "../Hooks/useContacts";
import ContactForm from "./ContactForm";
import ListContacts from "./ListContacts";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import {
  Actions,
  PhotoResolution,
} from "@commscopemycloud/humaui/Utilities/Constants";
import { getProfilePics } from "@commscopemycloud/humaui/Store/dataStore";
import { getProfilePicUrl } from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import Schedule from "../Schedule/Schedule";
import { translator } from "@commscopemycloud/humaui/Store/configStore";

export const Contacts = (props) => {
  console.debug("Contacts props", props);
  const trans = useSelector(translator);
  const resourceMap = useSelector(getProfilePics());
  const { parentBread, hubRecord, openSubMenu, handleTabChange, activeKey, userHubCall = false} = props;
  const mHubRecord = {
    ...hubRecord,
    profilePic64: getProfilePicUrl(
      hubRecord.useruuid,
      resourceMap,
      PhotoResolution.R64
    ),
  };

  const userApi = useSelector((state) => state.apis.userApi);

  const hubUseruuid = hubRecord.useruuid;
  const [contacts, fetchingContacts, fetchContacts] = useContacts({
    useruuid: hubUseruuid,
  });
  const mContacts = useMemo(
    () =>
      contacts.map((contact) => ({
        ...contact,
        profilePic64: getProfilePicUrl(
          contact.useruuid,
          resourceMap,
          PhotoResolution.R64
        ),
      })),
    [contacts]
  );

  const [action, setAction] = useState(null);
  const [record, setRecord] = useState(null);
  const [showHeader, setShowHeader] = useState(false);

  const onActionClick =
    (action, record, refresh = false) =>
    () => {
      setAction(action);
      setRecord(record);
      setShowHeader(true);
      refresh && fetchContacts();
    };

  const header = (parentBread ?? []).concat({
    label: trans("CONTACTS"),
    onClick: onActionClick(null, null),
  });

  const onRemove = (useruuid) => {
    const errorCallback = (error) => {
      message.error("Error removing contact!");
      console.error("Error removing contact", error);
    };
    const successCallback = (data) => {
      message.success("Contact removed!");
      console.info("contact removed successfully", data);
      fetchContacts();
    };
    try {
      console.debug("onRemove:", useruuid);
      userApi.removeContactFromUser(
        hubUseruuid,
        { useruuid },
        apiCallback({
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  return !action ? (
    <ListContacts
      header={header}
      hubRecord={mHubRecord}
      contacts={mContacts}
      fetchingContacts={fetchingContacts}
      onActionClick={onActionClick}
      onRemove={onRemove}
      openSubMenu={openSubMenu}
    />
  ) : action === Actions.inviteContact || action === Actions.editContact ? (
    <ContactForm
      parentBread={header}
      action={action}
      record={record}
      hubRecord={mHubRecord}
      onActionClick={onActionClick}
      openSubMenu={openSubMenu}
      contacts={contacts}
    />
  ) : action === Actions.addEvent ? (
    <Schedule
      parentBread={header}
      action={action}
      hubRecord={record}
      onActionClick={onActionClick}
      showHeader={showHeader}
      openSubMenu={openSubMenu}
      handleTabChange={handleTabChange}
      activeKey={activeKey}
      userHubCall={userHubCall}
    />
  ) : null;
};
