import React from "react";

export const SocialIcon = (props) => {
    const { className = "", style={} } = props;
    return (
        <svg className={"custom-icon "+className} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" style={style}>
            <path className="stroke" d="M6 7.5H13" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M6 11.5H11" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M13.3 17.1L19 19L17.1 13.3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M13.3 17.1C13.3 17.1 12.0847 18 9.5 18C4.80558 18 1 14.1944 1 9.5C1 4.80558 4.80558 1 9.5 1C14.1944 1 18 4.80558 18 9.5C18 12 17.1 13.3 17.1 13.3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}


