import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { NewNotificationIcon } from "../Common/Icons";
import { Badge } from "antd";
import { useMainContext } from "../../MainContext";
import {
  getNotificationMiniBannerMessage,
  updateNotificationMiniBanner,
} from "@commscopemycloud/humaui/Store/notificationStore";
import {
  NavigationKeys,
  WSMessageTypes,
} from "@commscopemycloud/humaui/Utilities/Constants";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";

const NotificationMiniBanner = ({ readOnly, isSelected, searchParams }) => {
  const dispatch = useDispatch();
  const { newNotificationCount, fetchNotifications } = useMainContext();
  const message = useSelector(getNotificationMiniBannerMessage);
  const [notification, setNotification] = useState(null);
  const currentUser = useSelector(getCurrentUser);

  useEffect(() => {
    // Fetch notifications when there is a websocket message
    if (message) {
      fetchNotifications();
    }
  }, [message]);

  const handleEventMessage = (mMessage) => {
    const event = mMessage?.message?.schedule_event;
    if (event == null) return;
    if (event.action?.includes("delete")) {
      if (event.schedule_eventuuid === notification?.schedule_eventuuid) {
        handleCancel();
      }
      return;
    } else setNotification(mMessage);
  };

  const handleContactMessage = (mMessage) => {
    const contact = mMessage?.message;
    if (contact == null || contact.action?.includes("deleted")) return;
    else if (contact.useruuid !== currentUser.useruuid) {
      setNotification(mMessage);
    }
  };

  useEffect(() => {
    if (message == null) return;
    if (message.message_type === WSMessageTypes.calendar)
      handleEventMessage(message);
    else if (message.message_type === WSMessageTypes.contact)
      handleContactMessage(message);
  }, [message]);

  const handleCancel = () => {
    setNotification(null);
    dispatch(updateNotificationMiniBanner(null));
  };

  const bellIcon = (
    <div className="flex-align-center" title="Notifications">
      <Badge
        className="notification-count"
        count={newNotificationCount}
        offset={[-2, 5]}
      >
        <NewNotificationIcon
          style={{
            height: "30px",
            width: "30px",
            fill: isSelected ? "#3fa9f5" : "#113E57",
            stroke: "none",
          }}
        />
      </Badge>
    </div>
  );
  const renderBellIcon = readOnly ? (
    bellIcon
  ) : (
    <Link
      to={{
        pathname: "/" + NavigationKeys.notification,
        search: "?" + searchParams(),
      }}
    >
      {bellIcon}
    </Link>
  );

  return (
    <div className="notification-mini-banner-wrapper">{renderBellIcon}</div>
  );
};

NotificationMiniBanner.propTypes = {
  readOnly: PropTypes.bool /* disable any clicks */,
};

export default NotificationMiniBanner;
