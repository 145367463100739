import React from "react";

export const HSCalendar = (props) => {
    const { className = "", style = {} } = props;
    return (
        <svg className={className} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <path d="M4 14.6667H12.4706" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M26.5882 11.9999V9.33329C26.5882 7.86053 25.3241 6.66663 23.7647 6.66663H6.82352C5.26413 6.66663 4 7.86053 4 9.33329V25.3333C4 26.806 5.26413 27.9999 6.82352 27.9999H12.4706" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.5294 4V9.33332" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.0588 4V9.33332" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <mask id="mask0_2357_29924" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="15" y="16" width="12" height="12">
                <rect x="15" y="16" width="12" height="12" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_2357_29924)">
                <path d="M21 28C19.54 28 18.265 27.545 17.175 26.635C16.085 25.725 15.4 24.58 15.12 23.2H16.98C17.24 24.08 17.735 24.8 18.465 25.36C19.195 25.92 20.04 26.2 21 26.2C22.16 26.2 23.15 25.79 23.97 24.97C24.79 24.15 25.2 23.16 25.2 22C25.2 20.84 24.79 19.85 23.97 19.03C23.15 18.21 22.16 17.8 21 17.8C20.42 17.8 19.88 17.905 19.38 18.115C18.88 18.325 18.44 18.62 18.06 19H19.8V20.8H15V16H16.8V17.71C17.34 17.19 17.97 16.775 18.69 16.465C19.41 16.155 20.18 16 21 16C22.66 16 24.075 16.585 25.245 17.755C26.415 18.925 27 20.34 27 22C27 23.66 26.415 25.075 25.245 26.245C24.075 27.415 22.66 28 21 28Z" fill="#1C1B1F" />
            </g>
        </svg>
    );
}


