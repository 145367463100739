import React from "react";

export const BellIcon = (props) => {
  const { className = "", style = {}, disabled = false, onClick = null } = props;
  return (
    <svg
      className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
      onClick={disabled ? null: onClick}
      style={style}
      width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"
    >
      <path className="fill" d="M14.998 7.07815C10.9913 7.07815 8.05064 10.5581 8.05064 13.6031C8.05064 20.9949 4.89275 22.8333 4.89275 22.8333L25.1033 22.8469C25.1033 22.8469 21.9454 20.9949 21.9454 13.6031C21.9454 10.5581 19.0047 7.07815 14.998 7.07815ZM14.998 5.4469C16.2684 5.44761 17.5196 5.71329 18.6447 6.2212C19.7056 6.70005 20.6475 7.35282 21.4174 8.14281C22.9407 9.66357 23.7978 11.5953 23.8401 13.6031C23.8401 17.1087 24.5936 19.1684 25.2258 20.2793C25.4376 20.6696 25.7084 21.0342 26.031 21.3635L26.17 21.4989C26.5047 21.6953 26.7574 21.9788 26.8912 22.3078C27.025 22.6369 27.0329 22.9942 26.9136 23.3274C26.7943 23.6606 26.5541 23.9521 26.2283 24.1592C25.9025 24.3663 25.5082 24.478 25.1033 24.4781L4.89275 24.4645C4.49298 24.4641 4.10358 24.3549 3.78028 24.1525C3.45698 23.9501 3.21632 23.6648 3.09277 23.3374C2.96921 23.0101 2.96907 22.6575 3.09238 22.3301C3.21568 22.0027 3.45613 21.7173 3.77928 21.5147C4.28686 21.0745 4.68108 20.5472 4.93631 19.9672C5.94683 17.9814 6.15779 15.41 6.15779 13.6053C6.20012 11.5975 7.05723 9.66573 8.58053 8.14497C9.35044 7.35498 10.2923 6.7022 11.3532 6.22336C12.4775 5.71495 13.7281 5.44855 14.998 5.4469Z" />
      <path className="fill" d="M14.9545 26.9999C14.2311 27.0021 13.5146 26.8783 12.8481 26.6361C12.1816 26.3939 11.5788 26.0383 11.0759 25.5905C10.9892 25.5137 10.9208 25.423 10.8747 25.3235C10.8287 25.2239 10.8058 25.1176 10.8075 25.0105C10.8091 24.9034 10.8353 24.7976 10.8844 24.6992C10.9336 24.6008 11.0048 24.5116 11.094 24.4369C11.1831 24.3622 11.2885 24.3033 11.4041 24.2636C11.5197 24.224 11.6432 24.2043 11.7676 24.2058C11.892 24.2072 12.0149 24.2297 12.1292 24.272C12.2436 24.3144 12.3471 24.3757 12.4339 24.4524C12.7617 24.7425 13.1537 24.9731 13.5868 25.1306C14.0199 25.2881 14.4853 25.3692 14.9554 25.3692C15.4256 25.3692 15.891 25.2881 16.3241 25.1306C16.7572 24.9731 17.1492 24.7425 17.477 24.4524C17.6528 24.2999 17.8914 24.2132 18.141 24.2112C18.3906 24.2093 18.631 24.2922 18.81 24.442C18.9889 24.5918 19.092 24.7963 19.0968 25.0112C19.1016 25.226 19.0077 25.4338 18.8355 25.5894C18.3325 26.0376 17.7294 26.3937 17.0624 26.6361C16.3954 26.8785 15.6785 27.0022 14.9545 26.9999Z" />
      <path className="fill" d="M17.5244 6.80624C17.2731 6.80624 17.0321 6.72031 16.8545 6.56736C16.6768 6.4144 16.577 6.20693 16.577 5.99062C16.577 5.63009 16.4107 5.28433 16.1145 5.0294C15.8184 4.77447 15.4168 4.63125 14.9981 4.63125C14.5793 4.63125 14.1777 4.77447 13.8816 5.0294C13.5855 5.28433 13.4191 5.63009 13.4191 5.99062C13.4191 6.20693 13.3193 6.4144 13.1416 6.56736C12.964 6.72031 12.723 6.80624 12.4718 6.80624C12.2205 6.80624 11.9795 6.72031 11.8019 6.56736C11.6242 6.4144 11.5244 6.20693 11.5244 5.99062C11.5244 5.19746 11.8904 4.43677 12.5418 3.87593C13.1932 3.31508 14.0768 3 14.9981 3C15.9193 3 16.8029 3.31508 17.4543 3.87593C18.1058 4.43677 18.4717 5.19746 18.4717 5.99062C18.4717 6.20693 18.3719 6.4144 18.1943 6.56736C18.0166 6.72031 17.7756 6.80624 17.5244 6.80624Z" />
      <path className="fill" d="M23.7138 19.0406H6.28226C5.72268 19.0406 5.27171 19.0912 5.27171 19.8563C5.27171 20.6213 5.72584 20.6719 6.28226 20.6719H23.7138C24.2734 20.6719 24.7244 20.6213 24.7244 19.8563C24.7244 19.0912 24.274 19.0406 23.7138 19.0406Z" />
      <path className="fill" d="M6.28235 18.7688H23.7139C24.1787 18.7688 25.0402 18.7688 25.0402 19.8563C25.0402 20.9438 24.1756 20.9438 23.7139 20.9438H6.28235C5.81751 20.9438 4.95601 20.9438 4.95601 19.8563C4.95601 18.7688 5.81687 18.7688 6.28235 18.7688ZM23.7139 20.4C24.2324 20.4 24.4087 20.3739 24.4087 19.8563C24.4087 19.3386 24.2293 19.3125 23.7139 19.3125H6.28235C5.76382 19.3125 5.58759 19.3386 5.58759 19.8563C5.58759 20.3739 5.76698 20.4 6.28235 20.4H23.7139Z" />
    </svg>
  );
};
