import { useSelector } from "react-redux";

// Helper function to check if the user has Staff or Staff Admin privileges
export const hasStaffPrivileges = (userRole) => {
  return userRole === "Staff" || userRole === "Staff Admin";
};

// Hook to get user role
export const useUserRole = () => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const userRole = currentUser?.permission_level;
  return userRole;
};
