import React from "react";

export const MorningIcon = () => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11119_9201)">
        <path
          d="M7.76208 2.2776C7.88208 2.3301 8.00958 2.3226 8.12208 2.2776C8.23458 2.2326 8.32458 2.1501 8.37708 2.0301L8.74458 1.1751C8.84958 0.942604 8.74458 0.665104 8.50458 0.560104C8.33208 0.485104 8.12958 0.530104 7.99458 0.650104L7.88958 0.800104L7.52208 1.6551C7.41708 1.8876 7.52208 2.1651 7.76208 2.2701V2.2776Z"
          fill="#F9C030"
        />
        <path
          d="M4.25953 2.26143C4.37953 2.21643 4.46953 2.11893 4.51453 2.01393C4.55953 1.90893 4.56703 1.78143 4.51453 1.65393L4.16953 0.791425C4.07203 0.551425 3.80203 0.438925 3.56203 0.528925C3.38203 0.596425 3.26953 0.768925 3.26953 0.948925L3.29953 1.12893L3.64453 1.99143C3.74203 2.23143 4.01203 2.34393 4.25203 2.25393L4.25953 2.26143Z"
          fill="#F9C030"
        />
        <path
          d="M1.7776 4.73798C1.8301 4.61798 1.8226 4.49048 1.7776 4.37798C1.7326 4.26548 1.6501 4.17548 1.5301 4.12298L0.675104 3.75548C0.442604 3.65048 0.165104 3.75548 0.0601042 3.99548C-0.0148958 4.16798 0.0301042 4.37048 0.150104 4.50548L0.300104 4.61048L1.1551 4.97798C1.3876 5.08298 1.6651 4.97798 1.7701 4.73798H1.7776Z"
          fill="#F9C030"
        />
        <path
          d="M1.7624 8.24084C1.7174 8.12084 1.6199 8.03084 1.5149 7.98584C1.4099 7.94084 1.2824 7.93334 1.1549 7.98584L0.292402 8.33084C0.0524019 8.42834 -0.0600981 8.69834 0.0299019 8.93834C0.0974019 9.11834 0.269902 9.23084 0.449902 9.23084L0.629902 9.20084L1.4924 8.85584C1.7324 8.75834 1.8449 8.48834 1.7549 8.24834L1.7624 8.24084Z"
          fill="#F9C030"
        />
        <path
          d="M4.23798 10.7222C4.11798 10.6697 3.99048 10.6772 3.87798 10.7222C3.76548 10.7672 3.67548 10.8497 3.62298 10.9697L3.25548 11.8247C3.15048 12.0572 3.25548 12.3347 3.49548 12.4397C3.66798 12.5147 3.87048 12.4697 4.00548 12.3497L4.11048 12.1997L4.47798 11.3447C4.58298 11.1122 4.47798 10.8347 4.23798 10.7297V10.7222Z"
          fill="#F9C030"
        />
        <path
          d="M7.73986 10.7382C7.61986 10.7832 7.52986 10.8807 7.48486 10.9857C7.43986 11.0907 7.43236 11.2182 7.48486 11.3457L7.82986 12.2082C7.92736 12.4482 8.19736 12.5607 8.43736 12.4707C8.61736 12.4032 8.72986 12.2307 8.72986 12.0507L8.69986 11.8707L8.35486 11.0082C8.25736 10.7682 7.98736 10.6557 7.74736 10.7457L7.73986 10.7382Z"
          fill="#F9C030"
        />
        <path
          d="M10.2222 8.26306C10.1697 8.38306 10.1772 8.51056 10.2222 8.62306C10.2672 8.73556 10.3497 8.82556 10.4697 8.87806L11.3247 9.24556C11.5572 9.35056 11.8347 9.24556 11.9397 9.00556C12.0147 8.83306 11.9697 8.63056 11.8497 8.49556L11.6997 8.39056L10.8447 8.02305C10.6122 7.91805 10.3347 8.02306 10.2297 8.26306H10.2222Z"
          fill="#F9C030"
        />
        <path
          d="M10.2372 4.75953C10.2822 4.87953 10.3797 4.96953 10.4847 5.01453C10.5897 5.05953 10.7172 5.06703 10.8447 5.01453L11.7072 4.66953C11.9472 4.57203 12.0597 4.30203 11.9697 4.06203C11.9022 3.88203 11.7297 3.76953 11.5497 3.76953L11.3697 3.79953L10.5072 4.14453C10.2672 4.24203 10.1547 4.51203 10.2447 4.75203L10.2372 4.75953Z"
          fill="#F9C030"
        />
        <path
          d="M5.92473 10.4159C8.07449 10.4159 9.81723 8.67313 9.81723 6.52336C9.81723 4.37359 8.07449 2.63086 5.92473 2.63086C3.77496 2.63086 2.03223 4.37359 2.03223 6.52336C2.03223 8.67313 3.77496 10.4159 5.92473 10.4159Z"
          fill="#F9C030"
        />
      </g>
      <defs>
        <clipPath id="clip0_11119_9201">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
