import React from "react";

export const CloseIcon = (props) => {
    const { className = "", style={}, onClick, disabled=false } = props;
    return (
        <svg
            className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
            onClick={disabled ? null: onClick}
            xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"
            style={style}
        >
            <path className="fill" d="M3.50866 1.44429C3.03062 0.966259 2.25558 0.966258 1.77754 1.44429C1.29951 1.92233 1.29951 2.69737 1.77754 3.17541L1.79596 3.19382L4.58369 6.01838L1.79596 8.8061C1.3093 9.27249 1.3015 10.051 1.77309 10.5327C2.24959 11.0193 3.03622 11.0281 3.51782 10.5465L3.52707 10.5372L6.35163 7.71266L9.13936 10.5372C9.60428 11.0224 10.3771 11.0306 10.8523 10.5554L10.8887 10.519C11.3638 10.0439 11.3556 9.27102 10.8705 8.8061L8.04591 6.01838L10.8705 3.19382L10.8797 3.18457C11.3613 2.70297 11.3526 1.91633 10.8659 1.43983C10.3843 0.968248 9.60574 0.976048 9.13936 1.46271L6.35163 4.25043L3.52707 1.46271L3.50866 1.44429Z"/>
        </svg>
    );
}


