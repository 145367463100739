import React from "react";

export const MicOnIcon = (props) => {
    const { className = "", style = {}, disabled = false, onClick } = props;
    return (
        <svg
            className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
            onClick={disabled ? null: onClick}
            style={style}
            width="15" height="22" viewBox="0 0 15 22" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
            <path className="stroke" d="M10.8499 10.85V4.55002C10.8499 2.81033 9.43964 1.40002 7.6999 1.40002C5.96015 1.40002 4.5499 2.81033 4.5499 4.55002V10.85C4.5499 12.5898 5.96015 14 7.6999 14C9.43964 14 10.8499 12.5898 10.8499 10.85Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M13.9999 10.85C13.9999 14.3294 11.1793 17.15 7.6999 17.15C4.22051 17.15 1.3999 14.3294 1.3999 10.85" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M7.69991 17.15V20.3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M10.8499 20.3H7.6999H4.5499" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}