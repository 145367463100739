import React from "react";

export const ScheduleAddIcon = (props) => {
    const { className="", style={}, onClick, disabled=false } = props;
    return (
        <svg className={`custom-icon ${disabled ? "disabled" : ""} ${className}`} onClick={disabled ? null: onClick} width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <g clipPath="url(#clip0_9133_82401)">
                <path d="M5.8485 11.0685H21.8505V8.89675H5.8485V11.0685ZM5.8485 26.3132C5.21985 26.3132 4.67693 26.0989 4.23401 25.6703C3.7911 25.2416 3.5625 24.7273 3.5625 24.1272V8.89675C3.5625 8.29667 3.7911 7.78233 4.23401 7.3537C4.67693 6.92508 5.21985 6.71076 5.8485 6.71076H6.9915V5.68206C6.9915 5.05341 7.50585 4.53906 8.1345 4.53906C8.76315 4.53906 9.2775 5.05341 9.2775 5.68206V6.71076H18.4215V5.68206C18.4215 5.05341 18.9359 4.53906 19.5645 4.53906C20.1931 4.53906 20.7075 5.05341 20.7075 5.68206V6.71076H21.8505C22.4792 6.71076 23.0221 6.92508 23.465 7.3537C23.9079 7.78233 24.1365 8.29667 24.1365 8.89675V15.0689C23.7793 14.9118 23.4078 14.7689 23.0221 14.6546C22.6363 14.5403 22.2506 14.4689 21.8505 14.4117V13.2402H5.8485V24.1272H13.0494C13.178 24.5273 13.3351 24.913 13.5209 25.2702C13.7066 25.6274 13.9209 25.9703 14.1638 26.2989H5.8485V26.3132Z" fill="#113E57" stroke="none" />
                <path d="M24.7652 18.1113C23.6508 17.054 22.3077 16.5254 20.7218 16.5254C19.1359 16.5254 17.7929 17.054 16.6785 18.1113C15.564 19.1686 15.0068 20.4545 15.0068 21.9546C15.0068 23.4548 15.564 24.7407 16.6785 25.8123C17.7929 26.8695 19.1359 27.3982 20.7218 27.3982C22.3077 27.3982 23.6508 26.8695 24.7652 25.8123C25.8796 24.755 26.4368 23.4691 26.4368 21.9546C26.4368 20.4402 25.8796 19.1686 24.7652 18.1113ZM23.065 22.8833H21.7362V24.2121C21.7362 24.7693 21.279 25.2265 20.7218 25.2265C20.1646 25.2265 19.7074 24.7693 19.7074 24.2121V22.8833H18.3787C17.8215 22.8833 17.3643 22.4261 17.3643 21.8689C17.3643 21.3117 17.8215 20.8545 18.3787 20.8545H19.7074V19.5258C19.7074 18.9686 20.1646 18.5114 20.7218 18.5114C21.279 18.5114 21.7362 18.9686 21.7362 19.5258V20.8545H23.065C23.6222 20.8545 24.0794 21.3117 24.0794 21.8689C24.0794 22.4261 23.6222 22.8833 23.065 22.8833Z" fill="#113E57" stroke="none" />
            </g>
            <defs>
                <clipPath id="clip0_9133_82401">
                    <rect width="22.8743" height="22.86" fill="white" transform="translate(3.5625 4.53906)" />
                </clipPath>
            </defs>
        </svg>
    );
}

// Figma: schedule