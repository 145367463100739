import React from "react";
import PropTypes from "prop-types";
import "../../styles/Maintenance.less";
import { AlertIcon } from "./Icons";

const Maintenance = ({ type, message, onClose }) => {
  return (
    <div className="ribbon">
      <AlertIcon announcement={type === "Announcement"} />
      <div className="message-type">{type}:</div>
      <div
        className="message"
        dangerouslySetInnerHTML={{ __html: message }}
      />
      {type === "Information" && (
        <div className="ribbon-close" onClick={onClose}>
          &times;
        </div>
      )}
    </div>
  );
};

Maintenance.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Maintenance;
