import React from "react";

export const HSNewRepeatingIcon = (props) => {
    const { className = "", style={} } = props;
    return (
        <svg className={className} style={style} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.6219 23.5329H6.31852V12.6765H22.5482V13.8448C22.9539 13.9018 23.3452 13.9873 23.7364 14.087C24.1277 14.201 24.5044 14.3292 24.8667 14.5002V8.34538C24.8667 7.747 24.6349 7.2341 24.1856 6.80669C23.7364 6.37927 23.1858 6.16557 22.5482 6.16557H21.3889V5.13977C21.3889 4.5129 20.8673 4 20.2297 4C19.5921 4 19.0704 4.5129 19.0704 5.13977V6.16557H9.79631V5.13977C9.79631 4.5129 9.27464 4 8.63705 4C7.99945 4 7.47779 4.5129 7.47779 5.13977V6.16557H6.31852C5.68093 6.16557 5.13028 6.37927 4.68107 6.80669C4.23185 7.2341 4 7.747 4 8.34538V23.5471C4 24.1455 4.23185 24.6584 4.68107 25.0858C5.13028 25.5132 5.68093 25.7269 6.31852 25.7269H14.7522C14.5058 25.3992 14.2884 25.0573 14.1001 24.7011C13.9117 24.3449 13.7523 23.9603 13.6219 23.5613V23.5329ZM6.31852 8.34538H22.5482V10.511H6.31852V8.34538Z" fill="#113E57"/>
        <path d="M25.7652 18.1616C23.9248 15.7681 20.447 15.2979 18.0126 17.1073L17.1721 16.0245L15.9694 20.3272L20.505 20.3699L19.6645 19.2871C20.8818 18.3753 22.6207 18.6175 23.5336 19.8143C24.461 21.011 24.2146 22.7207 22.9974 23.6183C21.7802 24.5301 20.0413 24.2879 19.1284 23.0911C18.7371 22.5782 18.5487 21.9656 18.5777 21.3672L15.81 21.3387C15.781 22.5212 16.1433 23.7037 16.9258 24.7153C18.7661 27.1088 22.2439 27.579 24.6783 25.7696C27.1128 23.9602 27.591 20.5409 25.7507 18.1474L25.7652 18.1616Z" fill="#113E57"/>
        </svg>

    );
}
