import React from "react";

export const ContactsIcon = (props) => {
    const { className = "", style={} } = props;
    return (
        <svg className={className} width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
        <g clipPath="url(#clip0_9133_77058)">
        <path d="M22.5 4.5C12.569 4.5 4.5 12.569 4.5 22.5C4.5 32.431 12.569 40.5 22.5 40.5C32.431 40.5 40.5 32.431 40.5 22.5C40.5 12.569 32.431 4.5 22.5 4.5ZM22.5 37.1897C14.3851 37.1897 7.81034 30.6149 7.81034 22.5C7.81034 14.3851 14.3851 7.81034 22.5 7.81034C30.6149 7.81034 37.1897 14.3851 37.1897 22.5C37.1897 30.6149 30.6149 37.1897 22.5 37.1897Z" fill={className}/>
        <path d="M16.7987 24.5466C17.9481 24.5466 18.9366 24.1328 19.7642 23.3052C20.5918 22.4776 21.0056 21.4891 21.0056 20.3397C21.0056 19.1903 20.5918 18.2018 19.7642 17.3742C18.9366 16.5466 17.9481 16.1328 16.7987 16.1328C15.6493 16.1328 14.6608 16.5466 13.8332 17.3742C13.0056 18.2018 12.5918 19.1903 12.5918 20.3397C12.5918 21.4891 13.0056 22.4776 13.8332 23.3052C14.6608 24.1328 15.6493 24.5466 16.7987 24.5466Z" fill={className}/>
        <path d="M33.3508 28.018C31.8105 27.0984 29.7875 26.6387 27.2818 26.6387C26.1094 26.6387 25.0749 26.7536 24.1094 26.9605C26.2703 28.4548 26.7531 30.3858 26.7531 31.7651V34.6846C29.8795 33.6042 32.4542 31.3743 33.9944 28.5007C33.7875 28.3398 33.6036 28.1559 33.3508 28.018Z" fill={className}/>
        <path d="M22.8679 28.018C22.615 27.8571 22.3161 27.7421 22.0403 27.6042C21.6955 27.4433 21.3047 27.3053 20.9138 27.1904C20.3161 27.0065 19.6955 26.8686 19.0058 26.7766C18.3161 26.6846 17.5805 26.6387 16.7989 26.6387C15.5345 26.6387 14.4081 26.7536 13.3966 26.9835C12.3851 27.2134 11.5116 27.5582 10.7529 28.018C12.8449 32.4088 17.3276 35.4203 22.5001 35.4203C23.4196 35.4203 24.3161 35.3283 25.1897 35.1444V31.7651C25.1897 30.9835 25.0058 30.2709 24.615 29.6502C24.2242 29.0295 23.6495 28.4778 22.8909 28.018H22.8679Z"/>
        <path d="M27.2821 24.5466C28.4315 24.5466 29.42 24.1328 30.2476 23.3052C31.0752 22.4776 31.489 21.4891 31.489 20.3397C31.489 19.1903 31.0752 18.2018 30.2476 17.3742C29.42 16.5466 28.4315 16.1328 27.2821 16.1328C26.1327 16.1328 25.1442 16.5466 24.3166 17.3742C23.489 18.2018 23.0752 19.1903 23.0752 20.3397C23.0752 21.4891 23.489 22.4776 24.3166 23.3052C25.1442 24.1328 26.1327 24.5466 27.2821 24.5466Z" fill={className}/>
        </g>
        <defs>
        <clipPath id="clip0_9133_77058">
        <rect width="36" height="36" fill={className} transform="translate(4.5 4.5)"/>
        </clipPath>
        </defs>
        </svg>        
    );
}

// Figma: new contact