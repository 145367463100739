/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useEffect } from "react";
import { Skeleton} from "antd";
import {
  Actions,
  DateFormat,
  PhotoResolution,
  sessionType,
} from "@commscopemycloud/humaui/Utilities/Constants";
import MainPage from "../../MainPage";
import { CallIcon, ContactIcon, HSCalendar, SuccessCallIcon, DeclinedCallIcon, MissedCallStatusIcon, ContactNewUIIcon, OutgoingCallIcon, IncomingCallIcon } from "../Common/Icons";
import {
  getDateFormat,
  getTimeFormat,
} from "@commscopemycloud/humaui/Utilities/DateTime";
import {
  getProfilePicUrl,
  getUsername,
} from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import { getContacts } from "../Schedule/Contacts";
import { HSDatePicker } from "../Common/HSWidget";
import { UserImage } from "../Schedule/UserImage";
import { getProfilePics } from "@commscopemycloud/humaui/Store/dataStore";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import moment from "moment";
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(timezone);

const LocalTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const ListCallActivity = (props) => {
  console.debug("ListCallActivity Props", props);
  const {
    onlyContent,
    parentBread,
    selectedDate,
    setSelectedDate,
    onActionClick,
    contacts,
    userInfo,
    userHubsData,
    rolename,
    hubRecord,
    notifications,
    fetchingNotifications,
    scheduleList,
    fetchingScheduleList,
    timezone,
    loginUserTimeformat,
  } = props;
  const trans = useSelector(translator);
  const dispatch = useDispatch();
  const resourcesMap = useSelector(getProfilePics());
  const header = parentBread || [];
  const userTimezone = timezone || LocalTimezone;
  const currentUser = useSelector(getCurrentUser);

  const formatList = (notifications, scheduleList) => {
    // Filter notifications with message_type equal to "call_status"
    const filteredNotifications = notifications?.filter(event => event.message_type === "call_status" && (event.call_status__status === "missed" || event.call_status__status === "declined" ||  event.hasOwnProperty("duration")));

    // Filter schedule events with event_type equal to "schedule"
    const filteredScheduleEvents = scheduleList?.filter(event => event.event_type === "schedule");

    // Merge filtered notifications and schedule events into a single array
    const mergedEvents = [...filteredNotifications, ...filteredScheduleEvents];

    const selectedDateTime = new Date(selectedDate);
    selectedDateTime.setDate(selectedDateTime.getDate() + 1);
    const now = new Date();

    let filteredList = mergedEvents.filter((event) => {
      const eventDate = new Date(event.event_timestamp);
      return eventDate.getTime() <= selectedDateTime.getTime() &&
      eventDate.getTime() <= now.getTime();
    });

    // Sort the filtered list by event timestamp
    filteredList.sort((a, b) => new Date(a.event_timestamp) - new Date(b.event_timestamp));

    const list = filteredList.reduce((acc, event) => {
      const dateObj = getDateFormat(event.event_timestamp, userTimezone);
      const date = dateObj.date;

      if (!acc[date]) {
        acc[date] = [];
      }

      const duplicate = acc[date].some(
        (e) => e.schedule_eventuuid === event.schedule_eventuuid
      );

      if (!duplicate) {
        acc[date].push(event);
      }

      return acc;
    }, {});
    const sortedList = Object.values(list).sort((a, b) => {
      return new Date(b[0]?.event_timestamp) - new Date(a[0]?.event_timestamp);
    });
    return sortedList;
  };

  const formattedList = useMemo(() => formatList(notifications, scheduleList), [notifications, scheduleList]);

  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };

  const contactImage = (contact) => {
    let displayName = getUsername(contact);
    const url = getProfilePicUrl(
      contact.useruuid,
      resourcesMap,
      PhotoResolution.R64
    );
    return <UserImage name={displayName} url={url} />;
  };

  const renderDateListItem = (item) => {
    let dateObj = getDateFormat(item?.event_timestamp, userTimezone);
    return (
      <div
        key={dateObj.dayDateformat}
        className={
          dateObj.dayDateformat.startsWith("Today")
            ? "callactivity-list-date callactivity-list-today"
            : "callactivity-list-date"
        }
      >
        <div className="callactivity-date">{dateObj.dayDateformat}</div>
      </div>
    );
  };

  const renderCallDirection = (item) => {
    if (item?.incoming !== undefined) {
      const invitedList = renderInvitedList(item.invited_contacts);
      if (invitedList.count > 2) {
        return null; // Do not render call direction if invited contacts are more than 2
      }
       else if (item?.incoming) {
        return <span className="call-direction"><IncomingCallIcon/></span>;
      } else {
        return <span className="call-direction"><OutgoingCallIcon/></span>;
      }
    }
    return null; // Don't render anything if the incoming information is not present
  };
  
  const renderInvitedList = (invitedUseruuid, event_name) => {
    const updatedInvitedUser = event_name === null ? invitedUseruuid.map(user => user.useruuid) : invitedUseruuid;
    
    let invitedContacts = getContacts(updatedInvitedUser, [
      ...(contacts ?? []),
      ...(userHubsData ?? []),
      ...(userInfo ? [userInfo] : []),
    ]);

    const count = invitedContacts.length;

    const invitedContactsData = invitedContacts
      .slice(0, 9)
      .map((contact, index) => (
        <div key={index} className="user-image-container">
          {contact && contactImage(contact)}
        </div>
      ));

    return { invitedContactsData, count };
  };

  const getCallStatusIcon = (buttonType, duration) => {
    const formatDuration = (duration) => {
      // Round up durations less than 60 seconds to one minute
      if (duration <= 60) {
        return '1 min';
      }
    
      const minutes = Math.ceil(duration / 60);
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
    
      if (hours > 0) {
        return `${hours} hr ${remainingMinutes} min`;
      } else {
        return `${remainingMinutes} min`;
      }
    };

    if(duration){
      return(
      <>
        <SuccessCallIcon />
        <span className="call-duration" style={{boxSizing:"border-box", backgroundColor:"#808080", borderRadius:"30px",flexDirection:"row", justifyContent:"center",alignItems:"center",padding:"5px 13px", gap:"10px",order:"8", fontSize:"14px", fontWeight:"700",textAlign:"left", color:"#FFFFFF", marginLeft: "25px"}}>{formatDuration(duration)}</span>
        </>
      )
    }
    else{
      switch (buttonType) {
        case "missed":
          return <MissedCallStatusIcon />;
        case "declined":
          return <DeclinedCallIcon />;
        // case "joining":
        //   return <SuccessCallIcon />;
        default:
          return null;
      }
    }
  };

  const disabledFutureDate = (current) => {
    return current && current > moment().endOf('day');
  };

  const renderCallActivityList = () => {
    return (
      <div className="callactivity-list-container">
        <div className="callactivity-actions">
          <HSDatePicker
            DateFormat={DateFormat}
            timeZone={userTimezone}
            defaultValue={selectedDate}
            onDateSelect={handleDateSelect}
            disabledDate={disabledFutureDate}
            value={selectedDate ? dayjs(selectedDate).tz(userTimezone, true) : null}
          />
        </div>
        {fetchingNotifications ? (
          <div className="callactivity-container skeleton-container">
            <Skeleton active avatar paragraph={false} />
          </div>
        ) : (
          formattedList.map((list, index) => (
            <div key={"callevents_day_" + index} className="callactivite-data-list">
              {renderDateListItem(list[0])}
              {list.map((item) =>
              (
                <div
                  key={item.message_id}
                  className={`callactivity-container ${item.call_status__status === "missed" || item.call_status__status === "declined" ? "callactivity-grey-container" : ""
                    }`}
                >
                  <div className="callactivity-list-row1">
                    <div className="container-1">
                      <div title={userTimezone} className="callactivity-eventtime">
                        {(loginUserTimeformat && loginUserTimeformat?.is24hoursformat) ? 
                          getTimeFormat(item.event_timestamp, userTimezone, loginUserTimeformat?.is24hoursformat) : 
                          getTimeFormat(item.event_timestamp, userTimezone, loginUserTimeformat?.is24hoursformat)}
                      </div>
                      {item.recurring ? ( <HSCalendar />) : (<div className="empty-action" style={{width:"30px", marginLeft:"10px"}}></div>)}
                      <div
                        className={`${item.event_type?.toLowerCase() === sessionType.zoom
                          ? "call-activity-zoomeventtype"
                          : "call-activity-hseventype"
                          }`}
                      >
                        {item.event_type?.toLowerCase() === sessionType.zoom
                          ? trans("ZOOMCALL")
                          : trans("VIDEOCALL")}
                      </div>
                      <div className="callactivity-eventname">
                        {item?.event_name}
                      </div>
                      {renderCallDirection(item)}
                    </div>
                    <div className="container-2" style={{width: "250px"}}>
                        {getCallStatusIcon(item?.call_status__status, item?.duration)}
                    </div>
                  </div>
                  <div className="callactivity-list-row2">
                    <div>
                      {item?.message_type === "call_status" ? <ContactIcon className="contacts-icon" /> : <ContactNewUIIcon className="contact-icon" />}
                    </div>
                    {renderInvitedList(item.invited_contacts).count > 0 && (
                      <div className="invited-count">
                        {renderInvitedList(item.invited_contacts).count}
                      </div>
                    )}
                    <div className="invited-list">
                      {
                        item?.invited_contacts && renderInvitedList(item?.invited_contacts, item?.event_name)
                          .invitedContactsData
                      }
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))
        )}
      </div>
    );
  };

  const headerSection = () => {
    return (
      <div className="callactivity-header">
        <div className="callactivity-title">
          <div><CallIcon className="callactivity-icon-newui" /></div>
          <div className="callactivity-text-newui">{trans("CALLACTIVITY")}</div>
        </div>
      </div>
    );
  };

  const render = (
    <div className="list-callactivity-container page-content-wrapper">
      {headerSection()}
      <div className="callactivity-content">{renderCallActivityList()}</div>
    </div>
  );

  return onlyContent ? (
    render
  ) : (
    <MainPage header={header} noModals={true}>
      {render}
    </MainPage>
  );
};

export default ListCallActivity;