import { Modal } from "antd";
import React from "react";
import CustomButton, { ButtonTypes } from "../Common/CustomButton";
import { CallIcon, ProceedCallIcon,CloseIcon } from "../Common/Icons";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { translator } from "@commscopemycloud/humaui/Store/configStore";

const AutoCallModal = ({ data }) => {
  const trans = useSelector(translator);

  const handleCancel = () => {
    if (data?.cancel) {
      data.cancel();
    }
  };

  const handleRing = () => {
    if (data?.ring) {
      data.ring();
    }
  };

  const handleProceed = () => {
    if (data?.proceed) {
      data.proceed();
    }
  };
  return (
    <Modal
      open={true}
      closable={false}
      footer={null}
      maskClosable={true}
      width={null}
      wrapClassName="vertical-center-modal auto-call-modal"
    >
      <div className="auto-call-modal-content-container">
        {data?.icon}
        <div className="auto-call-description-container">
          <p className="auto-call-title">{data?.title}</p>
          <p className="auto-call-description">{data?.description}</p>
          <p className="sub-call-description">{data?.subdescription}</p>
        </div>
        <div className="auto-call-actions-container">
          {data?.cancel && (
            <CustomButton
              label={trans("CANCEL_U")}
              icon={<CloseIcon className="cancel-icon" />}
              onClick={handleCancel}
            />
          )}
          {data?.ring && (
            <CustomButton
              label={trans("CALL_WAIT_FOR_ANSWER")}
              icon={<CallIcon className="ring-container" />}
              onClick={handleRing}
            />
          )}

          {data?.proceed && (
            <CustomButton
              type={ButtonTypes.primary}
              label={trans("PROCEED_AUTO")}
              icon={<ProceedCallIcon className="proceed-container" />}
              onClick={handleProceed}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

AutoCallModal.propTypes = {
  data: PropTypes.object,
};

export default AutoCallModal;
