import React from "react";

export const CareIcon = (props) => {
    const { className = "", style={} } = props;
    return (
        <svg className={"custom-icon "+className} xmlns="http://www.w3.org/2000/svg" width="19" height="16" viewBox="0 0 19 16" fill="none" style={style}>
            <path className="stroke" d="M5.56188 1.33337C4.39903 1.33337 3.36303 1.79337 2.58074 2.51337C1.8196 3.23337 1.33331 4.21337 1.33331 5.33337C1.33331 6.43337 1.8196 7.41337 2.58074 8.15337L9.79046 14.9734L17.0002 8.15337C17.7613 7.43337 18.2476 6.45337 18.2476 5.33337C18.2476 4.23337 17.7613 3.25337 17.0002 2.51337C16.239 1.79337 15.203 1.33337 14.019 1.33337C12.8562 1.33337 11.8202 1.79337 11.0379 2.51337C10.2767 3.23337 9.79046 4.21337 9.79046 5.33337C9.79046 4.23337 9.30417 3.25337 8.54303 2.51337C7.78188 1.79337 6.74588 1.33337 5.56188 1.33337Z" strokeWidth="1.5" strokeLinejoin="round"/>
        </svg>
    );
}


