import React from "react";

export const ManageIcon = (props) => {
  const { className = "", style = {}, onClick } = props;
  return (
    <svg
      onClick={onClick}
      className={"custom-icon " + className}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M12.009 17.2576L8.09591 13.3445L9.38491 12.0555L12.009 14.6796L17.211 9.47751L18.5 10.7665L12.009 17.2576ZM17.0729 8.11945H15.2315V3.51587H13.39V6.27802H4.18286V3.51587H2.34143V16.4059H7.86573V18.2473H2.34143C1.83504 18.2473 1.40153 18.067 1.04092 17.7064C0.680307 17.3458 0.5 16.9123 0.5 16.4059V3.51587C0.5 3.00948 0.680307 2.57597 1.04092 2.21536C1.40153 1.85475 1.83504 1.67444 2.34143 1.67444H6.18542C6.35422 1.13736 6.68414 0.696179 7.17519 0.350911C7.66624 0.00564206 8.20333 -0.166992 8.78645 -0.166992C9.40026 -0.166992 9.94885 0.00564206 10.4322 0.350911C10.9156 0.696179 11.2417 1.13736 11.4105 1.67444H15.2315C15.7379 1.67444 16.1714 1.85475 16.532 2.21536C16.8926 2.57597 17.0729 3.00948 17.0729 3.51587V8.11945ZM8.78645 3.51587C9.04731 3.51587 9.26598 3.42764 9.44246 3.25117C9.61893 3.0747 9.70716 2.85603 9.70716 2.59516C9.70716 2.33429 9.61893 2.11562 9.44246 1.93915C9.26598 1.76268 9.04731 1.67444 8.78645 1.67444C8.52558 1.67444 8.30691 1.76268 8.13043 1.93915C7.95396 2.11562 7.86573 2.33429 7.86573 2.59516C7.86573 2.85603 7.95396 3.0747 8.13043 3.25117C8.30691 3.42764 8.52558 3.51587 8.78645 3.51587Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="none"
      />
    </svg>
  );
};
