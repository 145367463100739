import React from "react";

export const ContactNewIcon = (props) => {
  const { className = "", style = {}, disabled = false, onClick } = props;
  return (
    <svg
     className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
      onClick={disabled ? null : onClick}
      style={style}
      width="26" height="26" viewBox="0 0 26 26"fill="none" xmlns="http://www.w3.org/2000/svg" stroke="none"
    >
      <path
        d="M13 0.5C6.1117 0.5 0.5 6.1117 0.5 13C0.5 19.8883 6.1117 25.5 13 25.5C19.8883 25.5 25.5 19.8883 25.5 13C25.5 6.1117 19.8883 0.5 13 0.5ZM10.5931 12.375C9.92819 11.7101 9.59575 10.9122 9.59575 9.96809C9.59575 9.02394 9.92819 8.22606 10.5931 7.56117C11.258 6.89628 12.0559 6.56383 13 6.56383C13.9441 6.56383 14.742 6.89628 15.4069 7.56117C16.0718 8.22606 16.4043 9.02394 16.4043 9.96809C16.4043 10.9122 16.0718 11.7101 15.4069 12.375C14.7952 12.9867 14.0638 13.3059 13.2261 13.359C13.1463 13.359 13.0798 13.359 13 13.359C12.9202 13.359 12.8537 13.359 12.7739 13.359C11.9362 13.3059 11.2048 12.9867 10.5931 12.375ZM13 15.2872C13.0798 15.2872 13.1463 15.2739 13.2261 15.2606C15.1543 15.2872 16.7367 15.6596 17.9335 16.3777C19.1835 17.1223 19.8085 18.1463 19.8085 19.4229V20.2872C19.8085 20.2872 19.7686 20.3271 19.742 20.3537C18.0133 22.0691 15.6463 23.133 13.0133 23.133C10.3803 23.133 8 22.0691 6.28457 20.3537C6.25798 20.3271 6.23138 20.2872 6.20479 20.2606V19.4229C6.20479 18.1463 6.82979 17.1356 8.07979 16.3777C9.2766 15.6596 10.859 15.2872 12.7872 15.2606C12.867 15.2606 12.9335 15.2872 13.0133 15.2872H13ZM21.617 18.4255C21.391 17.2154 20.6995 15.8191 18.9176 14.742C18.2527 14.3431 17.4814 14.0372 16.6569 13.8112C16.6835 13.7846 16.7234 13.758 16.75 13.7314C17.7739 12.7074 18.3059 11.4043 18.3059 9.96809C18.3059 8.53191 17.7606 7.22872 16.75 6.21809C15.7261 5.19415 14.4229 4.66223 12.9867 4.66223C11.5505 4.66223 10.2473 5.20745 9.2367 6.21809C8.21277 7.24202 7.68085 8.54521 7.68085 9.98138C7.68085 11.4176 8.22606 12.7207 9.2367 13.7447C9.2633 13.7713 9.30319 13.7979 9.34309 13.8245C8.51862 14.0505 7.74734 14.3564 7.08245 14.7553C5.30053 15.8324 4.60904 17.2287 4.38298 18.4255C3.38564 16.8564 2.81383 14.9947 2.81383 13C2.80053 7.3617 7.3617 2.80053 13 2.80053C18.6383 2.80053 23.1995 7.3617 23.1995 13C23.1995 14.9947 22.6144 16.8564 21.6303 18.4255H21.617Z"
        stroke="none"
      />
    </svg>
  );
};

// Figma: new contact