import { AppStorageKeys } from "./Constants";

class SessionStorage {
  static get = (key) => {
    try {
      return JSON.parse(sessionStorage.getItem(key));
    } catch (error) {
      switch (key) {
        case AppStorageKeys.currentUser:
          return {};
        case false:
          return false;
        case "":
          return "";
        default:
          return {};
      }
    }
  };

  static set = (key, value) => {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error("Error setting session storage:", key);
    }
  };

  static remove = (key) => {
    try {
      sessionStorage.removeItem(key);
    } catch (error) {
      console.error("Error removing session storage:", key);
    }
  };

  static clear = () => {
    sessionStorage.clear();
  };
}

export default SessionStorage;
