import React from "react";

export const DeleteIcon = (props) => {
    const { className = "", style = {}, disabled = false, onClick } = props;
    return (
        <svg
            className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
            onClick={disabled ? null: onClick}
            width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg"
            style={{ padding:"1px 1px 2px 1px", ...style }}
        >
            <path className="stroke" d="M9.47058 10.8823V19.3529" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M15.1176 10.8823V19.3529" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M20.7647 5.23535V22.1765C20.7647 23.736 19.5006 25.0001 17.9412 25.0001H6.64705C5.08765 25.0001 3.82352 23.736 3.82352 22.1765V5.23535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M1 5.23535H23.5882" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M16.5294 5.23529V3.82353C16.5294 2.26414 15.2653 1 13.7059 1H10.8824C9.32297 1 8.05884 2.26414 8.05884 3.82353V5.23529" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}


