import React from "react";

export const ResendEmailIcon = (props) => {
    const { className = "", style={}, onClick } = props;
    return (
        <svg className={className}  onClick={onClick} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <g clipPath="url(#clip0_6111_43953)">
                <path d="M18.4268 21.0078C17.4534 21.0078 16.6034 20.7045 15.8768 20.0978C15.1501 19.4911 14.6934 18.7278 14.5068 17.8078H15.7468C15.9201 18.3945 16.2501 18.8745 16.7368 19.2478C17.2234 19.6211 17.7868 19.8078 18.4268 19.8078C19.2001 19.8078 19.8601 19.5345 20.4068 18.9878C20.9534 18.4411 21.2268 17.7811 21.2268 17.0078C21.2268 16.2345 20.9534 15.5745 20.4068 15.0278C19.8601 14.4811 19.2001 14.2078 18.4268 14.2078C18.0401 14.2078 17.6801 14.2778 17.3468 14.4178C17.0134 14.5578 16.7201 14.7545 16.4668 15.0078H17.6268V16.2078H14.4268V13.0078H15.6268V14.1478C15.9868 13.8011 16.4068 13.5245 16.8868 13.3178C17.3668 13.1111 17.8801 13.0078 18.4268 13.0078C19.5334 13.0078 20.4768 13.3978 21.2568 14.1778C22.0368 14.9578 22.4268 15.9011 22.4268 17.0078C22.4268 18.1145 22.0368 19.0578 21.2568 19.8378C20.4768 20.6178 19.5334 21.0078 18.4268 21.0078Z" fill="#113E57" stroke="none"/>
                <mask id="mask0_6111_43953" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="0" y="-1" width="31" height="31">
                    <rect x="0.396484" y="-0.5" width="30" height="30" fill="#D9D9D9" stroke="none"/>
                </mask>
                <g mask="url(#mask0_6111_43953)">
                    <path d="M12.3965 12.6992L4.39648 7.69922V17.6992H13.3965V19.6992H4.39648C3.84648 19.6992 3.37565 19.5034 2.98398 19.1117C2.59232 18.7201 2.39648 18.2492 2.39648 17.6992V5.69922C2.39648 5.14922 2.59232 4.67839 2.98398 4.28672C3.37565 3.89505 3.84648 3.69922 4.39648 3.69922H20.3965C20.9465 3.69922 21.4173 3.89505 21.809 4.28672C22.2007 4.67839 22.3965 5.14922 22.3965 5.69922V12.6992H20.3965V7.69922L12.3965 12.6992ZM12.3965 10.6992L20.3965 5.69922H4.39648L12.3965 10.6992ZM4.39648 7.69922V18.6992V12.6992V12.7742V5.69922V7.69922Z" fill="#113E57" stroke="none"/>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_6111_43953">
                    <rect width="24" height="24" fill="white" transform="translate(0.396484 0.5)"/>
                </clipPath>
            </defs>
        </svg>        
    );
}