import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import { Radio } from "antd";
import "chart.js/auto";
import moment from "moment";

const TrendChart = ({
  outdoorData,
  indoorData,
  deviceUuidToShowTrend,
  tempUnit,
}) => {
  const [view, setView] = useState("temperature");

  const dataMap = {};

  // Process outdoor data
  Object.keys(outdoorData).forEach((date) => {
    const outdoor = outdoorData[date].outdoor.environData;
    Object.keys(outdoor.hourlyTemp).forEach((hour) => {
      const dateTime = moment(date).hour(hour).format("YYYY-MM-DD HH:mm");
      if (!dataMap[dateTime]) {
        dataMap[dateTime] = { dateTime };
      }
      dataMap[dateTime].outdoorTemperature = outdoor.hourlyTemp[hour];
      dataMap[dateTime].outdoorHumidity = outdoor.hourlyHumidity[hour];
    });
  });

  // Process indoor data
  Object.keys(indoorData).forEach((date) => {
    const indoor = indoorData[date][deviceUuidToShowTrend];
    if (indoor) {
      const environData = indoor.environData;
      Object.keys(environData.hourlyTemp).forEach((hour) => {
        const dateTime = moment(date).hour(hour).format("YYYY-MM-DD HH:mm");
        if (!dataMap[dateTime]) {
          dataMap[dateTime] = { dateTime };
        }
        dataMap[dateTime].indoorTemperature = environData.hourlyTemp[hour];
        dataMap[dateTime].indoorHumidity = environData.hourlyHumidity[hour];
      });
    }
  });

  const data = Object.values(dataMap).sort(
    (a, b) => new Date(a.dateTime) - new Date(b.dateTime)
  );

  const convertTemperature = (temp) => {
    return tempUnit === "C" ? ((temp - 32) * 5) / 9 : temp;
  };

  const createDataset = (label, data, borderColor, backgroundColor) => ({
    label,
    data: data.map((temp) =>
      temp !== undefined ? convertTemperature(temp) : null
    ),
    borderColor,
    backgroundColor,
    borderWidth: 2,
    fill: !!backgroundColor,
    tension: 0.4,
    spanGaps: true,
    pointBackgroundColor: borderColor,
    pointBorderColor: borderColor,
    pointRadius: 0,
    pointHoverRadius: 0,
    pointStyle: "circle",
  });

  const chartData = {
    labels: data.map((entry) => moment(entry.dateTime).format("MMM DD, YYYY")),
    datasets:
      view === "temperature"
        ? [
            createDataset(
              "Outdoor Temperature",
              data.map((entry) => entry.outdoorTemperature),
              "red"
            ),
            createDataset(
              "Indoor Temperature",
              data.map((entry) => entry.indoorTemperature),
              "blue"
            ),
          ]
        : [
            createDataset(
              "Outdoor Humidity",
              data.map((entry) => entry.outdoorHumidity),
              "red"
            ),
            createDataset(
              "Indoor Humidity",
              data.map((entry) => entry.indoorHumidity),
              "blue"
            ),
          ],
  };

  const chartOptions = {
    scales: {
      x: {
        ticks: {
          maxTicksLimit: 10,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const value = context.raw.toFixed(1);
            if (context.dataset.label.includes("Humidity")) {
              return `${context.dataset.label}: ${value}%`;
            } else {
              return `${context.dataset.label}: ${value}°${tempUnit}`;
            }
          },
        },
      },
      afterDatasetsDraw: (chart) => {
        const ctx = chart.ctx;
        chart.data.datasets.forEach((dataset, i) => {
          const meta = chart.getDatasetMeta(i);
          meta.data.forEach((point, index) => {
            const value = dataset.data[index];
            if (value !== null) {
              ctx.save();
              ctx.fillStyle = dataset.borderColor;
              ctx.font = "12px Arial";
              ctx.textAlign = "center";
              ctx.textBaseline = "bottom";
              ctx.fillText(value.toFixed(1), point.x, point.y - 10);
              ctx.restore();
            }
          });
        });
      },
    },
    interaction: {
      mode: "nearest",
      intersect: false,
    },
  };

  return (
    <div>
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <Radio.Group value={view} onChange={(e) => setView(e.target.value)}>
          <Radio.Button value="temperature">Temperature</Radio.Button>
          <Radio.Button value="humidity">Humidity</Radio.Button>
        </Radio.Group>
      </div>
      <Line data={chartData} options={chartOptions} />
    </div>
  );
};

export default TrendChart;
