/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import MainPage from "../../MainPage";
import StickyHeader from "../Common/StickyHeader";
import useContacts from "../Hooks/useContacts";
import { useDispatch, useSelector } from "react-redux";
import { startVideoCall } from "@commscopemycloud/humaui/Store/videoCallStore";
import {
  Actions,
  sessionType,
} from "@commscopemycloud/humaui/Utilities/Constants";
import { getContacts } from "./Contacts";
import { HSButton, HSButtonType } from "../Common/HSWidget";
import {
  toDateTimeByTimeZone,
  getTimeZoneFormat,
} from "@commscopemycloud/humaui/Utilities/DateTime";
import ContactSelectList from "../Common/ContactSelectList";
import {
  KnownActionsList,
  isActionAllowed,
} from "@commscopemycloud/humaui/Utilities/AccessControl";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { message } from "antd";
import { OpenInNewIcon } from "../Common/Icons/OpenInNewIcon";
import { getPermissions } from "@commscopemycloud/humaui/Store/permisssionsStore";
const Mins_in_millis = 15 * 60 * 1000; // 15 minutes = 900000
const Hours_in_millis = 4 * 60 * 60 * 1000; // 4 hours = 14400000

export const StartEvent = (props) => {
  console.debug("StartEvent props", props);
  const {
    onlyContent,
    parentBread,
    eventRecord,
    onActionClick,
    userHubsData,
    userInfo,
    rolename,
    hubRecord,
    openSubMenu = false,
    loginUserTimeformat,
  } = props;
  const timeZone = getTimeZoneFormat(userInfo.timezone);
  const dispatch = useDispatch();
  const trans = useSelector(translator);
  const [contacts, fetchingContacts] = useContacts({
    useruuid: eventRecord.useruuid,
  });
  const [invites, setInvites] = useState([]);
  const header = (parentBread || []).concat({ label: trans("EVENT") });
  const currentUser = useSelector(getCurrentUser);
  const loginUseruuid = useMemo(() => currentUser?.useruuid, [currentUser]);
  const permissions = useSelector(getPermissions());
  const [activeTab, setActiveTab] = useState("schedule"); // State to manage the active tab
  const handleTabChange = (key) => {
    setActiveTab(key);
    onActionClick(null,null, key)();
  };
  const userApi = useSelector((state) => state.apis.userApi);

  const fetchData = (mUseruuid) => {
    const errorCallback = (error) => {
      message.error("Error fetching user information!");
      console.error("Error fetching user information:", error);
    };
    const successCallback = (data) => {
      console.info("UserInfo: fetch result", data);
      setInvites((prevState) =>
        prevState.map((item) => {
          if (item.useruuid === data?.user?.useruuid) return data.user;
          else return item;
        })
      );
    };
    try {
      if (!mUseruuid) return;
      userApi.getUserById(
        mUseruuid,
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  useEffect(() => {
    let invitedContacts = eventRecord.invited_contacts;
    let filteredContacts = getContacts(invitedContacts, [
      ...(contacts ?? []),
      ...(userHubsData ?? []),
      ...(userInfo ? [userInfo] : []),
    ]);
    setInvites(filteredContacts);
    if (contacts.length > 0)
      filteredContacts
        .filter((item) => !("firstname" in item))
        .forEach((item) => {
          fetchData(item.useruuid);
        });
  }, [contacts, eventRecord]);

  const checkCallButtonVisibility = (datetime, invitedList) => {
    //console.log("datetime",datetime);
    //Disabled call button if contact not exists in invited list.
    let contactExist = invitedList.includes(loginUseruuid);
    if (!contactExist) {
      return false;
    }
    if (!datetime) return false;
    let currentTime = new Date().getTime();
    let eventTime = new Date(datetime).getTime();
    let beforeEvent = eventTime - currentTime;
    let afterEvent = currentTime - eventTime;
    //console.log("beforeEvent",beforeEvent);
    //console.log("afterEvent",afterEvent);
    //Display the meeting start button before 15 minutes from event starttime
    if (beforeEvent > 0 && beforeEvent <= Mins_in_millis) {
      return true;
    }
    //Display the meeting start button until 4 hours from event starttime
    if (afterEvent >= 0 && afterEvent <= Hours_in_millis) {
      return true;
    }
    return false;
  };

  const checkEditButtonVisibility = (event_originator_id) => {
    if (
      event_originator_id === loginUseruuid ||
      (rolename !== undefined &&
        isActionAllowed(
          rolename,
          KnownActionsList.manage_schedule,
          permissions
        ))
    ) {
      return true;
    }
  };

  const headerSection = (
    <div className="event-details-header flex-just-space-between">
      <div className="event-details-title">
        <div className="header1">
        {((loginUserTimeformat && loginUserTimeformat?.is24hoursformat) ?
            toDateTimeByTimeZone(eventRecord.event_timestamp, timeZone, loginUserTimeformat?.is24hoursformat) :
            toDateTimeByTimeZone(eventRecord.event_timestamp, timeZone, loginUserTimeformat?.is24hoursformat)).format(
              "MM/DD/YY"
            )}
        </div>
        <div className="header2">{eventRecord.event_name}</div>
        <div className="header3">
          <div>{trans("TYPE")} </div>
          <div
            className={`${
              eventRecord.event_type?.toLowerCase() === sessionType.zoom
                ? "zoomeventtype"
                : eventRecord.event_type?.toLowerCase() === sessionType.reminder
                ? "remindereventtype"
                : "hseventype"
            }`}
          >
            {eventRecord.event_type?.toLowerCase() === sessionType.zoom
              ? trans("ZOOMCALL")
              : eventRecord.event_type?.toLowerCase() === sessionType.reminder
              ? "Calendar Reminder"
              : trans("VIDEOCALL")}
          </div>
        </div>
      </div>
    </div>
  );

  const bodySection = (
    <>
    <div className="event-details-body">
      <ContactSelectList
        displayOnlySelected={true}
        fetchingContacts={fetchingContacts}
        selectedContactList={invites}
        selectedListHeader={`${trans("PARTICIPANTS")} (${invites.length})`}
        selectionListHeader={trans("ADDCONTACTS")}
        selectionContactList={[]}
        showRemoveIcon={false}
        showRelationship={false}
        hubRecord={hubRecord}
        starteventAction={openSubMenu ? false : true}
      />
      <div className="event-description">
        <div className="label">{trans("DESCRIPTION")}</div>
        <div className="text description">{eventRecord.event_description}</div>
        {eventRecord.meeting_url && (
          <>
            <div className="label">{trans("LINK")}</div>
            <div className="url-link">
              <a
                target="_blank"
                rel="noreferrer"
                href={eventRecord.meeting_url}
              >
                {eventRecord.meeting_url}
                <OpenInNewIcon className="custom-icon" />
              </a>
            </div>
          </>
        )}
      </div>
    </div>
     <div className="schedule-cancel-button-container">
     <HSButton
         type={HSButtonType.cancel}
         onClick={onActionClick(null, null)}
       />
        {checkEditButtonVisibility(eventRecord.event_originator_id) && hubRecord && (
          <HSButton
            type={HSButtonType.edit}
            onClick={onActionClick(Actions.editEvent, {
              ...eventRecord,
              userHubsData,
            })}
          />
        )}
        {checkCallButtonVisibility(
          eventRecord.event_timestamp,
          eventRecord.invited_contacts
        ) &&
          eventRecord.event_type?.toLowerCase() !== sessionType.zoom && eventRecord.event_type?.toLowerCase() !== sessionType.reminder && (
            <HSButton
              className="button-call"
              type={HSButtonType.call}
              onClick={() =>
                dispatch(
                  startVideoCall({
                    meeting_id: eventRecord.schedule_eventuuid,
                    eventInfo: eventRecord,
                  })
                )
              }
            />
          )}
      </div>
     </>
  );

  const render = (
    <div
      className="startevent-page-wrapper flex-column"
      style={{ width: "100%", height: "100%" }}
    >
      <StickyHeader>{headerSection}</StickyHeader>
      {bodySection}
    </div>
  );

  return onlyContent ? render : <MainPage header={header} hasSider={true} activeKey={activeTab} openSubMenu={openSubMenu} onChange={handleTabChange} hubUserRole={hubRecord?.rolename} noModals={!!openSubMenu}>{render}</MainPage>;
};

StartEvent.propTypes = {
  action: PropTypes.string,
  onlyContent: PropTypes.bool,
  parentBread: PropTypes.arrayOf(PropTypes.object),
};

export default StartEvent;
