import React from "react";

export const FactoryResetIcon = (props) => {
  const { className="", style={}, onClick, disabled=false } = props;
  return (
    <svg
      className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
      onClick={disabled ? null: onClick}
      style={style}
      width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4206_24008)">
        <mask id="mask0_4206_24008" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="4" y="4" width="24" height="24">
          <rect x="4" y="4" width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_4206_24008)">
          <path className="fill" d="M20.4721 22.6901L17.7621 25.4001L15.9246 23.5626L18.6346 20.8276C18.5146 20.5726 18.4205 20.3068 18.3521 20.0301C18.2838 19.7534 18.2496 19.4684 18.2496 19.1751C18.2496 18.0851 18.6271 17.1626 19.3821 16.4076C20.1371 15.6526 21.0596 15.2751 22.1496 15.2751C22.4563 15.2751 22.7521 15.3072 23.0371 15.3713C23.3221 15.4355 23.5938 15.5293 23.8521 15.6526L21.2396 18.2651L23.0596 20.0851L25.6721 17.5051C25.7955 17.7634 25.8892 18.0297 25.9534 18.3039C26.0175 18.578 26.0496 18.8684 26.0496 19.1751C26.0496 20.2651 25.6721 21.1876 24.9171 21.9426C24.1621 22.6976 23.2396 23.0751 22.1496 23.0751C21.8513 23.0751 21.5609 23.0409 21.2784 22.9726C20.9959 22.9043 20.7271 22.8101 20.4721 22.6901ZM24.9071 11.9001H23.5596C22.9296 10.1434 21.8446 8.70427 20.3046 7.58259C18.7646 6.46093 16.9963 5.9001 14.9996 5.9001C12.4646 5.9001 10.3142 6.78301 8.54836 8.54885C6.78253 10.3147 5.89961 12.4651 5.89961 15.0001C5.89961 16.8268 6.38086 18.4693 7.34336 19.9276C8.30586 21.3859 9.55794 22.4768 11.0996 23.2001V18.9001H12.3996V25.4001H5.89961V24.1001H10.2046C8.5446 23.2334 7.19544 21.998 6.15711 20.3938C5.11878 18.7897 4.59961 16.9918 4.59961 15.0001C4.59961 13.5584 4.87085 12.2072 5.41334 10.9463C5.95586 9.68551 6.69836 8.58385 7.64086 7.64135C8.58336 6.69885 9.68503 5.95635 10.9459 5.41383C12.2067 4.87134 13.5579 4.6001 14.9996 4.6001C17.3446 4.6001 19.415 5.28635 21.2109 6.65884C23.0067 8.03134 24.2388 9.77842 24.9071 11.9001Z" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4206_24008">
          <rect width="24" height="24" fill="white" transform="translate(3 3)"/>
        </clipPath>
      </defs>
    </svg>
  );
};
