import React from "react";

export const DevicesNewIcon = (props) => {
    const { className = "", style={} } = props;
    return (
        <svg className={"custom-icon "+className} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
        <g clipPath="url(#clip0_10809_996)">
        <path d="M8.15587 13.1167C9.0381 13.1167 9.75328 12.4015 9.75328 11.5193C9.75328 10.6371 9.0381 9.92188 8.15587 9.92188C7.27365 9.92188 6.55847 10.6371 6.55847 11.5193C6.55847 12.4015 7.27365 13.1167 8.15587 13.1167Z" stroke="none"/>
        <path d="M21.4805 6H8.51948C5.46753 6 3 8.46753 3 11.5195C3 14.5714 5.46753 17.039 8.51948 17.039H11.6364V20.9481C11.6364 22.961 13.2727 24.5974 15.2857 24.5974H19.026C21.039 24.5974 22.6753 22.961 22.6753 20.9481V16.8961C25.1558 16.3506 27.013 14.1558 27.013 11.5195C27.013 8.48052 24.5455 6 21.4935 6L21.4805 6ZM20.7922 20.9481C20.7922 21.4416 20.5974 21.8831 20.2727 22.2078C20.1169 22.3636 19.9221 22.4935 19.7143 22.5844C19.6104 22.6234 19.4935 22.6623 19.3766 22.6883C19.2597 22.7143 19.1429 22.7273 19.026 22.7273H15.2857C14.3117 22.7273 13.5065 21.9221 13.5065 20.9481V12.4026C13.5065 12.2857 13.5065 12.1558 13.5455 12.0519C13.5714 11.9351 13.6104 11.8312 13.6494 11.7143C13.7403 11.5065 13.8701 11.3117 14.026 11.1558C14.3506 10.8312 14.7922 10.6364 15.2857 10.6364H19.026C19.1429 10.6364 19.2727 10.6364 19.3766 10.6753C19.4935 10.7013 19.5974 10.7403 19.7143 10.7792C19.9221 10.8701 20.1169 11 20.2727 11.1558C20.4286 11.3117 20.5584 11.5065 20.6494 11.7143C20.6883 11.8182 20.7273 11.9351 20.7532 12.0519C20.7792 12.1688 20.7922 12.2857 20.7922 12.4026V20.9481ZM22.6623 14.9481V12.4026C22.6623 10.3896 21.026 8.75325 19.013 8.75325H15.2727C13.2597 8.75325 11.6234 10.3896 11.6234 12.4026V15.1688H8.50649C6.50649 15.1688 4.85714 13.5325 4.85714 11.5195C4.85714 9.50649 6.49351 7.87013 8.50649 7.87013H21.4805C23.4805 7.87013 25.1299 9.50649 25.1299 11.5195C25.1299 13.1169 24.0909 14.4545 22.6623 14.9481Z" stroke="none"/>
        <path d="M20.7923 12.3897C20.7923 12.2728 20.7923 12.143 20.7533 12.0391C20.7793 12.1559 20.7923 12.2728 20.7923 12.3897Z" stroke="none"/>
        <path d="M13.5065 12.3897C13.5065 12.2728 13.5065 12.143 13.5454 12.0391C13.5195 12.1559 13.5065 12.2728 13.5065 12.3897Z" stroke="none"/>
        <path d="M17.3247 21.0652C17.9702 21.0652 18.4935 20.5419 18.4935 19.8964C18.4935 19.2508 17.9702 18.7275 17.3247 18.7275C16.6792 18.7275 16.1559 19.2508 16.1559 19.8964C16.1559 20.5419 16.6792 21.0652 17.3247 21.0652Z" stroke="none"/>
        </g>
        <defs>
        <clipPath id="clip0_10809_996">
        <rect width="24" height="18.5844" fill="white" transform="translate(3 6)"/>
        </clipPath>
        </defs>
        </svg>
    );
}


