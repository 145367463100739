import React from "react";

export const NewScheduleIcon = (props) => {
    const { className = "", style={} } = props;
    return (
        <svg className={"custom-icon "+className} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
        <path d="M5.4 9.855H22.2V7.575H5.4V9.855ZM5.4 25.86C4.74 25.86 4.17 25.635 3.705 25.185C3.24 24.735 3 24.195 3 23.565V7.575C3 6.945 3.24 6.405 3.705 5.955C4.17 5.505 4.74 5.28 5.4 5.28H6.6V4.2C6.6 3.54 7.14 3 7.8 3C8.46 3 9 3.54 9 4.2V5.28H18.6V4.2C18.6 3.54 19.14 3 19.8 3C20.46 3 21 3.54 21 4.2V5.28H22.2C22.86 5.28 23.43 5.505 23.895 5.955C24.36 6.405 24.6 6.945 24.6 7.575V14.055C24.225 13.89 23.835 13.74 23.43 13.62C23.025 13.5 22.62 13.425 22.2 13.365V12.135H5.4V23.565H12.96C13.095 23.985 13.26 24.39 13.455 24.765C13.65 25.14 13.875 25.5 14.13 25.845H5.4V25.86ZM21 27C19.335 27 17.925 26.445 16.755 25.335C15.585 24.225 15 22.875 15 21.285C15 19.695 15.585 18.36 16.755 17.25C17.925 16.14 19.335 15.585 21 15.585C22.665 15.585 24.075 16.14 25.245 17.25C26.415 18.36 27 19.71 27 21.285C27 22.86 26.415 24.21 25.245 25.335C24.075 26.445 22.665 27 21 27ZM23.01 24L23.85 23.205L21.6 21.06V17.865H20.4V21.525L23.01 24.015V24Z" stroke="none"/>
        </svg>
    );
}


