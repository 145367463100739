import React from "react";

export const CallIcon = (props) => {
    const { className = "", style={}, disabled=false, onClick } = props;
    return (
        <svg className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
        onClick={disabled ? null: onClick} style={style} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="none">
        <g clipPath="url(#clip0_9650_11963)">
        <path d="M9.84205 5.55244C10.0547 5.55244 10.3561 5.65879 10.5511 5.81832C11.3132 6.45643 11.9868 9.25702 11.7564 10.2674C11.65 10.7459 11.136 11.2068 10.6397 11.6677C10.4624 11.8272 10.3029 11.9867 10.1434 12.1462C9.82432 12.4653 9.75342 12.9439 9.98385 13.3338C11.5614 16.1167 13.8657 18.421 16.6485 19.9985C16.8081 20.0872 16.9676 20.1226 17.1271 20.1226C17.3753 20.1226 17.6412 20.0162 17.8361 19.839C17.9957 19.6795 18.1552 19.5199 18.3147 19.3427C18.7756 18.8464 19.2542 18.3146 19.715 18.226C19.8568 18.1905 20.0163 18.1905 20.1936 18.1905C21.4344 18.1905 23.6323 18.7932 24.1818 19.449C24.359 19.6617 24.4477 19.9099 24.4477 20.2112V23.3486C24.4477 23.6499 24.3059 23.9513 24.0754 24.164C23.8627 24.3589 23.5968 24.4653 23.2778 24.4653C23.2423 24.4653 23.1892 24.4653 23.1537 24.4653C18.3856 24.4653 13.9366 22.6573 10.6574 19.3781C7.37824 16.099 5.57026 11.6499 5.57026 6.77548C5.53481 6.47415 5.65889 6.17282 5.87159 5.94239C6.08429 5.71196 6.38562 5.57016 6.68695 5.57016H9.84205C9.84205 5.57016 9.85977 5.57016 9.8775 5.57016M9.84205 3H9.77115H6.6515C5.64116 3 4.66627 3.44313 3.95726 4.20532C3.30143 4.93205 2.96465 5.88922 3.0001 6.84638C3.01782 12.3412 5.09168 17.4284 8.84943 21.1684C12.5895 24.9084 17.6412 26.9823 23.0828 27C23.1537 27 23.2246 27 23.2955 27C24.2704 27 25.1389 26.6632 25.8302 26.0251C26.0607 25.8124 26.2734 25.5643 26.4506 25.2984C26.8228 24.7134 27.0178 24.0399 27.0178 23.3486V20.2112C27.0355 19.3604 26.7342 18.4742 26.1493 17.7829C24.7844 16.1699 21.5053 15.6381 20.2113 15.6381C19.8391 15.6381 19.5023 15.6736 19.1833 15.7445C18.1906 15.9572 17.4462 16.5775 16.879 17.1625C15.3014 16.0812 13.9189 14.7164 12.8553 13.1388C13.4403 12.5716 14.0607 11.8272 14.2734 10.8346C14.6279 9.15067 13.848 5.28656 12.235 3.90399C11.5968 3.35451 10.7106 3.01773 9.85977 3.01773L9.84205 3Z" stroke="none"/>
        </g>
        <defs>
        <clipPath id="clip0_9650_11963">
        <rect width="24" height="24" fill="white" transform="translate(3 3)"/>
        </clipPath>
        </defs>
        </svg>
        
    );
}

// Figma: call