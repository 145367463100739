import React from "react";

export const VideoOnIcon = (props) => {
    const { className = "", style = {}, disabled = false, onClick } = props;
    return (
        <svg
            className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
            onClick={disabled ? null: onClick}
            width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg"
            style={style}
        >
            <path className="stroke" d="M12.8576 1H1.98813C1.44241 1 1 1.44241 1 1.98813V12.8576C1 13.4034 1.44241 13.8457 1.98813 13.8457H12.8576C13.4034 13.8457 13.8457 13.4034 13.8457 12.8576V1.98813C13.8457 1.44241 13.4034 1 12.8576 1Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M13.8449 4.45845L18.7856 2.48218V12.3635L13.8449 10.3873" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}