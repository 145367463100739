import React from "react";
import { useSelector } from "react-redux";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import SessionStorage from "@commscopemycloud/humaui/Utilities/SessionStorage";
import { AppStorageKeys } from "@commscopemycloud/humaui/Utilities/Constants";
import { OpenInNewIcon } from "../Common/Icons/OpenInNewIcon";
const ENV_VARS = SessionStorage.get(AppStorageKeys.envVars);

const AboutCardInfo = () => {
  const trans = useSelector(translator);
  return (
    <div>
      <div className="info-header">{trans("ABOUT")}</div>
      <div className="data"><p>{trans("WEBPORTALVERSION")} {ENV_VARS.VERSION}</p></div>
      <div className="data">
        <span className="copywriteinfo">{trans("COPYWRITETEXT")}</span>
      </div>
    </div>
  );
};

export default AboutCardInfo;
