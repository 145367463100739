import React from "react";

export const ManageNewIcon = (props) => {
    const { className = "", style = {}, onClick } = props;
    return (
        <svg className={className} width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <g clipPath="url(#clip0_9133_77918)">
                <mask id="mask0_9133_77918" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="0" y="-1" width="20" height="21">
                    <rect y="-0.03125" width="20" height="20" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_9133_77918)">
                    <path d="M13.009 18.2273L9.09591 14.3142L10.3849 13.0252L13.009 15.6493L18.211 10.4472L19.5 11.7362L13.009 18.2273ZM18.0729 9.08918H16.2315V4.4856H14.39V7.24775H5.18286V4.4856H3.34143V17.3756H8.86573V19.2171H3.34143C2.83504 19.2171 2.40153 19.0367 2.04092 18.6761C1.68031 18.3155 1.5 17.882 1.5 17.3756V4.4856C1.5 3.9792 1.68031 3.5457 2.04092 3.18509C2.40153 2.82447 2.83504 2.64417 3.34143 2.64417H7.18542C7.35422 2.10708 7.68414 1.66591 8.17519 1.32064C8.66624 0.975369 9.20333 0.802734 9.78645 0.802734C10.4003 0.802734 10.9488 0.975369 11.4322 1.32064C11.9156 1.66591 12.2417 2.10708 12.4105 2.64417H16.2315C16.7379 2.64417 17.1714 2.82447 17.532 3.18509C17.8926 3.5457 18.0729 3.9792 18.0729 4.4856V9.08918ZM9.78645 4.4856C10.0473 4.4856 10.266 4.39736 10.4425 4.22089C10.6189 4.04442 10.7072 3.82575 10.7072 3.56488C10.7072 3.30401 10.6189 3.08534 10.4425 2.90887C10.266 2.7324 10.0473 2.64417 9.78645 2.64417C9.52558 2.64417 9.30691 2.7324 9.13043 2.90887C8.95396 3.08534 8.86573 3.30401 8.86573 3.56488C8.86573 3.82575 8.95396 4.04442 9.13043 4.22089C9.30691 4.39736 9.52558 4.4856 9.78645 4.4856Z" fill="#1C1B1F" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_9133_77918">
                    <rect width="20" height="20" fill="white" transform="translate(0 0.96875)" />
                </clipPath>
            </defs>
        </svg>
    );
}


