import React from "react";

export const ArrowLeftIcon = (props) => {
  const { className = "", disabled = false, onClick, style = {} } = props;
  return (
    <svg
      className={className}
      style={style}
      onClick={disabled ? null : onClick}
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 12L0 6L6 0L7.4 1.45L3.85 5H16V7H3.85L7.4 10.55L6 12Z"
        fill="black"
      />
    </svg>
  );
};
