import React from "react";

export const CalendarEventIcon = (props) => {
  const { className = "", style = {} } = props;
  return (
    <svg
      className={className}
      style={style}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7436 14.3972H3.10368C2.27295 14.3972 1.59961 13.7391 1.59961 12.9271V3.84501C1.59961 3.03308 2.27295 2.375 3.10368 2.375H12.7942C13.6249 2.375 14.2983 3.03308 14.2983 3.84501V10.9273C14.2983 11.3104 14.1379 11.6779 13.8537 11.9429L11.5685 14.0752C11.3455 14.2832 11.0511 14.3986 10.7436 14.3986V14.3972Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.59961 4.8125H14.2983"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.47656 1.60156V3.15276"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4219 1.69531V3.24651"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7089 6.94531H11.9277"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.79486 6.85938H9.01367"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.88275 6.85938H6.10156"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.96869 6.85938H3.1875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7089 9.22656H11.9277"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.79486 9.14062H9.01367"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.88275 9.14062H6.10156"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.96869 9.14062H3.1875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.88275 11.4297H6.10156"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.96869 11.4297H3.1875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3497 11.1016H12.2166C11.6834 11.1016 11.25 11.5203 11.25 12.0388V14.1071"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
