import React from "react";

export const DeviceInventoryIcon = (props) => {
  const { className = "", style = {}, disabled = false, onClick } = props;
  return (
    <svg width="24" height="24" className={className} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.5651 4.51567C19.4786 4.51567 20.2338 5.27085 20.2338 6.18437V9.69228C20.2338 10.6058 19.4786 11.361 18.5651 11.361H5.42265C4.50913 11.361 3.75395 10.6058 3.75395 9.69228V6.18437C3.75395 5.27085 4.50913 4.51567 5.42265 4.51567H18.5773M18.5651 2.76172H5.42265C3.53471 2.76172 2 4.29643 2 6.18437V9.69228C2 11.5802 3.53471 13.1149 5.42265 13.1149H18.5773C20.4652 13.1149 22 11.5802 22 9.69228V6.18437C22 4.29643 20.4652 2.76172 18.5773 2.76172L18.5651 2.76172Z"/>
    <path d="M16.9087 8.86432C17.5141 8.86432 18.0049 8.37352 18.0049 7.7681C18.0049 7.16267 17.5141 6.67188 16.9087 6.67188C16.3033 6.67188 15.8125 7.16267 15.8125 7.7681C15.8125 8.37352 16.3033 8.86432 16.9087 8.86432Z"/>
    <path d="M14.4588 8.51127C14.8826 8.51127 15.2261 8.16772 15.2261 7.74392C15.2261 7.32012 14.8826 6.97656 14.4588 6.97656C14.035 6.97656 13.6914 7.32012 13.6914 7.74392C13.6914 8.16772 14.035 8.51127 14.4588 8.51127Z"/>
    <path d="M12.3299 8.51127C12.7537 8.51127 13.0972 8.16772 13.0972 7.74392C13.0972 7.32012 12.7537 6.97656 12.3299 6.97656C11.9061 6.97656 11.5625 7.32012 11.5625 7.74392C11.5625 8.16772 11.9061 8.51127 12.3299 8.51127Z"/>
    <path d="M11.0621 15.5879C11.6468 15.5879 12.1096 16.063 12.1096 16.6354V18.4381C12.1096 19.0228 11.6346 19.4856 11.0621 19.4856H4.80146C4.2168 19.4856 3.75395 19.0106 3.75395 18.4381V16.6354C3.75395 16.0508 4.22898 15.5879 4.80146 15.5879H11.0499M11.0621 13.834H4.80146C3.25456 13.834 2 15.0885 2 16.6354V18.4381C2 19.985 3.25456 21.2396 4.80146 21.2396H11.0499C12.5968 21.2396 13.8514 19.985 13.8514 18.4381V16.6354C13.8514 15.0885 12.5968 13.834 11.0499 13.834H11.0621Z"/>
    <path d="M19.2 15.5879C19.7847 15.5879 20.2475 16.063 20.2475 16.6354V18.4381C20.2475 19.0228 19.7725 19.4856 19.2 19.4856H17.0319C16.4473 19.4856 15.9844 19.0106 15.9844 18.4381V16.6354C15.9844 16.0508 16.4595 15.5879 17.0319 15.5879H19.2ZM19.2 13.834H17.0319C15.485 13.834 14.2305 15.0885 14.2305 16.6354V18.4381C14.2305 19.985 15.485 21.2396 17.0319 21.2396H19.2C20.7469 21.2396 22.0015 19.985 22.0015 18.4381V16.6354C22.0015 15.0885 20.7469 13.834 19.2 13.834Z"/>
    <path d="M10.9541 18.1699H9.34634C9.1378 18.1699 8.96875 18.339 8.96875 18.5475C8.96875 18.756 9.1378 18.9251 9.34634 18.9251H10.9541C11.1627 18.9251 11.3317 18.756 11.3317 18.5475C11.3317 18.339 11.1627 18.1699 10.9541 18.1699Z"/>
    <path d="M19.4045 18.9007C19.6063 18.9007 19.7699 18.7371 19.7699 18.5353C19.7699 18.3335 19.6063 18.1699 19.4045 18.1699C19.2027 18.1699 19.0391 18.3335 19.0391 18.5353C19.0391 18.7371 19.2027 18.9007 19.4045 18.9007Z"/>
    </svg> 
  );
};
