import "../../styles/HSButton.less";
import React from "react";
import { Tooltip } from "antd";
import {
  ScheduleNewIcon,
  EditIcon,
  CallIcon,
  SendIcon,
  SocialIcon,
  ManageIcon,
  CareIcon,
  DeleteIcon,
  CloseIcon,
} from "../Common/Icons";
import { useSelector } from "react-redux";
import { translator } from "@commscopemycloud/humaui/Store/configStore";

export const HSButtonType = {
  addEvent: "ADD EVENT",
  edit: "Edit",
  call: "Start",
  cancel: "Cancel",
  send: "Send",
  social: "SOCIAL",
  care: "CARE",
  manage: "MANAGE",
  staff: "STAFF",
  staff_admin: "STAFFADMIN",
  keep_the_meeting: "KEEPTHEMEETING_U",
  cancel_the_meeting: "CANCELTHEMEETING_U",
};

export const HSButton = (props) => {
  const trans = useSelector(translator);

  const { onClick, type, className = "", children, disabled = false } = props;
  let buttonIcon = "",
    buttonText = type,
    buttonLabel;

  switch (type) {
    case HSButtonType.addEvent:
      buttonIcon = <ScheduleNewIcon className="hs-button-icon" />;
      buttonLabel = trans("ADDEVENT")
      break;
    case HSButtonType.edit:
      buttonIcon = <EditIcon className="hs-button-icon" />;
      buttonLabel = trans("EDIT")
      break;
    case HSButtonType.call:
      buttonIcon = <CallIcon className="hs-button-icon" />;
      buttonLabel = trans("START")
      break;
    case HSButtonType.send:
      buttonIcon = <SendIcon className="hs-button-icon" />;
      buttonLabel = trans("SEND_U")
      break;
    case HSButtonType.cancel:
      buttonIcon = <CloseIcon className="hs-button-icon" />;
      buttonLabel = trans("CANCEL_U")
      break;
    case HSButtonType.social:
      buttonIcon = <SocialIcon className={"hs-button-icon " + className} />;
      buttonLabel = trans("SOCIAL")
      break;
    case HSButtonType.care:
      buttonIcon = <CareIcon className={"hs-button-icon " + className} />;
      buttonLabel = trans("CARE")
      break;
    case HSButtonType.manage:
      buttonIcon = <ManageIcon className={"hs-button-icon " + className} />;
      buttonLabel = trans("MANAGE")
      break;
    case HSButtonType.staff:
      buttonIcon = <SocialIcon className={"hs-button-icon " + className} />;
      buttonLabel = trans("STAFF")
      break;
    case HSButtonType.staff_admin:
      buttonIcon = <ManageIcon className={"hs-button-icon " + className} />;
      buttonLabel = trans("STAFFADMIN")
      break;
    case HSButtonType.keep_the_meeting:
      buttonIcon = <ManageIcon className={"hs-button-icon " + className} />;
      buttonLabel = trans("KEEPTHEMEETING_U")
      break;
    case HSButtonType.cancel_the_meeting:
      buttonIcon = <DeleteIcon className={"hs-button-icon " + className} />;
      buttonLabel = trans("CANCELTHEMEETING_U")
      break;
    default:
      break;
  }

  const disabledClass = disabled && type === HSButtonType.send ? "disabled" : "";

  return (
    <div
      className={"hs-button " + className + " " + disabledClass}
      onClick={disabled ? null : onClick}
    >
      <Tooltip title={buttonLabel}>
        <div className="action">
          {buttonIcon}
          <div className={"hs-button-text " + className}>
            {children ? children : buttonLabel}
          </div>
        </div>
      </Tooltip>
    </div>
  );
};
