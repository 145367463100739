import React from "react";

export const SendIcon = (props) => {
    const { className = "", style={}, onClick } = props;
    return (
        <svg onClick={onClick} className={"custom-icon "+className} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" style={style}>
            <path className="stroke" d="M19 1L2 8L9 11L12 18L19 1Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M19 1L9 11" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}


