/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { message } from "antd";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import { DashboardIcon } from "../Common/Icons";
import DevicesComponent from "./Devices.component";
import useUserInfo from "../Hooks/useUserInfo";
import HubDetails from "../HubDetails/HubDetails";
import AddAccount from "./AddAccount";
import { getProfilePicUrl } from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import {
  Actions,
  PhotoResolution,
} from "@commscopemycloud/humaui/Utilities/Constants";
import {
  getProfilePics,
  getUserHubs,
} from "@commscopemycloud/humaui/Store/dataStore";
import { useLocation } from "react-router-dom";
import useUserHubs from "../Hooks/useUserHubs";

const Devices = (props) => {
  console.debug("Devices props", props);
  const { parentBread } = props;
  const location = useLocation();
  const deviceCount = location.state?.count || 1000;
  const trans = useSelector(translator);
  const deviceApi = useSelector((state) => state.apis.deviceApi);
  const currentUser = useSelector(getCurrentUser);
  const { useruuid, providerid } = useMemo(() => currentUser, [currentUser]);
  useUserInfo({ useruuid });

  const showPagination = deviceCount > 1000; // change here to show pagination
  const [deviceData, setDeviceData] = useState([]);
  const [fetchingDeviceData, setFetchingDeviceData] = useState(false);
  const [limit, setLimit] = useState(showPagination ? 10 : deviceCount);
  const [offset, setOffset] = useState(0);

  const [action, setAction] = useState(null);
  const [record, setRecord] = useState(null);
  const navigationKey = true;
  const [, fetchingUserHubs, fetchUserHubs] = useUserHubs({
    useruuid,
  });
  const userHubs = useSelector(getUserHubs(useruuid));
  const resourceMap = useSelector(getProfilePics());
  const mUserHubs = useMemo(() => {
    return userHubs.map((hub) => ({
      ...hub,
      profilePic64: getProfilePicUrl(
        hub.useruuid,
        resourceMap,
        PhotoResolution.R64
      ),
    }));
  }, [userHubs]);

  useEffect(() => {
    if (limit === undefined || offset === undefined) return;
    fetchDevices(providerid);
  }, [limit, offset]);

  /* handler functions */
  const onActionClick =
    (action, record, offset, limit, refresh = false) =>
    () => {
      setAction(action);
      setRecord(record);
      setOffset(offset);
      setLimit(limit);
      refresh && fetchDevices(providerid) && fetchUserHubs();
    };

  const header = (parentBread || []).concat({
    label: trans("DASHBOARD"),
    icon: <DashboardIcon />,
    onClick: onActionClick(null, null),
  });

  const fetchDevices = (providerId, params, callback) => {
    const errorCallback = (error) => {
      setFetchingDeviceData(false);
      console.error("Error fetching list of devices:", error);
      message.error("Error fetching list of devices!");
    };
    const successCallback = (data) => {
      setFetchingDeviceData(false);
      console.info("fetchDevices result", data);
      if (callback) {
        callback(data?.devices);
      } else {
        setDeviceData(data?.devices || []);
      }
    };
    //To do : limit to be modified based on pagination
    // let limit = 20;
    // if (location.state) {
    //   limit = location.state.count;
    // }
    // let offset = 0;
    let queryParams = {};
    if (!params) {
      queryParams.filterkey = "category";
      queryParams.filtervalue = "DEVICE-PRIMARY";
      queryParams.sortby = "deviceid";
      queryParams.orderby = "asc";
    } else {
      queryParams = { ...params };
    }
    try {
      setFetchingDeviceData(true);
      deviceApi.getDevicesByProviderId(
        providerId,
        offset,
        limit,
        queryParams,
        apiCallback({
          translator: trans,
          errorCallback,
          failCallback: errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  const onSubmit = ({ update = true, redirect = true } = {}) => {
    update && fetchDevices(providerid);
    redirect && setAction(null);
    redirect && setRecord(null);
  };

  return !action ? (
    <DevicesComponent
      header={header}
      providerid={providerid}
      fetchDevices={fetchDevices}
      deviceData={deviceData}
      fetchingDeviceData={fetchingDeviceData}
      onActionClick={onActionClick}
      // onSubmit={onSubmit}
      deviceCount={deviceCount}
      showPagination={showPagination}
      offset={offset}
      limit={limit}
      userHubsData={mUserHubs}
    />
  ) : action === Actions.listHub ? (
    <HubDetails
      parentBread={header}
      onActionClick={onActionClick}
      hubRecord={record}
      userHubsData={mUserHubs} 
      offset={offset}
      limit={limit}
      openSubMenu={true}
      navigationKey={navigationKey}
      sendMsgDisplay={true}
      showContactingStaff={true}
    />
  ) : action === Actions.assignDevice ? (
    <AddAccount
      parentBread={header}
      onActionClick={onActionClick}
      deviceRecord={record}
      offset={offset}
      limit={limit}
    />
  ) : (
    <></>
  );
};

Devices.propTypes = {};

export default Devices;
