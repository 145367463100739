import React from "react";

export const RefreshIcon = (props) => {

  const { className = "", style = {}, onClick } = props;
    return (
      <svg xmlns="http://www.w3.org/2000/svg" onClick={onClick} className={"custom-icon "+ className}style={style} width="21" height="21" viewBox="0 0 21 21" fill="none">
        <line x1="15.75" y1="2.89258" x2="15.75" y2="7.89259" className="stroke" strokeWidth="1.5"/>
        <line x1="21" y1="2.64233" x2="15" y2="2.64233" className="stroke" strokeWidth="1.5"/>
        <path d="M9.30002 11.4923V0H11.1V11.4923H9.30002ZM10.2 20.4923C8.79691 20.4923 7.47691 20.2246 6.24001 19.6892C5.00309 19.1538 3.92155 18.423 2.99539 17.4969C2.06924 16.5707 1.33848 15.4892 0.803101 14.2523C0.2677 13.0154 0 11.6954 0 10.2923C0 8.77691 0.318471 7.34576 0.955412 5.99884C1.59233 4.65192 2.48001 3.48923 3.61846 2.51076L4.87153 3.76384C3.90383 4.55614 3.14998 5.52229 2.60997 6.66229C2.06997 7.80229 1.79997 9.01229 1.79997 10.2923C1.79997 12.6123 2.61997 14.5923 4.25998 16.2323C5.89998 17.8723 7.87998 18.6923 10.2 18.6923C12.54 18.6923 14.525 17.8723 16.155 16.2323C17.785 14.5923 18.6 12.6123 18.6 10.2923C18.6 9.01229 18.335 7.80229 17.805 6.66229C17.275 5.52229 16.5161 4.55614 15.5284 3.76384L16.7815 2.51076C17.9277 3.48923 18.8173 4.65192 19.4504 5.99884C20.0834 7.34576 20.4 8.77691 20.4 10.2923C20.4 11.6954 20.1323 13.0154 19.5969 14.2523C19.0615 15.4892 18.3338 16.5707 17.4138 17.4969C16.4938 18.423 15.4154 19.1538 14.1784 19.6892C12.9415 20.2246 11.6154 20.4923 10.2 20.4923Z" className="fill"/>
      </svg>
)}
