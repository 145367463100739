import React from "react";

export const SocialNewIcon = (props) => {
  const { className = "", style = {}, onClick } = props;
  return (
    <svg
      onClick={onClick}
      className={"custom-icon " + className}
      style={style}
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3459 15.6102C23.3459 16.4041 22.7108 17.0393 21.9168 17.0393H12.771C11.977 17.0393 11.3419 16.4041 11.3419 15.6102C11.3419 14.8163 11.977 14.1812 12.771 14.1812H21.9168C22.7108 14.1812 23.3459 14.8163 23.3459 15.6102ZM19.2969 18.5318H12.7551C11.9612 18.5318 11.326 19.167 11.326 19.9609C11.326 20.7548 11.9612 21.3899 12.7551 21.3899H19.2969C20.0908 21.3899 20.726 20.7548 20.726 19.9609C20.726 19.167 20.0908 18.5318 19.2969 18.5318ZM30.7611 30.774C30.4911 31.0439 30.1259 31.1868 29.7448 31.1868C29.5861 31.1868 29.4432 31.1551 29.3003 31.1074L22.4567 28.821C21.5834 29.2497 19.9162 29.8689 17.3439 29.8689C10.421 29.8848 4.80005 24.2639 4.80005 17.3568C4.80005 10.4498 10.421 4.81299 17.3439 4.81299C24.2668 4.81299 29.8878 10.4339 29.8878 17.3568C29.8878 19.8815 29.2685 21.5805 28.8398 22.4538L31.1263 29.3132C31.3009 29.8213 31.158 30.3929 30.7769 30.774H30.7611ZM27.506 27.5031L25.9182 22.7713C25.9182 22.7713 25.9182 22.7078 25.9182 22.6761C25.8864 22.5808 25.8864 22.4855 25.8864 22.3903C25.8864 22.295 25.8864 22.2156 25.8864 22.1203C25.8864 22.0409 25.9341 21.9615 25.9499 21.8663C25.9817 21.771 26.0134 21.6757 26.0611 21.5963C26.077 21.5646 26.077 21.5328 26.0928 21.5011C26.0928 21.5011 27.0138 20.0561 27.0138 17.341C27.0138 12.0059 22.6631 7.6552 17.328 7.6552C11.9929 7.6552 7.65814 12.0217 7.65814 17.3568C7.65814 22.6919 12.0088 27.0426 17.3439 27.0426C20.1226 27.0426 21.4723 26.1375 21.4723 26.1375C21.504 26.1217 21.5358 26.1217 21.5675 26.0899C21.6628 26.0264 21.7739 25.9946 21.8851 25.9629C21.9486 25.947 21.9962 25.9311 22.0598 25.9152C22.1868 25.8994 22.3138 25.8994 22.4408 25.9152C22.4885 25.9152 22.5361 25.9152 22.5837 25.9311C22.6473 25.9311 22.6949 25.9311 22.7425 25.947L27.4743 27.5348L27.506 27.5031Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="none"
      />
    </svg>
  );
};
