import pako from "pako";
import moment from "moment";
import CryptoJS from "crypto-js";
import { formatUserInfo } from "../Services/UserService";
import { StringFormats, UnknownUsername, UserRoles } from "./Constants";

// const EncryptionAlgo = "aes-256-cbc";

/* ===== User and Accounts related functions ===== */

/**
 * get full name of user from user/hub record
 */
export const getUsername = (record = {}, { nameExt, defaultName } = {}) => {
  const { firstname, lastname, hubname, email } = record;
  let name = record.name
    ? record.name
    : firstname
    ? firstname + ` ${lastname || ""}`
    : hubname || email;
  if (!!nameExt) {
    name = name + nameExt;
  }
  return name || (defaultName ?? UnknownUsername);
};

/**
 * get first name of user from user/hub record
 */
export const getName = (record = {}, { nameExt } = {}) => {
  const { firstname, hubname, email } = record;
  let name = firstname ?? hubname ?? email;
  if (!!nameExt) {
    name = name + nameExt;
  }
  return name ?? UnknownUsername;
};

/**
 * get full name display of user with restriction from user/hub record
 */
export const formatFullNameDisplay = (firstName, lastName) => {
  let fullName = `${firstName ?? ""} ${lastName ?? ""}`;
  let formattedName = fullName.substring(0, 32);
  if (fullName?.length > formattedName?.length)
    formattedName = formattedName + "...";
  return formattedName.trim();
};

/**
 * Hub related functions
 * @param {object} hub : hub record
 */
export const isManage = (hub) =>
  hub?.rolename === UserRoles.admin ||
  hub?.rolename === UserRoles.principal ||
  hub?.rolename === UserRoles.manage;
export const isCare = (hub) =>
  hub?.rolename === UserRoles.staff || hub?.rolename === UserRoles.care;
export const isSocial = (hub) =>
  hub?.rolename === UserRoles.contact || hub?.rolename === UserRoles.social;

/* ===== function related to sorting ===== */

/**
 * Sorting functions
 */
/* pass sort function as (a, b) => defaultSort(b, a) for descening */
export const defaultSort = (a, b) => {
  if (!!a && !!b) {
    return a.localeCompare(b);
  } else if (!!a) {
    return -1;
  } else if (!!b) {
    return 1;
  }
  return 0;
};
/* pass sort function as (a, b) => numericalSort(b, a) for descening */
export const numericalSort = (a, b) => {
  if (!!a && !!b) {
    return a - b;
  } else if (!!a) {
    return -1;
  } else if (!!b) {
    return 1;
  }
  return 0;
};
/* pass sort function as (a, b) => dateSort(b, a) for descening */
export const dateSort = (a, b, format) => {
  if (!!a && !!b) {
    return moment(a, format).unix() - moment(b, format).unix();
  } else if (!!a) {
    return -1;
  } else if (!!b) {
    return 1;
  }
  return 0;
};
/** function to sort objects by some key in it */
export const objectSort = (key) => (a, b) => defaultSort(a[key], b[key]);

/* ===== Video Call related functions ===== */

export const allowAutoVideoCall = (hub) => {
  return isManage(hub) || isCare(hub);
};

/**
 * decode chime external userid
 */
export const decodeExternalUserId = (exUserId) => {
  const temp = exUserId?.split("#") || [];
  return {
    useruuid: temp[0],
    name: temp[1],
  };
};

/* ===== Filter and Search related functions ===== */

/**
 * filter functions for dropdown search
 */
export const selectAdvanceFilterFunction = (searchBy) => (input, option) =>
  option[searchBy]?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0;

export const selectFilterFunction = selectAdvanceFilterFunction("children");

/* ===== String Formatting related functions ===== */

export const formatString = (text, format) => {
  if (!!!text) return null;
  if (format === StringFormats.upper) {
    return text.toUpperCase();
  } else if (format === StringFormats.lower) {
    return text.toLowerCase();
  } else if (format === StringFormats.pascal) {
    return text.replace(
      /\w+/g,
      (w) => w.charAt(0)?.toUpperCase() + w.slice(1)?.toLowerCase()
    );
  } else if (format === StringFormats.sentence) {
    const temp = text.toLowerCase();
    return temp.charAt(0)?.toUpperCase() + temp.slice(1);
  }
  return text;
};

/* ===== functions to encrypt and decrypt the message ===== */

export const encrypt = (clientId, message) => {
  const keyWordArray = CryptoJS.enc.Utf8.parse(clientId);
  const iv = crypto.randomBytes(16);
  const hexEncodedIV = iv.toString("hex");
  const ivWordArray = CryptoJS.enc.Hex.parse(hexEncodedIV);
  const encrypted = CryptoJS.AES.encrypt(message, keyWordArray, {
    iv: ivWordArray,
  });
  return { iv: hexEncodedIV, encryptedData: encrypted.toString("hex") };
};
export const decrypt = async (encMessage, clientId) => {
  const ivData = CryptoJS.enc.Hex.parse(encMessage.iv);
  const keyData = CryptoJS.enc.Utf8.parse(clientId);
  const encryptedData = CryptoJS.enc.Hex.parse(encMessage.encryptedData);
  let decryptedData = CryptoJS.AES.decrypt(
    { ciphertext: encryptedData },
    keyData,
    { iv: ivData, mode: CryptoJS.mode.CBC }
  );
  const decryptedMessage = decryptedData.toString();
  return decryptedMessage;
};

/* ===== functions to zip and unzip the message ===== */

const arrayBufferToBase64 = (buffer) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};
const base64ToArrayBuffer = (base64) => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};
export const zipMessage = (message) => {
  try {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);
    const compressedArray = pako.gzip(data);
    const compressedData = arrayBufferToBase64(compressedArray.buffer);
    return compressedData;
  } catch (error) {
    console.error("Error zipping message:", error);
  }
};
export const unzipMessage = (message) => {
  try {
    const compressedArray = base64ToArrayBuffer(message);
    const decompressedArray = pako.ungzip(compressedArray);
    const decoder = new TextDecoder();
    const decompressedData = decoder.decode(decompressedArray);
    return decompressedData;
  } catch (error) {
    console.error("Error unzipping message:", error);
  }
};

export const getNextDayISOString = (days) => {
  const nextDay = new Date();
  nextDay.setDate(nextDay.getDate() + days);
  return nextDay.toISOString().slice(0, 10);
};

export function formatDateToDateTime(date) {
  const year = date.getUTCFullYear().toString();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.0000Z`;
}

/* ===== Profile Pic related functions ===== */

export function getProfilePicUrl(uuid, picsMap, resolution) {
  const resources = picsMap[uuid] ?? [];
  const resource = resources?.find((resource) => {
    return resource.resolution === resolution;
  });
  return resource?.url;
}

export function getProfilePicUrlFromArray(picsArray, resolution) {
  const resource = picsArray?.find((resource) => {
    return resource.resolution === resolution;
  });
  return resource?.url;
}

export const formatProfilePic = (list = []) => {
  const newList = [...list];
  newList.forEach((item, index) => {
    newList[index] = formatUserInfo(item);
  });
  return newList;
};

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
