import React from "react";

export const ScreenShareOnIcon = (props) => {
    const { className = "", style = {}, disabled = false, onClick } = props;
    return (
        <svg
            className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
            onClick={disabled ? null: onClick}
            width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg"
            style={style}
        >
            <path className="stroke" d="M11.2912 5.20207L7.26367 8.81165" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M11.5522 8.98472L11.5109 4.74228L7.26843 4.78357" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M13 16H9H5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M9 16V12" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M16 1H2C1.44772 1 1 1.44771 1 2V11C1 11.5523 1.44772 12 2 12H9H16C16.5523 12 17 11.5523 17 11V2C17 1.44772 16.5523 1 16 1Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}