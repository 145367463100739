/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { storeContacts } from "@commscopemycloud/humaui/Store/dataStore";

const useContacts = ({ useruuid }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [fetchingData, setFetchingData] = useState(true);

  const trans = useSelector(translator);
  const userApi = useSelector((state) => state.apis.userApi);

  const fetchData = () => {
    const errorCallback = (error) => {
      setFetchingData(false);
      message.error("Error fetching contact list!");
      console.error("Error fetching contact list:", error);
      setData([]);
    };
    const successCallback = (data) => {
      setFetchingData(false);
      console.info("Contacts: fetch result", data);
      dispatch(storeContacts({ useruuid, contacts: data?.contacts ?? [] }));
      setData(data.contacts ?? []);
    };
    try {
      if (!useruuid) {
        setFetchingData(false);
        return;
      }
      setFetchingData(true);
      userApi.getContactsByUseruuid(
        useruuid,
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  useEffect(() => fetchData(), [useruuid]);

  return [data, fetchingData, fetchData];
};

export default useContacts;
