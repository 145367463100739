import React from "react";

export const EditNewIcon = (props) => {
    const {className = "", style = {}, onClick, disabled = false } = props;
    return (
        <svg className={`custom-icon ${disabled ? "disabled" : ""} ${className}`} onClick={disabled ? null : onClick} width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <path d="M4 21.6797V26.7555H9.17647L22.1176 14.0661L26 10.2593L20.8235 5.18359L16.9412 8.9904L4 21.6797Z" stroke="#113E57" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M22.1179 14.066L16.9414 8.99023" stroke="#113E57" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}