import React from "react";
import PropTypes from "prop-types";

export const ButtonTypes = {
  primary: "primary",
  warning: "warning",
};

const NewCustomButton = ({
  className,
  style,
  type,
  icon,
  label,
  disabled = false,
  onClick = () => {},
  onFocus = () => {},
  onMouseEnter = () => {},
  onMouseLeave = () => {},
}) => (
  <div
    className={`new-custom-button ${className ?? ""} ${
      disabled ? "disabled" : type ?? ""
    }`}
    onClick={disabled ? null : onClick}
    onFocus={onFocus}
    onMouseLeave={onMouseLeave}
    onMouseEnter={onMouseEnter}
    style={style}
  >
    {icon && <div className="new-button-icon">{icon}</div>}
    {label && <span className="new-button-text">{label}</span>}
  </div>
);

NewCustomButton.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.node,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

export default NewCustomButton;