import React from "react";

export const NewNotificationIcon = (props) => {
    const { className = "", style={} } = props;
    return (
        <svg className={`custom-icon ${className}`} width="31" height="30" viewBox="0 0 31 30" xmlns="http://www.w3.org/2000/svg" style={style}>
        <g clipPath="url(#clip0_8961_45346)">
        <path d="M15.1406 5.52447C15.9821 5.52447 16.6658 6.20818 16.6658 7.04967C16.6658 7.10226 16.6483 7.15486 16.6308 7.20745C21.2064 8.1366 21.2239 13.3959 21.2239 16.1658C21.2239 19.1987 24.2568 20.7239 24.2568 20.7239V22.2491H18.1735V22.9503C18.1735 23.7918 17.4898 24.4755 16.6483 24.4755H13.6154C12.7739 24.4755 12.0902 23.7918 12.0902 22.9503V22.2491H6.02447V20.7239C6.02447 20.7239 9.05734 19.1987 9.05734 16.1658C9.05734 13.3959 9.05734 8.1366 13.6505 7.20745C13.6505 7.15486 13.6154 7.10226 13.6154 7.04967C13.6154 6.20818 14.2991 5.52447 15.1406 5.52447ZM15.1406 3C13.5628 3 12.1954 3.91161 11.5292 5.22644C6.53287 7.3477 6.53287 13.6413 6.53287 16.1658C6.53287 17.5858 4.88495 18.4624 4.86742 18.4799C4.04346 18.9182 3.5 19.7947 3.5 20.7239V22.2491C3.5 23.6516 4.62199 24.7736 6.02447 24.7736H10.004C10.6702 26.0884 12.0376 27 13.6154 27H16.6483C18.2261 27 19.5935 26.0884 20.2597 24.7736H24.2392C25.6417 24.7736 26.7637 23.6516 26.7637 22.2491V20.7239C26.7637 19.7597 26.2202 18.9007 25.3612 18.4624C25.3612 18.4624 23.7133 17.5683 23.7133 16.1658C23.7133 13.6589 23.7133 7.3477 18.7169 5.22644C18.0508 3.91161 16.6833 3 15.1056 3L15.1406 3Z"/>
        </g>
        <defs>
        <clipPath id="clip0_8961_45346">
        <rect width="23.2812" height="24" fill="white" transform="translate(3.5 3)"/>
        </clipPath>
        </defs>
        </svg>     
    );
}


