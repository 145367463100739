import React from "react";

export const CalendarIcon = (props) => {
    const { className = "", style={} } = props;
    return (
        <svg className={className} width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <path d="M20.8513 3.77062C20.3934 3.33815 19.8465 3.12192 19.1978 3.12192H18.0276V2.0662C18.0276 1.4175 17.5061 0.895996 16.8574 0.895996C16.2087 0.895996 15.6872 1.4175 15.6872 2.0662V3.12192H6.351V2.0662C6.351 1.4175 5.8295 0.895996 5.1808 0.895996C4.5321 0.895996 4.0106 1.4175 4.0106 2.0662V3.12192H2.8404C2.20442 3.12192 1.64476 3.33815 1.18686 3.77062C0.728952 4.20308 0.5 4.72459 0.5 5.33512V20.8912C0.5 21.5017 0.728952 22.0232 1.18686 22.4557C1.64476 22.8881 2.1917 23.1044 2.8404 23.1044H19.1342C20.4443 23.1044 21.5 22.0486 21.5 20.7385V5.33512C21.5 4.72459 21.271 4.20308 20.8131 3.77062H20.8513ZM19.1978 20.8912H2.86584V9.78697H19.1978M19.1978 7.56105H2.86584V5.33512H19.1978V7.56105Z" fill="#113E57"/>
            <path d="M5.79112 11.3003H4.6082C4.3834 11.3003 4.20117 11.4825 4.20117 11.7073V12.8902C4.20117 13.115 4.3834 13.2973 4.6082 13.2973H5.79112C6.01591 13.2973 6.19814 13.115 6.19814 12.8902V11.7073C6.19814 11.4825 6.01591 11.3003 5.79112 11.3003Z" fill="#113E57"/>
            <path d="M5.79112 14.2134H4.6082C4.3834 14.2134 4.20117 14.3956 4.20117 14.6204V15.8033C4.20117 16.0281 4.3834 16.2103 4.6082 16.2103H5.79112C6.01591 16.2103 6.19814 16.0281 6.19814 15.8033V14.6204C6.19814 14.3956 6.01591 14.2134 5.79112 14.2134Z" fill="#113E57"/>
            <path d="M5.79112 17.1265H4.6082C4.3834 17.1265 4.20117 17.3087 4.20117 17.5335V18.7164C4.20117 18.9412 4.3834 19.1234 4.6082 19.1234H5.79112C6.01591 19.1234 6.19814 18.9412 6.19814 18.7164V17.5335C6.19814 17.3087 6.01591 17.1265 5.79112 17.1265Z" fill="#113E57"/>
            <path d="M8.69151 11.3003H7.50859C7.28379 11.3003 7.10156 11.4825 7.10156 11.7073V12.8902C7.10156 13.115 7.28379 13.2973 7.50859 13.2973H8.69151C8.9163 13.2973 9.09853 13.115 9.09853 12.8902V11.7073C9.09853 11.4825 8.9163 11.3003 8.69151 11.3003Z" fill="#113E57"/>
            <path d="M8.69151 14.2134H7.50859C7.28379 14.2134 7.10156 14.3956 7.10156 14.6204V15.8033C7.10156 16.0281 7.28379 16.2103 7.50859 16.2103H8.69151C8.9163 16.2103 9.09853 16.0281 9.09853 15.8033V14.6204C9.09853 14.3956 8.9163 14.2134 8.69151 14.2134Z" fill="#113E57"/>
            <path d="M8.69151 17.1265H7.50859C7.28379 17.1265 7.10156 17.3087 7.10156 17.5335V18.7164C7.10156 18.9412 7.28379 19.1234 7.50859 19.1234H8.69151C8.9163 19.1234 9.09853 18.9412 9.09853 18.7164V17.5335C9.09853 17.3087 8.9163 17.1265 8.69151 17.1265Z" fill="#113E57"/>
            <path d="M11.5787 11.3003H10.3958C10.171 11.3003 9.98877 11.4825 9.98877 11.7073V12.8902C9.98877 13.115 10.171 13.2973 10.3958 13.2973H11.5787C11.8035 13.2973 11.9857 13.115 11.9857 12.8902V11.7073C11.9857 11.4825 11.8035 11.3003 11.5787 11.3003Z" fill="#113E57"/>
            <path d="M11.5787 14.2134H10.3958C10.171 14.2134 9.98877 14.3956 9.98877 14.6204V15.8033C9.98877 16.0281 10.171 16.2103 10.3958 16.2103H11.5787C11.8035 16.2103 11.9857 16.0281 11.9857 15.8033V14.6204C11.9857 14.3956 11.8035 14.2134 11.5787 14.2134Z" fill="#113E57"/>
            <path d="M11.5787 17.1265H10.3958C10.171 17.1265 9.98877 17.3087 9.98877 17.5335V18.7164C9.98877 18.9412 10.171 19.1234 10.3958 19.1234H11.5787C11.8035 19.1234 11.9857 18.9412 11.9857 18.7164V17.5335C11.9857 17.3087 11.8035 17.1265 11.5787 17.1265Z" fill="#113E57"/>
            <path d="M14.4659 11.3003H13.283C13.0582 11.3003 12.876 11.4825 12.876 11.7073V12.8902C12.876 13.115 13.0582 13.2973 13.283 13.2973H14.4659C14.6907 13.2973 14.8729 13.115 14.8729 12.8902V11.7073C14.8729 11.4825 14.6907 11.3003 14.4659 11.3003Z" fill="#113E57"/>
            <path d="M14.4659 14.2134H13.283C13.0582 14.2134 12.876 14.3956 12.876 14.6204V15.8033C12.876 16.0281 13.0582 16.2103 13.283 16.2103H14.4659C14.6907 16.2103 14.8729 16.0281 14.8729 15.8033V14.6204C14.8729 14.3956 14.6907 14.2134 14.4659 14.2134Z" fill="#113E57"/>
            <path d="M14.4659 17.1265H13.283C13.0582 17.1265 12.876 17.3087 12.876 17.5335V18.7164C12.876 18.9412 13.0582 19.1234 13.283 19.1234H14.4659C14.6907 19.1234 14.8729 18.9412 14.8729 18.7164V17.5335C14.8729 17.3087 14.6907 17.1265 14.4659 17.1265Z" fill="#113E57"/>
            <path d="M17.3663 11.3003H16.1834C15.9586 11.3003 15.7764 11.4825 15.7764 11.7073V12.8902C15.7764 13.115 15.9586 13.2973 16.1834 13.2973H17.3663C17.5911 13.2973 17.7733 13.115 17.7733 12.8902V11.7073C17.7733 11.4825 17.5911 11.3003 17.3663 11.3003Z" fill="#113E57"/>
            <path d="M17.3663 14.2134H16.1834C15.9586 14.2134 15.7764 14.3956 15.7764 14.6204V15.8033C15.7764 16.0281 15.9586 16.2103 16.1834 16.2103H17.3663C17.5911 16.2103 17.7733 16.0281 17.7733 15.8033V14.6204C17.7733 14.3956 17.5911 14.2134 17.3663 14.2134Z" fill="#113E57"/>
            <path d="M17.3663 17.1265H16.1834C15.9586 17.1265 15.7764 17.3087 15.7764 17.5335V18.7164C15.7764 18.9412 15.9586 19.1234 16.1834 19.1234H17.3663C17.5911 19.1234 17.7733 18.9412 17.7733 18.7164V17.5335C17.7733 17.3087 17.5911 17.1265 17.3663 17.1265Z" fill="#113E57"/>
            </svg>
    );
}


