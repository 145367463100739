import React from "react";

export const NewCloseIcon = (props) => {
  const { className = "", disabled = false, onClick, style = {} } = props;
  return (
    <svg
      className={className}
      style={style}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C6.4881 2 2 6.4881 2 12C2 17.5119 6.4881 22 12 22C17.5119 22 22 17.5119 22 12C22 6.4881 17.5119 2 12 2ZM12 19.9405C7.61905 19.9405 4.05952 16.381 4.05952 12C4.05952 7.61905 7.60714 4.05952 12 4.05952C16.3929 4.05952 19.9405 7.61905 19.9405 12C19.9405 16.381 16.3929 19.9405 12 19.9405Z"
      />
      <path
        d="M13.5478 8.0907L12.0001 9.63839L10.4524 8.0907C9.79762 7.43591 8.74598 7.43591 8.09119 8.0907C7.4364 8.74549 7.4364 9.79713 8.09119 10.4519L9.63888 11.9996L8.09119 13.5473C7.4364 14.2021 7.4364 15.2537 8.09119 15.9085C8.74598 16.5633 9.79762 16.5633 10.4524 15.9085L12.0001 14.3608L13.5478 15.9085C14.2026 16.5633 15.2542 16.5633 15.909 15.9085C16.5638 15.2537 16.5638 14.2021 15.909 13.5473L14.3613 11.9996L15.909 10.4519C16.5638 9.79713 16.5638 8.74549 15.909 8.0907C15.2542 7.43591 14.2026 7.43591 13.5478 8.0907Z"
      />
    </svg>
  );
};
