/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { storeUserInfo } from "@commscopemycloud/humaui/Store/dataStore";
import { onLanguageChange } from "@commscopemycloud/humaui/Utilities/Translation";
import { DefaultLanguage } from "@commscopemycloud/humaui/Utilities/Constants";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";

const useUserInfo = ({ useruuid }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [fetchingData, setFetchingData] = useState(false);
  const currentUser = useSelector(getCurrentUser);
  const currentUseruuid = useMemo(() => currentUser?.useruuid, [currentUser]);

  const trans = useSelector(translator);
  const userApi = useSelector((state) => state.apis.userApi);

  const fetchData = () => {
    const errorCallback = (error) => {
      setFetchingData(false);
      message.error("Error fetching user information!");
      console.error("Error fetching user information:", error);
      setData({});
    };
    const successCallback = (data) => {
      setFetchingData(false);
      console.info("UserInfo: fetch result", data);
      dispatch(storeUserInfo(data?.user));
      if (currentUseruuid === data?.user?.useruuid) {
        onLanguageChange(data?.user?.locale || DefaultLanguage);
      }
      setData(data?.user);
    };
    try {
      if (!useruuid) return;
      setFetchingData(true);
      userApi.getUserById(
        useruuid,
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  useEffect(() => fetchData(), [useruuid]);

  return [data, fetchingData, fetchData];
};

export default useUserInfo;
