import { Button, Result } from "antd";
import React from "react";
import "../../styles/Result.less";
import PropTypes from "prop-types";
import { NavigationKeys } from "@commscopemycloud/humaui/Utilities/Constants";
import { Link } from "react-router-dom";

const DefaultMessage = "404: Page Not Found";
const DefaultDescription = "Sorry, the page you visited does not exist.";

const PageNotFound = ({ className, message, description }) => {
  return (
    <div className={`result-container ${className}`}>
      <Result
        status="404"
        title={message}
        subTitle={description}
        extra={
          <Link to={"/" + NavigationKeys.accounts}>
            <Button type="primary">Back Home</Button>
          </Link>
        }
      />
    </div>
  );
};

PageNotFound.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  description: PropTypes.string,
};

PageNotFound.defaultProps = {
  message: DefaultMessage,
  description: DefaultDescription,
};

export default PageNotFound;
