import React from "react";

export const LowLightIcon = (props) => {
  const {
    className = "",
    style = {},
    disabled = false,
    onClick,
    fill = null,
  } = props;
  return (
    <svg
      className={className}
      style={style}
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.9525 10.1911C23.0876 8.34664 20.6147 7.33691 17.9932 7.33691C17.9392 7.33691 17.8986 7.33691 17.8446 7.33691C15.2906 7.3773 12.8717 8.38703 11.0475 10.2045C9.20968 12.022 8.15566 14.4184 8.0881 16.9764C8.00702 19.7902 9.1286 22.5097 11.1556 24.4484C12.3177 25.5523 12.9798 26.562 12.9798 28.1237V28.2988C12.9798 28.945 13.4933 29.4566 14.142 29.4566H21.8444C22.4796 29.4566 23.0066 28.9315 23.0066 28.2988V28.1237C23.0066 26.5351 23.6687 25.5658 24.8579 24.408C26.8173 22.5232 27.8983 19.9786 27.8983 17.2457C27.8983 14.5127 26.8443 12.0624 24.9525 10.1776V10.1911ZM23.2633 22.752C21.885 24.0849 20.9931 25.31 20.7634 27.141H15.25C15.0203 25.2965 14.142 24.0714 12.7771 22.7655C11.2231 21.2846 10.3718 19.1978 10.4259 17.0437C10.534 13.0318 13.8987 9.71986 17.8986 9.66601C19.9526 9.63908 21.885 10.4199 23.3309 11.8605C24.7903 13.301 25.5876 15.2262 25.5876 17.2726C25.5876 19.319 24.7633 21.3115 23.2498 22.752H23.2633Z"
        fill={fill ? fill : "#FFDEA1"}
      />
      <path
        d="M21.0066 30.6553H14.9933C14.3582 30.6553 13.8312 31.1803 13.8312 31.8131C13.8312 32.4458 14.3447 32.9709 14.9933 32.9709H21.0066C21.6418 32.9709 22.1688 32.4458 22.1688 31.8131C22.1688 31.1803 21.6553 30.6553 21.0066 30.6553Z"
        fill={fill ? fill : "#FFDEA1"}
      />
      <path
        d="M18.0068 35.9996C19.2905 35.9996 20.4256 35.2457 21.1013 34.0879H14.9258C15.5879 35.2457 16.723 35.9996 18.0203 35.9996H18.0068Z"
        fill={fill ? fill : "#FFDEA1"}
      />
      <path
        d="M18.5743 11.7127C18.1149 11.7127 17.75 12.0762 17.75 12.5339C17.75 12.9917 18.1149 13.3552 18.5743 13.3552C20.4526 13.3552 21.9931 14.8765 21.9931 16.7613C21.9931 17.219 22.358 17.5825 22.8174 17.5825C23.2769 17.5825 23.6417 17.219 23.6417 16.7613C23.6417 13.9745 21.3715 11.6992 18.5608 11.6992L18.5743 11.7127Z"
        fill={fill ? fill : "#FFDEA1"}
      />
    </svg>
  );
};
