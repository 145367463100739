import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { message } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";

const useDevicesList = ({ providerid }) => {
  const deviceApi = useSelector((state) => state.apis.deviceApi);
  const trans = useSelector(translator);

  const [fetchingData, setFetchingData] = useState(false);
  const [devicesData, setDevicesData] = useState([]);

  const fetchDevices = (params, callback) => {
    const errorCallback = (error) => {
      setFetchingData(false);
      console.error("Error fetching list of devices:", error);
      message.error("Error fetching list of devices!");
    };
    const successCallback = (data) => {
      setFetchingData(false);
      console.info("fetchDevices result", data);
      if (callback) {
        callback(data?.devices);
      } else {
        setDevicesData(data?.devices || []);
      }
    };
    //To do : limit to be modified based on pagination
    let offset = 0,
      limit = 10000;
    let queryParams = {};
    if (!params) {
      queryParams.filterkey = "category";
      queryParams.filtervalue = "DEVICE-PRIMARY";
      queryParams.sortby = "deviceid";
      queryParams.orderby = "asc";
    } else {
      queryParams = { ...params };
    }
    try {
      setFetchingData(true);
      deviceApi.getDevicesByProviderId(
        providerid,
        offset,
        limit,
        queryParams,
        apiCallback({
          translator: trans,
          errorCallback,
          failCallback: errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  return [fetchingData, fetchDevices, devicesData];
};

export default useDevicesList;
