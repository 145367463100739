import React from "react";

export const MotionEmpty = (props) => {
  const { className = "", disabled = false, onClick, style = {} } = props;
  return (
    <svg
      style={style}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9169_110659)">
        <path
          d="M8.99411 0C4.02616 0 0 4.0274 0 9.00587C0 13.9843 4.02616 18.0117 8.99411 18.0117C13.9621 18.0117 17.9882 13.9843 17.9882 9.00587C17.9882 4.0274 13.9621 0 8.99411 0ZM3.97907 14.1605C-0.459123 9.39335 3.29627 4.63796 3.29627 4.63796L13.3734 14.6888C8.07587 18.4579 3.97907 14.1605 3.97907 14.1605ZM14.692 13.3738L4.61478 3.3229C9.91236 -0.446184 14.0092 3.85127 14.0092 3.85127C18.4474 8.60665 14.692 13.3738 14.692 13.3738Z"
          fill="#7A7A7A"
        />
      </g>
      <defs>
        <clipPath id="clip0_9169_110659">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
