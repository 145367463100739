import React from "react";

export const ExpandMoreIcon = (props) => {
    const { className = "", style={}, onClick } = props;
    return (
        <svg onClick={onClick} className={"custom-icon "+className} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" style={style}>
            <mask id="mask0_576_28344" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="-1" y="-1" width="24" height="24">
                <rect x="-1" y="-1" width="24" height="24" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_576_28344)">
                <path className="fill" d="M11 14.0365C10.8295 14.0365 10.6757 14.0089 10.5385 13.9538C10.4013 13.8987 10.2693 13.8076 10.1423 13.6807L5.59812 9.13651C5.37632 8.91472 5.26799 8.66152 5.27312 8.37691C5.27824 8.09229 5.39491 7.83588 5.62312 7.60768C5.85132 7.37948 6.10612 7.26538 6.38752 7.26538C6.66892 7.26538 6.92372 7.37948 7.15192 7.60768L11 11.4808L14.8731 7.60768C15.0949 7.3859 15.3481 7.27756 15.6327 7.28268C15.9173 7.28781 16.1737 7.40448 16.4019 7.63268C16.6301 7.86088 16.7442 8.11569 16.7442 8.39711C16.7442 8.67851 16.6301 8.93331 16.4019 9.16151L11.8577 13.6807C11.7308 13.8076 11.5987 13.8987 11.4615 13.9538C11.3244 14.0089 11.1705 14.0365 11 14.0365Z"/>
            </g>
        </svg>
    );
}


