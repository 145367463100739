import React from "react";
import PropTypes from "prop-types";
import { LoadingOutlined } from "@ant-design/icons";

const Loading = ({ height, message, indicator }) => (
  <div className="loading-container" style={{ height }}>
    <div className="loading-icon">
      {indicator ? indicator : <LoadingOutlined />}
    </div>
    <span className="loading-message">{message}</span>
  </div>
);

Loading.defaultProps = {
  height: "200px",
  message: "Loading...",
};

Loading.propTypes = {
  height: PropTypes.string,
  indicator: PropTypes.node,
};

export default Loading;
