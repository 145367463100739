import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  CallIcon,
  CloseIcon,
  ContactImage,
  BellIcon,
  MissedCallIcon,
  CallDeclineIcon,
} from "../Common/Icons";
import { Badge, Divider } from "antd";
import { CalendarEventIcon } from "../Common/Icons/CalendarEventIcon";
import { PersonIcon } from "../Common/Icons/PersonIcon";
import {
  getNotificationMiniBannerMessage,
  updateNotificationMiniBanner,
} from "@commscopemycloud/humaui/Store/notificationStore";
import {
  NavigationKeys,
  PhotoResolution,
  WSMessageTypes,
} from "@commscopemycloud/humaui/Utilities/Constants";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import {
  getProfilePicUrl,
  getProfilePicUrlFromArray,
} from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import { getProfilePics } from "@commscopemycloud/humaui/Store/dataStore";
import { startVideoCall } from "@commscopemycloud/humaui/Store/videoCallStore";
import { useMainContext } from "../../MainContext";
import { Tooltip } from 'antd';

const NotificationBanner = ({ readOnly }) => {
  const dispatch = useDispatch();
  const trans = useSelector(translator);
  const message = useSelector(getNotificationMiniBannerMessage);
  const currentUser = useSelector(getCurrentUser);
  const resourceMap = useSelector(getProfilePics());

  const [notification, setNotification] = useState(null);
  const userInfo = useSelector((state) => state.data?.userInfo ?? {});
  const { newNotificationCount, fetchNotifications } = useMainContext();

  const videoCallNotificationModalVisible = useSelector(
    (state) => state.notification.videoCallNotificationModalVisible
  );

  useEffect(() => {
    if(Notification.permission !== "granted") {
      if (message && message.message_type === WSMessageTypes.calendar) {
        handleEventMessage(message);
      } else if (message && message.message_type === WSMessageTypes.contact) {
        handleContactMessage(message);
      } else if (
        message &&
        message.message_type === WSMessageTypes.videoCallStatus
      ) {
        handleCallStatusMessage(message);
      }
    }
  }, [message]);

  const handleEventMessage = (mMessage) => {
    const event = mMessage?.message?.schedule_event;
    if (!event) return;
    if (event.action?.includes("delete")) {
      if (event.schedule_eventuuid === notification?.schedule_eventuuid) {
        handleCancel();
      }
    } else {
      setNotification(mMessage);
    }
  };

  const handleContactMessage = (mMessage) => {
    const contact = mMessage?.message;
    if (!contact || contact.action?.includes("deleted")) return;
    if (contact.useruuid !== currentUser.useruuid) {
      setNotification(mMessage);
    }
  };

  const handleCallStatusMessage = (mMessage) => {
    const call = mMessage?.message;
    if (
      !call ||
      !(
        call.call_status__status === "missed" ||
        call.call_status__status === "declined"
      )
    )
      return;
    setNotification(mMessage);
  };

  const formatName = (name, maxLength) => {
    if (!name) return "";
    const length = maxLength !== undefined ? maxLength : 15;
    return name.length > length ? name.substring(0, length) + "..." : name;
  };

  const constructBanner = () => {
    if (!notification) return null;
    if (notification.message_type === WSMessageTypes.calendar) {
      return constructEventBanner(notification?.message?.schedule_event);
    } else if (notification.message_type === WSMessageTypes.contact) {
      return constructContactBanner(notification?.message);
    } else if (notification.message_type === WSMessageTypes.videoCallStatus) {
      return constructCallStatusBanner(notification?.message);
    }
  };

  const constructEventBanner = (event) => {
    if (!event) {
      handleCancel();
      return null;
    }
    let header =
      event.event_type === "reminder" ? "New Reminder" : trans("NEW_EVENT");
    if (event.action?.includes("modified")) {
      header = "Event modified";
    }
    let formattedName = formatName(event?.event_name, 32);
    let formattedTime = "";
    if (event.event_timestamp) {
      let dateTime = moment(+event.event_timestamp);
      formattedTime = dateTime.format("h:mm A, dddd, MMMM D");
    } else {
      let time = moment(event.event_time, "HH:mm");
      time.add(time.utcOffset(), "minutes");
      header += " (" + trans("RECURRING") + ")";
      formattedTime = time.format("h:mm A");
    }
    return (
      <>
        <div className="icon-container">
          <CalendarEventIcon className="icon" style={{ stroke: "#005c98", width: "22px", height: "22px", margin: "0 10px"}} />
        </div>
        <div className="event-info-container">
          <p className="info-heading">{header}</p>
          {(event?.event_name).length > 32 ? (
            <Tooltip
              title={event?.event_name}
              placement="bottom"
              overlayStyle={{ zIndex: 9999 }}
            >
              <p className="info-description">{formattedName}</p>
            </Tooltip>
          ) : (
            <p className="info-description">{formattedName}</p>
          )}
          <p className="info-description">{formattedTime}</p>
        </div>
      </>
    );
  };

  const constructContactBanner = (contact) => {
    const { firstname, lastname, resources, relationship } = contact;
    const contactFullName = (`${firstname} ${lastname}`);
    const currentUserFullName = (currentUser?.name);
    const photo = getProfilePicUrlFromArray(resources, PhotoResolution.R64);
    const userPhoto = getProfilePicUrl(
      currentUser?.useruuid,
      resourceMap,
      PhotoResolution.R64
    );
    return (
      <>
        <div className="image-container">
          {photo ? (
            <img src={photo} className=".border" alt="" />
          ) : (
            <ContactImage />
          )}
        </div>
        <div className="info-container">
          <Tooltip title={`${firstname} ${lastname}`} placement="bottom" overlayStyle={{ zIndex: 9999 }}>
            <span>{formatName(contactFullName)}</span>
          </Tooltip>
          : New contact
          <Tooltip title={currentUserFullName} placement="bottom" overlayStyle={{ zIndex: 9999 }}>
            <span> {formatName(currentUserFullName)}</span>
          </Tooltip>
          <p className="info-description">{relationship}</p>
        </div>
        <div className="image-container">
          {userPhoto ? (
            <img src={userPhoto} className=".border" alt="" />
          ) : (
            <ContactImage />
          )}
        </div>
      </>
    );
  };

  const constructCallStatusBanner = (call) => {
    const { timestamp, event_originator_id, call_status__status } = call;
    const userDetails = userInfo[event_originator_id] || {};
    const { firstname, lastname, photo_url } = userDetails;
    let fullName = formatName(`${firstname} ${lastname}`);
    const callTime = moment(timestamp).format("h:mm A");
    const isMissedCall = call_status__status === "missed";
    const callStatusText = isMissedCall
      ? "Missed Call from"
      : "Declined Call from";
    const CallStatusIcon = isMissedCall ? MissedCallIcon : CallDeclineIcon;
    const backgroundColor = isMissedCall ? "#F03B3B" : "#B9B9B9";
    const strokeColor = isMissedCall ? "#fff" : "#000000";
    return (
      <div className="mini-notification-banner">
        <div className="contact-container">
          <div className="image-container">
            {photo_url ? (
              <img src={photo_url} className="img" alt="" />
            ) : (
              <ContactImage />
            )}
          </div>
        </div>
        <div className="call-info-container">
          <div className="missed-icon" style={{ backgroundColor }}>
            <CallStatusIcon className="icon" style={{ stroke: strokeColor }} />
          </div>
          <div className="info-container ">
            <p className="call-info">
              {callStatusText}
              <Tooltip title={`${firstname} ${lastname}`} placement="bottom" overlayStyle={{ zIndex: 9999 }}>
                <span> {fullName}</span>
              </Tooltip>
            </p>
            <p className="call-time">Today, at {callTime}</p>
          </div>
        </div>
        <div
          className="call-icon-container"
          onClick={() => {
            dispatch(startVideoCall({ callees: [userDetails] }));
            handleCancel();
          }}
        >
          <CallIcon className="call-icon" />
        </div>
        <div className="close-icon">
          <CloseIcon className="action-icons icon" onClick={handleCancel} />
        </div>
        <div className="divider"></div>
        <div className="bell-icon">
          <div className="notification-count">
            <Badge
              className="notification-count"
              count={newNotificationCount}
              offset={[-2, 5]}
            >
              <BellIcon className="icon" alt="bell icon" />
            </Badge>
          </div>
        </div>
      </div>
    );
  };
  const handleCancel = () => {
    setNotification(null);
    dispatch(updateNotificationMiniBanner(null));
  };

  return (
    <div className="notification-banner">
      {notification && (
         <div className="notification-mini-banner">{constructBanner()}
         {notification.message_type !== WSMessageTypes.videoCallStatus && (
            <CloseIcon className="action-icons cancel-icon" onClick={handleCancel} style={{ cursor: "pointer", margin:10}} />
           )}
        </div>
      )}
    </div>
  );
};

export default NotificationBanner;
