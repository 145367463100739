import React from "react";

export const BadgeIcon = (props) => {
    const { className = "", style={} } = props;
    return (
        <svg className={"custom-icon "+className}  xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" style={style}>
            <mask id="mask0_94_116599" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="30">
                <rect width="30" height="30"/>
            </mask>
            <g mask="url(#mask0_94_116599)">
                <path className="fill" d="M5 27.5C4.3125 27.5 3.72396 27.2552 3.23438 26.7656C2.74479 26.276 2.5 25.6875 2.5 25V11.25C2.5 10.5625 2.74479 9.97396 3.23438 9.48438C3.72396 8.99479 4.3125 8.75 5 8.75H11.25V5C11.25 4.3125 11.4948 3.72396 11.9844 3.23438C12.474 2.74479 13.0625 2.5 13.75 2.5H16.25C16.9375 2.5 17.526 2.74479 18.0156 3.23438C18.5052 3.72396 18.75 4.3125 18.75 5V8.75H25C25.6875 8.75 26.276 8.99479 26.7656 9.48438C27.2552 9.97396 27.5 10.5625 27.5 11.25V25C27.5 25.6875 27.2552 26.276 26.7656 26.7656C26.276 27.2552 25.6875 27.5 25 27.5H5ZM5 25H25V11.25H18.75C18.75 11.9375 18.5052 12.526 18.0156 13.0156C17.526 13.5052 16.9375 13.75 16.25 13.75H13.75C13.0625 13.75 12.474 13.5052 11.9844 13.0156C11.4948 12.526 11.25 11.9375 11.25 11.25H5V25ZM7.5 22.5H15V21.9375C15 21.5833 14.901 21.2552 14.7031 20.9531C14.5052 20.651 14.2292 20.4167 13.875 20.25C13.4583 20.0625 13.0365 19.9219 12.6094 19.8281C12.1823 19.7344 11.7292 19.6875 11.25 19.6875C10.7708 19.6875 10.3177 19.7344 9.89062 19.8281C9.46354 19.9219 9.04167 20.0625 8.625 20.25C8.27083 20.4167 7.99479 20.651 7.79688 20.9531C7.59896 21.2552 7.5 21.5833 7.5 21.9375V22.5ZM17.5 20.625H22.5V18.75H17.5V20.625ZM11.25 18.75C11.7708 18.75 12.2135 18.5677 12.5781 18.2031C12.9427 17.8385 13.125 17.3958 13.125 16.875C13.125 16.3542 12.9427 15.9115 12.5781 15.5469C12.2135 15.1823 11.7708 15 11.25 15C10.7292 15 10.2865 15.1823 9.92188 15.5469C9.55729 15.9115 9.375 16.3542 9.375 16.875C9.375 17.3958 9.55729 17.8385 9.92188 18.2031C10.2865 18.5677 10.7292 18.75 11.25 18.75ZM17.5 16.875H22.5V15H17.5V16.875ZM13.75 11.25H16.25V5H13.75V11.25Z"/>
            </g>
        </svg>
    );
}


