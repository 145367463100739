import "../../styles/HSDayPicker.less";
import React, { useEffect, useState } from "react";
const shortWeekDays = ["sun", "mon", "tues", "wed", "thurs", "fri", "sat"];
const weekDays = ["sunday","monday","tuesday","wednesday","thursday","friday","saturday"];

export const HSDayPicker = (props) => {
  const {days, onDayChange} = props;
  let defaultSeletedDays = [];
  if(Array.isArray(days)){
    defaultSeletedDays = days.map(item=>{
       return shortWeekDays[weekDays.indexOf(item)]
    }).filter(item=>item); 
  }
  const [selectedDays, setSelectedDays] = useState(defaultSeletedDays);;
  const handleClick = (e)=>{
    let day = e.target?.innerText?.toLowerCase();
    setSelectedDays(prev => {
      if(prev.includes(day)){
        return prev.filter(item=> item!=day);
      }else{
        return [...prev, day]
      }
    });
  }
  useEffect(()=>{
    let values = selectedDays.map(item=>{
      return weekDays[shortWeekDays.indexOf(item)]
   }).filter(item=>item);
    onDayChange(values);
  },[selectedDays])

  return (
    <div className="hs-daypicker">
        {shortWeekDays.map((value, index) => (
          <div onClick={handleClick} key={index} className={(selectedDays.includes(value.toLowerCase())) ? "list-item-selected" : "list-item"}>{value}</div>
        ))}
    </div>
  );
};
