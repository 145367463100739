import React from "react";

export const ProfileOutlineIcon = ({ image }) => {
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "232px",
    height: "232px",
    position: "relative",
  };

  const innerCircleStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  return (
    <div style={containerStyle}>
      {/* Outer circles */}
      <svg xmlns="http://www.w3.org/2000/svg" width="232" height="232" viewBox="0 0 232 232" fill="none" style={innerCircleStyle}>
        <circle opacity="0.3" cx="116" cy="116" r="116" fill="#044C7B" />
      </svg>

      <svg xmlns="http://www.w3.org/2000/svg" width="196" height="196" viewBox="0 0 196 196" fill="none" style={innerCircleStyle}>
        <circle opacity="0.3" cx="97.9998" cy="97.9979" r="97.2166" fill="#005C98" />
      </svg>

      <svg xmlns="http://www.w3.org/2000/svg" width="158" height="158" viewBox="0 0 158 158" fill="none" style={innerCircleStyle}>
        <circle cx="78.9997" cy="78.9958" r="78.4333" fill="#113E57" />
      </svg>

      {/* Image */}
      {image && (
        <img
          src={image}
          width="136"
          height="136"
          style={{ position: "absolute", zIndex: 2, borderRadius: "100%" }}
          alt="pic"
        />
      )}
    </div>
  );
};
