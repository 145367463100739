import React from "react";

export const RCPower = (props) => {
  const { className = "", style = {}, env = false, isSelected } = props;

  return (
    <svg
      className={`${className} ${isSelected ? "rc-button-selected" : ""}`}
      style={style}
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_18891_160221)">
        <path
          d="M18.9202 37.0349C8.93542 37.0349 0.81543 28.9036 0.81543 18.9178C0.81543 8.93206 8.93542 0.800781 18.9202 0.800781C28.905 0.800781 37.025 8.92315 37.025 18.9178C37.025 28.9125 28.905 37.0349 18.9202 37.0349Z"
          fill="#1C1C1C"
        />
        <path d="M18.9205 1.60486C28.4617 1.60486 36.2105 9.35276 36.2105 18.9195C36.2105 28.4862 28.4617 36.2341 18.9205 36.2341C9.37925 36.2341 1.63041 28.4773 1.63041 18.9195C1.63041 9.36167 9.3702 1.60486 18.9205 1.60486ZM18.9205 0C8.49212 0 0.000976562 8.48792 0.000976562 18.9195C0.000976562 29.3511 8.49212 37.839 18.9205 37.839C29.3488 37.839 37.84 29.3511 37.84 18.9195C37.84 8.48792 29.3579 0 18.9205 0Z" />
        <path
          d="M22.8398 12C25.3383 13.3552 27.0401 15.9676 27.0401 18.9811C27.0401 23.3767 23.4192 26.943 18.9563 26.943C14.4935 26.943 10.8726 23.3767 10.8726 18.9811C10.8726 15.9943 12.5473 13.3909 15.0186 12.0267"
          stroke="white"
          stroke-width="2.41525"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M18.958 19.2318V8.42578"
          stroke="white"
          stroke-width="2.41525"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_18891_160221">
          <rect width="37.839" height="37.839" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
