import React from "react";

export const HighLightIcon = (props) => {
  const {
    className = "",
    style = {},
    disabled = false,
    onClick,
    fill = null,
  } = props;
  return (
    <svg
      className={className}
      style={style}
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13571_10054)">
        <path
          d="M24.9525 10.1911C23.0876 8.34664 20.6147 7.33691 17.9932 7.33691C17.9392 7.33691 17.8986 7.33691 17.8446 7.33691C15.2906 7.3773 12.8717 8.38703 11.0475 10.2045C9.20968 12.022 8.15566 14.4184 8.0881 16.9764C8.00702 19.7902 9.1286 22.5097 11.1556 24.4484C12.3177 25.5523 12.9798 26.562 12.9798 28.1237V28.2988C12.9798 28.945 13.4933 29.4566 14.142 29.4566H21.8444C22.4796 29.4566 23.0066 28.9315 23.0066 28.2988V28.1237C23.0066 26.5351 23.6687 25.5658 24.8579 24.408C26.8173 22.5232 27.8983 19.9786 27.8983 17.2457C27.8983 14.5127 26.8443 12.0624 24.9525 10.1776V10.1911ZM23.2633 22.752C21.885 24.0849 20.9931 25.31 20.7634 27.141H15.25C15.0203 25.2965 14.142 24.0714 12.7771 22.7655C11.2231 21.2846 10.3718 19.1978 10.4259 17.0437C10.534 13.0318 13.8987 9.71986 17.8986 9.66601C19.9526 9.63908 21.885 10.4199 23.3309 11.8605C24.7903 13.301 25.5876 15.2262 25.5876 17.2726C25.5876 19.319 24.7633 21.3115 23.2498 22.752H23.2633Z"
          fill={fill ? fill : "#FFE200"}
        />
        <path
          d="M21.0066 30.6553H14.9933C14.3582 30.6553 13.8312 31.1803 13.8312 31.8131C13.8312 32.4458 14.3447 32.9709 14.9933 32.9709H21.0066C21.6418 32.9709 22.1688 32.4458 22.1688 31.8131C22.1688 31.1803 21.6553 30.6553 21.0066 30.6553Z"
          fill={fill ? fill : "#FFE200"}
        />
        <path
          d="M18.0068 35.9996C19.2905 35.9996 20.4256 35.2457 21.1013 34.0879H14.9258C15.5879 35.2457 16.723 35.9996 18.0203 35.9996H18.0068Z"
          fill={fill ? fill : "#FFE200"}
        />
        <path
          d="M18.5743 11.7127C18.1149 11.7127 17.75 12.0762 17.75 12.5339C17.75 12.9917 18.1149 13.3552 18.5743 13.3552C20.4526 13.3552 21.9931 14.8765 21.9931 16.7613C21.9931 17.219 22.358 17.5825 22.8174 17.5825C23.2769 17.5825 23.6417 17.219 23.6417 16.7613C23.6417 13.9745 21.3715 11.6992 18.5608 11.6992L18.5743 11.7127Z"
          fill={fill ? fill : "#FFE200"}
        />
        <path
          d="M18.0068 5.68138C18.6148 5.68138 19.1148 5.18325 19.1148 4.57741V1.10396C19.1148 0.49813 18.6148 0 18.0068 0C17.3987 0 16.8987 0.49813 16.8987 1.10396V4.59088C16.8987 5.19671 17.3987 5.69484 18.0068 5.69484V5.68138Z"
          fill={fill ? fill : "#FFE200"}
        />
        <path
          d="M27.047 9.41091C27.3308 9.41091 27.6146 9.30321 27.8308 9.0878L30.3037 6.62407C30.7361 6.19326 30.7361 5.49318 30.3037 5.06237C29.8713 4.63155 29.1686 4.63155 28.7362 5.06237L26.2633 7.52609C25.8309 7.95691 25.8309 8.65698 26.2633 9.0878C26.4795 9.30321 26.7633 9.41091 27.047 9.41091Z"
          fill={fill ? fill : "#FFE200"}
        />
        <path
          d="M34.2765 16.2227H30.7767C30.1686 16.2227 29.6686 16.7208 29.6686 17.3266C29.6686 17.9325 30.1686 18.4306 30.7767 18.4306H34.2765C34.8846 18.4306 35.3846 17.9325 35.3846 17.3266C35.3846 16.7208 34.8846 16.2227 34.2765 16.2227Z"
          fill={fill ? fill : "#FFE200"}
        />
        <path
          d="M8.18271 9.1005C8.39892 9.3159 8.6827 9.42361 8.96647 9.42361C9.25025 9.42361 9.53402 9.3159 9.75023 9.1005C10.1827 8.66968 10.1827 7.9696 9.75023 7.53879L7.27733 5.07506C6.84491 4.64425 6.14223 4.64425 5.70981 5.07506C5.27739 5.50588 5.27739 6.20595 5.70981 6.63677L8.18271 9.1005Z"
          fill={fill ? fill : "#FFE200"}
        />
        <path
          d="M6.31789 17.3266C6.31789 16.7208 5.8179 16.2227 5.20981 16.2227H1.72343C1.11534 16.2227 0.615356 16.7208 0.615356 17.3266C0.615356 17.9325 1.11534 18.4306 1.72343 18.4306H5.22333C5.83142 18.4306 6.3314 17.9325 6.3314 17.3266H6.31789Z"
          fill={fill ? fill : "#FFE200"}
        />
        <path
          d="M27.8308 25.5389C27.6146 25.3235 27.3308 25.2158 27.047 25.2158C26.7633 25.2158 26.4795 25.3235 26.2633 25.5389C25.8309 25.9697 25.8309 26.6698 26.2633 27.1006L28.7362 29.5644C29.1686 29.9952 29.8713 29.9952 30.3037 29.5644C30.7361 29.1335 30.7361 28.4335 30.3037 28.0027L27.8308 25.5389Z"
          fill={fill ? fill : "#FFE200"}
        />
        <path
          d="M8.96647 25.2158C8.6827 25.2158 8.39892 25.3235 8.18271 25.5389L5.70981 28.0027C5.27739 28.4335 5.27739 29.1335 5.70981 29.5644C6.14223 29.9952 6.84491 29.9952 7.27733 29.5644L9.75023 27.1006C10.1827 26.6698 10.1827 25.9697 9.75023 25.5389C9.53402 25.3235 9.25025 25.2158 8.96647 25.2158Z"
          fill={fill ? fill : "#FFE200"}
        />
      </g>
      <defs>
        <clipPath id="clip0_13571_10054">
          <rect
            width="34.7692"
            height="36"
            fill="white"
            transform="translate(0.615356)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
