import React from "react";

export const NewLogoutIcon = (props) => {
    const { className = "", style = {}, disabled = false, onClick } = props;
    return (
        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_8961_45362)">
        <path d="M8.17475 27C7.44693 27 6.81008 26.7453 6.28241 26.2176C5.75474 25.6899 5.5 25.0713 5.5 24.3252V5.67475C5.5 4.94693 5.75474 4.31008 6.28241 3.78241C6.81008 3.25474 7.42873 3 8.17475 3H16.1808C16.9086 3 17.5091 3.60045 17.5091 4.32828C17.5091 5.0561 16.9086 5.65656 16.1808 5.65656H8.17475V24.3252H16.1808C16.9086 24.3252 17.5091 24.9257 17.5091 25.6535C17.5091 26.3813 16.9086 26.9818 16.1808 26.9818H8.17475V27Z" fill="#113E57"/>
        <path d="M18.5281 20.6678C18.0368 20.1402 18.0368 19.3214 18.5463 18.8119L21.0391 16.3373H11.4682C10.7403 16.3373 10.1399 15.7368 10.1399 15.009C10.1399 14.2812 10.7403 13.6807 11.4682 13.6807H21.0391L18.5463 11.2061C18.0368 10.6967 18.0186 9.87785 18.5281 9.35018C19.0375 8.80431 19.9109 8.80431 20.4386 9.33199L26.1338 15.0272L20.4386 20.7224C19.9109 21.2501 19.0375 21.2319 18.5281 20.7042V20.6678Z" fill="#113E57"/>
        </g>
        <defs>
        <clipPath id="clip0_8961_45362">
        <rect width="20.6338" height="24" fill="white" transform="translate(5.5 3)"/>
        </clipPath>
        </defs>
        </svg>
           
    );
}


