import React, { useEffect, useMemo, useRef, useState } from "react";
import calling from "../../../static/images/calling.gif";
import {
  PhotoResolution,
  VideoCallStatus,
} from "@commscopemycloud/humaui/Utilities/Constants";
import { ContactImage, MicOffIcon, MicOnIcon } from "../Common/Icons";
import { RemoteVideo } from "amazon-chime-sdk-component-library-react";

const RingingStatus = [VideoCallStatus.calling, VideoCallStatus.ringing];
const BorderColor = {
  [VideoCallStatus.declined]: "#B41515",
  [VideoCallStatus.missed]: "#B41515",
  [VideoCallStatus.busy]: "#B41515",
  [VideoCallStatus.failed]: "#B41515",
  [VideoCallStatus.unreachable]: "#B41515",
};

const UserVideoTile = (props) => {
  const {
    tileId,
    videoProps,
    className = "",
    name,
    status,
    isMuted,
    actions,
    user,
    scale = 1,
  } = props;
  const [size, setSize] = useState(props.size || 100);
  const ref = useRef();

  const parentElement = ref?.current?.parentElement;
  const w = parentElement?.offsetWidth || 100;
  const h = parentElement?.offsetHeight || 100;

  const image = useMemo(
    () => user?.profilePics && user?.profilePics[PhotoResolution.R256],
    [user]
  );

  useEffect(() => {
    // const s = Math.min(w, h) * 0.9;
    const s = Math.min(w, h);
    setSize(s);
  }, [w, h]);

  // useEffect(() => {
  //   setBoxSize();
  //   window.addEventListener("resize", setBoxSize);
  //   return () => {
  //     window.removeEventListener("resize", setBoxSize);
  //   };
  // }, []);

  // const setBoxSize = () => {
  //   if (!props.size) {
  //     const parentElement = ref?.current?.parentElement;
  //     const w = parentElement?.offsetWidth || 100;
  //     const h = parentElement?.offsetHeight || 100;
  //     const s = Math.min(w, h) * 0.9;
  //     setSize(s);
  //   }
  // };

  const renderNameplate = (!!name || isMuted != null) && (
    <div className="nameplate-container">
      {isMuted != null &&
        (isMuted ? (
          <MicOffIcon className="mic-icon" />
        ) : (
          <MicOnIcon className="mic-icon" />
        ))}
      {name != null && (
        <span className={`nameplate ${isMuted != null ? "" : "no-icon"}`}>
          {name}
        </span>
      )}
    </div>
  );

  if (tileId) {
    return (
      <div className={`user-video-tile flex-center ${className}`} ref={ref}>
        {renderNameplate}
        <RemoteVideo tileId={tileId} {...videoProps} />
      </div>
    );
  }

  return (
    <div className={`user-video-tile flex-center ${className}`} ref={ref}>
      {renderNameplate}
      <div
        className="flex-center"
        style={{ flexDirection: "column", height: size, width: size }}
      >
        {RingingStatus.includes(status) ? (
          <div className="animation-icon-container">
            {image && (
              <img src={image} className="user-profile-pic" alt="pic" />
            )}
            <img className="calling-gif" src={calling} alt="" />
          </div>
        ) : (
          <div
            className="user-icon-container"
            style={{ height: `${50 * scale}%` }}
          >
            <div
              className={`layer-1 flex-center ${status ? "with-status" : ""}`}
            >
              <div className="layer-2 flex-center">
                <div className="layer-3 flex-center">
                  {image ? (
                    <img
                      src={image}
                      className="user-profile-pic"
                      style={{ borderColor: BorderColor[status] }}
                      alt="pic"
                    />
                  ) : (
                    <ContactImage
                      className="user-icon"
                      style={{ borderColor: BorderColor[status] }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {!!status && <span className="status">{status}</span>}
        {actions && <div className="actions-container">{actions}</div>}
      </div>
    </div>
  );
};

export default UserVideoTile;
