import React from "react";

export const VolumeOffIcon = (props) => {
    const { className="", style={}, onClick, disabled=false } = props;
    return (
        <svg
            className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
            onClick={disabled ? null: onClick}
            style={style}
            width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
            <path className="stroke" d="M8.4 11.7222L15 7V24L8.4 19.2778H6.2C4.98497 19.2778 4 18.4321 4 17.3889V13.6111C4 12.5679 4.98497 11.7222 6.2 11.7222H8.4Z"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <line className="stroke" x1="18.1444" y1="10.9873" x2="26.2762" y2="19.119"  strokeWidth="1.5"/>
            <path className="stroke" d="M18.0659 19.1976L26.1976 11.0659"  strokeWidth="1.5"/>
        </svg>
    );
}


