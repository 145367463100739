import React from "react";
import * as d3 from "d3";

const MotionLineChart = ({ dataPerHourArray, colors }) => {
  if (!dataPerHourArray || dataPerHourArray.length === 0) return null;

  const margin = { top: 20, right: 30, bottom: 30, left: 0 };
  const hourWidth = 32; // 32 pixels per hour
  const padding = 1; // 1 pixel padding between each hour
  const width = (hourWidth + padding) * 24 - padding; // Total width considering padding
  const height = 50; // Fixed height for y-axis

  // Create scales
  const xScale = d3.scaleLinear().domain([0, 23]).range([0, width]);

  // Function to generate line path
  const generateLinePath = (motionValues, hours) => {
    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(motionValues)])
      .range([height, 0]);

    return d3
      .line()
      .x((d, i) => xScale(hours[i]))
      .y((d) => yScale(d))
      .curve(d3.curveMonotoneX)(motionValues);
  };

  return (
    <svg
      width={width + margin.left + margin.right}
      height={height + margin.top + margin.bottom}
      style={{ pointerEvents: "none" }}
    >
      <g transform={`translate(${margin.left + 15},${margin.top + 15})`}>
        <line x1={0} y1={0} x2={width} y2={0} stroke="grey" strokeWidth="2" />
        {dataPerHourArray.map((dataPerHour, index) => {
          if (dataPerHour === undefined) return null;
          // Extracting hours and motion values, filtering out undefined entries
          const filteredData = Object.keys(dataPerHour)
            .map((hour) => ({
              hour: parseInt(hour, 10),
              value: dataPerHour[hour],
            }))
            .filter((d) => d.value !== undefined);

          // If no valid data is found, skip the dataset
          if (filteredData.length === 0) return null;

          const hours = filteredData.map((d) => d.hour);
          const motionValues = filteredData.map((d) => d.value);

          return (
            <path
              key={index}
              d={generateLinePath(motionValues, hours)}
              fill="none"
              stroke={colors[index]}
              strokeWidth="2"
            />
          );
        })}
      </g>
    </svg>
  );
};

export default MotionLineChart;
