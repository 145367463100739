/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import useNotification from "./Components/Hooks/useNotification";
import { processNotification } from "@commscopemycloud/humaui/Services/NotificationService";
import {
  NavigationKeys,
  NotificationTypes,
  PhotoResolution,
  VideoCallStatusUpdate,
} from "@commscopemycloud/humaui/Utilities/Constants";
import {
  getProfilePicUrl,
  objectSort,
} from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import { getProfilePics } from "@commscopemycloud/humaui/Store/dataStore";
import desktopNotification from "./Components/Notifications/DesktopNotification";

const MainContext = createContext();

const MainProvider = ({ children }) => {
  const location = useLocation();
  const currentUser = useSelector(getCurrentUser);
  const useruuid = useMemo(() => currentUser.useruuid, [currentUser]);
  const resourcesMap = useSelector(getProfilePics());
  const currentUserProfilePic = useMemo(
    () => getProfilePicUrl(useruuid, resourcesMap, PhotoResolution.R64),
    [useruuid, resourcesMap]
  );
  const [notifications, fetchingNotifications, fetchNotifications] =
    useNotification({ useruuid });
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [newNotificationCount, setNewNotificationCount] = useState(0);

  const path = location.pathname.split("/")[1]?.toLowerCase();
  const [menuClickKey, setMenuClickKey] = useState(0);
  const [currentMenu, setCurrentMenu] = useState(
    !!path ? path : NavigationKeys.accounts
  );

  const handleMainMenuClick = (key) => {
    if (currentMenu === key) {
      setMenuClickKey((prevKey) => prevKey + 1);
    } else {
      setMenuClickKey(0);
    }
    setCurrentMenu(key);
  };

  useEffect(() => {
    const filteredList = filterNotifications(notifications);
    setFilteredNotifications(filteredList);
    const newNotif = filteredList.filter((n) => n.status === "NEW");
    setNewNotificationCount(newNotif?.length ?? 0);

    // Reset the count to 0 if the user is on a page related to notifications.
    if (window.location.href.includes("notification")) {
      setNewNotificationCount(0);
    }
  }, [notifications]);

  const filterNotifications = (notifList = []) => {
    let list = [];
    let eventIdList = [];
    let contactIdList = [];
    const newNotifList = notifList.filter((notification) => {
      if (notification.message_type === NotificationTypes.newEvent) {
        if (eventIdList.includes(notification.calendar__schedule_eventuuid)) {
          return false;
        } else {
          eventIdList.push(notification.calendar__schedule_eventuuid);
          return true;
        }
      } else if (notification.message_type === NotificationTypes.newContact) {
        if (notification?.contact__action === "modified") {
          return false;
        }
        if (contactIdList.includes(notification.contact__useruuid)) {
          return false;
        } else {
          contactIdList.push(notification.contact__useruuid);
          return true;
        }
      } else return true;
    });

    newNotifList.forEach((notification) => {
      if (
        notification.message_type === NotificationTypes.newEvent ||
        notification.message_type === NotificationTypes.newContact ||
        (notification.message_type === NotificationTypes.callStatus &&
          (notification.call_status__status ===
            VideoCallStatusUpdate.declined ||
            notification.call_status__status === VideoCallStatusUpdate.missed))
      ) {
        const obj = processNotification(
          notification,
          currentUser,
          currentUserProfilePic
        );
        obj && list.push(obj);
      }
    });
    list = list.sort((a, b) => objectSort("utc_timestamp_inms")(b, a));
    return list;
  };

  return (
    <MainContext.Provider
      value={{
        notifications: filteredNotifications,
        fetchingNotifications,
        newNotificationCount,
        fetchNotifications,
        setNewNotificationCount,
        currentMenu,
        handleMainMenuClick,
        menuClickKey,
        desktopNotification,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

const useMainContext = () => useContext(MainContext);

export { MainProvider, useMainContext };
