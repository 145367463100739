import React from "react";

export const NewCareIcon = (props) => {
  const { className = "", style = {}, onClick } = props;
  return (
    <svg
      onClick={onClick}
      className={"custom-icon " + className}
      style={style}
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.6531 10.6488C31.1795 9.51226 30.4873 8.48573 29.613 7.60584C28.7387 6.72595 27.7187 6.02937 26.5711 5.55276C24.1485 4.52623 21.3981 4.5079 18.9937 5.75441C18.429 6.0477 17.9008 6.39599 17.3908 6.78094C16.8808 6.37766 16.3525 6.02937 15.7879 5.75441C13.3835 4.52623 10.6148 4.52623 8.21047 5.55276C7.08114 6.02937 6.0611 6.72595 5.18679 7.60584C4.31247 8.48573 3.6203 9.51226 3.14671 10.6671C2.65491 11.8586 2.3999 13.1051 2.3999 14.4066C2.3999 15.7081 2.65491 16.9547 3.14671 18.1462C3.63852 19.301 4.31247 20.3276 5.18679 21.1891C5.27786 21.2808 14.8771 30.2263 16.4254 30.8679C16.7351 30.9962 17.0629 31.0695 17.409 31.0695C17.7551 31.0695 18.083 30.9962 18.3926 30.8679C19.9227 30.208 29.5219 21.2808 29.613 21.1891C30.4873 20.3092 31.1613 19.2827 31.6531 18.1462C32.1449 16.9547 32.3999 15.6898 32.3999 14.4066C32.3999 13.1235 32.1449 11.8586 31.6531 10.6671V10.6488ZM29.4491 14.3883C29.4491 15.2682 29.2669 16.1481 28.9391 16.973C28.6112 17.7612 28.1376 18.4761 27.5365 19.081C26.8261 19.796 18.9026 26.9634 17.409 28.0266C15.9154 26.9634 7.99189 19.796 7.2815 19.081C6.68041 18.4761 6.20682 17.7612 5.87895 16.973C5.53287 16.1481 5.36894 15.2865 5.36894 14.3883C5.36894 13.4901 5.55109 12.6285 5.87895 11.822C6.20682 11.0337 6.68041 10.3372 7.2815 9.7139C7.8826 9.10898 8.59298 8.63237 9.37622 8.30242C10.1959 7.95413 11.052 7.78915 11.9263 7.78915C12.8006 7.78915 13.6385 8.00912 14.4582 8.4124C15.0957 8.74236 16.2068 9.75056 16.5893 10.1172L17.409 10.9237L18.2287 10.1172C18.6112 9.75056 19.7223 8.74236 20.3598 8.4124C21.9992 7.56917 23.8389 7.62417 25.4418 8.30242C26.225 8.63237 26.9354 9.10898 27.5365 9.7139C28.1376 10.3188 28.6112 11.0337 28.9391 11.822C29.2851 12.6469 29.4491 13.5084 29.4491 14.3883Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="none"
      />
    </svg>
  );
};
