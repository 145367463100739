import React from "react";

export const SuccessCallIcon = (props) => {
    const { className = "", style = {} } = props;

    return (
        <svg className={className} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <path d="M29.727 15.0007C29.727 23.1344 23.1334 29.728 14.9997 29.728C6.86608 29.728 0.272461 23.1344 0.272461 15.0007C0.272461 6.86706 6.86608 0.273438 14.9997 0.273438C23.1334 0.273438 29.727 6.86706 29.727 15.0007Z" fill="#027B0E" />
            <path d="M13.0845 21.2372L12.9802 21.3391L6.7334 15.0862L9.13149 12.6881L12.9876 16.5442L20.8679 8.66016L23.2672 11.0582L13.0845 21.2372Z" fill="white" />
        </svg>
    );
}