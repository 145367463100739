import { Result } from "antd";
import React from "react";
import "../../styles/Result.less";
import PropTypes from "prop-types";

const DefaultMessage = "Something went wrong!";

const Error = ({ className, message, description }) => (
  <div className={`result-container ${className}`}>
    <Result status="500" title={message} subTitle={description} />
  </div>
);

Error.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  description: PropTypes.string,
};

Error.defaultProps = {
  message: DefaultMessage,
};

export default Error;
