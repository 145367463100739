import React from "react";

export const RCDeclineCall = (props) => {
  const { className = "", style = {}, env = false, isSelected } = props;

  return (
    <svg
      className={`${className} ${isSelected ? "rc-button-selected" : ""}`}
      style={style}
      width="47"
      height="47"
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_18891_160225)">
        <path
          d="M23.5 46.0027C11.0978 46.0027 1.01196 35.9028 1.01196 23.4994C1.01196 11.096 11.0978 0.996094 23.5 0.996094C35.9021 0.996094 45.988 11.096 45.988 23.4994C45.988 35.9028 35.9021 46.0027 23.5 46.0027Z"
          fill="#F46363"
        />
        <path d="M23.5 1.9934C35.3512 1.9934 44.9761 11.6171 44.9761 23.5C44.9761 35.3829 35.3512 45.0066 23.5 45.0066C11.6488 45.0066 2.02392 35.3718 2.02392 23.5C2.02392 11.6282 11.6376 1.9934 23.5 1.9934ZM23.5 0C10.5469 0 0 10.5429 0 23.5C0 36.4571 10.5469 47 23.5 47C36.4531 47 47 36.4571 47 23.5C47 10.5429 36.4644 0 23.5 0Z" />
        <path
          d="M11.8398 22.5905C11.5587 22.8341 11.4351 23.111 11.4351 23.421C11.4351 23.7201 11.5475 24.008 11.7499 24.1963L13.9312 26.234C14.1336 26.4333 14.381 26.533 14.6284 26.5551C15.5729 26.6105 17.9004 25.1376 18.4176 24.2849C18.6537 23.8972 18.6087 23.2328 18.5638 22.6015C18.5525 22.38 18.53 22.1586 18.53 21.9592C18.53 21.5384 18.7999 21.1619 19.2047 21.04C22.1281 20.1652 25.254 20.0876 28.2111 20.8075C28.6272 20.9071 28.9195 21.2726 28.9308 21.6934C28.9308 21.9038 28.9308 22.1143 28.9308 22.3358C28.9195 22.9781 28.897 23.6425 29.1669 24.008C29.7291 24.8164 32.1128 26.1675 33.0573 26.0789C33.3159 26.0568 33.5858 25.9128 33.7319 25.7467L35.8121 23.5872C36.0032 23.3878 36.1044 23.0999 36.0819 22.7898C36.0595 22.4908 35.9245 22.225 35.6997 22.0478C32.3264 18.8805 28.0537 17.2304 23.6123 17.3412C19.1822 17.4519 15.0107 19.3013 11.8623 22.5683L11.8398 22.5905Z"
          fill="white"
          stroke="white"
          stroke-width="0.6"
          stroke-miterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_18891_160225">
          <rect width="47" height="47" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
