import React from "react";

export const _41 = (props) => {
  const { className = "", style = {} } = props;
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 840 840"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M624.09 315.69C583.1 315.69 542.11 315.66 501.11 315.72C493.75 315.73 487.23 312.96 480.88 309.91C455.31 297.63 440.01 277.03 435.74 249.34C429.15 206.64 444.89 172.53 479.34 147.21C495.93 135.01 515.07 129.69 535.8 130.95C539.96 131.2 544.1 131.83 548.14 132.9C549.69 133.31 550.58 133.11 551.21 131.38C558.61 111.28 572.8 97.27 591.26 87.18C606.07 79.09 622.03 74.93 638.79 73.53C664.75 71.36 689.46 75.81 712.67 87.68C730.95 97.03 745.9 110.09 755.71 128.46C762.78 141.68 765.36 155.75 763.46 170.61C763.18 172.82 763.3 173.82 766.05 174.25C787.85 177.68 802.13 190.86 810.02 210.68C820.73 237.62 817.58 263.65 801.67 287.81C791.67 303.01 777.16 311.74 759.19 314.61C754 315.44 748.77 315.81 743.5 315.81C703.69 315.77 663.88 315.79 624.07 315.79C624.09 315.75 624.09 315.72 624.09 315.69ZM681.5 281.82C681.5 281.83 681.5 281.84 681.5 281.86C703.31 281.86 725.12 281.81 746.93 281.88C760.17 281.92 769.93 276.22 776.18 264.58C781.62 254.45 783.16 243.62 781.22 232.38C779.87 224.54 776.89 217.36 770.6 212.04C766.67 208.72 762.23 207.18 756.98 208.18C752.9 208.96 749.49 210.87 746.46 213.56C741.73 217.78 736.41 219.79 729.97 218.73C724.04 217.76 718.59 216.09 715.23 210.64C710.61 203.16 711.89 194.63 718.47 188.08C731.61 175.01 733.79 157.88 724.36 142.09C718.06 131.54 708.92 124.09 698.21 118.47C680.56 109.21 661.72 105.74 641.87 107.35C629.3 108.37 617.4 111.64 606.36 117.75C595.91 123.53 587.49 131.3 583.22 142.81C581.63 147.09 580.56 151.49 581.9 156.1C582.72 158.93 582.23 161.71 581.29 164.38C577.57 174.93 565.09 179.04 555.46 172.93C553.32 171.57 551.35 169.97 549 168.92C534.54 162.47 520.3 163.78 506.42 170.51C480.54 183.05 464.84 212.87 468.79 241.6C470.62 254.94 476.39 266.18 487.27 274.37C492.44 278.26 498.03 281.47 504.87 281.49C544.58 281.6 584.28 281.36 623.99 281.77C643.15 281.99 662.33 281.82 681.5 281.82Z"
        fill="#808080"
      />
      <path
        d="M152.118 526.4C138.038 526.4 119.228 526.35 100.408 526.43C93.6783 526.46 87.4683 524.77 81.7383 521.35C64.3783 511 51.5183 496.69 44.5883 477.59C37.2683 457.39 37.9683 437.57 49.6983 419.04C58.9883 404.36 72.7283 395.93 89.6783 392.68C101.418 390.42 112.998 391.46 124.358 395.05C126.198 395.63 126.818 395.45 127.088 393.35C129.988 370.44 140.718 351.58 157.658 336.27C173.848 321.64 193.008 312.67 214.298 308.11C226.368 305.52 238.608 304.56 250.858 305.55C280.948 307.99 307.568 318.53 328.408 341.11C341.208 354.97 348.838 371.37 350.618 390.34C351.228 396.8 351.238 403.21 350.068 409.59C349.688 411.64 350.728 411.96 352.378 412.31C368.548 415.7 381.718 423.92 391.098 437.56C408.038 462.19 402.298 494.81 378.088 512.97C366.898 521.36 354.358 525.58 340.318 525.59C300.598 525.61 260.888 525.56 221.168 525.94C199.738 526.14 178.288 526.03 152.118 526.4ZM141.348 492.5C158.028 492.2 172.928 492.33 187.828 492.14C228.438 491.62 269.058 492.18 309.668 491.54C320.118 491.37 330.568 491.27 341.018 491.48C347.268 491.61 352.568 489.54 357.458 485.98C369.068 477.51 370.118 462.57 359.648 452.77C352.218 445.82 343.098 444 333.318 445.19C329.118 445.7 325.138 445.19 321.268 443.86C314.878 441.67 311.218 437.09 310.068 430.37C309.278 425.79 310.878 421.82 312.608 417.79C318.578 403.82 318.618 389.91 311.788 376.23C305.938 364.53 296.738 356.01 285.308 350C261.758 337.6 237.098 336.17 211.918 343.93C193.778 349.52 178.428 359.45 168.428 376.05C160.148 389.8 157.718 404.34 164.358 419.72C167.418 426.81 167.418 433.98 161.908 440.11C154.538 448.32 143.028 448.28 134.908 440.18C128.398 433.69 120.818 428.97 111.848 426.88C102.668 424.74 93.5683 424.8 85.3583 430.27C75.9683 436.52 72.0083 447.91 74.8583 460.18C77.9583 473.52 85.7583 483.53 97.1683 490.86C98.9583 492.01 100.918 492.52 103.088 492.51C116.418 492.47 129.778 492.5 141.348 492.5Z"
        fill="#808080"
      />
      <path
        d="M571.879 423.131C567.929 424.391 564.799 427.091 561.369 429.221C537.709 443.941 514.009 458.601 490.139 472.981C488.279 474.101 486.199 474.831 484.239 475.791C483.239 476.281 482.189 476.911 481.239 475.951C480.169 474.871 480.949 473.691 481.529 472.731C483.959 468.691 486.489 464.711 488.959 460.691C505.459 433.801 521.939 406.901 538.459 380.021C547.949 364.571 557.519 349.171 567.009 333.721C567.949 332.191 568.939 331.471 570.889 331.491C589.519 331.641 608.149 331.651 626.779 331.741C629.219 331.751 631.659 331.981 634.109 332.121C634.519 333.271 633.619 333.831 633.169 334.521C625.359 346.341 616.559 357.451 608.349 368.981C607.829 369.711 607.039 370.291 607.339 371.361C607.109 371.411 606.889 371.451 606.659 371.491C606.719 371.601 606.779 371.701 606.829 371.811C607.009 371.681 607.199 371.551 607.379 371.421C612.619 368.951 617.879 366.541 623.089 364.001C641.439 355.071 659.459 345.471 678.199 337.351C679.039 336.991 680.029 335.861 680.839 336.941C681.549 337.881 680.479 338.781 679.939 339.581C670.449 353.771 659.889 367.181 649.799 380.931C632.569 404.411 615.219 427.811 597.889 451.221C575.219 481.851 552.489 512.441 529.829 543.081C510.139 569.701 490.509 596.371 470.829 623.011C461.129 636.151 451.189 649.111 441.819 662.491C441.559 662.861 441.269 663.221 440.939 663.531C439.719 664.681 438.509 666.561 436.639 665.211C434.849 663.921 435.949 661.931 436.709 660.531C439.989 654.461 443.449 648.491 446.849 642.491C458.049 622.751 469.309 603.041 480.459 583.281C493.489 560.191 506.489 537.091 519.399 513.941C530.329 494.361 541.559 474.951 552.849 455.581C558.879 445.231 565.159 435.021 571.309 424.741C571.579 424.291 571.739 423.771 571.949 423.281C572.019 423.171 572.099 423.061 572.169 422.951C572.099 422.981 571.989 423.061 571.879 423.131Z"
        fill="#E4C276"
      />
      <path
        d="M383.791 407.381C377.731 404.731 371.611 402.271 365.011 401.221C363.691 401.011 363.141 400.121 363.061 398.701C362.211 384.741 358.971 371.301 353.491 358.471C349.081 348.141 343.321 338.601 335.441 330.481C334.471 329.481 334.111 328.451 334.121 327.091C334.351 310.941 335.881 294.931 338.821 279.041C342.431 259.521 348.181 240.681 356.071 222.481C359.031 215.651 362.301 208.961 365.961 202.461C366.381 201.711 367.301 200.821 366.681 200.011C366.001 199.111 364.961 199.981 364.171 200.211C347.221 205.021 331.301 212.131 316.341 221.451C302.151 230.291 289.461 240.951 278.191 253.251C267.551 264.871 258.451 277.641 251.311 291.731C250.431 293.461 249.431 294.191 247.531 293.951C236.421 292.521 225.501 294.081 214.621 296.151C213.821 296.301 212.801 297.261 212.161 296.201C211.681 295.401 212.421 294.451 212.761 293.651C217.651 281.891 223.591 270.681 230.451 259.951C240.431 244.341 252.221 230.251 265.771 217.651C278.641 205.681 292.821 195.481 308.201 186.931C326.801 176.591 346.441 169.041 367.241 164.561C379.991 161.821 392.831 159.331 405.961 159.941C411.561 160.201 417.221 161.261 421.061 166.141C425.831 172.201 425.431 178.601 422.381 185.311C419.991 190.551 415.841 194.431 412.521 198.941C397.881 218.811 387.341 240.661 380.451 264.331C376.541 277.761 373.951 291.441 372.501 305.311C371.361 316.221 370.801 327.211 371.351 338.191C372.481 360.891 376.101 383.171 383.351 404.791C383.641 405.661 383.951 406.511 383.791 407.441V407.381Z"
        fill="#F5F5F5"
      />
      <path
        d="M42.6401 767.21C35.6001 766.8 30.0001 764.03 26.5701 757.86C22.8501 751.16 23.2301 744.31 27.3101 737.97C35.9601 724.54 44.8801 711.28 53.6701 697.94C84.3001 651.46 114.93 604.99 145.55 558.51C148.85 553.51 152.16 548.51 155.39 543.47C156.26 542.11 157.26 541.54 158.93 541.53C172.1 541.42 185.27 541.2 198.44 541.03C199.22 541.02 200.31 540.6 200.72 541.33C201.23 542.23 200.22 542.96 199.75 543.68C183.04 569.1 166.32 594.51 149.59 619.91C120.08 664.73 90.5501 709.54 61.0801 754.39C58.1001 758.92 55.0701 763.24 49.8801 765.53C47.5101 766.58 45.0101 766.86 42.6401 767.21Z"
        fill="#6087A0"
      />
      <path
        d="M131.27 711.819C131.4 706.739 132.9 702.809 135.3 699.169C152.67 672.759 170.03 646.349 187.38 619.929C199.79 601.029 212.19 582.119 224.58 563.189C228.27 557.549 232.62 552.939 239.73 551.769C249.27 550.189 258.73 556.719 260.57 566.209C261.72 572.149 259.98 577.339 256.8 582.189C242.76 603.609 228.67 624.999 214.62 646.409C198.68 670.709 182.74 695.009 166.82 719.319C160.68 728.679 150.89 732.119 141.96 727.889C134.95 724.559 131.71 718.729 131.27 711.819Z"
        fill="#6087A0"
      />
      <path
        d="M531.38 333.759C535.29 333.759 539.189 333.749 543.1 333.769C543.98 333.769 545.09 333.439 545.54 334.469C545.91 335.329 545.019 335.949 544.589 336.599C531.049 357.039 517.489 377.459 503.929 397.889C490.619 417.939 477.349 438.009 463.999 458.029C457.149 468.299 450.309 478.589 443.149 488.639C437.849 496.079 427.929 498.349 420.049 494.459C410.889 489.939 407.32 479.119 411.85 469.589C413.57 465.959 415.959 462.739 418.169 459.419C429.719 442.019 441.34 424.659 452.85 407.239C468.36 383.759 483.819 360.239 499.239 336.699C500.339 335.019 501.469 334.199 503.579 334.229C512.839 334.349 522.099 334.279 531.359 334.279C531.379 334.099 531.38 333.929 531.38 333.759Z"
        fill="#6087A0"
      />
      <path
        d="M229.978 680.77C229.798 675.57 232.208 671.31 234.948 667.19C255.468 636.32 275.998 605.46 296.518 574.6C304.368 562.8 312.248 551.02 320.038 539.18C321.198 537.42 322.408 536.62 324.618 536.61C337.698 536.53 350.778 536.25 363.858 536.08C364.618 536.07 365.668 535.64 366.098 536.48C366.508 537.29 365.618 537.95 365.188 538.6C331.978 588.52 298.748 638.43 265.558 688.37C262.448 693.05 258.688 696.89 253.168 698.48C241.238 701.9 229.938 693.22 229.978 680.77Z"
        fill="#6087A0"
      />
      <path
        d="M468.33 553.471C465.21 552.121 462.36 550.991 459.59 549.671C443.33 541.961 428.45 532.171 414.98 520.221C411.92 517.501 409.06 514.581 406.04 511.841C404.48 510.421 404.16 509.241 405.53 507.491C406.98 505.641 407.82 502.461 409.54 501.951C411.04 501.511 413.38 504.011 415.38 505.171C424.39 510.421 438.34 508.931 446.2 501.691C447.93 500.101 448.97 499.891 450.98 501.371C464.34 511.151 478.9 518.641 494.82 523.321C496.9 523.931 496.95 524.561 495.98 526.281C484.18 547.341 472.43 568.431 460.71 589.531C459.97 590.851 459.07 591.481 457.58 591.751C446.97 593.641 436.33 595.031 425.54 595.661C401.31 597.071 377.64 594.071 354.31 587.681C351.97 587.041 352.23 586.311 353.33 584.681C359.08 576.181 364.76 567.641 370.41 559.081C371.31 557.721 372.18 557.151 373.97 557.551C384.86 559.991 395.87 561.281 407.04 561.851C426.89 562.851 446.22 560.061 465.31 554.941C466.24 554.701 467.29 554.601 468.33 553.471Z"
        fill="#F5F5F5"
      />
      <path
        d="M744.56 332.621C750.65 332.621 756.74 332.681 762.82 332.591C765 332.561 765.07 333.131 764.08 334.851C742.82 371.741 721.62 408.661 700.35 445.541C694.5 455.681 688.45 465.711 682.47 475.781C679.15 481.381 674.5 485.011 667.88 486.031C656.01 487.871 645.15 476.651 647.41 464.791C647.98 461.801 649.05 459.041 650.6 456.451C674.41 416.441 698.27 376.461 721.99 336.401C723.73 333.461 725.57 332.241 729.05 332.471C734.21 332.821 739.4 332.561 744.58 332.561C744.56 332.581 744.56 332.601 744.56 332.621Z"
        fill="#6087A0"
      />
      <path
        d="M29.8605 614.131C29.7605 607.84 31.1505 604.021 33.4605 600.571C46.0505 581.801 58.7105 563.071 71.3105 544.291C72.2105 542.951 73.3005 542.691 74.7505 542.681C87.9105 542.531 101.081 542.331 114.241 542.171C115.101 542.161 116.321 541.681 116.771 542.511C117.301 543.471 116.231 544.321 115.701 545.111C105.771 559.881 95.8205 574.631 85.8605 589.371C78.4905 600.281 71.2005 611.241 63.6805 622.041C57.8805 630.371 47.7505 632.73 39.1505 627.94C33.0005 624.51 30.1205 619.021 29.8605 614.131Z"
        fill="#6087A0"
      />
      <path
        d="M383.781 407.391C383.861 407.461 383.941 407.531 384.021 407.601C383.971 407.591 383.901 407.601 383.881 407.571C383.841 407.521 383.821 407.441 383.801 407.381C383.791 407.381 383.781 407.391 383.781 407.391Z"
        fill="#F5F5F5"
      />
      <path
        d="M607.4 371.4C607.22 371.53 607.03 371.66 606.85 371.79C606.79 371.68 606.73 371.58 606.68 371.47C606.91 371.43 607.13 371.38 607.36 371.34C607.35 371.34 607.4 371.4 607.4 371.4Z"
        fill="#E4C276"
      />
      <path
        d="M571.879 423.13C571.989 423.06 572.099 422.98 572.209 422.91C572.139 423.02 572.059 423.13 571.989 423.24L571.879 423.13Z"
        fill="#E4C276"
      />
    </svg>
  );
};
