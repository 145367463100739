import React from "react";

export const AmbientLightIcon = (props) => {
  const { className = "", style = {}, disabled = false, onClick } = props;
  return (
    <svg
      className={className}
      style={style}
      width="31"
      height="46"
      viewBox="0 0 31 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13571_5525)">
        <path
          d="M4.80601 27.1004C6.62215 28.8479 7.65595 30.4545 7.65595 32.9348V33.2026C7.65595 34.2173 8.46622 35.0347 9.47208 35.0347H21.5284C22.5343 35.0347 23.3445 34.2173 23.3445 33.2026V32.9348C23.3445 30.4263 24.3783 28.8761 26.2364 27.0581C29.3098 24.0705 31.0002 20.0399 31.0002 15.7275C31.0002 11.4152 29.3657 7.51145 26.3761 4.52378C23.4563 1.59248 19.6005 0 15.4933 0C15.4234 0 15.3396 0 15.2697 0C11.2742 0.0563711 7.50227 1.66295 4.6244 4.53788C1.7605 7.4128 0.125979 11.2179 0.000246513 15.2625C-0.125486 19.7158 1.6208 24.0282 4.80601 27.1004ZM3.63251 15.3752C3.81413 9.01938 9.05297 3.76277 15.3396 3.67822C18.5527 3.63594 21.5703 4.86201 23.8475 7.14504C26.1246 9.42807 27.3819 12.4721 27.3819 15.7275C27.3819 18.983 26.0827 22.1257 23.7357 24.4087C21.5703 26.5226 20.1873 28.4674 19.824 31.3705H11.1904C10.8272 28.4533 9.45811 26.5226 7.30669 24.4369C4.87587 22.0834 3.53472 18.7716 3.63251 15.3611V15.3752Z"
          fill="#005D98"
        />
        <path
          d="M22.0172 38.7832C22.0172 37.7686 21.2069 36.9512 20.201 36.9512H10.799C9.79319 36.9512 8.98291 37.7686 8.98291 38.7832C8.98291 39.7979 9.79319 40.6153 10.799 40.6153H20.201C21.2069 40.6153 22.0172 39.7979 22.0172 38.7832Z"
          fill="#005D98"
        />
        <path
          d="M15.5069 45.3932C17.5186 45.3932 19.3068 44.1953 20.3406 42.3633H10.6732C11.707 44.1813 13.4952 45.3932 15.5069 45.3932Z"
          fill="#005D98"
        />
        <path
          d="M16.4011 9.54076C19.3488 9.54076 21.7517 11.9647 21.7517 14.9383C21.7517 15.657 22.3385 16.2489 23.051 16.2489C23.7634 16.2489 24.3502 15.657 24.3502 14.9383C24.3502 10.5273 20.7878 6.93359 16.4151 6.93359C15.7026 6.93359 15.1158 7.52549 15.1158 8.24422C15.1158 8.96295 15.7026 9.55485 16.4151 9.55485L16.4011 9.54076Z"
          fill="#005D98"
        />
      </g>
      <defs>
        <clipPath id="clip0_13571_5525">
          <rect width="31" height="45.3929" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
