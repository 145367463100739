/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { UserOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Upload, message, Select, Switch } from "antd";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import {
  DefaultTimezone,
  PhotoResolution,
  TimeZones,
  Units,
  DefaultUnit,
  } from "@commscopemycloud/humaui/Utilities/Constants";
import Pencil from "../../../static/icons/icon_pencil.svg";
import Cross from "../../../static/icons/icon_x_letter.svg";
import WhiteTick from "../../../static/icons/icon_tick_white.svg";
import BlackTick from "../../../static/icons/icon_tick_black.svg";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import {
  selectProviderData,
  setProviderData,
} from "@commscopemycloud/humaui/Store/providerDataStore";
import MainPage from "../../MainPage";
import useUserInfo from "../Hooks/useUserInfo";
import Loading from "../Common/Loading";
import {
  getProfilePicUrl,
  getBase64,
} from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import { getProfilePics } from "@commscopemycloud/humaui/Store/dataStore";
import { DashboardIcon, NewGearIcon, NewCloseIcon, NewUpdateIcon } from "../Common/Icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../Common/CustomButton";
import { updatePassword } from "@commscopemycloud/humaui/Services/KeycloakService";
import NewCustomButton,{ ButtonTypes } from "../Common/NewCustomButton";

const EditUserProfile = ({
  parentBread,
  hubRecord,
  fetchUserHubs,
  onActionClick,
  handleTabChange,
  userHubCall = false,
  openSubMenu = false,
  showContactingStaff,
  filteredUserHubsData,
  successCallBack = () => {}
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname.split("/")[1]?.toLowerCase();
  const [form] = Form.useForm();
  const trans = useSelector(translator);
  const userApi = useSelector((state) => state.apis.userApi);
  const currentUser = useSelector(getCurrentUser);
  const useruuid = useMemo(
    () => (hubRecord ? hubRecord.useruuid : currentUser.useruuid),
    [hubRecord, currentUser]
  );

  const [userInfo, fetchingUserInfo, fetchData] = useUserInfo({ useruuid });
  const currentUserExist = currentUser.useruuid === userInfo.useruuid;
  const allowedPermissions = 4 - userInfo?.revoke?.length; // count of permissions is 4
  const toggleUpdateOnToggleAllow =
    allowedPermissions === 0 || allowedPermissions === 4; // edge case for update btn

  const [areButtonsDisabled, setAreButtonsDisabled] = useState(true);
  const [isUpdatingProfile, setIsUpdatingProfile] = useState(false);
  const [isUpdatingData, setIsUpdatingData] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotourl, setProfilePhotoUrl] = useState("");
  const [isProfilePhotoUpdated, setIsProfilePhotoUpdated] = useState(false);
  const [isTimeFormatEnabled, setIsTimeFormatEnabled] = useState(false);
  const [isAutoCallAnswerEnabled, setIsAutoCallAnswerEnabled] = useState(false);

  const [allow, setAllow] = useState(true);
  const [accountPrincipal, setAccountPrincipal] = useState(false);
  const [manage, setManage] = useState(true);
  const [care, setCare] = useState(true);
  const [social, setSocial] = useState(true);

  const [isAllow, setIsAllow] = useState(false);
  const [initialAccountPrincipal, setInitialAccountPrincipal] = useState(false);
  const [initialManage, setInitialManage] = useState(false);
  const [initialCare, setInitialCare] = useState(false);
  const [initialSocial, setInitialSocial] = useState(false);

  const [formData, setFormData] = useState({});
  const resourcesMap = useSelector(getProfilePics());
  const photoUrl = getProfilePicUrl(
    useruuid,
    resourcesMap,
    PhotoResolution.R256
  );

  const formRef = useRef();
  const providerData = useSelector(selectProviderData);
  const defaultLocaleVal =
    providerData?.supportedlocales[0].slice(-6, -1) || "en-US";
  const defaultTimezoneVal =
    providerData?.supportedtimezones.find(
      (timezone) => timezone[defaultLocaleVal]
    )?.[defaultLocaleVal] || "America/Los_Angeles (PDT)";

  const createSearchParams = () => {
    const currentSearchParams = new URLSearchParams(location.search);
    const paramsMap = new Map(currentSearchParams.entries());
    const uniqueSearchParams = new URLSearchParams(paramsMap);
    return uniqueSearchParams.toString();
  };

  const changeTabKey = (key) => {
    handleTabChange(key);
  };
  const allowStaffAdmin = useMemo(
    () => currentUser?.permission_level === "Staff Admin",
    [currentUser]
  );

  const allowStaffAndManageAccounts = useMemo(() => {
    const isManageRole = (role) => role?.toLowerCase() === "manage";
    return (
      currentUser?.permission_level === "Staff" &&
      (isManageRole(hubRecord?.rolename) ||
        (filteredUserHubsData &&
          isManageRole(filteredUserHubsData[0]?.rolename)))
    );
  }, [currentUser, hubRecord, filteredUserHubsData]);
  // Function to get UTC offset abbreviation for a time zone
  const get_utc_offset = (timeZone) => {
    return Intl.DateTimeFormat(navigator.language, {
      timeZoneName: "longOffset",
      timeZone,
    })
      .formatToParts()
      .find((i) => i.type === "timeZoneName").value;
  };

  const serviceProviderApi = useSelector(
    (state) => state.apis.serviceProviderApi
  );
  const providerId = useSelector(getCurrentUser)?.providerid;

  useEffect(() => {
    if (!providerData) {
      fetchProviderData();
    }
  }, []);

  const fetchProviderData = () => {
    const errorCallback = (error) => {
      console.error(error);
    };
    const successCallback = (data) => {
      dispatch(setProviderData(data?.serviceProvider));
    };

    try {
      serviceProviderApi.getServiceproviderById(
        providerId,
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  useEffect(() => {
    const obj = {
      firstName: userInfo?.firstname ?? "",
      lastName: userInfo?.lastname ?? "",
      timeZone: userInfo?.timezone ?? defaultTimezoneVal[0] ?? "",
      units: userInfo?.measurement ?? DefaultUnit.value ?? "",
      latitude: userInfo?.latitude ?? "",
      longitude: userInfo?.longitude ?? "",
      postalCode: userInfo?.postalcode ?? "",
      email: userInfo?.email ?? "",
      staffRole: userInfo?.staffrole ?? "",
      locale: userInfo?.locale ?? defaultLocaleVal, // fallback value from serviceProvider API
      is24hoursformat: userInfo?.is24hoursformat ?? isTimeFormatEnabled,
      autoacceptcall: userInfo?.autoacceptcall ?? isAutoCallAnswerEnabled,
    };
    setFormData(obj);
    form.setFieldsValue(obj);
    // setLocale(obj.locale);
    setProfilePhoto(userInfo.photo);
    setProfilePhotoUrl(photoUrl);

    const isAllowEnabled = userInfo?.revoke?.length < 4 ? true : false;
    setIsAllow(isAllowEnabled);
    setAllow(isAllowEnabled);

    // Initialize contacting staff toggle state based on userInfo
    const isAccountPrincipal = !userInfo?.revoke?.includes("Principal");
    const isManageEnabled = !userInfo?.revoke?.includes("Manage");
    const isCareEnabled = !userInfo?.revoke?.includes("Care");
    const isSocialEnabled = !userInfo?.revoke?.includes("Social");

    setIsTimeFormatEnabled(userInfo?.is24hoursformat ?? false);
    setIsAutoCallAnswerEnabled(userInfo?.autoacceptcall ?? false);
    setInitialAccountPrincipal(isAccountPrincipal);
    setInitialManage(isManageEnabled);
    setInitialCare(isCareEnabled);
    setInitialSocial(isSocialEnabled);
    setAccountPrincipal(!userInfo?.revoke?.includes("Principal") ?? true);
    setManage(!userInfo?.revoke?.includes("Manage") ?? true);
    setCare(!userInfo?.revoke?.includes("Care") ?? true);
    setSocial(!userInfo?.revoke?.includes("Social") ?? true);
  }, [userInfo, photoUrl]);

  const localeValue = useMemo(() => {
    return providerData?.supportedlocales.some((locale) =>
      locale.includes(userInfo?.locale)
    )
      ? userInfo?.locale
      : defaultLocaleVal;
  }, []);

  // const timezoneValue = useMemo(() => {
  //   return providerData.supportedtimezones;
  // });
  const [locale, setLocale] = useState(localeValue);
  // const [tz, setTz] = useState(null);

  const handleValuesChange = (changedValues) => {
    if (changedValues.hasOwnProperty("locale")) {
      const value = formRef.current
        ? formRef.current.getFieldValue("locale")
        : null;
      setLocale(value);

      // Reset the timeZone field when locale changes
      form.setFieldsValue({ timeZone: "" });
      setAreButtonsDisabled(true); // Disable update button
    }
  };

  // useEffect(() => {
  //   const localeValue = formRef.current
  //     ? formRef.current.getFieldValue("locale")
  //     : null;
  //   setLocale(localeValue);
  // }, [formRef.current]);

  let header = [];

  if (path.includes("profile")) {
    header = (parentBread || []).concat([
      {
        label: "",
        icon: (
          <Link to="/">
            <DashboardIcon />
          </Link>
        ),
      },
      {
        label: trans("EDITPROFILE"),
        icon: <UserOutlined />,
      },
    ]);
  } else {
    header = (parentBread || []).concat([
      {
        label: trans("EDITPROFILE"),
        icon: <UserOutlined />,
      },
    ]);
  }

  const onProfilePhotoUpdate = (photo) => {
    const errorCallback = (error) => {
      setIsUpdatingProfile(false);
      setIsProfilePhotoUpdated(false);
      console.error("Error uploading picture:", error);
      message.error("Error uploading picture");
      fetchData();
    };
    const successCallback = (data) => {
      setIsUpdatingProfile(false);
      setAreButtonsDisabled(true);
      setIsProfilePhotoUpdated(false);
      console.info("onProfilePhotoUpdate result", data);
      message.success("Profile picture updated successfully");
      fetchData();
    };
    try {
      userApi.upload(
        useruuid,
        { profileimage: photo },
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  const onUpdateHandler = () => {
    const errorCallback = (error) => {
      setIsUpdatingProfile(false);
      console.error("Error updating profile details:", error);
      message.error("Error updating profile details");
    };
    const successCallback = (data) => {
      if (isProfilePhotoUpdated) {
        onProfilePhotoUpdate(profilePhoto);
      } else {
        fetchUserHubs && fetchUserHubs();
        setIsUpdatingProfile(false);
        setAreButtonsDisabled(true);
        fetchData();
        successCallBack();
      }
      setIsUpdatingData(false);
      console.debug("onSubmit:", data);
      message.success("Profile details updated successfully");
      currentUserExist
        ? navigate("/" + "?" + createSearchParams())
        : userHubCall
        ? onActionClick(null, null, openSubMenu, true)()
        : changeTabKey("schedule");
    };

    isProfilePhotoUpdated && !isUpdatingData
      ? onProfilePhotoUpdate(profilePhoto)
      : form
          .validateFields([
            "firstName",
            "lastName",
            "postalCode",
            "timeZone",
            "units",
            "staffRole",
            "locale",
            "is24hoursformat",
            "autoacceptcall",
          ])
          .then((values) => {
            try {
              setIsUpdatingProfile(true);
              const user = {
                useruuid: useruuid,
                firstname: values.firstName.trim(),
                lastname: values.lastName.trim(),
                postalcode: values.postalCode,
                timezone: values.timeZone,
                measurement: values.units,
                staffrole: values.staffRole,
                locale: values.locale,
                is24hoursformat: isTimeFormatEnabled ? true : false,
                autoacceptcall: isAutoCallAnswerEnabled ? true : false,
              };
              const allowList = [];
              const revokeList = [];

              if (allow) {
                if (accountPrincipal) allowList.push("Principal");
                if (manage) allowList.push("Manage");
                if (care) allowList.push("Care");
                if (social) allowList.push("Social");
              } else {
                revokeList.push("Principal", "Manage", "Care", "Social");
              }

              // Check individual permissions when `allow` is true
              if (allow) {
                if (!accountPrincipal) revokeList.push("Principal");
                if (!manage) revokeList.push("Manage");
                if (!care) revokeList.push("Care");
                if (!social) revokeList.push("Social");
              }
              const payload = {
                ...user,
                allow: allowList,
                revoke: revokeList,
              };

              userApi.updateUser(
                payload,
                apiCallback({
                  translator: trans,
                  failCallback: errorCallback,
                  errorCallback,
                  successCallback,
                })
              );
            } catch (error) {
              errorCallback(error);
            }
          })
          .catch((errorInfo) => {
            // Validation failed, display an error message
            message.error("Please fill in all required fields.");
          });
  };

  const handleUploadChange = (info) => {
    if (info.file.status === "uploading") {
      //setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        //setLoading(false);
        setIsProfilePhotoUpdated(true);
        setAreButtonsDisabled(false);
        setProfilePhoto(info.file.originFileObj);
        setProfilePhotoUrl(url);
      });
    }
  };

  const onCancelHandler = () => {
    form.resetFields();
    setProfilePhoto(userInfo.photo);
    setProfilePhotoUrl(photoUrl);
    setAreButtonsDisabled(true);
    currentUserExist
      ? navigate("/" + "?" + createSearchParams())
      : userHubCall
      ? onActionClick(null, null, openSubMenu, true)()
      : changeTabKey("schedule");
  };

  const onChangePasswordHandler = () => {
    updatePassword();
  };

  const renderHeaderSection = () => {
    return (
      <div className="edit-profile-header">
        <div className="edit-profile-title-container">
        <NewGearIcon
          className="edit-icon"
          style={{ width: "36px", height: "36px", fill: "#f5f5f5" }}
        />
        <p>{trans("EDITPROFILE")}</p>
      </div>
      </div>
    );
  };

  const renderProfileAvatar = () => {
    return (
      <Upload
        onChange={handleUploadChange}
        showUploadList={false}
        customRequest={({ file, onSuccess }) => {
          setTimeout(() => {
            onSuccess("ok");
          }, 0);
        }}
        accept=".png,.jpg,.jpeg,.gif,.jfif,.webp,.svg,.tiff"
      >
        {profilePhotourl ? (
          <div className="profile-avatar-container">
            <img
              className={`profile-avatar ${profilePhotourl ? 'profile-avatar-border' : ''}`}
              src={profilePhotourl}
              alt="Avatar"
            />
            <img className="profile-pencil" src={Pencil} alt="Edit" />
          </div>
        ) : (
          <div className="emptyprofile">
            <div className="empty-profile-container">
              <PlusOutlined />
            </div>
            <img className="profile-pencil" src={Pencil} alt="Edit" />
          </div>
        )}
      </Upload>
    );
  };

  const onFormChangeHandler = () => {
    const currentValues = form.getFieldsValue();
  
    // Trim the current values for firstName and lastName to ignore leading/trailing spaces
    const trimmedFirstName = currentValues.firstName ? currentValues.firstName.trim() : "";
    const trimmedLastName = currentValues.lastName ? currentValues.lastName.trim() : "";
  
    // Check if firstName or lastName is empty after trimming spaces
    const isFirstNameEmpty = trimmedFirstName === "";
    const isLastNameEmpty = trimmedLastName === "";
  
    const isFirstNameChanged = hasSignificantChange(currentValues.firstName, formData.firstName);
    const isLastNameChanged = hasSignificantChange(currentValues.lastName, formData.lastName);
  
    // Check for changes in other fields
    const isOtherFieldsChanged = Object.keys(currentValues).some((key) => {
      if (key === 'firstName' || key === 'lastName') {
        return false; // Skip firstName and lastName 
      }
      return currentValues[key] !== formData[key];
    });
  
    const timeZone = formRef.current
      ? formRef.current.getFieldValue("timeZone")
      : "";
    const locale = formRef.current
      ? formRef.current.getFieldValue("locale")
      : "";
    const isAccountPrincipalChanged =
      accountPrincipal !== initialAccountPrincipal;
    const isManageChanged = manage !== initialManage;
    const isCareChanged = care !== initialCare;
    const isSocialChanged = social !== initialSocial;
    const isAllowChanged = allow !== isAllow;
    const is24HourFormatChanged =
      isTimeFormatEnabled !== userInfo?.is24hoursformat;
    const isAutoCallAnswerEnabledChanged =
      isAutoCallAnswerEnabled !== userInfo?.autoacceptcall;

    setAreButtonsDisabled(
      !(
        (isFirstNameChanged && !isFirstNameEmpty) ||
        (isLastNameChanged && !isLastNameEmpty) ||
        isOtherFieldsChanged ||
        isAccountPrincipalChanged ||
        isManageChanged ||
        isCareChanged ||
        isSocialChanged ||
        is24HourFormatChanged ||
        isAllowChanged ||
        isAutoCallAnswerEnabledChanged
      ) || !(timeZone && locale)
    );
    setIsUpdatingData(true);
  };
   
  const hasSignificantChange = (currentValue, initialValue) => {
    // Trim leading and trailing spaces from both values
    const trimmedCurrentValue = (currentValue ?? "").trim();
    const trimmedInitialValue = (initialValue ?? "").trim();
    
    return trimmedCurrentValue !== trimmedInitialValue;
  };
  
  const isStaff = (user) => {
    return user?.usertype?.toLowerCase() === "staff";
  };

  const getTimezoneOptions = () => {
    const localeVal =
      formRef.current && formRef.current.getFieldValue("locale")
        ? formRef.current.getFieldValue("locale")
        : locale;
    const timezones =
      providerData?.supportedtimezones.find(
        (timezone) => timezone[localeVal]
      ) ?? {};
    return (
      timezones[localeVal]?.map((timeZone) => ({
        label: timeZone,
        value: timeZone,
      })) ?? []
    );
  };

  const isStaffRolePresent = isStaff(userInfo);

  const renderProfileDetails = () => {
    return (
      <Form
        form={form}
        initialValues={formData}
        onChange={onFormChangeHandler}
        colon={false}
        ref={formRef}
        onValuesChange={handleValuesChange}
      >
        <div
          className="account-form-row"
          style={{ marginBottom: "120px", marginTop: "25px" }}
        >
          <div className="account-form-group account-form-row">
            {renderProfileAvatar()}
          </div>
          <div className="account-form-group account-form-row">
            <Form.Item label={trans("FIRSTNAME")} name={"firstName"}>
              <Input placeholder="First Name" />
            </Form.Item>
          </div>
          <div className="account-form-group account-form-row">
            <Form.Item label={trans("LASTNAME")} name={"lastName"}>
              <Input placeholder="Last Name" />
            </Form.Item>
          </div>
        </div>
        <div className="account-form-row">
          <div className="account-form-group">
            <Form.Item label={trans("LOCALE/LANGUAGE")} name={"locale"}>
              <Select
                className="form-item-dropdown"
                onSelect={onFormChangeHandler}
                value={defaultLocaleVal}
                placeholder="Locale"
                options={
                  providerData &&
                  providerData.supportedlocales
                    .map((item) => {
                      return {
                        value: item.slice(-6, -1),
                        label: item,
                      };
                    })
                    .sort((a, b) => a.label.localeCompare(b.label))
                }
              />
            </Form.Item>
          </div>
          <div className="account-form-group">
            <Form.Item name="units" label="Measurement Units">
              <Select
                className="form-item-dropdown"
                onSelect={onFormChangeHandler}
                dropdownStyle={{ padding: 1 }}
                options={Units.map((unit) => ({
                  label: `${unit.label}`,
                  value: `${unit.value}`,
                }))}
              />
            </Form.Item>
          </div>
          <div className="account-form-group">
            <Form.Item
              label={
                <span>
                  {trans("POSTALCODE")}
                  <span
                    style={{
                      color: "#868585",
                      fontSize: "14px",
                      fontWeight: 500,
                      marginLeft: 10,
                    }}
                  >
                    ({trans("OPTIONAL")})
                  </span>
                </span>
              }
              name={"postalCode"}
            >
              <Input placeholder="Postal Code" />
            </Form.Item>
          </div>
        </div>
        <div className="account-form-row">
          <div className="account-form-group">
            <Form.Item name="timeZone" label={trans("TIMEZONE")}>
              <Select
                className="form-item-dropdown custom-select"
                onSelect={onFormChangeHandler}
                dropdownStyle={{ padding: 1 }}
                options={getTimezoneOptions()}
              />
            </Form.Item>
          </div>
          <div
            className="account-form-group"
            style={{ alignItems: "flex-start" }}
          >
            <Form.Item label="" name={"is24hoursformat"}>
              <div className="togglebutton">
                <div>
                  <Switch
                    checked={isTimeFormatEnabled}
                    onChange={(checked) => {
                      setIsTimeFormatEnabled(checked);
                      setAreButtonsDisabled(
                        !form.getFieldValue("timeZone") ||
                          (checked === userInfo?.is24hoursformat &&
                            allow === isAllow &&
                            accountPrincipal === initialAccountPrincipal &&
                            manage === initialManage &&
                            care === initialCare &&
                            social === initialSocial &&
                            isAutoCallAnswerEnabled ===
                              userInfo?.autoacceptcall &&
                            Object.keys(formData).every(
                              (key) => form.getFieldValue(key) === formData[key]
                            ))
                      );
                    }}
                  />
                </div>
                <div className="timeformat-text">24 hour format</div>
              </div>
            </Form.Item>
          </div>
          <div className="account-form-group"></div>
        </div>
        {(hubRecord?.rolename !== undefined || showContactingStaff) && (
          <>
            <div style={{ border: "0.5px solid black", width: "80%" }}></div>
            <div className="account-form-row">
              <div
                className="account-form-group"
                style={{ alignItems: "flex-start" }}
              >
                <Form.Item name="staffcontact" label="Contacting Staff">
                  <div className="contactingstaff">
                    <div className="togglelistbutton">
                      <div className="subtogglebuttonprincipal">
                        <Switch
                          checked={allow}
                          onChange={(checked) => {
                            setAllow(checked);
                            setAccountPrincipal(checked);
                            setManage(checked);
                            setCare(checked);
                            setSocial(checked);
                            setAreButtonsDisabled(
                              !form.getFieldValue("timeZone") ||
                                (checked === isAllow &&
                                  Object.keys(formData).every(
                                    (key) =>
                                      form.getFieldValue(key) === formData[key]
                                  ) &&
                                  isTimeFormatEnabled ===
                                    userInfo.is24hoursformat &&
                                  isAutoCallAnswerEnabled ===
                                    userInfo?.autoacceptcall &&
                                  toggleUpdateOnToggleAllow &&
                                  allowedPermissions === (checked ? 4 : 0))
                            ); // Enable update button if accountPrincipal is true, disable otherwise
                          }}
                        />
                      </div>
                      <div>Allow</div>
                    </div>
                    <div className="subtogglelist">
                      <div className="subtogglebutton">
                        <div className="subtogglebuttonprincipal">
                          <Switch
                            checked={accountPrincipal}
                            onChange={(checked) => {
                              setAccountPrincipal(checked);
                              setAreButtonsDisabled(
                                !form.getFieldValue("timeZone") ||
                                  (checked === initialAccountPrincipal &&
                                    manage === initialManage &&
                                    care === initialCare &&
                                    social === initialSocial &&
                                    allow === isAllow &&
                                    Object.keys(formData).every(
                                      (key) =>
                                        form.getFieldValue(key) ===
                                        formData[key]
                                    ) &&
                                    isTimeFormatEnabled ===
                                      userInfo.is24hoursformat &&
                                    isAutoCallAnswerEnabled ===
                                      userInfo?.autoacceptcall)
                              ); // Enable update button if accountPrincipal is true, disable otherwise
                            }}
                            disabled={!allow}
                          />
                        </div>
                        <div>{trans("ACCOUNTPRINCIPAL")}</div>
                      </div>
                      <div className="subtogglebutton">
                        <div className="subtogglebuttonprincipal">
                          <Switch
                            checked={manage}
                            onChange={(checked) => {
                              setManage(checked);
                              setAreButtonsDisabled(
                                !form.getFieldValue("timeZone") ||
                                  (checked === initialManage &&
                                    accountPrincipal ===
                                      initialAccountPrincipal &&
                                    care === initialCare &&
                                    social === initialSocial &&
                                    allow === isAllow &&
                                    Object.keys(formData).every(
                                      (key) =>
                                        form.getFieldValue(key) ===
                                        formData[key]
                                    ) &&
                                    isTimeFormatEnabled ===
                                      userInfo.is24hoursformat &&
                                    isAutoCallAnswerEnabled ===
                                      userInfo?.autoacceptcall)
                              ); // Enable update button if accountPrincipal is true, disable otherwise
                            }}
                            disabled={!allow}
                          />
                        </div>
                        <div>{trans("MANAGE")}</div>
                      </div>
                      <div className="subtogglebutton">
                        <div className="subtogglebuttonprincipal">
                          <Switch
                            checked={care}
                            onChange={(checked) => {
                              setCare(checked);
                              setAreButtonsDisabled(
                                !form.getFieldValue("timeZone") ||
                                  (checked === initialCare &&
                                    accountPrincipal ===
                                      initialAccountPrincipal &&
                                    manage === initialManage &&
                                    social === initialSocial &&
                                    allow === isAllow &&
                                    Object.keys(formData).every(
                                      (key) =>
                                        form.getFieldValue(key) ===
                                        formData[key]
                                    ) &&
                                    isTimeFormatEnabled ===
                                      userInfo.is24hoursformat &&
                                    isAutoCallAnswerEnabled ===
                                      userInfo?.autoacceptcall)
                              ); // Enable update button if accountPrincipal is true, disable otherwise
                            }}
                            disabled={!allow}
                          />
                        </div>
                        <div>{trans("CARE")}</div>
                      </div>
                      <div className="subtogglebutton">
                        <div className="subtogglebuttonprincipal">
                          <Switch
                            checked={social}
                            onChange={(checked) => {
                              setSocial(checked);
                              setAreButtonsDisabled(
                                !form.getFieldValue("timeZone") ||
                                  (checked === initialSocial &&
                                    accountPrincipal ===
                                      initialAccountPrincipal &&
                                    manage === initialManage &&
                                    care === initialCare &&
                                    allow === isAllow &&
                                    Object.keys(formData).every(
                                      (key) =>
                                        form.getFieldValue(key) ===
                                        formData[key]
                                    ) &&
                                    isTimeFormatEnabled ===
                                      userInfo.is24hoursformat &&
                                    isAutoCallAnswerEnabled ===
                                      userInfo?.autoacceptcall)
                              ); // Enable update button if accountPrincipal is true, disable otherwise
                            }}
                            disabled={!allow}
                          />
                        </div>
                        <div>{trans("SOCIAL")}</div>
                      </div>
                    </div>
                  </div>
                </Form.Item>
              </div>
              <div
                className="account-form-group"
                style={{ alignItems: "flex-start", marginLeft: "40px" }}
              >
                <Form.Item
                  label="Auto Answer (Smart Camera)"
                  name={"autoacceptcall"}
                >
                  <div className="togglebutton" style={{ marginTop: "0px" }}>
                    <div className="subtogglebuttonprincipal">
                      <Switch
                        checked={isAutoCallAnswerEnabled}
                        onChange={(checked) => {
                          setIsAutoCallAnswerEnabled(checked);
                          setAreButtonsDisabled(
                            !form.getFieldValue("timeZone") ||
                              (checked === userInfo?.autoacceptcall &&
                                allow === isAllow &&
                                accountPrincipal === initialAccountPrincipal &&
                                manage === initialManage &&
                                care === initialCare &&
                                social === initialSocial &&
                                isTimeFormatEnabled ===
                                  userInfo.is24hoursformat &&
                                Object.keys(formData).every(
                                  (key) =>
                                    form.getFieldValue(key) === formData[key]
                                ))
                          );
                        }}
                      />
                    </div>
                    <div className="timeformat-text">Allow for Care / Manage</div>
                  </div>
                </Form.Item>
              </div>
              <div className="account-form-group"></div>
            </div>
          </>
        )}
        {currentUserExist && (
          <div className="account-form-row">
            {isStaffRolePresent && (
              <div className="account-form-group">
                <Form.Item
                  label={
                    <span>
                      {trans("STAFFROLE")}
                      <span
                        style={{
                          color: "#868585",
                          fontSize: "14px",
                          fontWeight: 500,
                          marginLeft: 10,
                        }}
                      >
                        ({trans("OPTIONAL")})
                      </span>
                    </span>
                  }
                  name={"staffRole"}
                >
                  <Input placeholder="Staff Role" />
                </Form.Item>
              </div>
            )}
            <div
              className={`${
                isStaffRolePresent
                  ? "account-form-group emailcenter"
                  : "account-form-group"
              } `}
            >
              {formData.email && (
                <Form.Item label={trans("EMAIL")} name={"email"}>
                  <span style={{padding:"10px"}}>{formData.email}</span>
                </Form.Item>
              )}
            </div>
            <div className="account-form-group"></div>
          </div>
        )}
      </Form>
    );
  };

  return (
    <MainPage
      header={header}
      hasSider={path.includes("profile") ? true : false}
      buttonSelected="gear"
      noModals={!!openSubMenu}
    >
      <div className="edit-profile-container">
        {fetchingUserInfo ? (
          <Loading message="Fetching User Profile..." />
        ) : isUpdatingProfile ? (
          <Loading message="Updating User Profile..." />
        ) : (
          <>
            {renderHeaderSection()}
            {currentUserExist && (
              <div className="custom-button-container">
              <NewCustomButton
                label={"Change Password"}
                className={"password-button"}
                onClick={onChangePasswordHandler}
              />
              </div>
            )}
            <div className="edit-profile-form-container">
              <div style={{ display: "flex" }}>{renderProfileDetails()}</div>
              <div
                className={`${
                  hubRecord === undefined
                    ? "edit-profile-button-container"
                    : "edit-profile-button-container edit-contact-staff "
                } `}
              >
                <div className="form-button">
                <NewCustomButton
                  className="cancel"
                  onClick={onCancelHandler}
                  label={trans("CANCEL")}
                  icon={<NewCloseIcon className="cancel-icon" />}
                />
                </div>

                <div className="form-button">
                <NewCustomButton
                      type={ButtonTypes.primary}
                      label={trans("UPDATE")}
                      icon={
                        <NewUpdateIcon
                          className={
                            !areButtonsDisabled
                              ? "update-icon-enabled"
                              : "update-icon-disabled"
                          }
                        />
                      }
                      onClick={onUpdateHandler}
                      disabled={areButtonsDisabled}
                    />
                  </div>
              </div>
            </div>
          </>
        )}
      </div>
    </MainPage>
  );
};

EditUserProfile.propTypes = {
  parentBread: PropTypes.arrayOf(PropTypes.object),
  onActionClick: PropTypes.func,
};

export default EditUserProfile;
