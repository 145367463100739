import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";

import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import Card from "./Card";
import SupportCardInfo from "./SupportCardInfo";
import MobileAppCardInfo from "./MobileAppCardInfo";
import AboutCardInfo from "./AboutCardInfo";
import MainPage from "../../MainPage";
import { DashboardIcon } from "../Common/Icons";
import { Link } from "react-router-dom";
import useConfig from "../Hooks/useConfig";

const AboutPage = ({ parentBread }) => {
  const trans = useSelector(translator);
  const header = [
    {
      label: <Link to="/">{trans("DASHBOARD")}</Link>,
      icon: <DashboardIcon />,
    },
    {
      label:  trans("SUPPORT"),
    },
  ];
  const [isDataFetched, setIsDataFethed] = useState(null);
  const [loading, setLoding] = useState(true);
  const [userConfigData, setUserConfigData] = useState(null);
  const serviceProviderApi = useSelector(
    (state) => state.apis.serviceProviderApi
  );
  const providerId = useSelector(getCurrentUser)?.providerid;
  const useruuid = useSelector(getCurrentUser)?.useruuid;
  const [configData, fetchingUserConfig ] = useConfig({ useruuid });


  useEffect(() => {
    fetchProviderData();
  }, []);

  useEffect(() => {
    if(configData){
      setUserConfigData({
        email: configData?.customer_support?.email,
        contact: configData?.customer_support?.phone,
        website : configData?.customer_support?.website
      })
    }
  },[configData,fetchingUserConfig])

  const fetchProviderData = () => {
    const errorCallback = (error) => {
      console.error(error);
    };
    const successCallback = (data) => {
      setIsDataFethed({
        providerPIN: data?.serviceProvider?.providerPIN
      });
      setLoding(false);
    };

    try {
      serviceProviderApi.getServiceproviderById(
        providerId,
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  return (
    <MainPage header={header} hasSider={true} buttonSelected="help">
      <div style={{ display: "flex"}}>
        <Card>
          <SupportCardInfo userConfigData={userConfigData} loading={fetchingUserConfig}/>
        </Card>
        <Card>
          <MobileAppCardInfo isDataFetched={isDataFetched} loading={loading} />
        </Card>
      </div>
      <Card>
        <AboutCardInfo />
      </Card>
    </MainPage>
  );
};

export default AboutPage;
