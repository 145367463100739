import React, { useState } from "react";
import { ContactImage } from "../Common/Icons";

export const UserImage = (props) => {
  const { url, name } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const onLoad = () => setIsLoaded(true);
  const onError = () => setIsLoaded(false);
  return (
    <div className="user-image-container" title={name}>
      {url && (
        <img
          onLoad={onLoad}
          onError={onError}
          className={!isLoaded ? "user-image user-image-hide" : "user-image"}
          src={url}
          alt="avatar"
        ></img>
      )}
      {!isLoaded && <ContactImage className="user-profile-pic" />}
    </div>
  );
};
