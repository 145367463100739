import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Pagination.less";

export const Pagination = ({ deviceCount, onActionClick }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(Math.ceil(deviceCount / limit));

  const onPageChange = (page) => {
    setCurrentPage(page);
    onActionClick(null, null, (page - 1) * limit, limit)();
  };

  const onLimitChange = (newLimit) => {
    setCurrentPage(1);
    setLimit(newLimit);
    setTotalPages(Math.ceil(deviceCount / newLimit));
    onActionClick(null, null, 0, newLimit)();
  };

  const renderPageNumbers = () => {
    console.log("totalPages", totalPages);
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button key={i} className="page" onClick={() => onPageChange(i)}>
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  return (
    <div className="pagination">
      <span
        className="prev"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        &lt;
      </span>
      {renderPageNumbers()}
      <span
        className="next"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        &gt;
      </span>
      <select
        className="pagination-select"
        value={limit}
        onChange={(e) => onLimitChange(parseInt(e.target.value))}
      >
        <option value={10}>10 / page</option>
        <option value={20}>20 / page</option>
        <option value={50}>50 / page</option>
      </select>
    </div>
  );
};

Pagination.propTypes = {
  deviceCount: PropTypes.number,
  limit: PropTypes.number,
  offset: PropTypes.number,
  onActionClick: PropTypes.func,
};
export default Pagination;
