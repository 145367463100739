import React from "react";

export const NewContactsIcon = (props) => {
  const { className = "", disabled = false, onClick, style = {} } = props;
  return (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path d="M21.5637 13.1785C20.9603 12.8141 20.2544 12.5409 19.4916 12.3359C19.5371 12.2904 19.5941 12.2563 19.6396 12.2107C20.5277 11.3227 21.0058 10.1841 21.0058 8.93178C21.0058 7.67941 20.539 6.54089 19.6396 5.65284C18.7516 4.7648 17.6131 4.28662 16.3607 4.28662C15.1083 4.28662 13.9698 4.75341 13.0817 5.65284C12.5694 6.16518 12.2165 6.75721 11.9888 7.40616C12.1595 7.88434 12.262 8.39667 12.262 8.93178C12.262 9.46688 12.1595 9.97921 11.9888 10.4574C12.2165 11.1063 12.5694 11.6984 13.0817 12.2107C13.1273 12.2563 13.1842 12.2904 13.2411 12.3359C12.7971 12.4498 12.3873 12.5978 12.0002 12.7686C12.2848 12.8938 12.5694 13.0191 12.8313 13.1785C13.1956 13.3948 13.503 13.6452 13.7649 13.8843C14.5277 13.6908 15.3929 13.5883 16.3607 13.5883C18.1595 13.5883 19.6055 13.9185 20.7098 14.5788C21.8142 15.2392 22.3607 16.1386 22.3607 17.2543V18.0741H15.2677C15.2677 18.9849 14.5391 19.7135 13.6282 19.7135H22.3607C23.2715 19.7135 24.0001 18.9849 24.0001 18.0741V17.2543C24.0001 16.0589 23.5789 14.3853 21.5523 13.1671L21.5637 13.1785ZM14.2544 6.80275C14.8465 6.21072 15.5523 5.92609 16.3721 5.92609C17.1918 5.92609 17.8977 6.2221 18.4897 6.80275C19.0817 7.39478 19.3664 8.10066 19.3664 8.92039C19.3664 9.74012 19.0704 10.4574 18.4897 11.038C17.8977 11.6301 17.1918 11.9147 16.3721 11.9147C15.5523 11.9147 14.8465 11.6187 14.2544 11.038C13.6624 10.446 13.3778 9.74012 13.3778 8.92039C13.3778 8.10066 13.6738 7.39478 14.2544 6.80275Z" fill="#113E57"/>
    <path d="M13.7761 13.8843C13.5028 13.6339 13.2068 13.3948 12.8425 13.1785C12.5806 13.0191 12.296 12.8938 12.0114 12.7686C11.6243 12.5978 11.203 12.4498 10.7704 12.3359C10.8159 12.2904 10.8729 12.2563 10.9184 12.2107C11.4307 11.6984 11.7837 11.1063 12.0114 10.4574C12.1821 9.97921 12.2846 9.46688 12.2846 8.93178C12.2846 8.39667 12.1821 7.88434 12.0114 7.40616C11.7837 6.75721 11.4307 6.16518 10.9184 5.65284C10.0303 4.7648 8.89183 4.28662 7.63946 4.28662C6.38709 4.28662 5.24857 4.75341 4.36053 5.64146C3.47248 6.5295 2.9943 7.66802 2.9943 8.92039C2.9943 10.1728 3.4611 11.3113 4.36053 12.1993C4.40607 12.2449 4.46299 12.279 4.51992 12.3246C3.75711 12.5295 3.05123 12.8027 2.44781 13.1671C0.432637 14.3853 0 16.0589 0 17.2543V18.0741C0 18.9849 0.728652 19.7135 1.63947 19.7135H13.6395C14.5503 19.7135 15.2789 18.9849 15.2789 18.0741V17.2543C15.2789 16.2752 14.9829 14.9887 13.7761 13.8729V13.8843ZM5.52181 6.80275C6.11384 6.21072 6.81973 5.92609 7.63946 5.92609C8.45919 5.92609 9.16507 6.2221 9.7571 6.80275C10.3491 7.39478 10.6338 8.10066 10.6338 8.92039C10.6338 9.74012 10.3377 10.4574 9.7571 11.038C9.16507 11.6301 8.45919 11.9147 7.63946 11.9147C6.81973 11.9147 6.11384 11.6187 5.52181 11.038C4.92978 10.446 4.64516 9.74012 4.64516 8.92039C4.64516 8.10066 4.94117 7.39478 5.52181 6.80275ZM13.6395 18.0855H1.63947V17.2657C1.63947 16.9811 1.67362 16.7192 1.74193 16.4688C1.87856 15.9678 2.1518 15.5124 2.56167 15.1253C2.7666 14.9318 3.00569 14.7496 3.27893 14.5902C4.3833 13.9299 5.82921 13.5997 7.62807 13.5997C8.59581 13.5997 9.46109 13.7022 10.2239 13.8957C10.8729 14.0551 11.4649 14.2828 11.9772 14.5902C11.9772 14.5902 11.9772 14.5902 11.9886 14.6016C12.7969 15.0912 13.3093 15.7173 13.5142 16.4688C13.5825 16.7192 13.6167 16.9925 13.6167 17.2657V18.0855H13.6395Z" fill="#113E57"/>
   </svg>
  );
};