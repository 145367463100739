import React from "react";

export const MicOffIcon = (props) => {
    const { className = "", style = {}, disabled = false, onClick } = props;
    return (
        <svg
            className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
            onClick={disabled ? null: onClick}
            width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
            style={style}
        >
            <path className="stroke" d="M5.97884 2.16667C6.38228 1.46923 7.13635 1 7.99999 1C9.28869 1 10.3333 2.04467 10.3333 3.33333V6.44444" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M12.6666 8C12.6666 8.26499 12.6445 8.52484 12.6021 8.77778" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M1 1L15 15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M3.33333 8C3.33333 10.5773 5.42266 12.6667 7.99999 12.6667C8.26498 12.6667 8.52484 12.6446 8.77777 12.6021" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M8 12.6666V15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M10.3333 15H8H5.66667" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}