import React from "react";

export const PersonIcon = (props) => {
  const { className = "", style = {} } = props;
  return (
    <svg
      className={className}
      style={style}
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8469 10.1953H6.31354C5.0617 10.1953 4.04688 11.2102 4.04688 12.462V13.5953H8.58021H13.1135V12.462C13.1135 11.2102 12.0987 10.1953 10.8469 10.1953Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.58112 7.93177C9.83294 7.93177 10.8478 6.91695 10.8478 5.6651C10.8478 4.41326 9.83294 3.39844 8.58112 3.39844C7.32927 3.39844 6.31445 4.41326 6.31445 5.6651C6.31445 6.91695 7.32927 7.93177 8.58112 7.93177Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
