import { NotificationTypes } from "@commscopemycloud/humaui/Utilities/Constants";
import moment from "moment";

const ONGOING_MEETING_DISPLAY_TIME_HOURS = 4;

export const processScheduleNotification = (scheduleList) => {
  let now = moment();
  const filteredList = scheduleList.filter((event) => {
    let time = moment(event.display_event_time, "HH:mm:ss");
    let tillTime = moment().subtract(
      ONGOING_MEETING_DISPLAY_TIME_HOURS,
      "hour"
    );
    return (
      "display_event_date" in event &&
      event.display_event_date === now.format("YYYY-MM-DD") &&
      time.isAfter(tillTime)
    );
  });

  let processedList = [];
  filteredList.forEach((event) => {
    let eventInfo = {
      key: event.schedule_eventuuid,
      type: NotificationTypes.scheduledEvent,
      datetime: "Today, ",
      description_row_1: "Event reminder",
      description_row_2: event.event_name,
      status: null,
      display_event_time: event.display_event_time,
      otherUserInfo: event,
    };
    if ("display_event_time" in event) {
      let dateTime = moment(event.display_event_time, "HH:mm:ss");
      eventInfo.datetime = eventInfo.datetime + dateTime.format("h:mm A");
    } else if ("event_timestamp" in event) {
      let dateTime = moment(+event.event_timestamp);
      eventInfo.datetime = eventInfo.datetime + dateTime.format("h:mm A");
    } else {
      let time = moment(event.event_time, "HH:mm");
      time.add(time.utcOffset(), "minutes");
      eventInfo.datetime = eventInfo.datetime + time.format("h:mm A");
    }
    processedList.push(eventInfo);
  });
  return processedList;
};
