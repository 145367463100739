import { store } from "@commscopemycloud/humaui/Store/Store";
import {
  closeVideoCallNotificationModal,
  videoCallNotificationReceived,
} from "@commscopemycloud/humaui/Store/notificationStore";
import {
  resetState,
  startVideoCall,
  updateInvites,
} from "@commscopemycloud/humaui/Store/videoCallStore";
import {
  VideoCallStatus,
  VideoCallStatusUpdate,
} from "@commscopemycloud/humaui/Utilities/Constants";
import { showMissedCallNotification } from "./DesktopNotificationHelper";
import desktopNotification from "../Notifications/DesktopNotification";

/** video call status update message */
const CallStatusMessage = {
  source: "cloud",
  ack_required: false,
  message_type: "call_status_update",
  call_status_update: {
    meeting_id: "",
    useruuid: "",
    status: "",
  },
};

const isValid = (meeting_id) => {
  const videoCallState = store.getState()?.videoCall || {};
  if (!videoCallState.meetingInfo) {
    console.warn("Status Update Error: No ongoing Meeting!");
    return false;
  }
  if (meeting_id !== videoCallState.meetingInfo.meeting_id) {
    console.warn(
      `Status Update Error: Received Meeting Id ${meeting_id} not matching with ongoing Meeting Id ${videoCallState.meeting_id}:`
    );
    return false;
  }
  return true;
};

const handleCloseVideoCallNotificationModal = ({ meeting_id, dispatch }) => {
  desktopNotification.closeNotification(meeting_id);
  dispatch(closeVideoCallNotificationModal());
};

/** video call status update related handler */
const handleCallStatusUpdate = ({ sendMessage, dispatch, message }) => {
  const state = store.getState();
  const { useruuid: currentUseruuid } = state?.auth?.currentUser || {};
  const { meeting_id, useruuid, status, callee_useruuid } = message;
  /** user receives it's own status looped back */
  if (useruuid === currentUseruuid) {
    console.debug("Received status update sent by the current user itself");
    if (status === VideoCallStatusUpdate.missed) {
      handleVideoCallMissedAcknowledged({ dispatch, message });
    }
  }
  if (status === VideoCallStatusUpdate.leaving) {
    handleStatusVideoCallEnd({ dispatch, message });
  }
  if (status === VideoCallStatusUpdate.busy) {
    handleVideoCalleeBusy({ sendMessage, meeting_id, useruuid });
  }
  if (status === VideoCallStatusUpdate.call_ack) {
    handleVideoCallAcknowledged({ dispatch, message });
  } else {
    /** status update should be for same meeting which is currently going on AND
     *  same useruuid and callee indicates message sent by current user itself, to be ignored */
    if (isValid(meeting_id) && callee_useruuid !== useruuid) {
      !!VideoCallStatus[status] &&
        dispatch(
          updateInvites({
            list: [{ useruuid }],
            status: VideoCallStatus[status],
            // insert: false,
          })
        );
    } else {
      console.info("Status update ignored:", useruuid, status);
    }
  }
};

const handleStatusVideoCallEnd = ({ dispatch, message }) => {
  const notificationState = store.getState().notification;
  const notification = notificationState.videoCallNotification || {};
  if (!notification) return;
  const { useruuid, meeting_id } = notification;
  if (meeting_id === message.meeting_id && useruuid === message.useruuid) {
    console.debug("Caller ended call before pickup!");
    handleCloseVideoCallNotificationModal({ meeting_id, dispatch });
  }
};

const handleVideoCallAcknowledged = ({ dispatch, message }) => {
  const notificationState = store.getState().notification;
  const notification = notificationState.videoCallNotification || {};
  if (!notification) return;
  const state = store.getState();
  const { meeting_id } = notification;
  const { useruuid } = state?.auth?.currentUser || {};
  if (meeting_id === message.meeting_id && useruuid === message.useruuid) {
    console.info("Call accepted by user on other device/portal!");
    handleCloseVideoCallNotificationModal({ meeting_id, dispatch });
  }
};

const handleVideoCallMissedAcknowledged = ({ dispatch, message }) => {
  const notificationState = store.getState().notification;
  const { meeting_id: statusMeetingId } = message;
  const { videoCallNotification, videoCallNotificationModalVisible } =
    notificationState ?? {};
  const { useruuid: callerUseruuid, meeting_id: notificationMeetingId } =
    videoCallNotification ?? {};
  if (
    videoCallNotification &&
    videoCallNotificationModalVisible &&
    statusMeetingId === notificationMeetingId
  ) {
    console.info("Missed call received for incoming call");
    handleCloseVideoCallNotificationModal({
      meeting_id: notificationMeetingId,
      dispatch,
    });
    /* show missed call desktop notification */
    showMissedCallNotification({
      meeting_id: notificationMeetingId,
      useruuid: callerUseruuid,
    });
  } else {
    console.info("Missed call received after notification timeout, ignored!");
  }
};

/** video call notification modal related handlers */
const handleVideoCallReceive = ({ dispatch, message, sendMessage }) => {
  const { contact_id, accept_action } = message || {};
  const meeting_id = accept_action?.huddle_meeting_id;
  const notification = { meeting_id, useruuid: contact_id };
  dispatch(videoCallNotificationReceived(notification));
  sendStatusUpdate({
    sendMessage,
    meeting_id,
    status: VideoCallStatusUpdate.ringing,
  });
};

const handleVideoCallBusy = ({ sendMessage, message }) => {
  const { accept_action } = message || {};
  const meeting_id = accept_action?.huddle_meeting_id;
  sendStatusUpdate({
    sendMessage,
    meeting_id,
    status: VideoCallStatusUpdate.busy,
  });
};

const handleVideoCalleeBusy = ({ sendMessage, meeting_id, useruuid }) => {
  sendStatusUpdate({
    sendMessage,
    meeting_id,
    useruuid,
    status: VideoCallStatusUpdate.missed,
    call_status_params: { callee_useruuid: useruuid },
  });
};

const handleVideoCallAccept = ({ sendMessage, meeting_id, dispatch }) => {
  handleCloseVideoCallNotificationModal({ meeting_id, dispatch });
  dispatch(startVideoCall({ meeting_id }));
  sendStatusUpdate({
    sendMessage,
    meeting_id,
    status: VideoCallStatusUpdate.accepted,
  });
};

const handleVideoCallReject = ({ sendMessage, meeting_id, dispatch }) => {
  handleCloseVideoCallNotificationModal({ meeting_id, dispatch });
  sendStatusUpdate({
    sendMessage,
    meeting_id,
    status: VideoCallStatusUpdate.declined,
  });
  sendStatusUpdate({
    sendMessage,
    meeting_id,
    status: VideoCallStatusUpdate.available,
  });
};

const handleVideoCallTimeout = ({ sendMessage, meeting_id, dispatch }) => {
  handleCloseVideoCallNotificationModal({ meeting_id, dispatch });
  sendStatusUpdate({
    sendMessage,
    meeting_id,
    status: VideoCallStatusUpdate.missed,
  });
  sendStatusUpdate({
    sendMessage,
    meeting_id,
    status: VideoCallStatusUpdate.available,
  });
};

/* ===== video call related handlers ===== */
/* for sending video call status that requires callee_useruuid */
const sendVideoCallStatus = ({
  sendMessage,
  meeting_id,
  userList = [],
  status = VideoCallStatusUpdate.calling,
}) => {
  console.debug(`Sending ${status} status for list:`, userList);
  userList.forEach((callee) => {
    sendStatusUpdate({
      sendMessage,
      meeting_id,
      status,
      call_status_params: { callee_useruuid: callee.useruuid },
    });
  });
};

const handleVideoCallJoin = ({ sendMessage, meeting_id }) => {
  sendStatusUpdate({
    sendMessage,
    meeting_id,
    status: VideoCallStatusUpdate.joining,
  });
};

const handleVideoCallMissed = ({ sendMessage, meeting_id, useruuid }) => {
  sendStatusUpdate({
    sendMessage,
    meeting_id,
    useruuid,
    status: VideoCallStatusUpdate.missed,
    call_status_params: { callee_useruuid: useruuid },
  });
};

const handleVideoCallLeave = ({ sendMessage, meeting_id }) => {
  sendStatusUpdate({
    sendMessage,
    meeting_id,
    status: VideoCallStatusUpdate.leaving,
  });
};

const handleVideoCallAvail = ({ sendMessage, meeting_id }) => {
  sendStatusUpdate({
    sendMessage,
    meeting_id,
    status: VideoCallStatusUpdate.available,
  });
};

const handleVideoCallClose = ({ dispatch }) => {
  dispatch(resetState());
};

const handleVideoCallEnd = ({
  sendMessage,
  meeting_id,
  dispatch,
  missed = false,
  missedInviteeList,
}) => {
  dispatch(resetState());
  if (missed) {
    for (let invitee of missedInviteeList) {
      const { useruuid } = invitee;
      sendStatusUpdate({
        sendMessage,
        meeting_id,
        useruuid,
        status: VideoCallStatusUpdate.missed,
        call_status_params: { callee_useruuid: useruuid },
      });
    }
  }
  sendStatusUpdate({
    sendMessage,
    meeting_id,
    status: VideoCallStatusUpdate.leaving,
  });
  sendStatusUpdate({
    sendMessage,
    meeting_id,
    status: VideoCallStatusUpdate.available,
  });
};

/** send video call status update */
const sendStatusUpdate = ({
  sendMessage,
  meeting_id,
  status,
  useruuid,
  call_status_params = {},
}) => {
  if (!sendMessage) return;
  const state = store.getState();
  const { useruuid: currentUseruuid } = state?.auth?.currentUser || {};
  const message = JSON.parse(JSON.stringify(CallStatusMessage));
  message.call_status_update.meeting_id = meeting_id;
  message.call_status_update.useruuid = useruuid ?? currentUseruuid;
  message.call_status_update.status = status;
  Object.keys(call_status_params).forEach(
    (key) => (message.call_status_update[key] = call_status_params[key])
  );
  sendMessage(message);
};

export {
  handleCallStatusUpdate,
  handleVideoCallReceive,
  handleVideoCallBusy,
  handleVideoCallAccept,
  handleVideoCallReject,
  handleVideoCallTimeout,
  sendVideoCallStatus,
  handleVideoCallJoin,
  handleVideoCallMissed,
  handleVideoCallLeave,
  handleVideoCallAvail,
  handleVideoCallClose,
  handleVideoCallEnd,
  sendStatusUpdate,
};
