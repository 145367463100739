import { AppStorageKeys } from "../Utilities/Constants";
import SessionStorage from "../Utilities/SessionStorage";

const ENV_VARS = SessionStorage.get(AppStorageKeys.envVars);

export const formatKeycloakUserInfo = (keycloak) => {
  const { realm, token, tokenParsed } = keycloak;
  const {
    useruuid,
    preferred_username,
    name: uname,
    given_name,
    family_name,
    clientId = ENV_VARS.CLIENT_ID,
    resource_access,
    providerid,
    provider_display_name,
    permission_level,
    usertype,
  } = tokenParsed;

  const name = uname
    ? uname
    : given_name || family_name
    ? given_name + " " + family_name
    : preferred_username;
  const roles = resource_access[clientId]?.roles;

  return {
    realm,
    clientid: clientId,
    username: preferred_username,
    useruuid,
    token,
    roles,
    name,
    providerid,
    provider_display_name,
    permission_level,
    usertype,
  };
};

export const formatUserInfo = (user = {}) => {
  let obj = { ...user };
  const { resources = [] } = obj;
  let profilePics = {};
  Array.isArray(resources) &&
    resources?.forEach((res) => {
      const { resolution, url } = res;
      profilePics[resolution] = url;
    });
  obj.profilePics = profilePics;
  return obj;
};
