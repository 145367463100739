import React from "react";

export const MissedCallIcon = (props) => {
    const { className="", style={}, onClick, disabled=false } = props;
    return (
        <svg
            className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
            onClick={disabled ? null: onClick}
            style={style}
            xmlns="http://www.w3.org/2000/svg" width="19" height="14" viewBox="0 0 19 14"
        >
            <path className="stroke" d="M12.6929 1.55115L9.84919 4.69897L6.3089 1.55115"  strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M6.17958 4.33219L5.78821 1H8.96585"  strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M17.7969 11.2713L16.9154 12.2143C16.1362 13.0478 15.0842 13.0881 14.0268 12.4255C14.0196 12.4217 14.0142 12.4159 14.007 12.4102C13.0735 11.761 12.3751 11.3231 12.3751 11.3231L12.3518 9.48707C12.3518 9.48707 11.122 9.02421 9.45961 9.09527C7.79718 9.16633 6.63384 9.62919 6.63384 9.62919L6.59614 11.2924L5.10606 12.2623C5.10606 12.2623 5.09529 12.2757 5.08811 12.2796C3.95888 13.0689 2.98224 13.0497 2.19053 12.2008L1.20312 11.1445C1.20312 11.1445 3.12766 6.61389 9.89047 6.61389C15.8221 6.61389 17.7969 11.2694 17.7969 11.2694V11.2713Z"  strokeLinecap="round" strokeLinejoin="round"/>
       </svg>
    );
}


