import React from "react";

export const SearchIcon = (props) => {
    const { className = "", style={}, onClick, disabled=false } = props;
    return (
        <svg
            className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
            onClick={disabled ? null: onClick}
            xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none"
            style={style}
        >
            <path className="fill" d="M13.4058 3.02973C7.66774 3.02973 3 7.69747 3 13.4355C3 19.1736 7.66774 23.8413 13.4058 23.8413C15.1599 23.8413 16.8843 23.4251 18.3411 22.6224C18.4578 22.7628 18.5872 22.8922 18.7276 23.0089L21.7007 25.9819C21.9752 26.2908 22.31 26.5404 22.6844 26.7152C23.0589 26.8901 23.4651 26.9865 23.8782 26.9987C24.2913 27.0108 24.7025 26.9384 25.0866 26.7859C25.4707 26.6334 25.8196 26.404 26.1118 26.1118C26.404 25.8196 26.6334 25.4707 26.7859 25.0866C26.9384 24.7025 27.0108 24.2913 26.9987 23.8782C26.9865 23.4651 26.8901 23.0589 26.7152 22.6844C26.5404 22.31 26.2908 21.9752 25.9819 21.7007L23.0089 18.7276C22.864 18.5827 22.7046 18.4532 22.5332 18.3411C23.3359 16.8843 23.8413 15.1896 23.8413 13.4058C23.8413 7.66774 19.1736 3 13.4355 3L13.4058 3.02973ZM13.4058 6.00281C17.5384 6.00281 20.8385 9.30294 20.8385 13.4355C20.8385 15.3978 20.125 17.2113 18.8763 18.5492C18.8465 18.579 18.8168 18.6087 18.7871 18.6384C18.6466 18.7551 18.5173 18.8845 18.4006 19.0249C17.0924 20.2142 15.3086 20.898 13.3761 20.898C9.24348 20.898 5.94335 17.5978 5.94335 13.4653C5.94335 9.33267 9.24348 6.03255 13.3761 6.03255L13.4058 6.00281Z"/>
        </svg>
    );
}


