/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import MainPage from "../../MainPage";
import { Input, Spin, message } from "antd";
import { CheckIcon, CloseIcon, ContactImage } from "../Common/Icons";
import {
  PhotoResolution,
  RoleType,
} from "@commscopemycloud/humaui/Utilities/Constants";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import {
  getName,
  getUsername,
  objectSort,
} from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import useStaffList from "../Hooks/useStaffList";
import ContactSelectList from "../Common/ContactSelectList";
import CustomButton, { ButtonTypes } from "../Common/CustomButton";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import Roles from "../Roles/Roles";
import { useCallback } from "react";

const RequiredFields = {};

const DefaultValues = {
  sourcerelationship: "",
  targetrelationship: "",
};

const AssignStaff = (props) => {
  console.debug("AssignStaff props", props);
  const { parentBread, record, onActionClick, selectedUser = "" } = props;

  const [role, setRole] = useState("");
  const trans = useSelector(translator);
  const currentUser = useSelector(getCurrentUser);
  const userApi = useSelector((state) => state.apis.userApi);
  const [staffList, fetchingStaffList] = useStaffList({
    useruuid: currentUser?.useruuid,
  });

  const header = (parentBread || []).concat({ label: trans("ASSIGNSTAFF") });

  const [isValid, setIsValid] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [formData, setFormData] = useState(DefaultValues);
  const [selectedStaff, setSelectedStaff] = useState(null);

  const remainStaffList = useMemo(
    () =>
      selectedStaff
        ? staffList.filter((a) => a.useruuid !== selectedStaff.useruuid)
        : staffList ?? [],
    [selectedStaff, staffList]
  );

  const mOrderedStaffList = useMemo(() => {
    return [...remainStaffList].sort(objectSort("firstname"));
  }, [remainStaffList]);

  useEffect(() => {
    validate(false);
  }, [selectedStaff, formData]);

  const handleAddStaff = (staff) => {
    setSelectedStaff(staff);
  };
  const handleRemoveStaff = (staff) => {
    setSelectedStaff(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e?.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = (showError = true) => {
    const values = formData;
    let valid = true;
    let error = null;
    let erroredFields = [];
    if (!selectedStaff) {
      error = "Please select Staff to assign. ";
      valid = false;
    }
    for (let field of Object.keys(RequiredFields)) {
      if (!values.hasOwnProperty(field) || !!!values[field]) {
        erroredFields.push(RequiredFields[field]);
        valid = false;
      }
    }
    if (erroredFields.length) {
      error =
        `${error ?? ""}Please fill required fields: ` +
        erroredFields.join(", ");
    }
    if (showError && !valid) {
      message.error(error, 10);
    }
    setIsValid(valid);
    return valid;
  };

  const handleCancel = () => {
    setSelectedStaff(null);
    setFormData(DefaultValues);
    onActionClick(null, null, true)();
  };

  const handleSubmit = () => {
    const errorCallback = (error) => {
      setProcessing(false);
      const msg = `Error assigning staff`;
      console.error(msg, error);
      message.error(msg);
    };
    const successCallback = (data) => {
      setProcessing(false);
      const msg = `Staff assigned successfully!`;
      console.info(msg, data);
      message.success(msg);
      onActionClick(null, null, false, true)();
    };
    try {
      console.debug("Submit:", formData);
      const valid = validate();
      if (!valid) return;
      setProcessing(true);
      const userObj = {
        ...formData,
        email: selectedStaff.email,
        rolename: role,
      };
      userApi.inviteContactsToUser(
        record?.useruuid,
        userObj,
        apiCallback({
          translator: trans,
          successCallback,
          errorCallback,
          failCallback: errorCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  const handleRoleSelection = useCallback((role) => {
    setRole(role);
  }, []);

  const renderFormItem = ({
    name,
    label,
    disabled,
    placeholder,
    className,
  }) => (
    <div className={`form-item form-item-verticle ${className ?? ""}`}>
      <label>{label}</label>
      <Input
        name={name}
        placeholder={placeholder}
        value={formData[name]}
        onChange={handleInputChange}
        disabled={disabled}
      />
    </div>
  );

  const renderRole = ({ label, className }) => {
    return (
      <div className={`form-item form-item-verticle ${className ?? ""}`}>
        <label>{label}</label>
        <Roles type={RoleType.user} onHandleSelection={handleRoleSelection} />
      </div>
    );
  };

  // const renderButtonLabel = (btnText, desc) => {
  //   return (
  //     <div className="button-label">
  //       <Button>{btnText}</Button>
  //       <p>{desc}</p>
  //     </div>
  //   );
  // };

  // const renderPermissions = (isAllowed, permissions) => {
  //   return (
  //     <div className="permissions">
  //       {permissions.map((data) => {
  //         return (
  //           <div className="permissions-item">
  //             <CheckIcon />
  //             <p>{data}</p>
  //           </div>
  //         );
  //       })}
  //     </div>
  //   );
  // };

  return (
    <MainPage header={header} hasSider={false} noModals={true}>
      <div className="asignstaff-form-container page-content-wrapper">
        <Spin
          spinning={processing}
          wrapperClassName="asignstaff-form custom-form"
        >
          {/* <div className="form-group-1">
            
            </div> */}
          <div className="form-group-2">
            <ContactSelectList
              assignStaffAction = {true}
              fetchingContacts={fetchingStaffList}
              selectedContactList={selectedStaff && [selectedStaff]}
              selectedListHeader={`${trans("INCLUDEDSTAFF")} (${
                selectedStaff ? "1" : "0"
              })`}
              selectionListHeader={trans("SELECTASTAFFMEMBER")}
              selectionContactList={mOrderedStaffList}
              showAddIcon={!selectedStaff}
              onAddClick={handleAddStaff}
              onRemoveClick={handleRemoveStaff}
              selectedUser = {selectedUser}
            />
            <div className="flex-column" style={{ flex: "1" }}>
              <div className="hub-info-container">
                <label className="label">{trans("ACCOUNTPRINCIPAL")}</label>
                <div className="user-info-container">
                  <div className="flex-align-center overflow-hidden">
                    {record.profilePics[PhotoResolution.R64] ? (
                      <img
                        src={record.profilePic64}
                        className="user-profile-pic user-profile-image"
                        alt="pic"
                      />
                    ) : (
                      <ContactImage className="user-profile-pic" />
                    )}
                    <label className="user-name">{getUsername(record)}</label>
                  </div>
                </div>
              </div>
              <div className="form-card">
                <span className="header-text">{trans("ASSIGNTOACCOUNT")}</span>
                <div className="flex-column">
                  {renderFormItem({
                    name: "sourcerelationship",
                    label: trans("ASSIGNSTAFFMEMBERRELATIONSHIP"),
                    // placeholder: "Friend, sister, brother, neighbor, etc.",
                  })}
                  {renderFormItem({
                    name: "targetrelationship",
                    label: trans("ASSIGNSTAFFACCOUNTPRINCIPALRELATIONSHIP"),
                    // placeholder: "Friend, sister, brother, neighbor, etc.",
                    className: "top-margin",
                  })}
                  {renderRole({
                    label: `${trans("ASSIGNSTAFFPERSONROLE")}
                    ${getName(record)}'s ${trans("ECOSYSTEM")}`,
                    className: "top-margin",
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="assign-staff-button-container">
            <CustomButton
              label={trans("CANCEL_U")}
              icon={
                <CloseIcon className="cancel-icon" style={{ height: "12px" }} />
              }
              onClick={handleCancel}
            />
            <CustomButton
              type={ButtonTypes.primary}
              label={trans("SUBMIT_U")}
              icon={<CheckIcon className="submit-icon" />}
              onClick={handleSubmit}
              disabled={!isValid}
            />
          </div>
        </Spin>
      </div>
    </MainPage>
  );
};

AssignStaff.propTypes = {
  parentBread: PropTypes.arrayOf(PropTypes.object),
  record: PropTypes.object,
  onActionClick: PropTypes.func,
};

export default AssignStaff;
