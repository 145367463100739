import React from "react";

export const MediumLightIcon = (props) => {
  const {
    className = "",
    style = {},
    disabled = false,
    onClick,
    fill = null,
  } = props;
  return (
    <svg
      className={className}
      style={style}
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.9525 10.1911C23.0876 8.34664 20.6147 7.33691 17.9932 7.33691C17.9392 7.33691 17.8986 7.33691 17.8446 7.33691C15.2906 7.3773 12.8717 8.38703 11.0475 10.2045C9.20968 12.022 8.15566 14.4184 8.0881 16.9764C8.00702 19.7902 9.1286 22.5097 11.1556 24.4484C12.3177 25.5523 12.9798 26.562 12.9798 28.1237V28.2988C12.9798 28.945 13.4933 29.4566 14.142 29.4566H21.8444C22.4796 29.4566 23.0066 28.9315 23.0066 28.2988V28.1237C23.0066 26.5351 23.6687 25.5658 24.8579 24.408C26.8173 22.5232 27.8983 19.9786 27.8983 17.2457C27.8983 14.5127 26.8443 12.0624 24.9525 10.1776V10.1911ZM23.2633 22.752C21.885 24.0849 20.9931 25.31 20.7634 27.141H15.25C15.0203 25.2965 14.142 24.0714 12.7771 22.7655C11.2231 21.2846 10.3718 19.1978 10.4259 17.0437C10.534 13.0318 13.8987 9.71986 17.8986 9.66601C19.9526 9.63908 21.885 10.4199 23.3309 11.8605C24.7903 13.301 25.5876 15.2262 25.5876 17.2726C25.5876 19.319 24.7633 21.3115 23.2498 22.752H23.2633Z"
        fill={fill ? fill : "#FFA500"}
      />
      <path
        d="M21.0066 30.6553H14.9933C14.3582 30.6553 13.8312 31.1803 13.8312 31.8131C13.8312 32.4458 14.3447 32.9709 14.9933 32.9709H21.0066C21.6418 32.9709 22.1688 32.4458 22.1688 31.8131C22.1688 31.1803 21.6553 30.6553 21.0066 30.6553Z"
        fill={fill ? fill : "#FFA500"}
      />
      <path
        d="M18.0068 35.9996C19.2905 35.9996 20.4256 35.2457 21.1013 34.0879H14.9258C15.5879 35.2457 16.723 35.9996 18.0203 35.9996H18.0068Z"
        fill={fill ? fill : "#FFA500"}
      />
      <path
        d="M18.5743 11.7127C18.1149 11.7127 17.75 12.0762 17.75 12.5339C17.75 12.9917 18.1149 13.3552 18.5743 13.3552C20.4526 13.3552 21.9931 14.8765 21.9931 16.7613C21.9931 17.219 22.358 17.5825 22.8174 17.5825C23.2769 17.5825 23.6417 17.219 23.6417 16.7613C23.6417 13.9745 21.3715 11.6992 18.5608 11.6992L18.5743 11.7127Z"
        fill={fill ? fill : "#FFA500"}
      />
      <path
        d="M32.0323 16.1064H30.9247C30.3169 16.1064 29.8171 16.6094 29.8171 17.2211C29.8171 17.8329 30.3169 18.3359 30.9247 18.3359H32.0323C32.6401 18.3359 33.1399 17.8329 33.1399 17.2211C33.1399 16.6094 32.6401 16.1064 32.0323 16.1064Z"
        fill={fill ? fill : "#FFA500"}
      />
      <path
        d="M5.21515 16.1064H4.10758C3.49976 16.1064 3 16.6094 3 17.2211C3 17.8329 3.49976 18.3359 4.10758 18.3359H5.21515C5.82297 18.3359 6.32273 17.8329 6.32273 17.2211C6.32273 16.6094 5.82297 16.1064 5.21515 16.1064Z"
        fill={fill ? fill : "#FFA500"}
      />
      <path
        d="M27.1303 6.5118L26.3469 7.30025C25.9146 7.73526 25.9146 8.44214 26.3469 8.87715C26.7791 9.31215 27.4815 9.31215 27.9137 8.87715L28.6971 8.0887C29.1293 7.65369 29.1293 6.94681 28.6971 6.5118C28.2649 6.0768 27.5625 6.0768 27.1303 6.5118Z"
        fill={fill ? fill : "#FFA500"}
      />
      <path
        d="M8.17203 25.5919L7.38862 26.3803C6.9564 26.8153 6.9564 27.5222 7.38862 27.9572C7.82085 28.3922 8.52321 28.3922 8.95544 27.9572L9.73884 27.1688C10.1711 26.7338 10.1711 26.0269 9.73884 25.5919C9.30662 25.1569 8.60425 25.1569 8.17203 25.5919Z"
        fill={fill ? fill : "#FFA500"}
      />
      <path
        d="M17.9753 5.34411C18.5831 5.34411 19.0828 4.84113 19.0828 4.22941V3.1147C19.0828 2.50298 18.5831 2 17.9753 2C17.3674 2 16.8677 2.50298 16.8677 3.1147V4.22941C16.8677 4.84113 17.3674 5.34411 17.9753 5.34411Z"
        fill={fill ? fill : "#FFA500"}
      />
      <path
        d="M7.99649 8.7414C8.42872 9.17641 9.13108 9.17641 9.56331 8.7414C9.99553 8.3064 9.99553 7.59951 9.56331 7.16451L8.7799 6.37606C8.34768 5.94105 7.64531 5.94105 7.21308 6.37606C6.78086 6.81107 6.78086 7.51795 7.21308 7.95296L7.99649 8.7414Z"
        fill={fill ? fill : "#FFA500"}
      />
      <path
        d="M27.7382 25.4561C27.3059 25.0211 26.6036 25.0211 26.1713 25.4561C25.7391 25.8911 25.7391 26.598 26.1713 27.033L26.9547 27.8215C27.387 28.2565 28.0893 28.2565 28.5216 27.8215C28.9538 27.3865 28.9538 26.6796 28.5216 26.2446L27.7382 25.4561Z"
        fill={fill ? fill : "#FFA500"}
      />
    </svg>
  );
};
