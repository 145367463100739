import React from "react";

export const CallNewIcon = (props) => {
    const { className = "", style = {}, disabled = false, onClick } = props;
    return (
        <svg  className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
        onClick={disabled ? null: onClick} width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <g clipPath="url(#clip0_9133_82400)">
                <path d="M9.84156 6.52119C10.0543 6.52119 10.3556 6.62754 10.5506 6.78707C11.3128 7.42518 11.9863 10.2258 11.7559 11.2361C11.6495 11.7147 11.1355 12.1755 10.6392 12.6364C10.4619 12.7959 10.3024 12.9555 10.1429 13.115C9.82383 13.434 9.75293 13.9126 9.98336 14.3026C11.5609 17.0854 13.8652 19.3897 16.6481 20.9673C16.8076 21.0559 16.9671 21.0914 17.1266 21.0914C17.3748 21.0914 17.6407 20.985 17.8356 20.8077C17.9952 20.6482 18.1547 20.4887 18.3142 20.3114C18.7751 19.8151 19.2537 19.2834 19.7145 19.1947C19.8563 19.1593 20.0159 19.1593 20.1931 19.1593C21.4339 19.1593 23.6318 19.762 24.1813 20.4178C24.3585 20.6305 24.4472 20.8786 24.4472 21.18V24.3173C24.4472 24.6187 24.3054 24.92 24.0749 25.1327C23.8622 25.3277 23.5964 25.434 23.2773 25.434C23.2419 25.434 23.1887 25.434 23.1532 25.434C18.3851 25.434 13.9361 23.6261 10.6569 20.3469C7.37775 17.0677 5.56977 12.6187 5.56977 7.74423C5.53432 7.4429 5.6584 7.14157 5.8711 6.91114C6.0838 6.68071 6.38513 6.53891 6.68646 6.53891H9.84156C9.84156 6.53891 9.85928 6.53891 9.87701 6.53891M9.84156 3.96875H9.77066H6.65101C5.64067 3.96875 4.66578 4.41188 3.95677 5.17407C3.30094 5.9008 2.96416 6.85797 2.99961 7.81513C3.01733 13.31 5.09119 18.3971 8.84894 22.1371C12.589 25.8772 17.6407 27.951 23.0823 27.9688C23.1532 27.9688 23.2241 27.9688 23.295 27.9688C24.2699 27.9688 25.1385 27.632 25.8297 26.9939C26.0602 26.7812 26.2729 26.533 26.4501 26.2671C26.8224 25.6822 27.0173 25.0086 27.0173 24.3173V21.18C27.0351 20.3292 26.7337 19.4429 26.1488 18.7516C24.784 17.1386 21.5048 16.6069 20.2108 16.6069C19.8386 16.6069 19.5018 16.6423 19.1828 16.7132C18.1902 16.9259 17.4457 17.5463 16.8785 18.1312C15.3009 17.05 13.9184 15.6851 12.8549 14.1076C13.4398 13.5404 14.0602 12.7959 14.2729 11.8033C14.6274 10.1194 13.8475 6.25531 12.2345 4.87274C11.5964 4.32326 10.7101 3.98648 9.85928 3.98648L9.84156 3.96875Z" fill="#113E57" stroke="none" />
            </g>
            <defs>
                <clipPath id="clip0_9133_82400">
                    <rect width="24" height="24" fill="white" transform="translate(3 3.96875)" />
                </clipPath>
            </defs>
        </svg>
    );
}

// Figma: call