import React from "react";

export const CallDeclineIcon = (props) => {
    const { className = "", style={} } = props;
    return (
        <svg className={"custom-icon "+className} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" style={style}>
            <path className="stroke" d="M26.9993 3.00012L19 11M19 3.00012L26.9993 11M26.9993 24.5819V20.969C27.0139 20.3783 26.8106 19.8028 26.4278 19.352C25.3486 18.0808 21.6473 17.2678 20.1801 17.58C19.0365 17.8235 18.2362 18.9718 17.4539 19.7526C14.4329 18.038 11.9314 15.5416 10.2136 12.5264C10.9959 11.7457 12.1465 10.9472 12.3905 9.80572C12.7029 8.3437 11.8908 4.66599 10.6284 3.58245C10.1844 3.20133 9.61662 2.99436 9.031 3.00012H5.41081C4.03143 3.00141 2.88383 4.2386 3.00942 5.62559C2.99999 17.58 12.3334 27 24.3686 26.9906C25.763 27.1164 27.0049 25.9631 26.9993 24.5819Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

// Figma: Decline Call