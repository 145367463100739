export const getContact = (useruuid, searchList) =>
  searchList.find((item) => item.useruuid === useruuid);

/**
 * lookup by useruuid to get the user info from available list
 */
export const getContacts = (invitedUseruuids, searchList) => {
  let invitedContacts = Array.from(new Set(invitedUseruuids));
  invitedContacts = invitedContacts.map(
    (useruuid) => getContact(useruuid, searchList) || { useruuid }
  );
  return invitedContacts;
};
