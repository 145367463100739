import React from "react";

export const AddIcon = (props) => {
    const { className = "", style = {}, disabled = false, onClick } = props;
    return (
        <svg
            className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
            onClick={disabled ? null: onClick}
            width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg"
            style={style}
        >
            <path d="M7.67225 14.0076C6.65818 14.0076 5.83612 14.8297 5.83612 15.8438C5.83612 16.8578 6.65818 17.6799 7.67225 17.6799H7.71131L13.664 17.7189L13.664 23.6326C13.6425 24.6435 14.46 25.4775 15.4711 25.4882C16.4926 25.4989 17.3363 24.6739 17.3363 23.6522V23.6326L17.3363 17.6408L23.289 17.6799C24.2967 17.7013 25.1251 16.8903 25.1251 15.8824L25.1251 15.8051C25.1251 14.7972 24.2967 13.9862 23.289 14.0076L17.3363 14.0467L17.3363 8.0549V8.03527C17.3363 7.01365 16.4926 6.18859 15.4711 6.19935C14.46 6.20999 13.6425 7.04405 13.664 8.0549L13.664 13.9686L7.71131 14.0076H7.67225Z" />
        </svg>        
    );
}