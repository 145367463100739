import React from "react";

export const EditIcon = (props) => {
    const { className = "", style={}, onClick, disabled=false } = props;
    return (
        <svg 
            className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
            onClick={disabled ? null: onClick}
            xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none"
            style={style}
        >
            <path className="stroke" d="M1 18.9958V24.533H6.64706L20.7647 10.69L25 6.53717L19.3529 1L15.1176 5.15288L1 18.9958Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroke" d="M20.7648 10.6899L15.1177 5.15271" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}


